import { Box, Button, Typography, styled } from '@mui/material';
import { useSetAtom } from 'jotai';
import React from 'react';
import { PiCalendarPlusFill, PiMartiniFill, PiPrinterFill, PiUserGearFill } from 'react-icons/pi';

import { isPrintScheduleModalOpenAtom, isStaffVacationModalOpenAtom } from '../../atom';

const QuickActionButtonStyled = styled(Button)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '140px',
    gap: '8px',
    padding: '12px 16px',
    backgroundColor: theme.palette.primary[500],
    textWrap: 'wrap',
    '&:hover': {
        backgroundColor: theme.palette.primary[600] as string,
    },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
    width: '44px',
    display: 'flex',
    aspectRatio: 1,
    backgroundColor: theme.palette.primary[400] as string,
    borderRadius: '24px',
}));

const PrintIcon = styled(PiPrinterFill)(() => ({
    margin: 'auto',
    width: '20px',
    height: '20px',
    paddingInline: '2px',
    color: 'white',
}));
const MartiniIcon = styled(PiMartiniFill)(() => ({
    margin: 'auto',
    width: '20px',
    height: '20px',
    paddingInline: '2px',
    color: 'white',
}));
const CalendarPlusIcon = styled(PiCalendarPlusFill)(() => ({
    margin: 'auto',
    width: '20px',
    height: '20px',
    paddingInline: '2px',
    color: 'white',
}));
const UserIcon = styled(PiUserGearFill)(() => ({
    margin: 'auto',
    width: '20px',
    height: '20px',
    paddingInline: '2px',
    color: 'white',
}));

type QuickActionButtonProps = {
    icon: React.ReactNode;
    title: string;
    onClick: () => void;
};

const QuickActionButton = ({ icon, title, onClick }: QuickActionButtonProps) => {
    return (
        <QuickActionButtonStyled onClick={onClick}>
            <IconWrapper>{icon}</IconWrapper>
            <Typography fontSize="14px" fontWeight={700} color="white" lineHeight="20px" textAlign="center">
                {title}
            </Typography>
        </QuickActionButtonStyled>
    );
};

const SHOULD_SHOW_WORK_RESTRICTION = false;
const SHOULD_SHOW_NEXT_SCHEDULE = false;

const QuickActions = () => {
    const toggleStaffVacationModal = useSetAtom(isStaffVacationModalOpenAtom);
    const togglePrintScheduleModal = useSetAtom(isPrintScheduleModalOpenAtom);

    return (
        <Box display="flex" gap="12px">
            <QuickActionButton title="Print Schedule" icon={<PrintIcon />} onClick={togglePrintScheduleModal} />
            <QuickActionButton title="Add Staff Vacation" icon={<MartiniIcon />} onClick={toggleStaffVacationModal} />
            {SHOULD_SHOW_WORK_RESTRICTION && (
                <QuickActionButton title="Add Staff Work Restriction" icon={<UserIcon />} onClick={() => {}} />
            )}
            {SHOULD_SHOW_NEXT_SCHEDULE && (
                <QuickActionButton title="Publish Next Schedule" icon={<CalendarPlusIcon />} onClick={() => {}} />
            )}
        </Box>
    );
};

export default QuickActions;
