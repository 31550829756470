import { ZONES_DEFAULT_STATE } from '~/constants/redux';
import actionTypes from '~/redux/actionTypes';
import { ZonesState } from '~/types/redux';

const zones = (state: ZonesState = ZONES_DEFAULT_STATE, action) => {
    switch (action.type) {
        case actionTypes.ZONE_LOADING: {
            return {
                ...state,
                loading: action.payload,
            };
        }
        case actionTypes.ZONE_READ_LIST: {
            return {
                ...state,
                zonesList: action.payload,
            };
        }
        default:
            return state;
    }
};

export default zones;
