import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

type AlertStorageKeys = 'vacation' | 'scheduled_hours';

type AtomState = { expiresAt: string | null };

type HookOptions = {
    expiresAfter?: DateTime;
};

export default function useDismissibleAlert(
    storageKey: AlertStorageKeys,
    { expiresAfter }: HookOptions = { expiresAfter: DateTime.now().endOf('day') }
) {
    const atom = useMemo(
        () => atomWithStorage<AtomState>(`scheduling_Schedule_dismissible_alerts_${storageKey}`, { expiresAt: null }),
        [storageKey]
    );

    const [{ expiresAt }, setClose] = useAtom(atom);

    const closeAlert = () => {
        setClose({ expiresAt: expiresAfter ? expiresAfter.toString() : DateTime.now().endOf('day').toString() });
    };

    return {
        show: !expiresAt || DateTime.fromISO(expiresAt) < DateTime.now(),
        closeAlert,
    };
}
