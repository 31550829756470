import { Box, Skeleton, Typography } from '@mui/material';
import React from 'react';

import { useGetCommunitySettings } from '~/scheduling/api/queries/community-settings/getCommunitySettings';
import { useGetScheduledHours } from '~/scheduling/api/queries/reports/useGetScheduledHours';

import { usePeriod } from '../../usePeriod';

type CardProps = {
    hours: number;
    header: string;
    description: string;
    isLoading?: boolean;
};

const Card = (props: CardProps) => {
    if (props.isLoading) {
        return <Skeleton component="div" width="100%" height="120px" />;
    }

    return (
        <Box p="20px" borderRadius="8px" bgcolor="white" width="100%" height="100%">
            <Typography variant="body1" color="grey.500" textTransform="uppercase" fontWeight={600}>
                {props.header}
            </Typography>
            <Box height="16px" width="100%" />
            <Typography variant="h3" fontWeight={700} fontSize="32px">
                {props.hours}hr
            </Typography>
            <Box height="8px" width="100%" />
            <Typography variant="body1" color="grey.400" fontWeight={400} lineHeight="16px" fontSize="12px">
                {props.description}
            </Typography>
        </Box>
    );
};

const HourCards = () => {
    const { data: communitySettings, isLoading: isGetCommunitySettingsLoading } = useGetCommunitySettings();
    const { period } = usePeriod();
    const { data, isLoading } = useGetScheduledHours({ period, firstDayOfWeek: communitySettings?.firstDayOfWeek });

    const description =
        !data || data.budgetedHours === data.scheduledHours
            ? ''
            : data.budgetedHours < data.scheduledHours
              ? "You're over the budgeted hours, please decrease the caregiver staffs"
              : "You're under the budgeted hours, please increase the caregiver staffs";

    return (
        <Box display="flex" justifyContent="space-between" gap="24px">
            <Card
                isLoading={isLoading || isGetCommunitySettingsLoading}
                header="scheduled hours"
                hours={data?.scheduledHours ?? 0}
                description={description}
            />
            <Card
                isLoading={isLoading || isGetCommunitySettingsLoading}
                header="budgeted hours"
                hours={data?.budgetedHours ?? 0}
                description={description}
            />
        </Box>
    );
};

export default HourCards;
