import { ThemeOptions, createTheme } from '@mui/material';

import components from './components';
import palette from './palette';
import typography from './typography';

const theme = createTheme({
    components,
    palette,
    typography,
} as ThemeOptions); // Casting needed while we have overrides at /components/theme/index.ts

export default theme;
