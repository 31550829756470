import { Box, Typography, styled, useTheme } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { DateTime } from 'luxon';
import React from 'react';

import { GetRoles } from '~/scheduling/api/types/staff-roles/getRoles';

import { isChangeBudgetDialogOpenAtom, slotToHaveBudgetChangedAtom } from '../../atom';
import { budgetRecommendationListAtom } from '../../testData';
import { StaffOptimizations } from '../../types';
import { useUpdateBudgets } from '../../useUpdateBudgets';

import { ChangeBudgetDialog } from './ChangeBudgetDialog';
import { HighlightedBox } from './HighlightedBox';

interface PillProps {
    actionType?: StaffOptimizations.RecommendationActionType;
    amount: number;
    roleName: string;
}

const Pill = (props: PillProps) => {
    const { palette } = useTheme();

    return (
        <Box
            display="flex"
            gap="4px"
            padding="2px 8px"
            borderRadius="10px"
            justifyContent="center"
            bgcolor={
                (props.actionType
                    ? props.actionType === 'increase'
                        ? palette.primary[400]
                        : palette.error[400]
                    : palette.grey[300]) as string
            }
        >
            <Typography variant="body1" color="white" fontSize="11px" lineHeight="16px" fontWeight={600}>
                {props.amount > 0 ? `+${props.amount}` : props.amount}
            </Typography>
            <Typography variant="body1" color="white" fontSize="11px" lineHeight="16px" fontWeight={400}>
                {props.roleName}
            </Typography>
        </Box>
    );
};

const BudgetSlotStyled = styled(Box)<{ $action?: StaffOptimizations.RecommendationActionType }>(
    ({ theme, $action }) => ({
        flex: 1,
        width: '100%',
        textAlign: 'center',
        backgroundColor:
            $action === 'increase'
                ? theme.palette.primary[50]
                : $action === 'decrease'
                  ? theme.palette.error[50]
                  : theme.palette.grey[25],
        borderColor: theme.palette.grey[100],
        borderStyle: 'solid',
        borderWidth: 0,
        position: 'relative',
        cursor: 'pointer',
    })
);

interface BudgetSlotProps {
    day: DateTime;
    shift: GetRoles.StaffRoleShift;
    slot: StaffOptimizations.Slot;
    dayIndex: number;
    styles?: React.CSSProperties;
}

export const BudgetSlot = (props: BudgetSlotProps) => {
    const { palette } = useTheme();
    const budgetRecommendationList = useAtomValue(budgetRecommendationListAtom);
    const { getSlotModifications } = useUpdateBudgets();
    const toggle = useSetAtom(isChangeBudgetDialogOpenAtom);
    const [slotToHaveBudgetChanged, setSlotToHaveBudgetChanged] = useAtom(slotToHaveBudgetChangedAtom);

    const recommendation = React.useMemo(() => {
        const recommendations = budgetRecommendationList.flatMap(({ recommendations, ...item }) =>
            recommendations.map((recommendation) => ({ recommendation, ...item }))
        );

        return recommendations.find(
            (item) =>
                item.location.id === props.slot.location.id &&
                +item.recommendation.day === +props.day &&
                item.recommendation.shift.id === props.shift.id
        );
    }, [props, budgetRecommendationList]);

    const { budgets, appliedChanges } = React.useMemo(() => {
        const changes = getSlotModifications(props);

        return props.slot.budgets.reduce<{ budgets: number; appliedChanges: { roleName: string; amount: number }[] }>(
            ({ budgets, appliedChanges }, budget) => {
                const change = changes.find((change) => change.roleId === budget.role.id);
                const hasChangedBudget = change && change.budget !== budget.budget;

                if (hasChangedBudget) {
                    appliedChanges.push({ roleName: budget.role.name, amount: change.budget - budget.budget });
                }

                return {
                    budgets: budgets + (hasChangedBudget ? change.budget : budget.budget),
                    appliedChanges,
                };
            },
            { budgets: 0, appliedChanges: [] }
        );
    }, [props, getSlotModifications]);

    const handleClick = () => {
        toggle();
        setSlotToHaveBudgetChanged({ slot: props.slot, shift: props.shift, day: props.day });
    };

    return (
        <BudgetSlotStyled sx={props.styles} $action={recommendation?.action} onClick={handleClick}>
            <HighlightedBox
                shiftId={props.shift.id}
                locationId={props.slot.location.id}
                day={props.day}
                action={recommendation?.action}
            >
                <Typography
                    variant="body1"
                    lineHeight="16px"
                    fontSize="16px"
                    textAlign="center"
                    fontWeight={700}
                    color={
                        (recommendation?.action === 'increase'
                            ? palette.primary[600]
                            : recommendation?.action === 'decrease'
                              ? palette.error[600]
                              : palette.grey[300]) as string
                    }
                >
                    {props.slot.location.abbreviation} ({budgets})
                </Typography>
                <Box display="flex" flexDirection="column" gap="2px">
                    {appliedChanges.map((change) => (
                        <Pill key={change.roleName} {...change} />
                    ))}
                    {!!recommendation && (
                        <Pill
                            actionType={recommendation.action}
                            amount={
                                recommendation.recommendation.quantity * (recommendation.action === 'increase' ? 1 : -1)
                            }
                            roleName={recommendation.role.name}
                        />
                    )}
                </Box>
            </HighlightedBox>
            {slotToHaveBudgetChanged?.shift.name === props.shift.name &&
                slotToHaveBudgetChanged.slot.location.id === props.slot.location.id &&
                slotToHaveBudgetChanged.day.hasSame(props.day, 'day') && (
                    <ChangeBudgetDialog pushToTheLeft={props.dayIndex <= 1} />
                )}
        </BudgetSlotStyled>
    );
};
