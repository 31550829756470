import { api } from '~/api';
import { useV4ErrorHandledQuery } from '~/hooks/useErrorHandledQuery';

export const useLiveUpdateTimeoutThresholdQuery = (enabled?: boolean) => {
    return useV4ErrorHandledQuery(
        ['live-update'],
        async () => {
            const { data } = await api.get<{ response: { timeout: number } }>(`/live-update/timeout-threshold`);

            return data.response;
        },
        {
            enabled,
            staleTime: 30000,
        }
    );
};
