import { Box, Button, CircularProgress, Skeleton, Stack, Typography, styled } from '@mui/material';
import { useAtomValue } from 'jotai';
import range from 'lodash/range';
import { DateTime } from 'luxon';
import Pluralize from 'pluralize';
import React from 'react';
import { PiDownloadSimple } from 'react-icons/pi';

import { downloadBlob } from '~/lib/file';
import {
    useExportStaffingRequirementsQuery,
    useGetStaffingRequirements,
} from '~/scheduling/api/queries/dashboard/getStaffingRequirements';
import { teamIdAtom } from '~/scheduling/atoms';

import Table, { ColumnType, RowCellWrapper } from './Table';

type CellType = 'date' | 'actionNeeded' | 'dayShifts' | 'nightShift' | 'total';

const Numerator = styled(Typography)<{ needsAttention: boolean }>(({ theme, needsAttention }) => ({
    color: needsAttention ? theme.palette.error[600] : theme.palette.primary[500],
    fontWeight: needsAttention ? 600 : 400,
    display: 'inline-block',
}));

const Ratio = ({ numerator, denominator }: { numerator: number; denominator: number }) => (
    <>
        <Numerator needsAttention={numerator < denominator}>{numerator}</Numerator>
        <span>
            {` / `}
            {denominator}
        </span>
    </>
);

const Cell = ({
    cellType,
    type,
    shiftDay,
    numerator,
    denominator,
    missingDayShiftsHours,
    missingNightShiftHours,
}: {
    cellType: CellType;
    shiftDay?: string;
    numerator?: number;
    denominator?: number;
    missingDayShiftsHours?: number;
    missingNightShiftHours?: number;
    type: ColumnType;
    value: string;
}) => {
    const shiftDate = shiftDay ? DateTime.fromISO(shiftDay) : null;

    return (
        <RowCellWrapper type={type} exceedsThreshold={false}>
            {cellType === 'date'
                ? shiftDate!.hasSame(DateTime.now(), 'day')
                    ? 'Today'
                    : shiftDate!.toFormat('MMM. d, yyyy')
                : null}
            {cellType === 'actionNeeded' ? (
                <>
                    {missingDayShiftsHours ? (
                        <Box sx={{ textTransform: 'none' }}>
                            Add {Pluralize('hour', missingDayShiftsHours, true)} to AM + PM
                        </Box>
                    ) : null}
                    {missingNightShiftHours ? (
                        <Box sx={{ textTransform: 'none' }}>
                            Add {Pluralize('hour', missingNightShiftHours, true)} to NOC
                        </Box>
                    ) : null}
                </>
            ) : null}
            {cellType === 'dayShifts' ? <Ratio numerator={numerator!} denominator={denominator!} /> : null}
            {cellType === 'nightShift' ? <Ratio numerator={numerator!} denominator={denominator!} /> : null}
            {cellType === 'total' ? <Ratio numerator={numerator!} denominator={denominator!} /> : null}
        </RowCellWrapper>
    );
};

const StaffingRequirementTable = () => {
    const teamId = useAtomValue(teamIdAtom);
    const { data, isPending, error } = useGetStaffingRequirements(teamId);
    const { refetch: exportStaffingRequirements } = useExportStaffingRequirementsQuery(teamId);
    const [isExporting, setIsExporting] = React.useState(false);

    const headers = ['Date', 'Action Needed', 'AM + PM', 'NOC', 'Total Scheduled Hours'];

    const tableRows = React.useMemo(
        () =>
            data
                // order from the next 7 days to the past 7 days
                ?.sort((a, b) => DateTime.fromISO(b.shiftDay).valueOf() - DateTime.fromISO(a.shiftDay).valueOf())
                .map((row) => [
                    { value: `${row.shiftDay}`, cellType: 'date', shiftDay: row.shiftDay },
                    {
                        value: `${row.total.missingHours}`,
                        cellType: 'actionNeeded',
                        missingDayShiftsHours: row.dayShifts.missingHours,
                        missingNightShiftHours: row.nightShift.missingHours,
                    },
                    {
                        value: `${row.dayShifts.missingHours}`,
                        cellType: 'dayShifts',
                        numerator: row.dayShifts.scheduledHours,
                        denominator: row.dayShifts.requiredHours,
                    },
                    {
                        value: `${row.nightShift.missingHours}`,
                        cellType: 'nightShift',
                        numerator: row.nightShift.scheduledHours,
                        denominator: row.nightShift.requiredHours,
                    },
                    {
                        value: `${row.total.missingHours}`,
                        cellType: 'total',
                        numerator: row.total.scheduledHours,
                        denominator: row.total.requiredHours,
                    },
                ]) ?? [],
        [data]
    );

    if (error) {
        return null;
    }

    const handleExport = async () => {
        setIsExporting(true);

        try {
            const response = await exportStaffingRequirements();

            const currentDate = DateTime.now().toFormat('yyyy-MM-dd');
            const csvBlob = new Blob([response.data!], { type: 'text/csv' });

            downloadBlob(csvBlob, `staffing-requirements-${currentDate}.csv`);
        } finally {
            setIsExporting(false);
        }
    };

    const startDate = DateTime.now().minus({ days: 7 });
    const endDate = DateTime.now().plus({ days: 7 });
    const dateRange = `${startDate.toFormat('MMM. d')} - ${endDate.toFormat('MMM. d, yyyy')}`;

    return (
        <Box sx={{ p: '24px', backgroundColor: '#fff', borderRadius: '8px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <Typography variant="body1" fontWeight={700} fontSize="16px">
                        Scheduled vs. Minimum Required
                    </Typography>
                    <Typography variant="body1" fontWeight={400}>
                        Based on past and next 7 days {dateRange}
                    </Typography>
                </Box>
                <Button
                    variant="outlined"
                    onClick={handleExport}
                    sx={{ display: 'flex', gap: '8px' }}
                    disabled={isExporting}
                >
                    {isExporting ? <CircularProgress size={16} /> : <PiDownloadSimple />}
                    <Typography variant="body1" fontWeight={600}>
                        Download Full View (CSV)
                    </Typography>
                </Button>
            </Box>
            <Box sx={{ height: '24px', backgroundColor: '#fff' }} />
            {isPending ? (
                <Stack spacing="8px">
                    {range(5).map((i) => (
                        <Skeleton key={i} width="100%" height="40px" />
                    ))}
                </Stack>
            ) : (
                <Table
                    schema={['neutralHeader', 'alert', 'neutralHeader', 'neutralHeader', 'neutralHeader']}
                    headers={headers}
                    data={tableRows}
                    CellComponent={Cell}
                />
            )}
        </Box>
    );
};

export default StaffingRequirementTable;
