import { Box, Button, CircularProgress, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';

import { pxToRem } from '~/components/theme/typography';

type Props = {
    onCancel: () => void;
    onConfirm: () => void;
    residentFirstName: string;
    completedTasksNumber: number;
    loading: boolean;
};

const UnscheduledTasksConfirmation = ({
    onConfirm,
    onCancel,
    residentFirstName,
    completedTasksNumber,
    loading,
}: Props) => {
    const isMobile = useMediaQuery('(max-width:1024px)');

    const dialogStyles = {
        '& .MuiDialog-container': {
            alignItems: isMobile ? 'flex-end' : 'center',
        },
        '#dialog-title': {
            padding: 0,
            marginBottom: pxToRem(4),
            marginTop: isMobile ? 0 : pxToRem(24),
        },
        '#dialog-content': {
            marginLeft: pxToRem(24),
            marginRight: pxToRem(24),
            padding: 0,
        },
        '& .MuiDialog-container .MuiPaper-root.MuiDialog-paper': {
            margin: 0,
            borderRadius: 0,
            padding: pxToRem(24),
            width: '100%',
            maxWidth: 'max-content',
        },
    };

    const confirmTasksNumberAndResidentNameText =
        completedTasksNumber > 1
            ? `Please confirm you completed all ${completedTasksNumber} tasks for ${residentFirstName}.`
            : `Please confirm you completed this task for ${residentFirstName}.`;

    return (
        <Box sx={dialogStyles}>
            <DialogTitle id="dialog-title">
                <Typography
                    sx={{
                        fontWeight: 700,
                        fontSize: `${pxToRem(20)} !important`,
                        lineHeight: pxToRem(32),
                        textAlign: 'center',
                        color: '#2C2D3B',
                    }}
                >
                    To Confirm...
                </Typography>
            </DialogTitle>
            <DialogContent id="dialog-content">
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: pxToRem(14),
                        textAlign: 'center',
                        color: '#6F6F79',
                        lineHeight: pxToRem(24),
                    }}
                >
                    {`The PRN/unscheduled care tasks are going on ${residentFirstName}'s clinical record and are reviewed by staff. `}
                    <Box component="span" fontWeight="bold">
                        {confirmTasksNumberAndResidentNameText}
                    </Box>
                </Typography>
            </DialogContent>
            <DialogActions sx={{ padding: 0, marginTop: pxToRem(24), marginBottom: pxToRem(16) }}>
                {loading ? (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Button
                            onClick={onCancel}
                            sx={{
                                color: '#6F6F79',
                                borderColor: '#6F6F79',
                                paddingTop: pxToRem(12),
                                paddingBottom: pxToRem(12),
                                flex: 1,
                            }}
                            variant="outlined"
                            size="large"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={onConfirm}
                            sx={{
                                color: '#006B75',
                                borderColor: '#006B75',
                                paddingTop: pxToRem(12),
                                paddingBottom: pxToRem(12),
                                flex: 1,
                            }}
                            variant="outlined"
                            size="large"
                        >
                            Confirm
                        </Button>
                    </>
                )}
            </DialogActions>
        </Box>
    );
};

export default UnscheduledTasksConfirmation;
