import { atom } from 'jotai';
import { DateTime } from 'luxon';

import { ECall } from './types.d';

const CALLED_AT = DateTime.now().minus({ seconds: 30, minutes: 6 });
const STARTED_AT = DateTime.now().minus({ seconds: 15, minutes: 2 });

const callsMock: ECall.Call[] = [
    {
        id: 3,
        calledAt: CALLED_AT,
        resident: {
            id: 107,
            firstName: 'Michelle',
            lastName: 'Collinsworth',
            roomNumber: '204',
            photo: '107.jpeg',
        },
        status: ECall.CallStatus.Unclaimed,
        unclaimedLevel: ECall.UnclaimedLevel.Level3,
        suggestedLocations: ['Dining Hall', 'Room 205'],

        claimedCaregiver: null,
    },
    {
        id: 2,
        calledAt: CALLED_AT,
        resident: {
            id: 106,
            firstName: 'Elenor',
            lastName: 'Thompson',
            roomNumber: '208',
            photo: '106.jpeg',
        },
        status: ECall.CallStatus.Unclaimed,
        unclaimedLevel: ECall.UnclaimedLevel.Level1,
        suggestedLocations: ['Dining Room', 'Room 209'],

        claimedCaregiver: null,
    },
    {
        id: 1,
        calledAt: CALLED_AT,
        resident: {
            id: 109,
            firstName: 'Beatrice',
            lastName: 'Simmons',
            roomNumber: '218',
            photo: '109.jpeg',
        },
        status: ECall.CallStatus.InProgress,
        unclaimedLevel: ECall.UnclaimedLevel.Level2,
        suggestedLocations: ['Dining Hall', 'Room 219'],

        claimedCaregiver: {
            id: 1019,
            firstName: 'Stephen',
            lastName: 'Morrison',
        },
    },
    {
        id: 4,
        calledAt: CALLED_AT,
        resident: {
            id: 99,
            firstName: 'Evelyn',
            lastName: 'Reynolds',
            roomNumber: '201',
            photo: '119.jpeg',
        },
        status: ECall.CallStatus.ResidentReached,
        startedAt: DateTime.now(),
        unclaimedLevel: ECall.UnclaimedLevel.Level1,
        suggestedLocations: ['Dining Hall', 'Room 198'],

        claimedCaregiver: {
            id: 1019,
            firstName: 'Stephen',
            lastName: 'Morisson',
        },
    },
    {
        id: 5,
        calledAt: CALLED_AT,
        resident: {
            id: 103,
            firstName: 'Irene',
            lastName: 'Williams',
            roomNumber: '203',
            photo: '110.jpeg',
        },
        status: ECall.CallStatus.Claimed,
        unclaimedLevel: ECall.UnclaimedLevel.Level3,
        suggestedLocations: ['Dining Hall', 'Room 205'],

        claimedCaregiver: {
            id: 1018,
            firstName: 'Jessie',
            lastName: 'Lucci',
        },
    },
    {
        id: 6,
        calledAt: CALLED_AT,
        startedAt: STARTED_AT,
        resident: {
            id: 108,
            firstName: 'Walter',
            lastName: 'Davis',
            roomNumber: '209',
            photo: '108.jpeg',
        },
        status: ECall.CallStatus.InProgress,
        unclaimedLevel: ECall.UnclaimedLevel.Level3,
        suggestedLocations: ['Dining Hall', 'Room 198'],

        claimedCaregiver: {
            id: 1020,
            firstName: 'Jane',
            lastName: 'Doe',
        },
    },
];

export const callsAtom = atom(callsMock);
