import { Box } from '@mui/material';
import React from 'react';

import { pxToRem } from '~/components/theme/typography';

import { PRIVACY_POLICY } from './privacyPolicy';

const PrivacyPolicy = () => <Box sx={{ p: pxToRem(32) }} dangerouslySetInnerHTML={{ __html: PRIVACY_POLICY }} />;

export default PrivacyPolicy;
