import { Box, Link, Typography, styled, useTheme } from '@mui/material';
import React from 'react';
import { FiArrowUpRight } from 'react-icons/fi';
import { Link as RouterLink } from 'react-router-dom';

export const Row = styled(Box)<{ header?: boolean }>(({ theme, header: isHeader }) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'stretch',
    justifyContent: 'center',
    borderBottom: isHeader ? 'none' : `1px solid ${theme.palette.grey[200]}`,
}));

export const Header = styled(Typography)(({ theme }) => ({
    flex: 1,
    minHeight: '44px',
    padding: '12px 16px',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    backgroundColor: theme.palette.primary[50],
    color: theme.palette.primary[500],
    textWrap: 'balance',
}));

export const Cell = styled(Header)(({ theme }) => ({
    fontWeight: 400,
    backgroundColor: 'transparent',
    color: theme.palette.grey[900],
}));

type TableProps = {
    headers: string[] | React.ReactNode;
    children: React.ReactNode;
};

export const Table = (props: TableProps) => {
    return (
        <Box sx={{ width: '100%', backgroundColor: '#fff' }}>
            {!Array.isArray(props.headers) ? (
                props.headers
            ) : (
                <Row header>
                    {props.headers.map((value) => (
                        <Header key={value}>{value}</Header>
                    ))}
                </Row>
            )}
            {props.children}
        </Box>
    );
};

const ArrowUpRight = styled(FiArrowUpRight)(({ theme }) => ({
    color: theme.palette.primary[500],
    width: '16px',
    height: '16px',
    marginLeft: '4px',
}));

type TableLinkProps = {
    type?: 'primary' | 'secondary';
    href: string;
    children: React.ReactNode;
};

export const TableLink = ({ type = 'primary', href, children }: TableLinkProps) => {
    const { palette } = useTheme();

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <Link
                component={RouterLink}
                variant="body1"
                to={href}
                sx={{
                    fontWeight: 600,
                    textDecoration: 'underline',
                    color: (type === 'primary' ? palette.primary[500] : palette.secondary.main) as string,
                }}
            >
                {children}
            </Link>
            <ArrowUpRight
                sx={{
                    color: ((type ?? 'primary') === 'primary'
                        ? palette.primary[500]
                        : palette.secondary.main) as string,
                }}
            />
        </Box>
    );
};
