import { v4QueryClient } from '.';
import { useMutation } from '@tanstack/react-query-v4';

import { api } from '~/api';
import { useV4ErrorHandledQuery } from '~/hooks/useErrorHandledQuery';
import { useToken } from '~/lib/common';
import { UserRewardsDetailsResponse, UserRewardsHistoryResponse, UserRewardsSummaryResponse } from '~/types/myRewards';

export const useRewardsSummaryQuery = (userId?: number, branchId?: number) => {
    const token = useToken();

    return useV4ErrorHandledQuery(
        ['rewards', userId, 'summary'],
        async (): Promise<UserRewardsSummaryResponse> =>
            (
                await api.get('/rewards/user/summary', {
                    headers: {
                        authorization: token,
                    },
                    params: { branch_id: branchId }, // For permission checking
                })
            ).data.response,
        { enabled: !!userId && !!branchId, staleTime: 300000 }
    );
};

export const useRewardsDetailsQuery = (userId?: number, branchId?: number) => {
    const token = useToken();

    return useV4ErrorHandledQuery(
        ['rewards', userId, 'details'],
        async (): Promise<UserRewardsDetailsResponse> =>
            (
                await api.get('/rewards/user/details', {
                    headers: {
                        authorization: token,
                    },
                    params: { branch_id: branchId }, // For permission checking
                })
            ).data.response,
        { enabled: !!userId && !!branchId, staleTime: 300000 }
    );
};

export const useRewardsHistoryQuery = (userId?: number, branchId?: number) => {
    const token = useToken();

    return useV4ErrorHandledQuery(
        ['rewards', userId, 'history'],
        async (): Promise<UserRewardsHistoryResponse> =>
            (
                await api.get('/rewards/user/history', {
                    headers: {
                        authorization: token,
                    },
                    params: { branch_id: branchId }, // For permission checking
                })
            ).data.response,
        { enabled: !!userId && !!branchId, staleTime: 300000 }
    );
};

export const useClaimPointsMutation = (userId: number, branchId: number) => {
    const token = useToken();

    return useMutation({
        mutationFn: async () => {
            await api.post(
                '/rewards/user/claim-points',
                {},
                {
                    headers: {
                        authorization: token,
                    },
                    params: { branch_id: branchId }, // For permission checking
                }
            );
        },
        onSuccess: async () => {
            await v4QueryClient.invalidateQueries({
                queryKey: ['rewards', userId],
                exact: false,
                refetchType: 'active',
            });
        },
    });
};

export const useRedeemPointsMutation = (userId: number, branchId: number) => {
    const token = useToken();

    return useMutation({
        mutationFn: async () => {
            await api.post(
                '/rewards/user/redeem-reward',
                {},
                {
                    headers: {
                        authorization: token,
                    },
                    params: { branch_id: branchId }, // For permission checking
                }
            );
        },
        onSuccess: async () => {
            await v4QueryClient.invalidateQueries({
                queryKey: ['rewards', userId],
                exact: false,
                refetchType: 'active',
            });
        },
    });
};

export const useCheckInMutation = (userId: number, branchId: number) => {
    const token = useToken();

    return useMutation({
        mutationFn: async () => {
            await api.post(
                '/rewards/user/check-in',
                {},
                {
                    headers: {
                        authorization: token,
                    },
                    params: { branch_id: branchId }, // For permission checking
                }
            );
        },
        onSuccess: async () => {
            await v4QueryClient.invalidateQueries({
                queryKey: ['rewards', userId],
                exact: false,
                refetchType: 'active',
            });
        },
    });
};
