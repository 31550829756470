import { ComponentOverride } from '.';

export const MuiSwitch: ComponentOverride<'MuiSwitch'> = {
    styleOverrides: {
        root: ({ theme: { palette } }) => ({
            height: 20,
            width: 36,
            padding: 0,
            borderRadius: 100,

            // Thumb position
            '& .MuiSwitch-switchBase': {
                top: 2,
                left: 2,
                padding: 0,

                '&.Mui-checked': {
                    color: '#fff',
                    transform: 'translateX(16px)',
                },
            },

            // Thumb
            '& .MuiSwitch-thumb': {
                width: 16,
                height: 16,
            },

            // Unchecked track
            '& .MuiSwitch-track': {
                backgroundColor: palette.grey[200],
                opacity: 'unset',
            },

            // Checked track
            '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
                backgroundColor: palette.primary[500] as string,
                opacity: 'unset',
            },
        }),
    },
};
