import { DAILY_TASKS_DEFAULT_STATE } from '~/constants/redux';
import actionTypes from '~/redux/actionTypes';
import { Action, DailyTasksState } from '~/types/redux';

const dailyTasks = (state: DailyTasksState = DAILY_TASKS_DEFAULT_STATE, action: Action) => {
    switch (action.type) {
        case actionTypes.DAILY_TASKS_LOADING: {
            return {
                ...state,
                loading: action.payload,
            };
        }
        default:
            return state;
    }
};

export default dailyTasks;
