import { Box, SxProps, Theme } from '@mui/material';
import React, { ReactNode } from 'react';

import authBgLogo from '~/assets/auth_bg_logo.svg';
import { pxToRem } from '~/components/theme/typography';

type Props = {
    sx?: SxProps<Theme>;
    children: ReactNode;
};

const MainContainer = ({ sx, children }: Props) => (
    <Box
        sx={{
            position: {
                xs: 'absolute',
                lg: 'fixed',
            },
            inset: 0,
            backgroundImage: {
                xs: 'radial-gradient(at top right, rgba(0,107,117,0.15) 0%, rgba(255,255,255,1) 30%)',
                lg: `url(${authBgLogo})`,
            },
            bgcolor: {
                lg: '#F8FBFB',
            },
            backgroundRepeat: 'no-repeat',
            backgroundPosition: {
                lg: 'left -200px bottom -600px',
            },
            overflowY: {
                lg: 'auto',
            },
            p: {
                xs: pxToRem(24),
                lg: pxToRem(77),
            },
            ...sx,
        }}
    >
        {children}
    </Box>
);

export default MainContainer;
