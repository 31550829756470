import { Box, Button, Typography } from '@mui/material';
import { isAxiosError } from 'axios';
import { useAtom } from 'jotai';
import qs from 'qs';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useLoginInitMutation } from '~/api/queries/auth/login';
import allieLogo from '~/assets/allie_logo.svg';
import AuthButton from '~/components/Auth/AuthButton';
import AuthCtaLink from '~/components/Auth/AuthCtaLink';
import AuthDivider from '~/components/Auth/AuthDivider';
import AuthTitle from '~/components/Auth/AuthTitle';
import FormContainer from '~/components/Auth/FormContainer';
import MainContainer from '~/components/Auth/MainContainer';
import PhoneNumberInput from '~/components/Auth/PhoneNumberInput';
import { pxToRem } from '~/components/theme/typography';
import { APP_VERSION } from '~/constants/app';
import PageStructure from '~/pages/PageStructure';

import { ConfirmPhoneNumberModal } from './ConfirmPhoneNumberModal';
import { PhoneDisclaimer } from './PhoneDisclaimer';
import { isConfirmPhoneNumberModalOpenAtom, userPhoneNumberAtom } from './atom';

export type LoginInitFormValues = {
    phoneNumber: string;
};

const Login = () => {
    const navigate = useNavigate();
    const redirect = new URLSearchParams(window.location.search).get('redirect');
    const [isConfirmPhoneNumberModalOpen, toggleConfirmPhoneNumberModal] = useAtom(isConfirmPhoneNumberModalOpenAtom);
    const [userPhoneNumber, setUserPhoneNumber] = useAtom(userPhoneNumberAtom);

    const { mutateAsync: loginInitMutation, isLoading } = useLoginInitMutation();

    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
    } = useForm<LoginInitFormValues>({
        defaultValues: {
            phoneNumber: userPhoneNumber || '',
        },
        mode: 'onChange',
        criteriaMode: 'all',
        reValidateMode: 'onChange',
        delayError: 2000,
    });

    const onSubmit = async (data: LoginInitFormValues) => {
        try {
            const phoneNum = await loginInitMutation({ phoneNumber: data.phoneNumber });
            const params = qs.stringify({ phoneNumber: phoneNum, redirect: redirect ?? undefined });

            navigate(`/login/verify?${params}`, { replace: true });
        } catch (err) {
            if (isAxiosError(err) && err.response?.status === 404 && !isConfirmPhoneNumberModalOpen) {
                // show phone number confimation modal
                toggleConfirmPhoneNumberModal();
            }
        }
    };

    const handleUsernameSignInClick = () => {
        const params = qs.stringify({ redirect: redirect ?? undefined });
        navigate(`/login/username?${params}`, { replace: true });
    };

    return (
        <PageStructure>
            <MainContainer>
                <Box
                    sx={{
                        display: {
                            xs: 'none',
                            lg: 'flex',
                        },
                        justifyContent: 'center',
                    }}
                >
                    <Box component="img" src={allieLogo} alt="AllieHealth Logo" width={pxToRem(176)} />
                </Box>
                <FormContainer>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box
                            sx={{
                                mb: {
                                    lg: pxToRem(44),
                                },
                                pt: 'env(safe-area-inset-top)',
                            }}
                        >
                            <Box
                                component="img"
                                src={allieLogo}
                                alt="AllieHealth Logo"
                                width={pxToRem(144)}
                                sx={{
                                    display: {
                                        lg: 'none',
                                    },
                                }}
                            />
                            <Box
                                sx={{
                                    mt: {
                                        xs: pxToRem(48),
                                        lg: 0,
                                    },
                                    mb: {
                                        xs: pxToRem(36),
                                    },
                                }}
                            >
                                <AuthTitle title="Welcome Back!" subtitle="Sign in now to access your account" />
                            </Box>
                            <PhoneNumberInput
                                name="phoneNumber"
                                label="Phone Number"
                                control={control}
                                placeholder="(555) 555-1234"
                                error={errors.phoneNumber?.message}
                                onChange={setUserPhoneNumber}
                                rules={{
                                    required: 'Phone Number is required',
                                    minLength: {
                                        value: 8,
                                        message: 'Phone Number must be at least 8 characters',
                                    },
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                pb: {
                                    xs: pxToRem(24),
                                    lg: 0,
                                },
                                mt: {
                                    xs: pxToRem(8),
                                    lg: 0,
                                },
                            }}
                        >
                            <PhoneDisclaimer />
                            <AuthButton label="Continue" isDisabled={!isValid} isLoading={isLoading} isSubmit />
                            <AuthDivider />
                            <Button
                                variant="outlined"
                                size="large"
                                onClick={handleUsernameSignInClick}
                                sx={{
                                    color: '#6F6F79',
                                    fontWeight: 400,
                                    mb: pxToRem(48),
                                }}
                                fullWidth
                            >
                                Sign in with Username
                            </Button>
                            <AuthCtaLink
                                question="Don't have an account yet?"
                                linkLabel="Caregiver sign up"
                                linkUrl="signup"
                                isLink
                            />
                            <Typography
                                sx={{
                                    fontSize: {
                                        xs: pxToRem(12),
                                    },
                                    textAlign: 'center',
                                    mt: pxToRem(8),
                                    color: '#6F6F79',
                                }}
                            >{`Version ${APP_VERSION}`}</Typography>
                        </Box>
                    </form>
                </FormContainer>
            </MainContainer>

            <ConfirmPhoneNumberModal />
        </PageStructure>
    );
};

export default Login;
