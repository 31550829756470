import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';

import langDictionary from '~/app-strings';
import celebrateEmoji from '~/assets/celebrate_emoji.png';
import { pxToRem } from '~/components/theme/typography';

type Props = {
    residentName: string;
};

const AVATAR_SIZE = {
    xs: pxToRem(120),
    lg: pxToRem(150),
};

const AllTasksCompletedIndicator = (props: Props) => {
    const { residentName } = props;
    const { residents: residentsDictionary } = langDictionary;

    const personalizedMessage = residentsDictionary.allTasksDone.replace('{name}', residentName);

    return (
        <Box
            sx={{
                mt: pxToRem(18),
                display: 'grid',
                placeItems: 'center',
            }}
        >
            <Avatar
                alt="Task Done"
                src={celebrateEmoji}
                sx={{
                    width: AVATAR_SIZE,
                    height: AVATAR_SIZE,
                    p: {
                        xs: pxToRem(42),
                        lg: pxToRem(50),
                    },
                    backgroundColor: '#EBF3F4',
                    mb: pxToRem(12),
                }}
            />
            <Typography variant="h3" fontSize={{ xs: pxToRem(20), lg: pxToRem(22) }} lineHeight={1.6}>
                {residentsDictionary.congratulations}
            </Typography>
            <Typography
                fontWeight={400}
                fontSize={{ xs: pxToRem(18), lg: pxToRem(20) }}
                lineHeight={1.6}
                textAlign="center"
            >
                {personalizedMessage}
            </Typography>
        </Box>
    );
};

export default AllTasksCompletedIndicator;
