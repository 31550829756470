import { ComponentOverride } from '.';
import { CaretDown } from '@phosphor-icons/react';

export const MuiSelect: ComponentOverride<'MuiSelect'> = {
    defaultProps: {
        IconComponent: CaretDown,
        MenuProps: { PaperProps: { style: { maxHeight: '240px' } } },
    },
    styleOverrides: {
        root: ({ theme: { palette } }) => ({
            backgroundColor: 'white',
            fontWeight: 400,
            borderRadius: 8,
            '& .MuiSelect-icon': {
                color: palette.grey[900],
                fontSize: 16,
                right: 12,
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: palette.grey[100],
            },
        }),
    },
};
