import { Box, CircularProgress } from '@mui/material';
import React from 'react';

import { Center } from '~/components/Shared/Center';
import { CenteredText } from '~/router/components/shared';

type StepProps = {
    isLoading: boolean;
    isError: boolean;
    children: React.ReactNode;
};

export default function PrnFlowStep({ children, isLoading, isError }: Readonly<StepProps>) {
    if (isLoading) {
        return (
            <Center>
                <CircularProgress />
            </Center>
        );
    }

    if (isError) {
        return (
            <Center>
                <CenteredText>An error has occurred while loading results. Please try again later.</CenteredText>
            </Center>
        );
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{
                '-ms-overflow-style': 'none',
                scrollbarWidth: 'none',
            }}
        >
            {children}
        </Box>
    );
}
