import { Box, Button, CircularProgress, Stack, Typography, styled, useTheme } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import React, { useEffect, useMemo, useRef } from 'react';
import { PiArrowRight, PiCalendarBlankFill } from 'react-icons/pi';

import { SHIFT_SLOT_REQUEST_STATUS } from '@allie/utils/src/constants/scheduling/shift-slot.constants';

import { useGetShiftRequests } from '~/scheduling/api/queries/shift-requests/getShiftRequests';
import { useUpdateShiftRequest } from '~/scheduling/api/queries/shift-requests/updateSlotRequest';
import CustomConfetti from '~/scheduling/components/CustomConfetti';

import SegmentedModal from '../../../../../components/SegmentedModal';
import { isReviewOpenShiftsModalOpenAtom, selectedRequestsAtom } from '../../../atom';

import { SlotRequestContent } from './SlotRequestContent';

const IconContainer = styled(Box)(({ theme }) => ({
    width: '56px',
    height: '56px',
    padding: '16px',
    backgroundColor: theme.palette.primary[50],
    borderRadius: '100px',
}));

const EmptyState = ({ isCompleted, onAnimationEnd }: { isCompleted: boolean; onAnimationEnd: () => void }) => {
    const { palette } = useTheme();

    return (
        <Stack alignItems="center" height="240px" justifyContent="center" padding="24px">
            <IconContainer>
                <PiCalendarBlankFill size={24} color={palette.primary[600] as string} />
            </IconContainer>
            <Box height="32px" />
            <Typography color={palette.grey[900]} variant="h5" letterSpacing="unset">
                You are all set!
            </Typography>
            <Typography
                color={palette.grey[500]}
                variant="body1"
                textAlign="center"
                sx={{ textWrap: 'balance' }}
                fontWeight={400}
                marginTop="8px"
            >
                You have approved all shift applications. Great job!
            </Typography>

            <CustomConfetti activate={isCompleted} stop={onAnimationEnd} height={400} />
        </Stack>
    );
};

interface ReviewOpenShiftsModalProps {
    onConfirm: () => Promise<void>;
    onCancel: () => void;
    onSkip?: () => void;
    isLoading: boolean;
    openSlotsAmount: number;
}

const ReviewOpenShiftsActions = ({
    openSlotsAmount,
    isLoading,
    onConfirm,
    onSkip,
    onCancel,
}: ReviewOpenShiftsModalProps) => {
    const { palette } = useTheme();
    const selectedRequestsIds = useAtomValue(selectedRequestsAtom);

    const handleConfirm = async () => {
        await onConfirm();
    };

    const disabled = isLoading || openSlotsAmount !== selectedRequestsIds.length;

    return (
        <>
            <Button variant="outlined" onClick={onCancel} disabled={isLoading}>
                Cancel
            </Button>
            {onSkip && (
                <Button
                    variant="outlined"
                    onClick={onSkip}
                    disabled={isLoading}
                    startIcon={<PiArrowRight color={palette.grey[500]} size={20} />}
                >
                    Skip
                </Button>
            )}
            <Button disabled={disabled} onClick={handleConfirm}>
                {isLoading ? (
                    <CircularProgress size={20} thickness={4} sx={{ color: 'white' }} />
                ) : (
                    'Confirm & Next Shifts'
                )}
            </Button>
        </>
    );
};

interface ReviewOpenShiftsHeaderProps {
    shiftsToHandle: number;
    handledShifts: number;
}

const ReviewOpenShiftsHeader = ({ shiftsToHandle, handledShifts }: ReviewOpenShiftsHeaderProps) => {
    const { palette } = useTheme();

    const progress = shiftsToHandle > 0 ? Math.floor((100 * handledShifts) / shiftsToHandle) : 0;

    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="body1" fontSize="16px" fontWeight={700} lineHeight="36px">
                Review Open Shift Requests
            </Typography>
            <Box display="flex" gap="8px" alignItems="center" color={palette.primary[400] as string}>
                <Box position="relative" height="24px">
                    <CircularProgress
                        sx={{ position: 'absolute', color: 'rgba(0, 107, 117, 0.2)' }}
                        variant="determinate"
                        value={100}
                        size={24}
                        thickness={9}
                    />
                    <CircularProgress variant="determinate" value={progress} size={24} thickness={9} />
                </Box>
                <Typography variant="body1" fontWeight={500} fontSize="14px">
                    <span style={{ fontWeight: 700, fontSize: '16px' }}>
                        {shiftsToHandle ? shiftsToHandle - handledShifts : 0}
                    </span>{' '}
                    Left
                </Typography>
            </Box>
        </Box>
    );
};

const ReviewOpenShiftsModal = () => {
    const [isOpen, toggle] = useAtom(isReviewOpenShiftsModalOpenAtom);
    const [selectedRequestsIds, setSelectedRequestsIds] = useAtom(selectedRequestsAtom);
    const [currentSlotIndex, setCurrentSlotIndex] = React.useState(0);
    const [isCompleted, setIsCompleted] = React.useState(false);

    const scrollableBoxRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        scrollableBoxRef.current?.scrollTo({ top: 0 }); // Scroll to top on every page change
    }, [currentSlotIndex]);

    const { data: slotRequestsData, refetch } = useGetShiftRequests();

    const slotRequests = useMemo(() => slotRequestsData ?? [], [slotRequestsData]);
    const numberOfRequests = slotRequests.length;

    useEffect(() => {
        if (isOpen) {
            void refetch();
        }
    }, [isOpen, refetch]);

    const { mutateAsync: updateSlotRequest, isPending } = useUpdateShiftRequest();

    const currentSlotData = useMemo(() => slotRequests[currentSlotIndex], [slotRequests, currentSlotIndex]);
    const hasOnlyOneRequest = numberOfRequests === 1;

    // if there is only one request, select it as default
    useEffect(() => {
        if (currentSlotData?.requests.length === 1 && !selectedRequestsIds.length) {
            setSelectedRequestsIds([currentSlotData.requests[0].id]);
        }
    }, [currentSlotData]);

    const isMultiSelect = numberOfRequests > 1 && currentSlotData?.openSlotsAmount > 1;

    const handleConfirm = async () => {
        // TODO: Batch update requests
        for (let i = 0; i < (currentSlotData?.openSlotsAmount || 0); i++) {
            await updateSlotRequest({
                requestId: selectedRequestsIds[i],
                data: { status: SHIFT_SLOT_REQUEST_STATUS.APPROVED },
            });
        }

        moveToNextRequest();
    };

    const resetAndClose = () => {
        setSelectedRequestsIds([]);
        setCurrentSlotIndex(0);
        setIsCompleted(false);
        toggle();
    };

    const moveToNextRequest = () => {
        setSelectedRequestsIds([]);
        setCurrentSlotIndex((prev) => prev + 1);
        setIsCompleted(currentSlotIndex + 1 === numberOfRequests);
    };

    const handleSkip = () => {
        // TODO - Handle with no data/empty/loading states instead
        if (!currentSlotData) return;

        moveToNextRequest();
    };

    return (
        <SegmentedModal
            sx={{
                width: 'fit-content',
                maxWidth: 'unset',
                minWidth: '480px',
            }}
            isOpen={isOpen}
            center
            onClose={resetAndClose}
            header={<ReviewOpenShiftsHeader shiftsToHandle={numberOfRequests} handledShifts={currentSlotIndex} />}
            actions={
                currentSlotIndex === numberOfRequests ? (
                    <Box display="flex" justifyContent="flex-end">
                        <Button onClick={resetAndClose}>Done</Button>
                    </Box>
                ) : (
                    <ReviewOpenShiftsActions
                        openSlotsAmount={currentSlotData?.openSlotsAmount}
                        isLoading={isPending}
                        onConfirm={handleConfirm}
                        onCancel={resetAndClose}
                        onSkip={hasOnlyOneRequest ? handleSkip : undefined}
                    />
                )
            }
        >
            <>
                <Box
                    ref={scrollableBoxRef}
                    height="300px"
                    position="relative"
                    sx={{
                        overflowX: 'hidden',
                        overflowY: currentSlotIndex === numberOfRequests ? 'hidden' : 'auto', // Hide scrollbar on 'You are all set!' page
                    }}
                >
                    <Box
                        position="absolute"
                        display="grid"
                        gridTemplateColumns={`repeat(${numberOfRequests + 1}, 432px)`} // modal width minus 48px vertical padding (24px each side)
                        gap="32px"
                        height="calc(100% - 32px)"
                        left={-(currentSlotIndex * 464) + 'px'} // modal width minus half of vertical padding
                        sx={{ transition: 'left 0.5s' }}
                    >
                        {slotRequests.map((shift) => (
                            <SlotRequestContent key={shift.id} shift={shift} isMultiSelect={isMultiSelect} />
                        ))}
                        <EmptyState isCompleted={isCompleted} onAnimationEnd={() => setIsCompleted(false)} />
                    </Box>
                </Box>
            </>
        </SegmentedModal>
    );
};

export default ReviewOpenShiftsModal;
