import { useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

import { CustomSelect } from '~/components/Custom';
import { SelectStructure } from '~/types/inputs';

import { MOBILE_SELECTOR_STYLES, SELECTOR_STYLES } from './constants';

type Props = {
    value: number;
    options: SelectStructure[];
    onChange: (newValue: number) => void;
    isHeader?: boolean;
    fullWidth?: boolean;
};

export const BranchSelector = (props: Props) => {
    const { value, options, onChange, isHeader, fullWidth } = props;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <CustomSelect
            id="branch"
            label="Community"
            value={value}
            options={options}
            onChange={onChange}
            fullWidth={fullWidth}
            sx={{
                ...(isMobile && isHeader ? { ...MOBILE_SELECTOR_STYLES, ...SELECTOR_STYLES } : SELECTOR_STYLES),
            }}
        />
    );
};
