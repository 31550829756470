import { useCallback } from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledMutation, useToken } from '~/api/common';
import { ClaimCall } from '~/types/call/claimCall';

export const useClaimCallMutation = () => {
    const token = useToken();
    const branchId = useBranchId();

    const mutationFn = useCallback(
        async ({ callId }: ClaimCall.Params) => {
            const options = { headers: { Authorization: token } };

            await api.post(`/e-call/${branchId}/call/${callId}/claim`, null, options);
        },
        [token, branchId]
    );

    return useErrorHandledMutation({ mutationFn });
};
