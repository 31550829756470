import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import * as LiveUpdates from '@capacitor/live-updates';
import * as Sentry from '@sentry/react';
import React, { useEffect, useRef, useState } from 'react';

import { useLiveUpdateTimeoutThresholdQuery } from '~/api/queries/live-update';
import Loading from '~/components/Shared/Loading';

const timeoutError = Symbol('Timeout Error');

async function syncOrFail(
    timeoutThreshold: number,
    onSuccess: (result: LiveUpdates.SyncResult) => void | Promise<void>,
    onError: (e: unknown) => void
) {
    const sync = async () => {
        const result = await LiveUpdates.sync();

        void onSuccess(result);
    };

    try {
        return await execWithTimeout(sync, timeoutThreshold, timeoutError);
    } catch (e) {
        onError(e);
    }
}

async function execWithTimeout<T>(
    promise: () => Promise<T>,
    timeoutThreshold: number,
    failReason?: unknown
): Promise<T | undefined> {
    let timer: NodeJS.Timeout | undefined;

    const delay = (): Promise<undefined> =>
        new Promise((_, rej) => {
            timer = setTimeout(rej, timeoutThreshold, failReason);
        });

    try {
        return await Promise.race([promise(), delay()]);
    } finally {
        timer && clearTimeout(timer);
    }
}

const LoadingWrapper = ({ children }: { children: React.ReactNode }) => {
    const isNativePlatform = Capacitor.isNativePlatform();

    const { data: timeoutThreshold, error: getTimeoutThresholdError } =
        useLiveUpdateTimeoutThresholdQuery(isNativePlatform);

    const [isUpdateAvailable, _setIsUpdateAvailable] = useState<boolean | null>(null);
    const isUpdateAvailableRef = useRef(isUpdateAvailable); // Ref is needed to access the state inside the 'resume' listener below
    const gotTimeoutError = useRef(false);

    const setIsUpdateAvailable = (data: boolean) => {
        isUpdateAvailableRef.current = data;
        _setIsUpdateAvailable(data);
    };

    const handleSyncError = (err: unknown) => {
        // don't block if the update fails
        gotTimeoutError.current = true;
        setIsUpdateAvailable(false);
        if (err === timeoutError) {
            Sentry.captureException(`[APPFLOW LIVE UPDATE] ${err.description}`);
        } else {
            Sentry.captureException(err);
        }
    };

    const handleSyncSuccess = async (result: LiveUpdates.SyncResult) => {
        setIsUpdateAvailable(result.activeApplicationPathChanged);

        if (result.activeApplicationPathChanged && !gotTimeoutError.current) {
            await LiveUpdates.reload();
        }
    };

    useEffect(() => {
        // skipping on non native platforms
        if (!isNativePlatform || process.env.REACT_APP_OTA_ENABLED === 'false') {
            return setIsUpdateAvailable(false);
        }

        // People often keep apps minimized without ever closing them
        // This makes sure we keep looking for and applying OTA updates
        const resumeSync = async () => {
            try {
                if (isUpdateAvailableRef.current) {
                    await LiveUpdates.reload();
                } else {
                    const newResult = await LiveUpdates.sync();
                    setIsUpdateAvailable(newResult.activeApplicationPathChanged);
                }
            } catch (err) {
                // don't block if the update fails
                setIsUpdateAvailable(false);
                Sentry.captureException(err);
            }
        };

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        void CapacitorApp.addListener('resume', resumeSync);

        return () => {
            void CapacitorApp.removeAllListeners();
        };
    }, []);

    useEffect(() => {
        if (!isNativePlatform || process.env.REACT_APP_OTA_ENABLED === 'false' || getTimeoutThresholdError) {
            return setIsUpdateAvailable(false);
        }

        if (timeoutThreshold && timeoutThreshold.timeout > 0) {
            // Force update the app when it is opened
            void syncOrFail(timeoutThreshold.timeout, handleSyncSuccess, handleSyncError);
        }
    }, [timeoutThreshold, getTimeoutThresholdError]);

    if (isNativePlatform && isUpdateAvailable === null) {
        return <Loading label="Checking for updates..." />;
    }

    return children;
};

export default LoadingWrapper;
