import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';
import { DateTime } from 'luxon';

import { ALL_ASSIGNMENTS_ID } from '~/constants/filters';
import { atomWithToggle } from '~/scheduling/utils/atoms';
import { TabKey } from '~/types/dailyTasks.d';

export const selectedDateAtom = atom<DateTime<true> | null>(null);
export const isLocationSelectorDialogOpenAtom = atomWithToggle(false);
export const isShiftSelectorDialogOpenAtom = atomWithToggle(false);
export const isSortDialogOpenAtom = atomWithToggle(false);
export const selectedAssignmentIdsAtom = atomWithReset<number[]>([ALL_ASSIGNMENTS_ID]);
export const selectedTabAtom = atom<TabKey>(TabKey.LIVE_CALLS);
