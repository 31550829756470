import { Button, Stack, Typography } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';

import SegmentedModal from '~/scheduling/components/SegmentedModal';
import StaffSchedulePreview from '~/scheduling/pages/StaffList/components/StaffSchedulePreview';

import { isStaffScheduleModalOpenAtom, staffScheduleModalDataAtom } from '../../atom';

const StaffScheduleModalActions = () => {
    const toggle = useSetAtom(isStaffScheduleModalOpenAtom);
    const { onConfirm } = useAtomValue(staffScheduleModalDataAtom) ?? {};

    return (
        <>
            <Button variant="outlined" onClick={toggle}>
                Cancel
            </Button>
            <Button
                onClick={() => {
                    void onConfirm?.();
                    toggle();
                }}
            >
                Confirm
            </Button>
        </>
    );
};

export const StaffScheduleModal = () => {
    const [isOpen, toggle] = useAtom(isStaffScheduleModalOpenAtom);
    const { schedule } = useAtomValue(staffScheduleModalDataAtom) ?? {};

    return (
        <SegmentedModal
            isOpen={isOpen}
            onClose={toggle}
            minWidth="510px"
            header="Create New Schedule"
            actions={<StaffScheduleModalActions />}
            center
        >
            {schedule && (
                <Stack spacing="12px">
                    <Typography>{`You're about to create the following schedule:`}</Typography>
                    <StaffSchedulePreview schedule={schedule} />
                </Stack>
            )}
        </SegmentedModal>
    );
};
