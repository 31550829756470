/**
 * @deprecated - Text should go directly in the component
 */
const strings = {
    shared: {
        add: 'Add',
        addTaskNoteFieldTitle: 'Add a comment',
        ago: 'ago',
        cancel: 'Cancel',
        close: 'Close',
        confirm: 'Confirm',
        congratulations: 'Congratulations!',
        create: 'Create',
        date: 'Date',
        dateRangePicker: 'Select Date Range',
        dateRangeSeparatorLabel: 'to',
        day: 'day',
        days: 'days',
        delete: 'Delete',
        download: 'Download',
        downloadData: 'Download Data',
        earlyInShiftConfirmationDialogTitle: 'Are you sure?',
        earlyInShiftConfirmationDialogContent:
            'You are documenting a task in the {SHIFT_NAME} shift. Did you mean to do that?',
        edit: 'Edit',
        endDate: 'End Date',
        goToLoginPage: 'Go To Login Page',
        hello: 'Hello',
        hour: 'hour',
        hours: 'hours',
        justNow: 'Just now',
        loading: 'Loading...',
        minute: 'minute',
        minutes: 'minutes',
        mustStartWithALetter: 'Field must start with a letter.',
        no: 'No',
        noData: 'There are no existing data',
        noDataForFilters: 'There are no records matching the provided filters',
        noNurseCallsButton: 'No Call Lights',
        notApplicable: 'Not Applicable',
        notDefined: 'Not Defined',
        note: 'Note',
        notePlaceholder: 'Provide a brief description for the note',
        ok: 'OK',
        onlyLettersAndSpaces: 'Only letters and spaces are allowed.',
        onlyEmail: 'Field must be an email.',
        or: 'OR',
        paginationBlockData: 'Showing {XX}-{YY} from {ZZ} records',
        paginationRowsPerPage: 'Rows per page',
        resident: 'Resident',
        save: 'Save',
        saveChanges: 'Save Changes',
        second: 'second',
        seconds: 'seconds',
        seeDetails: 'See Details',
        sendEmail: 'Send Email',
        showAll: 'Show All',
        sortButtonTitle: 'Sort',
        sortDialogNameButton: 'Name',
        sortDialogRoomButton: 'Apt',
        sortDialogShowerLaundryButton: 'Shower & Laundry',
        sortDialogAscendingButton: 'Ascending',
        sortDialogDescendingButton: 'Descending',
        sortDialogTitle: 'Sort By',
        startDate: 'Start Date',
        submit: 'Submit',
        view: 'View',
        week: 'week',
        weeks: 'weeks',
        yes: 'Yes',
    },
    error: {
        actionDenied: 'Sorry, but you are not allowed to perform this action.',
        crendentialsMismatch: 'The credentials do not match! Please try again.',
        emptyValue: 'This is a required field!',
        futureDateError: 'cannot be set after today',
        generalError: 'There was an error while performing this action. Please try again.',
        inactiveUser: 'Sorry, but your account has been deactivated. Please contact your manager.',
        invalidDateError: 'is invalid',
        maxDateError: 'cannot be set after {DATE}',
        minDateError: 'cannot be set before {DATE}',
        missingCredentials: 'You need to provide a Username and Password!',
        missingEmail: 'You need to provide an Email!',
        missingUserId: 'You need to provide a User ID.',
        onlyCaregivers: 'Sorry, but this feature is exclusive for Caregivers',
        onlyDirectorsCareGivers: 'Sorry, but this feature is exclusive for Directors/Caregivers',
        onlyManagers: 'Sorry, but this feature is exclusive for Managers',
        pageDenied: 'Sorry, but you are not allowed to access this page.',
        pastDateError: 'cannot be set before today',
        recording: 'There was an error while recording your message. Please try again',
        tokenExpired: 'Sorry, but your token has expired. Please re-login.',
        userLogout: 'There was an error while trying to sign you out!',
        userNotFound: 'The Account you provided does not exist. Please check and try again.',
    },
    login: {
        content: 'Welcome back!',
        googleSignUp: 'Register with Google',
        pageTitle: 'Login',
        password: 'Password',
        signIn: 'Login',
        signOut: 'Sign Out',
        subContent: 'Sign in now to explore your account',
        username: 'Username',
    },
    navigator: {
        goodAfternoon: 'Good Afternoon',
        goodEvening: 'Good Evening',
        goodMorning: 'Good Morning',
        home: 'Home',
        integratePointClickCare: 'Integrated with PointClickCare',
        language: 'Español',
        myRewards: 'My Rewards',
        operationsSnapshots: 'Operations & Risks',
        privacy: 'Privacy',
        residents: 'Residents',
        settings: 'Settings',
        supportEmail: 'support@alliehealth.com',
        userCompany: 'Company',
        userManagementUsersList: 'User Management',
        userProfile: 'Profile',
        managerTaskList: 'Care Documentation',
        performanceSnapshots: 'Staffing & Performance',
        exportData: 'Export Data',
    },
    footer: {
        label: 'AllieHealth is a Trademark',
    },
    pageNotFound: {
        content: 'The page you were looking for was not found.',
        goToHome: 'Go Home',
        pageTitle: 'Page Not Found',
        title: 'Oh no!',
    },
    dashboards: {
        action: 'Action',
        activeCaregivers: 'Attending Caregivers',
        allTasks: 'All Tasks',
        byCaregivers: 'by Caregivers',
        byResidents: 'by Residents',
        byTotalCaregivers: 'by {XX} Caregivers',
        caregiver: 'Caregiver',
        careTasksProvided: 'Care Tasks Provided',
        chartCaregiverTasks: 'Tasks',
        completed: 'Completed',
        contact: 'Contact',
        contactName: 'Name',
        contactRelationship: 'Relationship',
        done: 'Done',
        fallenResidents: 'Fallen Residents',
        fallenResidentsReportBy: 'by {XX}, on {YY}',
        incompleted: 'Incomplete',
        incompleteTasks: 'All Incomplete Tasks',
        keyServices: 'Key Services',
        keyServicesReportedBy: 'by {XX}, on {YY}',
        keyServicesReportedOn: 'on {YY}',
        markAsCompleted: 'Mark as Completed',
        meal: 'Meal',
        missed: 'Missed Services',
        missedTab: 'Missed',
        note: 'Note',
        notes: 'Notes',
        unscheduledTasks: 'Unscheduled Tasks',
        overdue: 'Overdue',
        overdueTasks: 'Overdue Tasks',
        pageTitle: 'Reports',
        performance: 'Performance',
        phoneNumber: 'Phone Number',
        potentialContacts: 'Potential Contacts',
        refused: 'Refused Services',
        refusedTab: 'Refused',
        resident: 'Resident',
        residentName: 'Resident Name',
        residentRefused: 'Resident Refused',
        residentsServed: 'Residents Served',
        responseConfirmed: 'Documented',
        room: 'Apt',
        searchResidentLabel: 'Search Resident by Name',
        serviceCompleted: 'Service Completed',
        shiftName: 'Shift',
        shower: 'Shower',
        submissionCategory: 'Submission Category',
        task: 'Task',
        taskCategory: 'Task Category',
        taskList: 'Task List',
        taskStatus: 'Task Status',
        time: 'Time',
        toilet: 'Toilet',
        totalRecordsInPeriod: '{XX} records',
        totalTasksAttended: 'Total Tasks Attended',
        zone: 'Floor',
        prnTasksAdded: 'Unscheduled Tasks Added',
        residentNotesSubmitted: 'Resident Notes Submitted',
        undocumentedTasks: 'Undocumented Tasks',
        companyCareplanName: 'Care Plan',
    },
    privacyPolicy: {
        pageDetailsTitle: 'Privacy Policy',
        pageTitle: 'Privacy Policy',
    },
    termsOfService: {
        pageDetailsTitle: 'Terms of Service',
        pageTitle: 'Terms of Service',
    },
    emailSubscription: {
        pageDetailsTitle: 'Manage Email Subscription',
        pageTitle: 'Manage Email Subscription',
    },
    dashboardOverdueTasksDetails: {
        pageDetailsTitle: 'Overdue Tasks',
        pageTitle: 'Operations & Risks | Overdue Tasks',
    },
    dashboardIncompletedTasksDetails: {
        pageDetailsTitle: 'Incomplete Tasks',
        pageTitle: 'Operations & Risks | Incomplete Tasks',
    },
    home: {
        added: 'Added',
        addTask: 'Add Task',
        am: 'Morning',
        branch: 'Branch',
        caregiver: 'Caregiver',
        completeTaskDialogTitle: 'Complete Task',
        createTaskSuccess: 'The task has been Created successfully!',
        date: 'Shift Date',
        deleteTaskConfirmation: 'Are you sure to delete this Task?',
        deleteTaskDialogTitle: 'Delete Task',
        deleteTaskSuccess: 'The task has been Deleted successfully!',
        downloadCsvSuccess: 'The CSV file has been downloaded successfully!',
        editTaskDialogTitle: 'Edit Task',
        mid: 'Afternoon',
        missingParamsForAddedTask: 'You need to select a Resident and a Service for Adding a Task!',
        nonPending: 'Confirmed',
        noTasks: 'There are no Tasks for the Date, Shift and Floor selected',
        notes: 'Notes/Comments',
        outcome: 'Outcome',
        pageTitle: 'Home',
        pending: 'Pending',
        pm: 'Night',
        rejectTaskDialogTitle: 'Dismiss Reason',
        requested: 'Requested',
        scheduled: 'Scheduled',
        serviceProvided: 'Service Provided',
        shift: 'Shift',
        taskNotes: 'Task Notes',
        taskStatus: 'Task Status',
        taskType: 'Task Type',
        updateTaskSuccess: 'The task has been Updated successfully!',
        viewTaskDialogTitle: 'View Task',
        zone: 'Floor',
        room: 'Apt',
        addNotes: 'Add a comment',
        editNotes: 'Edit comment',
        singularTasksSelected: 'task selected',
        pluralTasksSelected: 'tasks selected',
        addTaskNoteDialogTitle: 'Add a comment',
        addTaskNoteFieldTitle: 'Comment',
        addTaskNoteFieldPlaceholder: 'Add a comment',
        addTaskNoteSubmitButton: 'Submit',
        rejectTaskCancelButton: 'Cancel',
        rejectTaskCancelTitle: 'Choose Option',
        changeTaskStatusTitle: 'Change Task Status',
        completionOptionsLabel: 'Progress tracker',
        completionOptionsPlaceholder: 'choose option',
    },
    managerTaskList: {
        pageDetailsTitle: 'Care Documentation',
        pageTitle: 'Care Documentation',
    },
    performanceSnapshots: {
        pageDetailsTitle: 'Staffing & Performance',
        pageTitle: 'Staffing & Performance',
    },
    operations: {
        pageDetailsTitle: 'Report',
        pageTitle: 'Report',
    },
    residents: {
        congratulations: 'Congratulations!',
        allTasksDone: "You finished all {name}'s tasks",
        bathing: 'Bathing',
        buildingStatus: 'Building Status',
        buildingStatusTitle: "Update Resident's Building Status",
        createResidentSuccess: 'The Resident has been created successfully!',
        createShiftNotesSuccess: 'The Report has been created successfully!',
        diet: 'Diet',
        dining: 'Dining',
        dressing: 'Dressing',
        focus: 'Care Type',
        healthIncident: 'Incidents / Health Concerns',
        hygiene: 'Hygiene',
        intakeRecord: 'Intake Record',
        level: 'Level',
        noMatch: 'No Residents match with the filter!',
        noResidents: 'There are no registered Residents in this Branch',
        one: '1 Person',
        others: 'Others',
        pageDetailsTitle: 'Resident',
        pageTitle: 'Residents',
        personalInfo: 'Personal Info',
        pictureDeleteSuccess: 'The Picture has been deleted successfully!',
        pictureUploadSuccess: 'The Picture has been uploaded successfully!',
        record: 'Record',
        report: 'Report',
        reportDialogTitle: 'Create {XXX} Report',
        residentNotesTitle: 'Resident Note',
        room: 'Apt',
        roomMaintenance: 'Behavioral Change',
        searchLabel: 'Resident Name/Apt',
        searchPlaceholder: 'Type by Name/Apt',
        shiftNotesAddButton: 'Add Resident Note',
        unscheduledTaskAddButton: 'Add Unscheduled/PRN Task',
        ShiftNotesDelete: 'Are you sure to delete this note?',
        status: 'Status',
        statusInBuilding: 'In Building',
        statusLeft: 'Left',
        statusOutOfBuilding: 'Out of Building',
        supply: 'Family Messages / Complaints',
        tasks: 'Tasks',
        toileting: 'Toileting',
        transfer: 'Transfer',
        two: '2 People',
        updateResidentSuccess: 'The Resident has been updated successfully!',
        updateShiftNotesSuccess: 'The Report has been updated successfully!',
        walking: 'Walking',
        missingParamsForAddedTask: 'You need to select a Resident and a Service for Adding a Task!',
    },
    residentDetails: {
        pageTitle: 'Resident Details',
    },
    myRewards: {
        attendedResident: 'Attended Resident',
        badgesEarned: 'Badges Earned',
        earnedAt: 'at',
        earnedOn: 'Earned on',
        earnedPoints: 'Points',
        history: 'History',
        month: 'month',
        mostAboveAndBeyond: 'Most Above & Beyond',
        mostCommunicate: 'Most Communicate',
        mostResidentServed: 'Most Resident Served',
        notesSubmitted: 'Notes Submitted',
        pageTitle: 'My Rewards',
        residentsServed: 'Residents Served',
        shift: 'shift',
        summary: 'Your Summary',
        taskDate: 'Task Date',
        taskName: 'Task Name',
        taskType: 'Task Type',
        thankAbove1: 'You went Above and Beyond for',
        thankAbove2: 'residents in',
        thankAbove3: 'times on this {XXX}!',
        thankHighFive1: 'High-five for sharing',
        thankHighFive2: 'notes regarding',
        thankHighFive3: 'residents. You rock!',
        thankHooray1: 'Hooray for helping',
        thankHooray2: 'residents with their care tasks!',
        thanksFromCommunity: 'Thanks From Community',
        timesAboveAndBeyond: 'Times Above & Beyond',
        topPerformer: 'Top Performer',
        totalPoints: 'Points Earned',
        week: 'week',
    },
    rewardsHistory: {
        pageDetailsTitle: 'Point History',
        pageTitle: 'Point History',
    },
    companies: {
        pageTitle: 'Companies',
    },
    branches: {
        pageTitle: 'Companies',
    },
    users: {
        pageTitle: 'Users',
    },
    userProfile: {
        dateOfBirth: 'Date of Birth',
        email: 'Email',
        enabledGoogleOauth: 'Enable access via Google',
        firstName: 'First Name',
        lastName: 'Last Name',
        pageTitle: 'Profile',
        password: 'Password',
        confirmPassword: 'Confirm Password',
        phoneNumber: 'Phone Number',
        status: 'Status',
        username: 'Username',
    },
    deleteAccount: {
        pageTitle: 'Delete Account',
        pageDetailsTitle: 'Delete Account',
    },
    deleteAccountVerify: {
        pageTitle: 'Delete Account',
        pageDetailsTitle: 'Delete Account (Verification)',
    },
    taskStatuses: {
        createTaskStatusSuccess: 'The task status has been Created successfully!',
        deleteTaskStatusSuccess: 'The task status has been Deleted successfully!',
        pageTitle: 'Task Statuses',
        updateTaskStatusSuccess: 'The task status has been Updated successfully!',
    },
    signup: {
        pageTitle: 'Signup',
    },
    loginVerify: {
        pageTitle: 'Login',
    },
    signupVerify: {
        pageTitle: 'Signup',
    },
    verifyCommunity: {
        pageTitle: 'Join Community',
    },
    usernameLogin: {
        pageTitle: 'Login',
    },
    exportData: {
        pageTitle: 'Export Data',
    },
};

export default strings;
