import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { showToast } from '~/components/Shared/Alerting/Toast/utils/showToast';

const handleError = (_error: unknown) => {
    const error = _error as AxiosError<{ error: string }>;

    const message =
        error.response?.data?.error || // AxiosError<{ error: string }> with standardized error field
        error.message || // Known client/network Error (with message)
        'An internal error occurred'; // Other unexpected/unknown Error (without message)

    showToast({
        message,
        type: 'error',
    });

    // Rethrow the error for react-query to handle that state
    throw error;
};

export const useErrorHandledQuery = <T>({ queryFn: _queryFn, ...options }: UseQueryOptions<T>) => {
    // Intercept the error and show an alert
    const queryFn = () => (_queryFn as () => Promise<T>)().catch(handleError);

    return useQuery({ queryFn, retry: false, ...options });
};

export const useErrorHandledMutation = <TVariables, TData>({
    onError: _onError,
    ...options
}: UseMutationOptions<TData, unknown, TVariables>) => {
    // Extend the error and show an alert
    const onError = (_error: unknown, variables: TVariables, context: unknown) => {
        _onError?.(_error, variables, context);
        handleError(_error);
    };

    return useMutation({ onError, retry: false, ...options });
};
