import { Box, styled } from '@mui/material';
import React from 'react';

const ButtonGridContainer = styled(Box)(() => ({
    display: 'grid',
    width: '100%',
    gap: '12px',
}));

const ButtonGrid = ({ maxItemsPerRow = 4, children }: { maxItemsPerRow?: number; children: React.ReactNode }) => {
    return (
        <ButtonGridContainer
            sx={({ breakpoints }) => {
                if (maxItemsPerRow <= 3) {
                    return {
                        gridTemplateColumns: `repeat(${maxItemsPerRow}, 1fr)`,
                    };
                }

                return {
                    gridTemplateColumns: `repeat(${maxItemsPerRow - 1}, 1fr)`,
                    [breakpoints.up('sm')]: {
                        gridTemplateColumns: `repeat(${maxItemsPerRow}, 1fr)`,
                    },
                };
            }}
        >
            {children}
        </ButtonGridContainer>
    );
};

export default ButtonGrid;
