import { Box } from '@mui/material';
import React from 'react';

import { pxToRem } from '~/components/theme/typography';

export const Container = ({ children }) => (
    <Box
        sx={{
            display: {
                xs: 'flex',
                lg: 'block',
            },
            flexDirection: 'column',
            justifyContent: 'space-between',
            maxWidth: {
                lg: pxToRem(500),
            },
            mx: {
                lg: 'auto',
            },
            mt: {
                lg: pxToRem(80),
            },
            height: {
                xs: 'calc(100% - env(safe-area-inset-top))',
                lg: 'auto',
            },
            bgcolor: {
                lg: 'common.white',
            },
            borderRadius: {
                lg: pxToRem(12),
            },
            p: {
                lg: pxToRem(32),
            },
        }}
    >
        {children}
    </Box>
);
