import { api } from '~/api';
import actionTypes from '~/redux/actionTypes';
import loading from '~/redux/actions/loading';
import { throwError } from '~/redux/actions/messages';
import '~/redux/reducers/shiftNotesCategories';
import { ShiftNotesCategoryResponse } from '~/types/ShiftNotesCategories';

/**
 * Fetches the list of Report Categories.
 * @param jsonParams the JSON object with the params for fetching the Report Categories list.
 */
export const readShiftNotesCategories =
    (branchId: number, actionType = actionTypes.SHIFT_NOTES_CATEGORY_READ_LIST) =>
    async (dispatch, getState) => {
        try {
            // Identify if the current alert message (if any displayed) should be hidden.
            const shouldHideAlert = actionType === actionTypes.SHIFT_NOTES_CATEGORY_READ_LIST;

            // Enable the loading process.
            dispatch(loading(actionTypes.SHIFT_NOTES_CATEGORY_LOADING, true, shouldHideAlert));

            // Get the token.
            const { token } = getState().session.sessionData;

            // Fetch the Report Categories List.
            const shiftNotesCategoriesList: ShiftNotesCategoryResponse[] = (
                await api.get('/shift-notes-category', {
                    headers: {
                        authorization: token,
                    },
                    params: { branch_id: branchId }, // For permission checking
                })
            ).data.response;

            dispatch({
                type: actionType,
                payload: shiftNotesCategoriesList,
            });
        } catch (error) {
            dispatch(throwError(error));
        } finally {
            // Disable the loading process.
            dispatch(loading(actionTypes.SHIFT_NOTES_CATEGORY_LOADING, false));
        }
    };
