import { styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import React, { ComponentProps } from 'react';

import { FullScheduleSlot } from '~/scheduling/api/queries/shift-slot/getFullSchedule';

import FilledSlotItem from './FilledSlotItem';
import OpenSlotItem from './OpenSlotItem';

const SlotCell = styled(Grid)(({ theme: { palette } }) => ({
    position: 'relative',
    height: '44px',
    borderStyle: 'solid',
    borderWidth: '0 1px 1px 0',
    borderColor: palette.grey[100],
}));

const SlotItem = ({ slot }: { slot: FullScheduleSlot }) =>
    slot.staffId || slot.agencyStaffId ? <FilledSlotItem slot={slot} /> : <OpenSlotItem slot={slot} />;

const ShiftSlotCell = ({
    slot,
    ...props
}: { slot: FullScheduleSlot | null } & Omit<ComponentProps<typeof Grid>, 'slot'>) => (
    <SlotCell xs {...props}>
        {slot ? <SlotItem slot={slot} /> : null}
    </SlotCell>
);

export default ShiftSlotCell;
