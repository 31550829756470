import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { api } from '~/api';
import { useV4ErrorHandledQuery } from '~/hooks/useErrorHandledQuery';
import { useToken } from '~/lib/common';
import { BranchResponse } from '~/types/branches';
import { ReduxStore } from '~/types/redux';

export const useBranchQuery = (branchId?: number) => {
    const token = useToken();

    return useV4ErrorHandledQuery(
        ['branch', branchId],
        async () =>
            (
                await api.get<{ response: BranchResponse }>(`/branches/${branchId}`, {
                    headers: {
                        authorization: token,
                    },
                    params: { branch_id: branchId }, // For permission checking
                })
            ).data.response,
        { enabled: !!branchId, staleTime: 300000 }
    );
};

export const useBranchShifts = (branchId?: number) => {
    const { data } = useBranchQuery(branchId);

    return data?.shifts ?? [];
};

export function withBranchShiftSelector<T>(WrappedComponent: (props: T) => React.ReactNode) {
    return function WithBranchShiftSelector(props: T) {
        const { branchId: defaultBranchId } = useSelector((state: ReduxStore) => state.session.sessionData);
        const [branchId, setBranchId] = useState<number | undefined>(defaultBranchId);

        const branchShifts = useBranchShifts(branchId);

        if (branchShifts.length === 0) {
            return null;
        }

        return <WrappedComponent {...props} setSelectedBranchId={setBranchId} selectedBranchId={branchId} />;
    };
}
