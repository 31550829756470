import { DateTime } from 'luxon';
import React from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/api/common';
import { GetDashboardBudgetedScheduledByDay } from '~/scheduling/api/types/dashboard/getBudgetedScheduledByDay';

type UseGetBudgetedScheduledByDayParams = {
    teamId?: number;
    locationId?: number;
    startDay: DateTime;
    endDay: DateTime;
};

export const useGetBudgetedScheduledByDay = (props: UseGetBudgetedScheduledByDayParams) => {
    const { teamId, locationId, startDay, endDay } = props;
    const branchId = useBranchId()!;
    const token = useToken();

    const queryFn = React.useCallback(async () => {
        const params = {
            startDay: startDay.toFormat('yyyy-MM-dd'),
            endDay: endDay.toFormat('yyyy-MM-dd'),
            locationId,
            teamId,
        };
        const options = { headers: { Authorization: token }, params };

        const { data } = await api.get<GetDashboardBudgetedScheduledByDay.Response>(
            `/scheduling/${branchId}/dashboard/budgeted-scheduled-by-day`,
            options
        );

        return data.response;
    }, [branchId, token, props]);

    return useErrorHandledQuery({
        queryKey: ['budgeted-scheduled', branchId, props],
        queryFn,
        staleTime: 300000,
        enabled: !!branchId && !!teamId && !!startDay && !!endDay,
    });
};
