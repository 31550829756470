import { useCallback } from 'react';

import { api } from '~/api';
import { useErrorHandledMutation } from '~/api/common';
import { useBranchId, useToken } from '~/lib/common';
import { UnscheduledTasks } from '~/types/unscheduledTasks';

export const useCreateUnscheduledTaskMutation = () => {
    const token = useToken();
    const branchId = useBranchId();

    const mutationFn = useCallback(
        async (params: UnscheduledTasks.CreateUnscheduledTaskParams[]) => {
            const options = { headers: { Authorization: token }, params: { branch_id: branchId } };

            const { data } = await api.post<UnscheduledTasks.CreateUnscheduledTaskResponse>(
                `/prn-tasks`,
                params,
                options
            );

            return data.response;
        },
        [branchId, token]
    );

    return useErrorHandledMutation({ mutationFn });
};
