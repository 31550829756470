import { Box, Skeleton, Stack, Typography, styled, useTheme } from '@mui/material';
import range from 'lodash/range';
import React from 'react';

import { STANDARDIZED_STAFF_SCHEDULE_TYPES } from '@allie/utils/src/constants/scheduling/staff-schedule-types.constants';

import { useGetCommunitySettings } from '~/scheduling/api/queries/community-settings/getCommunitySettings';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';

import { StaffList } from '../../types';

import { ShiftsGroup, buildHeaders, buildRows } from './helpers';

const StaffScheduleGridContainer = styled(Box)(({ theme }) => ({
    borderRadius: '8px',
    border: `1px solid ${theme.palette.grey[100]}`,
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
}));

const StaffScheduleGridHeader = styled(Box)(({ theme }) => ({
    padding: '12px',
    backgroundColor: theme.palette.grey[25],
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const StaffScheduleGridCell = styled(Box)(() => ({
    padding: '12px 8px',
    flex: 1,
    minHeight: '44px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

type StaffScheduleGridProps = {
    headers: string[];
    rows: {
        label: string;
        filled: boolean;
    }[][];
};

const StaffScheduleGrid = (props: StaffScheduleGridProps) => {
    const { palette } = useTheme();

    return (
        <StaffScheduleGridContainer>
            <Box display="flex" height="fit-content">
                {props.headers.map((header, idx) => (
                    <StaffScheduleGridHeader
                        sx={
                            idx !== props.headers.length - 1
                                ? { borderRight: `1px solid ${palette.grey[100]}` }
                                : undefined
                        }
                        key={idx}
                    >
                        <Typography variant="body1" color={palette.grey[600]} fontSize="15px" fontWeight={500}>
                            {header}
                        </Typography>
                    </StaffScheduleGridHeader>
                ))}
            </Box>
            {props.rows.map((row, rowIndex) => (
                <Box
                    key={rowIndex + 'row'}
                    display="flex"
                    flex={1}
                    sx={rowIndex !== props.rows.length - 1 ? { borderBottom: `1px solid ${palette.grey[100]}` } : {}}
                >
                    {row.map((cell, cellIndex) => (
                        <StaffScheduleGridCell
                            key={cellIndex + 'cell'}
                            sx={{
                                backgroundColor: cell.filled ? (palette.primary[50] as string) : 'white',
                                ...(cellIndex !== props.headers.length - 1
                                    ? { borderRight: `1px solid ${palette.grey[100]}` }
                                    : {}),
                            }}
                        >
                            <Typography
                                variant="body1"
                                textAlign="center"
                                color={cell.filled ? (palette.primary[500] as string) : palette.grey[600]}
                                fontSize="15px"
                                fontWeight={500}
                            >
                                {cell.label}
                            </Typography>
                        </StaffScheduleGridCell>
                    ))}
                </Box>
            ))}
        </StaffScheduleGridContainer>
    );
};

const StaffSchedulePreview = ({ schedule }: { schedule: Pick<StaffList.StaffSchedule, 'type' | 'shifts'> }) => {
    const { data: rolesData, isLoading: isGetRolesLoading } = useGetRoles();

    const { data: communitySettings, isLoading: isGetCommunitySettingsLoading } = useGetCommunitySettings();
    const firstDayOfWeek = communitySettings?.firstDayOfWeek;

    const shiftsGruppedByName = React.useMemo(() => {
        const shifts = rolesData?.roleShifts ?? [];

        // since the staff can assume different role, and each role has it own
        // shift id group, it must group all shifts ids by name to ease the
        // logic that renders the cell in `buildRows` function.
        return shifts.reduce<ShiftsGroup[]>((group, shift) => {
            const shiftsNames = group.map(({ name }) => name);
            if (shiftsNames.includes(shift.name)) {
                const idx = group.findIndex((el) => el.name === shift.name);
                group[idx].shiftIds.push(shift.id);
            } else {
                group.push({ shiftIds: [shift.id], name: shift.name });
            }
            return group;
        }, []);
    }, [rolesData]);

    if (isGetRolesLoading || isGetCommunitySettingsLoading) {
        return <Skeleton width="100%" height="181px" />;
    }

    if (!schedule || !firstDayOfWeek) return null;

    if (schedule.type === STANDARDIZED_STAFF_SCHEDULE_TYPES.BIWEEKLY) {
        return (
            <>
                {range(2).map((weekIndex) => {
                    if (!schedule) return null;

                    const weekCurrentSchedule = {
                        ...schedule,
                        shifts: schedule.shifts.filter(({ index }) => index === weekIndex),
                    };

                    const headers = buildHeaders(schedule.type, firstDayOfWeek);
                    const rows = buildRows(weekCurrentSchedule, shiftsGruppedByName, firstDayOfWeek);

                    return (
                        <Stack key={weekIndex} spacing="8px">
                            <Typography fontWeight={600}>Week {weekIndex + 1}</Typography>
                            <StaffScheduleGrid headers={headers} rows={rows} />
                        </Stack>
                    );
                })}
            </>
        );
    }

    const headers = buildHeaders(schedule.type, firstDayOfWeek);
    const rows = buildRows(schedule, shiftsGruppedByName, firstDayOfWeek);

    return <StaffScheduleGrid headers={headers} rows={rows} />;
};

export default StaffSchedulePreview;
