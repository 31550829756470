import { useCallback } from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/api/common';
import { BaseResponse } from '~/types/shared';
import { ZoneResponse } from '~/types/zones';

export const useGetZones = (enabled = true) => {
    const token = useToken();
    const branchId = useBranchId();

    const queryFn = useCallback(async () => {
        const { data } = await api.get<BaseResponse<ZoneResponse[]>>('/zones', {
            headers: { Authorization: token },
            params: { branch_id: branchId },
        });

        return data.response;
    }, [branchId, token]);

    return useErrorHandledQuery({
        queryKey: ['zones', branchId],
        queryFn,
        enabled: !!branchId && enabled,
        staleTime: 300000,
    });
};
