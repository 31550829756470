import { Stack, styled } from '@mui/material';
import React, { ReactNode } from 'react';

import { SingleLineTypography } from '~/components/Shared/SingleLineTypography';
import { useRouteHandle } from '~/router';

export const DesktopHeaderActionsRow = styled(Stack)(() => ({
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
}));

const DesktopHeaderContainer = styled(Stack)(({ theme: { palette } }) => ({
    backgroundColor: 'white',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '20px',
    padding: '20px 24px',
    height: '76px',
    borderBottom: `1px solid`,
    borderColor: palette.grey[50],
}));

const DesktopHeaderTitle = ({ title }: { title?: string }) => {
    const handle = useRouteHandle();

    return <SingleLineTypography variant="h6">{title ?? handle.title}</SingleLineTypography>;
};

const DesktopHeaderActions = ({ actions }: { actions?: React.ReactNode }) => {
    if (!actions) return null;

    return (
        <Stack
            sx={{
                flexDirection: 'row',
                alignItems: 'center',
                ml: 'auto',
            }}
        >
            {actions}
        </Stack>
    );
};

const DesktopHeader = ({ title, actions }: { title?: ReactNode; actions?: ReactNode }) => (
    <DesktopHeaderContainer>
        {title === undefined || typeof title === 'string' ? <DesktopHeaderTitle title={title} /> : title}
        <DesktopHeaderActions actions={actions} />
    </DesktopHeaderContainer>
);

export default DesktopHeader;
