import { Box, Skeleton, useTheme } from '@mui/material';
import { Stack } from '@mui/material';
import range from 'lodash/range';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';

import { STANDARDIZED_STAFF_TYPES_LABELS } from '@allie/utils/src/constants/scheduling/staff-types.constants';

import { useGetLocations } from '~/scheduling/api/queries/locations/getLocations';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';
import { GetStaff } from '~/scheduling/api/types/staff/getStaff';
import { FormDropdown } from '~/scheduling/components/form/FormDropdown';
import { FormTextField } from '~/scheduling/components/form/FormTextField';
import { emailFormat, nonDigitCharacters, phoneNumberUS, zipCodeFormat } from '~/scheduling/utils/regex';

import { US_STATES } from '../../constants';
import { StaffDetailsFormFields } from '../../types';

import { StaffRoleSelector } from './StaffRoleSelector';

// returns a mask for the zip code (e.g. 12345 or 12345-6789)
const zipCodeMask = (value: string) => {
    const zipCodeLength = 10;
    const cutValue = value.slice(0, zipCodeLength); // limit the length of the value to 10
    const valueWithoutDash = cutValue.replace('-', '');

    return valueWithoutDash.length >= 6 ? `${value.slice(0, 5)}-${valueWithoutDash.slice(5)}` : valueWithoutDash;
};

// returns a mask for the phone number (e.g. (555) 555-5555)
const phoneNumberMask = (value: string) => {
    const phoneNumberLength = 14;
    const cutValue = value.slice(0, phoneNumberLength); // limit the length of the value to 14
    const pureValue = cutValue.replace(/[-()\s]/g, '');

    const valueWithParanthees = `(${pureValue.slice(0, 3)})`;
    const firstPart = pureValue.slice(3, 6);
    const lastPart = pureValue.slice(6);

    if (pureValue.length <= 3) return pureValue;
    if (pureValue.length <= 6) return `${valueWithParanthees} ${firstPart}`;
    return `${valueWithParanthees} ${firstPart}-${lastPart}`;
};

interface StaffInfoFormProps {
    form: UseFormReturn<StaffDetailsFormFields>;
    roleFieldDeselectable?: boolean;
    staff?: GetStaff.Staff;
    isLoading: boolean;
    isSaving: boolean;
}

export const StaffInfoForm = ({ form, roleFieldDeselectable, staff, isLoading, isSaving }: StaffInfoFormProps) => {
    const { palette } = useTheme();
    const { data: staffRoles } = useGetRoles();
    const { data: locationsData } = useGetLocations();

    const locationOptions = React.useMemo(
        () =>
            (locationsData?.locations ?? []).map((location) => ({
                value: location.id.toString(),
                label: location.name,
            })),
        [locationsData]
    );

    const staffTypeOptions = Object.entries(STANDARDIZED_STAFF_TYPES_LABELS).map(([key, value]) => ({
        value: key,
        label: value,
    }));

    const staffRoleOptions = React.useMemo(
        () =>
            (staffRoles?.roles ?? []).map((role) => ({
                id: role.id,
                label: role.name,
            })),
        [staffRoles]
    );

    const isDisabled = isLoading || isSaving;

    if (isLoading) {
        return (
            <Stack spacing="24px">
                {range(4).map((i) => (
                    <Skeleton key={i} height="68px" />
                ))}
            </Stack>
        );
    }

    return (
        <Stack spacing="24px">
            <Box display="flex" gap="12px">
                <Box flex={1}>
                    <FormTextField
                        name="name"
                        form={form}
                        label="Staff name*"
                        validations={{
                            required: 'Required field',
                            min: { value: 2, message: 'Name too short' },
                            pattern: { value: nonDigitCharacters, message: 'Invalid value' },
                        }}
                        disabled={isDisabled}
                    />
                </Box>
                <Box flex={1}>
                    <FormDropdown
                        name="type"
                        selectProps={{ defaultValue: staff?.staffType, disabled: isDisabled }}
                        form={form}
                        label="Staff type*"
                        placeholder="Select staff type"
                        options={staffTypeOptions}
                        validations={{ required: 'Required field' }}
                    />
                </Box>
            </Box>
            <Box display="flex" gap="12px">
                <Box flex={1}>
                    <StaffRoleSelector
                        defaultValue={staff?.roles.map((role) => role.staffRoleId)}
                        deselectable={roleFieldDeselectable}
                        name="roles"
                        form={form}
                        options={staffRoleOptions}
                        disabled={isDisabled}
                    />
                </Box>
            </Box>
            <Box display="flex" gap="12px">
                <Box flex={1}>
                    <FormDropdown
                        selectProps={{
                            defaultValue: staff?.primaryLocation.id.toString(),
                            disabled: isDisabled,
                        }}
                        name="mainlyServe"
                        form={form}
                        label="Mainly Serve at*"
                        placeholder="Select location"
                        options={locationOptions}
                        validations={{ required: 'Required field' }}
                    />
                </Box>
                <Box flex={1}>
                    <FormTextField
                        type="email"
                        name="email"
                        form={form}
                        label="Email*"
                        validations={{
                            required: 'Required field',
                            pattern: { value: emailFormat, message: 'Invalid email' },
                        }}
                        disabled={isDisabled}
                    />
                </Box>
                <Box flex={1}>
                    <FormTextField
                        name="phone"
                        form={form}
                        label="Phone number*"
                        maskFn={phoneNumberMask}
                        icon={<span style={{ color: palette.grey[900] }}>+1</span>}
                        inputProps={{
                            defaultCountry: 'US',
                            type: 'tel',
                        }}
                        validations={{
                            required: 'Required field',
                            pattern: { value: phoneNumberUS, message: 'Invalid phone number' },
                        }}
                        disabled={isDisabled}
                    />
                </Box>
            </Box>
            <Box display="flex" gap="12px">
                <Box flex={1}>
                    <FormTextField
                        name="homeAddress"
                        form={form}
                        label="Home address*"
                        validations={{
                            required: 'Required field',
                            min: { value: 2, message: 'Address too short' },
                        }}
                        disabled={isDisabled}
                    />
                </Box>
                <Box flex={1}>
                    <FormDropdown
                        selectProps={{ defaultValue: staff?.state, disabled: isDisabled }}
                        name="state"
                        form={form}
                        label="State*"
                        placeholder="Select a state"
                        options={US_STATES.map((state) => ({ value: state, label: state }))}
                        validations={{ required: 'Required field' }}
                    />
                </Box>
                <Box flex={1}>
                    <FormTextField
                        name="city"
                        form={form}
                        label="City*"
                        validations={{
                            required: 'Required field',
                            min: { value: 2, message: 'City name too short' },
                            pattern: { value: nonDigitCharacters, message: 'Invalid value' },
                        }}
                        disabled={isDisabled}
                    />
                </Box>
                <Box flex={1}>
                    <FormTextField
                        name="zipCode"
                        form={form}
                        placeholder="12345"
                        label="Zip Code*"
                        maskFn={zipCodeMask}
                        validations={{
                            required: 'Required field',
                            pattern: {
                                value: zipCodeFormat,
                                message: 'Zip code must be 5 or 9 digits (i.e. 12345 or 12345-6789)',
                            },
                        }}
                        disabled={isDisabled}
                    />
                </Box>
            </Box>
        </Stack>
    );
};
