import { useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

import { CustomSelect } from '~/components/Custom';
import { SelectStructure } from '~/types/inputs';

import { MOBILE_SELECTOR_STYLES, SELECTOR_STYLES } from './constants';

type Props = {
    options: { label: string; value: number }[];
    value: number;
    onChange: (newValue: number) => void;
    fullWidth?: boolean;
    disabled?: boolean;
};

const AssignmentSelector = (props: Props) => {
    const { options, value, onChange, fullWidth, disabled } = props;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <CustomSelect
            id="assignment"
            label="Assignment"
            // TODO: Limiting value to string at SelectStructure doesn't make sense as we
            // use it mostly for numerical ids, we need to refactor it later (AH-1021)
            options={options as unknown as SelectStructure[]}
            value={value}
            onChange={onChange}
            hideIfEmpty={false}
            fullWidth={fullWidth}
            disabled={disabled}
            sx={{
                ...(isMobile ? MOBILE_SELECTOR_STYLES : {}),
                ...SELECTOR_STYLES,
            }}
        />
    );
};

export default AssignmentSelector;
