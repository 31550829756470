import { Box, Stack, Typography } from '@mui/material';
import React, { ComponentProps, ReactNode } from 'react';

import CustomModal from './CustomModal';
import { HorizontalSeparator } from './shared';

const Separator = () => <HorizontalSeparator sx={{ bgcolor: 'grey.100' }} />;

const SegmentedModalHeader = ({ children }: { children: ReactNode }) => (
    <Box p="24px">
        {typeof children === 'string' ? (
            <Typography variant="body1" fontSize="16px" fontWeight={700} lineHeight="36px">
                {children}
            </Typography>
        ) : (
            children
        )}
    </Box>
);

const SegmentedModalBody = ({ children }: { children: ReactNode }) => <Box p="24px">{children}</Box>;

const SegmentedModalActions = ({ children }: { children: ReactNode }) => (
    <Stack direction="row" justifyContent="end" p="24px" spacing="8px">
        {children}
    </Stack>
);

const SegmentedModal = ({
    header,
    actions,
    children,
    ...props
}: {
    header?: ReactNode;
    actions?: ReactNode;
} & ComponentProps<typeof CustomModal>) => (
    <CustomModal {...props}>
        {header && (
            <>
                <SegmentedModalHeader>{header}</SegmentedModalHeader>
                <Separator />
            </>
        )}
        <SegmentedModalBody>{children}</SegmentedModalBody>
        {actions && (
            <>
                <Separator />
                <SegmentedModalActions>{actions}</SegmentedModalActions>
            </>
        )}
    </CustomModal>
);

export default SegmentedModal;
