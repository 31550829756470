import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-old';
import { useAtom } from 'jotai';
import React from 'react';
import { PiArrowDownRight, PiArrowUpRight } from 'react-icons/pi';

import { OperationsBudgetedVsScheduledHoursStatsDataset } from '~/types/operationsDashboard';

import CustomMultilineChart from '../Charts/CustomMultilineChart';
import CustomMultilineChartTooltip from '../Charts/CustomMultilineChartTooltip';

import { SectionLayout } from './components/SectionLayout';
import { Table } from './components/Table';
import { SectionProps } from './shared';
import { selectedDateAtom } from './testData';
import { useSchedulingModules } from './useSchedulingModules';

const BudgetedVsScheduledHoursSection = ({ regionId, branchId, operationsPeriod, dateOptions }: SectionProps) => {
    const [selectedDate, setSelectedDate] = useAtom(selectedDateAtom);
    const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));

    const { budgetedVsScheduledHoursSectionData, branchesByRegionData } = useSchedulingModules({
        regionId,
        branchId,
        operationsPeriod,
    });

    if (!budgetedVsScheduledHoursSectionData || !branchesByRegionData) return null;

    const { tableData, chartData } = budgetedVsScheduledHoursSectionData;

    const columns: GridColDef[] = [
        {
            field: 'branchId',
            headerName: 'Community',
            flex: 1,
            width: isLargeScreen ? 220 : 200,
            renderCell: (params: GridRenderCellParams<OperationsBudgetedVsScheduledHoursStatsDataset>) => {
                const branchName = branchesByRegionData.branches.find(
                    (b) => b.branchId === params.value
                )?.branchShortName;

                return (
                    <Typography
                        variant="body1"
                        fontSize={{ lg: '16px', xs: '14px' }}
                        fontWeight={500}
                        lineHeight="150%"
                        sx={({ palette }) => ({
                            color: palette.grey[900],
                        })}
                        textOverflow="ellipsis"
                        overflow="hidden"
                    >
                        {branchName ?? '-'}
                    </Typography>
                );
            },
        },
        {
            field: 'gap',
            flex: 1,
            headerName: 'Gap',
            width: isLargeScreen ? 220 : 200,
            renderCell: (params: GridRenderCellParams<OperationsBudgetedVsScheduledHoursStatsDataset>) => {
                return (
                    <Typography
                        variant="body1"
                        fontSize={{ lg: '16px', xs: '14px' }}
                        fontWeight={500}
                        lineHeight="150%"
                        sx={({ palette }) => ({
                            color: palette.grey[900],
                        })}
                    >
                        {`${Math.abs(+params.value)} ${params.value < 0 ? 'under' : 'over'}`}
                    </Typography>
                );
            },
        },
        {
            field: 'previousPeriod',
            flex: 1,
            headerName:
                operationsPeriod === 'month'
                    ? 'Month over Month'
                    : operationsPeriod === 'day'
                      ? 'Day over Day'
                      : 'Week over Week',
            width: isLargeScreen ? 220 : 200,
            renderCell: (params: GridRenderCellParams<OperationsBudgetedVsScheduledHoursStatsDataset>) => {
                const previousGap = Math.abs(params.row.previousPeriod.gap);
                const currentGap = Math.abs(params.row.gap);

                const periodOverPeriod = currentGap / previousGap - 1;
                const hasImproved = currentGap <= previousGap;
                const color = hasImproved ? '#006B75' : '#EC3232';

                return (
                    <Box display="flex" gap="4px" alignItems="center">
                        {!Number.isFinite(periodOverPeriod) || Number.isNaN(periodOverPeriod) ? null : !hasImproved ? (
                            <PiArrowUpRight size={16} color={color} />
                        ) : (
                            <PiArrowDownRight size={16} color={color} />
                        )}
                        <Typography
                            variant="body1"
                            fontSize={{ lg: '16px', xs: '14px' }}
                            fontWeight={500}
                            lineHeight="150%"
                            color={color}
                        >
                            {!Number.isFinite(periodOverPeriod) || Number.isNaN(periodOverPeriod)
                                ? '-'
                                : Math.floor(Math.abs(periodOverPeriod) * 1000) / 10 + '%'}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: 'budgetedHours',
            flex: 1,
            headerName: 'Budgeted',
            width: isLargeScreen ? 220 : 200,
            renderCell: (params: GridRenderCellParams<OperationsBudgetedVsScheduledHoursStatsDataset>) => (
                <Typography
                    variant="body1"
                    fontSize={{ lg: '16px', xs: '14px' }}
                    fontWeight={500}
                    lineHeight="150%"
                    sx={({ palette }) => ({
                        color: palette.grey[900],
                    })}
                >
                    {params.value + ' hr'}
                </Typography>
            ),
        },
        {
            field: 'scheduledHours',
            flex: 1,
            headerName: 'Scheduled',
            width: isLargeScreen ? 220 : 200,
            renderCell: (params: GridRenderCellParams<OperationsBudgetedVsScheduledHoursStatsDataset>) => (
                <Typography
                    variant="body1"
                    fontSize={{ lg: '16px', xs: '14px' }}
                    fontWeight={500}
                    lineHeight="150%"
                    sx={({ palette }) => ({
                        color: palette.grey[900],
                    })}
                >
                    {params.value + ' hr'}
                </Typography>
            ),
        },
    ];

    return (
        <SectionLayout
            title="Budgeted v.s. Scheduled Hours"
            dateGroupTitle="Work Hours"
            dateOptions={dateOptions}
            chart={
                <CustomMultilineChart
                    data={chartData}
                    onPointClick={setSelectedDate}
                    selectedStartDate={selectedDate}
                    legend
                    tooltipComponent={<CustomMultilineChartTooltip unitOfMeasurement=" hr" />}
                />
            }
            table={<Table rows={tableData} columns={columns} rowSelectionModel={branchId} />}
        />
    );
};

export default BudgetedVsScheduledHoursSection;
