import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';

import { useGetChosenCareLocations } from '~/api/queries/assignments/getChoseCareLocations';

import { isOnCurrentShiftAtom } from './atom';
import { callsAtom } from './testData';
import { ECall } from './types.d';

export const useCalls = () => {
    const [calls, setCall] = useAtom(callsAtom);
    const isOnCurrentShift = useAtomValue(isOnCurrentShiftAtom);

    const { data: careLocationsIds } = useGetChosenCareLocations();

    const updateCall = useCallback(
        (id: number, alert: ECall.Call) => {
            setCall((prev) => {
                return prev.map((a) => (a.id === id ? alert : a));
            });
        },
        [setCall]
    );

    const claimCall = useCallback(
        (id: number, caregiver: ECall.Caregiver) => {
            setCall((prev) => {
                return prev.map((a) =>
                    a.id === id ? { ...a, status: ECall.CallStatus.InProgress, claimedCaregiver: caregiver } : a
                );
            });
        },
        [setCall]
    );

    return useMemo(
        () => ({
            calls: careLocationsIds?.length && isOnCurrentShift ? calls : [],
            updateCall,
            claimCall,
        }),
        [calls, careLocationsIds, isOnCurrentShift, updateCall, claimCall]
    );
};
