import { useAtom } from 'jotai';
import { orderBy } from 'lodash';
import { useSelector } from 'react-redux';

import { useResidentsQuery } from '~/api/queries/residents';
import { ReduxStore } from '~/types/redux';

import { unscheduledTaskResidentAtom } from '../../../state/atom';

export default function useSelectResidentStep() {
    const { branchId } = useSelector((state: ReduxStore) => state.session.sessionData);
    const [selectedResident, setSelectedResident] = useAtom(unscheduledTaskResidentAtom);

    const { data: residentsList, isLoading, isError } = useResidentsQuery({ branchId: branchId! });

    return {
        residentsList: orderBy(
            residentsList,
            (resident) => `${resident.firstName} ${resident.lastName}`.trim().toLocaleLowerCase(),
            'asc'
        ),
        isLoading,
        isError,
        selectedResident,
        setSelectedResident,
    };
}
