import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CategoricalChartState } from 'recharts/types/chart/types';
import { AnyAction } from 'redux';

export const useChartClick = (setSelectedStartDate: (date: string) => AnyAction) => {
    const dispatch = useDispatch();

    return useCallback(
        (e: CategoricalChartState) => {
            if (
                !setSelectedStartDate ||
                typeof e !== 'object' ||
                e === null ||
                !('activeLabel' in e) ||
                e.activeTooltipIndex === 0
            ) {
                return;
            }
            dispatch(setSelectedStartDate(e.activeLabel!));
        },
        [setSelectedStartDate]
    );
};
