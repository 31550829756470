import { useAtom } from 'jotai';

import { unscheduledTaskResidentAtom } from '../../../state/atom';

export default function useResidentInfo() {
    const [resident] = useAtom(unscheduledTaskResidentAtom);

    const photo = resident?.photo ?? null;
    const fullName = resident ? `${resident.firstName} ${resident.lastName}` : undefined;

    return {
        resident: { ...(resident ?? {}), fullName },
        photo,
    };
}
