import { Box, IconButton, Skeleton, Stack, Typography, styled } from '@mui/material';
import { useTheme } from '@mui/material';
import { ArrowUUpLeft, CaretLeft, CaretRight } from '@phosphor-icons/react';
import { DateTime, Interval } from 'luxon';
import React from 'react';

import { VerticalSeparator } from '~/scheduling/components/shared';
import { useWeekInterval } from '~/scheduling/useWeekInterval';

export type SlotStatus = 'filled' | 'open' | 'pending';

const colorBySlotStatus: Record<SlotStatus, string> = {
    filled: 'primary.400',
    open: 'secondary.500',
    pending: 'grey.300',
} as const;

const formatPeriod = ({ start, end }: Interval<true>) =>
    start.month === end.month
        ? start.toFormat('MMMM') // e.g. "January"
        : `${start.toFormat('MMM')}/${end.toFormat('MMM')}`; // e.g. "Jan/Feb"

const CalendarContainer = styled(Stack)(({ theme: { palette } }) => ({
    backgroundColor: 'white',
    gap: '12px',
    borderBottom: `1px solid ${palette.grey[100]}`,
}));

const CalendarInfoRow = styled(Stack)({
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',
});

const CalendarMonthText = styled(Typography)({
    fontSize: '16px',
    fontWeight: 700,
});

const CalendarJumpButton = () => {
    const { setWeekInterval } = useWeekInterval();

    return (
        <Stack
            direction="row"
            alignItems="center"
            spacing="4px"
            onClick={() => setWeekInterval(DateTime.now())}
            sx={({ palette }) => ({
                height: '24px',
                ml: '-8px',
                px: '8px',
                borderRadius: '6px',
                cursor: 'pointer',
                userSelect: 'none',
                '&:hover': { bgcolor: palette.grey[50] },
                '&:active': { bgcolor: palette.grey[100] },
            })}
        >
            <ArrowUUpLeft weight="bold" />
            <Typography fontSize="13px" fontWeight={600} lineHeight="16px">
                This Week
            </Typography>
        </Stack>
    );
};

const CalendarArrowButtons = () => {
    const { weekInterval, setWeekInterval } = useWeekInterval();

    if (!weekInterval) return null;

    return (
        <Stack direction="row" spacing="8px" ml="auto">
            <CalendarArrowButton onClick={() => setWeekInterval(weekInterval.start.minus({ weeks: 1 }))}>
                <CaretLeft weight="bold" />
            </CalendarArrowButton>
            <CalendarArrowButton onClick={() => setWeekInterval(weekInterval.start.plus({ weeks: 1 }))}>
                <CaretRight weight="bold" />
            </CalendarArrowButton>
        </Stack>
    );
};

const CalendarArrowButton = styled(IconButton)({
    color: 'white',
    backgroundColor: '#478E94',
    width: '32px',
    height: '32px',
    '& .MuiSvgIcon-root': { fontSize: 16 },
});

const CalendarDaysRow = styled(Stack)({
    flexDirection: 'row',
});

const CalendarDay = ({ day, slotStatuses }: { day: DateTime; slotStatuses: SlotStatus[] }) => {
    const { palette } = useTheme();

    const now = DateTime.now();
    const isPast = day < now.startOf('day');
    const isToday = day.hasSame(now, 'day');

    return (
        <Stack flex={1} alignItems="center" spacing="4px" sx={{ opacity: isPast ? 0.75 : 1 }}>
            <Typography color={palette.grey[600]} fontSize="13px" fontWeight={isToday ? 800 : 400} lineHeight="16px">
                {day.toFormat('ccccc')}
            </Typography>
            <Typography color={palette.grey[600]} fontSize="15px" fontWeight={isToday ? 800 : 500}>
                {day.day}
            </Typography>
            <Stack direction="row" spacing="4px">
                {slotStatuses.map((slotStatus, i) => (
                    <CalendarDayDot key={i} bgcolor={colorBySlotStatus[slotStatus]} />
                ))}
            </Stack>
        </Stack>
    );
};

const CalendarDayDot = styled(Box)({
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    margin: '2px !important',
});

const CalendarHeader = ({ slotStatuses }: { slotStatuses: SlotStatus[][] | null }) => {
    const { weekInterval, weekDays } = useWeekInterval();

    const now = DateTime.now();
    const isCurrentWeek = weekInterval?.contains(now);

    if (!slotStatuses || !weekInterval || !weekDays) {
        return (
            <CalendarContainer p="12px 16px">
                <CalendarInfoRow>
                    <Skeleton height="32px" sx={{ flex: 1 }} />
                    <Skeleton variant="circular" width="32px" height="32px" />
                    <Skeleton variant="circular" width="32px" height="32px" />
                </CalendarInfoRow>
                <Skeleton width="100%" height="56px" />
            </CalendarContainer>
        );
    }

    return (
        <CalendarContainer p="12px">
            <CalendarInfoRow p="0 12px">
                <CalendarMonthText>{formatPeriod(weekInterval)}</CalendarMonthText>
                {!isCurrentWeek && (
                    <>
                        <VerticalSeparator height="20px" />
                        <CalendarJumpButton />
                    </>
                )}
                <CalendarArrowButtons />
            </CalendarInfoRow>
            <CalendarDaysRow>
                {weekDays.map((weekDay, index) => (
                    <CalendarDay key={index} day={weekDay} slotStatuses={slotStatuses[index]} />
                ))}
            </CalendarDaysRow>
        </CalendarContainer>
    );
};

export default CalendarHeader;
