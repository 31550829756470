import { TabContext } from '@mui/lab';
import { Box } from '@mui/system';
import { ComponentProps } from 'react';
import React from 'react';

const CustomTabContext = (props: ComponentProps<typeof TabContext>) => (
    <Box
        sx={{
            '& .MuiTabs-flexContainer': { gap: '20px' },
            '& .MuiTabPanel-root': { p: 0 },
        }}
    >
        <TabContext {...props} />
    </Box>
);

export default CustomTabContext;
