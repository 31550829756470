import { Capacitor } from '@capacitor/core';
import { usePostHog } from 'posthog-js/react';
import { useSelector } from 'react-redux';

import { useBranchQuery } from '~/api/queries/branch';
import { usePermissionsQuery } from '~/api/queries/permissions';
import { useGeolocation } from '~/contexts/GeolocationContext';
import { isPostHogEnabled } from '~/helpers/PostHogHandler';
import { usePermissions } from '~/permissions/utils';
import { ReduxStore } from '~/types/redux';

type GeofencingState = 'not-applicable' | 'inside' | 'outside' | 'prompt' | 'unknown';

export const useGeofencing = (branchId?: number): GeofencingState => {
    const { isSuccess: isPermitLoaded } = usePermissionsQuery();
    const hasPermission = usePermissions();
    const { userId } = useSelector((state: ReduxStore) => state.session.sessionData);
    const { isPostHogLoaded } = useSelector((state: ReduxStore) => state.session);
    const posthog = usePostHog();

    const { data: branchData } = useBranchQuery(branchId);
    const { permissionStatus, position, isPositionAccurate, isUserInsideWorkingArea, distance } = useGeolocation();

    if (!Capacitor.isNativePlatform()) return 'not-applicable';

    if (!isPermitLoaded) return 'unknown';

    // Although we call it a 'permission', geofencing is more like the opposite
    if (!hasPermission('Community', 'require-geofencing')) return 'not-applicable';

    if (!branchData) return 'unknown';

    const { locationLatitude, locationLongitude, geofencingEnabled } = branchData;

    if (!geofencingEnabled) return 'not-applicable';

    if (
        permissionStatus?.location.includes('prompt') ||
        permissionStatus?.location === 'denied' ||
        isPositionAccurate === false
    ) {
        return 'prompt';
    }

    if (!position) return 'unknown';

    const isInsideWorkingArea = isUserInsideWorkingArea({
        branchLat: locationLatitude!,
        branchLon: locationLongitude!,
        maxDistance: 0.5,
    });

    // Monitoring during launch, we may remove this later
    posthog.capture('geofencing:monitor', {
        user_id: userId,
        isPositionAccurate,
        distance,
        positionAccuracy: position?.coords.accuracy,
        isInsideWorkingArea,
    });

    if (!isInsideWorkingArea) {
        if (isPostHogEnabled && isPostHogLoaded && posthog.isFeatureEnabled('geofencing')) {
            return 'outside';
        }
    }

    return 'inside';
};
