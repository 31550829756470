import { Box, FormControl, FormHelperText, OutlinedInput, Typography } from '@mui/material';
import React from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input/input';

import { pxToRem } from '~/components/theme/typography';

type Props = {
    name: string;
    label: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: Control<any>;
    placeholder: string;
    error?: string;
    rules?: RegisterOptions;
    onChange?: (value: string) => void;
};

const PhoneInputWrapper = React.forwardRef<HTMLInputElement, React.ComponentProps<typeof PhoneInput>>(
    function PhoneInputWrapper(props, ref) {
        return <PhoneInput {...props} ref={ref} />;
    }
);

const PhoneNumberAuthInput = ({ name, label, control, placeholder, error, rules, onChange }: Props) => (
    <Box>
        <FormControl variant="outlined" fullWidth error={!!error}>
            <Typography
                component="label"
                htmlFor={name}
                sx={{
                    fontSize: { xs: pxToRem(14) },
                    color: '#6F6F79',
                    fontWeight: 600,
                    lineHeight: 1.45,
                    mb: { xs: pxToRem(8) },
                    display: 'block',
                }}
            >
                {label}
            </Typography>
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field }) => (
                    <OutlinedInput
                        {...field}
                        id={name}
                        inputComponent={PhoneInputWrapper}
                        inputProps={{
                            placeholder,
                            defaultCountry: 'US',
                            type: 'tel',
                        }}
                        endAdornment={null}
                        placeholder={placeholder}
                        fullWidth
                        onChange={(value) => {
                            field.onChange(value ?? '');
                            onChange?.(value ? (typeof value === 'string' ? value : value.target.value) : '');
                        }}
                        sx={{
                            borderRadius: pxToRem(8),
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#DEDEE0',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#DEDEE0',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#006B75',
                                },
                            },
                            '& .MuiInputBase-input': {
                                fontSize: pxToRem(16),
                                p: pxToRem(16),
                                lineHeight: 1.5,
                                '&::placeholder': {
                                    color: '#B1B2B7',
                                    opacity: 1,
                                },
                            },
                        }}
                    />
                )}
            />
            <FormHelperText
                sx={{
                    fontSize: { xs: pxToRem(12), lg: pxToRem(13) },
                }}
            >
                {error}
            </FormHelperText>
        </FormControl>
    </Box>
);

export default PhoneNumberAuthInput;
