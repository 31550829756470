import { Box, Theme, styled, useMediaQuery } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ShiftOption } from '@allie/utils/src/shifts';

import { useGetAssignments } from '~/api/queries/assignments/getAssignments';
import { useGetCareLocations } from '~/api/queries/assignments/getCareLocations';
import { useGetChosenCareLocations } from '~/api/queries/assignments/getChoseCareLocations';
import { useGetZones } from '~/api/queries/zones/getZones';
import { ALL_ASSIGNMENTS_ID } from '~/constants/filters';
import { usePermissions } from '~/permissions/utils';
import { DailyTasksByTabStrict, TabKey } from '~/types/dailyTasks.d';
import { ReduxStore } from '~/types/redux';

import {
    isLocationSelectorDialogOpenAtom,
    isShiftSelectorDialogOpenAtom,
    isSortDialogOpenAtom,
    selectedAssignmentIdsAtom,
    selectedDateAtom,
} from '../../atom';
import { shouldUseAssignments } from '../../helpers';
import { TabSelector } from '../TabSelector';

import HeaderChip from './components/HeaderChip';
import { SortButton } from './components/SortButton';
import DatePicker from './components/filters/date/DatePicker';

const MAX_DAYS_TO_SHOW = 3;

const Container = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary[500],

    [theme.breakpoints.up('sm')]: {
        backgroundColor: theme.palette.common.white,
    },
}));

const Content = styled(Box)(({ theme }) => ({
    padding: '12px 16px 8px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

    [theme.breakpoints.up('sm')]: {
        justifyContent: 'flex-end',
    },
}));

const HeaderRow = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '100%',
}));

const FiltersContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    flexWrap: 'wrap',

    [theme.breakpoints.up('sm')]: {
        gap: '16px',
    },
}));

type HeaderV2Props = {
    selectedTab: TabKey;
    tasksByTab: DailyTasksByTabStrict;
    onTabChange: (tab: TabKey) => void;
    currentShiftDayDate: string;
    selectedShiftId: number;
    nearbyShifts: ShiftOption[];
    currentShiftId: number;
};

export default function HeaderV2({
    selectedTab,
    tasksByTab,
    onTabChange,
    currentShiftDayDate,
    nearbyShifts,
    selectedShiftId,
    currentShiftId,
}: HeaderV2Props) {
    const isMobile = useMediaQuery<Theme>(({ breakpoints }) => breakpoints.down('sm'));
    const hasPermission = usePermissions();

    // TODO - get rid of this and use atoms
    const { company, branch } = useSelector((state: ReduxStore) => state.session.sessionData);

    const isLiveCallsTab = selectedTab === TabKey.LIVE_CALLS;
    const { data: zones } = useGetZones(!isLiveCallsTab);
    const { data: assignments } = useGetAssignments(!isLiveCallsTab);
    const { data: careLocations } = useGetCareLocations(isLiveCallsTab);
    const { data: careLocationsIds } = useGetChosenCareLocations();

    const locations = useMemo(() => {
        if (selectedTab === TabKey.LIVE_CALLS) {
            return careLocations;
        }

        return shouldUseAssignments(company, branch)
            ? assignments?.map((assignment) => ({ id: assignment.assignmentId, name: assignment.assignmentName }))
            : zones?.map((zone) => ({ id: zone.zoneId, name: zone.zoneName }));
    }, [company, branch, assignments, selectedTab, careLocations, zones]);

    const [selectedDate, setSelectedDate] = useAtom(selectedDateAtom);

    const assignmentIds = useAtomValue(selectedAssignmentIdsAtom);
    const toggleShiftSelectorDialog = useSetAtom(isShiftSelectorDialogOpenAtom);
    const toggleLocationSelectorDialog = useSetAtom(isLocationSelectorDialogOpenAtom);
    const toggleSortDialog = useSetAtom(isSortDialogOpenAtom);

    const currentShiftDateTzDateTime = DateTime.fromISO(currentShiftDayDate);

    const handleDateChange = (newDate: DateTime<true> | null) => {
        if (newDate) {
            setSelectedDate(newDate);
        }
    };

    const selectedShiftsLabel = useMemo(() => {
        const selectedShiftName = nearbyShifts.find((shift) => shift.id === selectedShiftId)?.name;

        return selectedShiftName ? `${selectedShiftName}${selectedShiftId === currentShiftId ? ' (current)' : ''}` : '';
    }, [currentShiftId, nearbyShifts, selectedShiftId]);

    const selectedLocationsLabel = useMemo(() => {
        // deciding which assignmentIds to use
        // live calls: the care locations to receive ecall notifications
        // other tabs: simple location filter
        const selectedAssignmentIds = selectedTab === TabKey.LIVE_CALLS ? careLocationsIds : assignmentIds;

        if (selectedAssignmentIds?.includes(ALL_ASSIGNMENTS_ID)) {
            return locations?.map(({ name }) => name).join(', ') ?? '';
        }

        const zonesNames = locations?.filter(({ id }) => selectedAssignmentIds?.includes(id)) ?? [];

        return zonesNames.map(({ name }) => name).join(', ');
    }, [locations, assignmentIds, careLocationsIds, selectedTab]);

    return (
        <Container>
            <Content>
                <HeaderRow>
                    <DatePicker
                        selectedDate={selectedDate}
                        handleDateChange={handleDateChange}
                        minDate={
                            hasPermission('Community', 'read-all-resident-actions')
                                ? undefined
                                : DateTime.now().minus({ days: MAX_DAYS_TO_SHOW })
                        }
                        maxDate={currentShiftDateTzDateTime.isValid ? currentShiftDateTzDateTime : DateTime.now()}
                        hideArrows={selectedTab === TabKey.LIVE_CALLS}
                    />
                </HeaderRow>
                <FiltersContainer>
                    <HeaderRow order={1}>
                        <HeaderChip
                            title="Shift"
                            label={selectedShiftsLabel}
                            isDisabled={selectedTab === TabKey.LIVE_CALLS}
                            onClick={toggleShiftSelectorDialog}
                        />
                    </HeaderRow>
                    <HeaderRow order={isMobile ? 3 : 2} sx={{ maxWidth: { sm: '210px', md: '350px' } }}>
                        <HeaderChip
                            title={selectedTab === TabKey.LIVE_CALLS ? 'Call Location' : 'Location'}
                            label={selectedLocationsLabel}
                            onClick={toggleLocationSelectorDialog}
                        />
                    </HeaderRow>
                    <HeaderRow order={isMobile ? 2 : 3} marginLeft="auto">
                        <SortButton onClick={toggleSortDialog} />
                    </HeaderRow>
                </FiltersContainer>
            </Content>
            <TabSelector tabs={tasksByTab} selectedTab={selectedTab} onTabChange={onTabChange} />
        </Container>
    );
}
