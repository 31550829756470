import { Box, Button, Typography, Zoom, styled } from '@mui/material';
import React from 'react';
import { HiMicrophone as MicrophoneIcon } from 'react-icons/hi';

import CustomModal from '~/scheduling/components/CustomModal';

type Props = {
    showConfirmation: boolean;
    onAllow: () => void;
};

const IconContainer = styled(Box)(({ theme }) => ({
    color: '#006B75',
    width: '100px',
    height: '100px',
    borderRadius: '120px',
    backgroundColor: theme.palette.grey[50],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '24px',
}));

const ActivateMicConfirmation = (props: Props) => {
    const { showConfirmation, onAllow } = props;

    return (
        <CustomModal isOpen={showConfirmation} onClose={onAllow} center>
            <Box display="flex" flexDirection="column" alignItems="center" margin="16px">
                <IconContainer>
                    <Zoom
                        in={showConfirmation}
                        style={{
                            transitionDelay: showConfirmation ? '300ms' : '0ms',
                        }}
                    >
                        <Box>
                            <MicrophoneIcon size={36} />
                        </Box>
                    </Zoom>
                </IconContainer>
                <Typography
                    variant="body1"
                    fontWeight={700}
                    fontSize="20px"
                    lineHeight="32px"
                    mb="8px"
                    textAlign="center"
                    color={({ palette }) => palette.grey[900]}
                    sx={{ textWrap: 'balance' }}
                >
                    Activate Microphone
                </Typography>
                <Typography
                    variant="body2"
                    mb="24px"
                    lineHeight="18px"
                    textAlign="center"
                    fontSize="14px"
                    color={({ palette }) => palette.grey[900]}
                    sx={{ textWrap: 'balance' }}
                >
                    Instead of typing, you now can record a note in English or other languages by speaking to the
                    microphone. Let us write down your words for you by allowing access to your microphone.
                </Typography>
                <Box
                    mt="8px"
                    display="flex"
                    justifyContent="center"
                    sx={{
                        width: { xs: '100%', md: '200px' },
                    }}
                >
                    <Button fullWidth color="primary" onClick={onAllow}>
                        Allow
                    </Button>
                </Box>
            </Box>
        </CustomModal>
    );
};

export default ActivateMicConfirmation;
