import React from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/api/common';

import { GetDashboardStaffingRequirements } from '../../types/dashboard/getStaffingRequirements';

export const useGetStaffingRequirements = (teamId?: number) => {
    const branchId = useBranchId()!;
    const token = useToken();

    const queryFn = React.useCallback(async () => {
        const options = { headers: { Authorization: token } };

        const { data } = await api.get<GetDashboardStaffingRequirements.Response>(
            `/scheduling/${branchId}/dashboard/staffing-requirements/?teamId=${teamId}`,
            options
        );

        return data.response;
    }, [branchId, token, teamId]);

    return useErrorHandledQuery({
        queryKey: ['staffing-requirements', branchId, teamId],
        queryFn,
        staleTime: 900000,
        enabled: !!branchId && !!teamId,
    });
};

export const useExportStaffingRequirementsQuery = (teamId?: number) => {
    const branchId = useBranchId()!;
    const token = useToken();

    const queryFn = React.useCallback(async () => {
        const options = {
            headers: { Authorization: token },
            responseType: 'blob' as const,
        };

        const { data } = await api.get<ArrayBuffer>(
            `/scheduling/${branchId}/dashboard/staffing-requirements/export?teamId=${teamId}`,
            options
        );
        return data;
    }, [branchId, token, teamId]);

    return useErrorHandledQuery({
        queryKey: ['staffing-requirements-export', branchId, teamId],
        queryFn,
        enabled: false,
    });
};
