/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ComponentOverride } from '.';

export const MuiToggleButton: ComponentOverride<'MuiToggleButton'> = {
    defaultProps: {},
    styleOverrides: {
        root: ({ theme: { palette } }) => ({
            color: palette.grey[400],
            fontWeight: 400,
            textTransform: 'none',
            border: '1px solid transparent',
            borderRadius: '8px !important',
            '&.Mui-selected': {
                color: palette.primary[500],
                backgroundColor: 'white',
                fontWeight: 600,
                borderColor: palette.primary[600],
                '&:hover': {
                    backgroundColor: palette.primary[100],
                },
            },
        }),
        sizeSmall: {
            fontSize: '14px',
            lineHeight: '20px',
            padding: '6px 16px',
        },
    },
};

export const MuiToggleButtonGroup: ComponentOverride<'MuiToggleButtonGroup'> = {
    defaultProps: {
        exclusive: true,
        size: 'small',
    },
    styleOverrides: {
        root: ({ theme: { palette } }) => ({
            color: palette.grey[400],
            backgroundColor: palette.grey[50],
            padding: 2,
            gap: 4,
            borderRadius: 8,
        }),
    },
};
