export const EARLY_IN_SHIFT_DIALOG_ANALYTICS = {
    YES_BUTTON: 'early-in-shift-dialog-button-yes',
    NO_BUTTON: 'early-in-shift-dialog-button-cancel',
};

export const NURSE_CALLS_DIALOG_ANALYTICS = {
    CONTINUE_PROMPT_BUTTON: 'nurse-calls-dialog-button-continue-prompt',
    SUBMIT_BUTTON: 'nurse-calls-dialog-button-submit',
    NO_NURSE_CALLS_BUTTON: 'nurse-calls-dialog-button-cancel',
};

export const RECENT_UNSCHEDULED_TASKS_DIALOG_ANALYTICS = {
    SUBMIT_BUTTON: 'recent-unscheduled-tasks-dialog-button-submit',
    NO_BUTTON: 'recent-unscheduled-tasks-dialog-button-cancel',
};

export const ADD_RESIDENT_NOTE_DIALOG_ANALYTICS = {
    TRANSCRIPTION_BUTTON: 'add-resident-note-transcription-button',
};

export const ADD_COMMENT_DIALOG_ANALYTICS = {
    TRANSCRIPTION_BUTTON: 'add-comment-transcription-button',
};

export const REJECT_TASK_DIALOG_ANALYTICS = {
    TRANSCRIPTION_BUTTON: 'reject-task-transcription-button',
};

export const EDIT_TASK_DIALOG_ANALYTICS = {
    TRANSCRIPTION_BUTTON: 'edit-task-transcription-button',
};
