import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useBranchesByRegionQuery } from '~/api/queries/companyInfo';
import { useOperationsBranchStatsQuery, useOperationsOverallStatsQuery } from '~/api/queries/dashboard/operations';
import {
    OperationsBudgetedVsScheduledHoursStatsDataset,
    OperationsPeriod,
    OperationsRecurringOpenHoursStatsDataset,
    OperationsScheduledAgencyHoursStatsDataset,
    OperationsScheduledOvertimeHoursStatsDataset,
} from '~/types/operationsDashboard';
import { ReduxStore } from '~/types/redux';

import { selectedDateAtom } from './testData';

const buildTableData = <T extends { branchId: number; startDate: string }>(
    data: T[],
    selectedDate: string | null
): (T & { id: string })[] =>
    data
        .filter(({ startDate }) => !selectedDate || startDate === selectedDate)
        .map((item) => ({ id: item.branchId.toString(), ...item }));

const buildBudgetedVsScheduledChartData = (
    data: OperationsBudgetedVsScheduledHoursStatsDataset[],
    selectedDate: string | null
) => {
    const tableData = buildTableData(data, selectedDate);

    const chartData = data.map((item) => ({
        startDate: item.startDate,
        value: [
            { key: 'Budgeted', value: item.budgetedHours },
            { key: 'Scheduled', value: item.scheduledHours },
        ],
    }));

    return { tableData, chartData };
};

const buildScheduledOvertimeHoursChartData = (
    data: OperationsScheduledOvertimeHoursStatsDataset[],
    selectedDate: string | null
) => {
    const tableData = buildTableData(data, selectedDate);

    const chartData = data.map((item) => ({
        startDate: item.startDate,
        value: [{ key: 'Scheduled Overtime', value: item.overtimeHours }],
    }));

    return { tableData, chartData };
};
const buildScheduledAgencyHoursChartData = (
    data: OperationsScheduledAgencyHoursStatsDataset[],
    selectedDate: string | null
) => {
    const tableData = buildTableData(data, selectedDate);

    const chartData = data.map((item) => ({
        startDate: item.startDate,
        value: [{ key: 'Scheduled Agency', value: item.agencyHours }],
    }));

    return { tableData, chartData };
};

const buildRecurringOpenHoursChartData = (
    data: OperationsRecurringOpenHoursStatsDataset[],
    selectedDate: string | null
) => {
    const tableData = buildTableData(data, selectedDate);

    const chartData = data.map((item) => ({
        startDate: item.startDate,
        value: [{ key: 'Open Hours', value: item.openHours }],
    }));

    return { tableData, chartData };
};

interface SchedulingModuleParams {
    branchId?: number;
    regionId?: number;
    careType?: string;
    operationsPeriod: OperationsPeriod;
}

export const useSchedulingModules = (params: SchedulingModuleParams) => {
    const selectedDate = useAtomValue(selectedDateAtom);
    const { regionId, branchId, operationsPeriod, careType } = params;
    const {
        sessionData: { companyId },
    } = useSelector((state: ReduxStore) => state.session);

    const { data: overallStatsData } = useOperationsOverallStatsQuery(
        !branchId
            ? {
                  companyId: Number.isInteger(companyId) ? companyId : undefined,
                  groupBy: operationsPeriod,
                  regionId: regionId,
                  branchId: branchId,
                  careType,
              }
            : undefined
    );
    const { data: branchesByRegionData } = useBranchesByRegionQuery({ companyId, regionId, branchId });

    const { data: branchStatsData } = useOperationsBranchStatsQuery(
        branchId
            ? {
                  groupBy: operationsPeriod,
                  branchId,
                  careType,
              }
            : undefined
    );

    const overallData = useMemo(() => {
        if (!overallStatsData) {
            return;
        }

        const filteredByBranch = overallStatsData.sections.map((sectionData) => ({
            ...sectionData,
            datasets: sectionData.datasets.filter((dataset) => !branchId || dataset.branchId === branchId),
        }));

        const budgetedVsScheduledHoursSectionData = (filteredByBranch.find(
            ({ section, show }) => section === 'BUDGETED_SCHEDULED_HOURS' && show
        )?.datasets ?? []) as OperationsBudgetedVsScheduledHoursStatsDataset[];

        const scheduledOvertimeHoursSectionData = (filteredByBranch.find(
            ({ section, show }) => section === 'SCHEDULED_OVERTIME_HOURS' && show
        )?.datasets ?? []) as OperationsScheduledOvertimeHoursStatsDataset[];

        const scheduledAgencyHoursSectionData = (filteredByBranch.find(
            ({ section, show }) => section === 'SCHEDULED_AGENCY_HOURS' && show
        )?.datasets ?? []) as OperationsScheduledAgencyHoursStatsDataset[];

        const weeklyRecurringOpenHoursSectionData = (filteredByBranch.find(
            ({ section, show }) => section === 'RECURRING_OPEN_HOURS' && show
        )?.datasets ?? []) as OperationsRecurringOpenHoursStatsDataset[];

        return {
            budgetedVsScheduledHoursSectionData: buildBudgetedVsScheduledChartData(
                budgetedVsScheduledHoursSectionData,
                selectedDate
            ),
            scheduledOvertimeHoursSectionData: buildScheduledOvertimeHoursChartData(
                scheduledOvertimeHoursSectionData,
                selectedDate
            ),
            scheduledAgencyHoursSectionData: buildScheduledAgencyHoursChartData(
                scheduledAgencyHoursSectionData,
                selectedDate
            ),
            weeklyRecurringOpenHoursSectionData: buildRecurringOpenHoursChartData(
                weeklyRecurringOpenHoursSectionData,
                selectedDate
            ),
        };
    }, [overallStatsData, selectedDate, branchId]);

    const branchData = useMemo(() => {
        if (!branchStatsData) {
            return;
        }

        const filteredByBranch = branchStatsData.sections.map((sectionData) => ({
            ...sectionData,
            datasets: sectionData.datasets.filter((dataset) => !branchId || dataset.branchId === branchId),
        }));

        const budgetedVsScheduledHoursSectionData = (filteredByBranch.find(
            ({ section, show }) => section === 'BUDGETED_SCHEDULED_HOURS' && show
        )?.datasets ?? []) as OperationsBudgetedVsScheduledHoursStatsDataset[];

        const scheduledOvertimeHoursSectionData = (filteredByBranch.find(
            ({ section, show }) => section === 'SCHEDULED_OVERTIME_HOURS' && show
        )?.datasets ?? []) as OperationsScheduledOvertimeHoursStatsDataset[];

        const scheduledAgencyHoursSectionData = (filteredByBranch.find(
            ({ section, show }) => section === 'SCHEDULED_AGENCY_HOURS' && show
        )?.datasets ?? []) as OperationsScheduledAgencyHoursStatsDataset[];

        const weeklyRecurringOpenHoursSectionData = (filteredByBranch.find(
            ({ section, show }) => section === 'RECURRING_OPEN_HOURS' && show
        )?.datasets ?? []) as OperationsRecurringOpenHoursStatsDataset[];

        return {
            budgetedVsScheduledHoursSectionData: buildBudgetedVsScheduledChartData(
                budgetedVsScheduledHoursSectionData,
                selectedDate
            ),
            scheduledOvertimeHoursSectionData: buildScheduledOvertimeHoursChartData(
                scheduledOvertimeHoursSectionData,
                selectedDate
            ),
            scheduledAgencyHoursSectionData: buildScheduledAgencyHoursChartData(
                scheduledAgencyHoursSectionData,
                selectedDate
            ),
            weeklyRecurringOpenHoursSectionData: buildRecurringOpenHoursChartData(
                weeklyRecurringOpenHoursSectionData,
                selectedDate
            ),
        };
    }, [branchStatsData, selectedDate, branchId]);

    return useMemo(() => {
        if (!overallData && !branchData) {
            return {
                branchesByRegionData,
                budgetedVsScheduledHoursSectionData: undefined,
                scheduledOvertimeHoursSectionData: undefined,
                scheduledAgencyHoursSectionData: undefined,
                weeklyRecurringOpenHoursSectionData: undefined,
            };
        }

        return {
            branchesByRegionData,
            ...(overallData ?? branchData!),
        };
    }, [overallData, branchData, branchesByRegionData]);
};
