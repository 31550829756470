import { atom } from 'jotai';
import { range } from 'lodash';
import { DateTime } from 'luxon';

import { OperationsPeriod } from '~/types/operationsDashboard';

const today = DateTime.now();
const getDate = (id: number, period: OperationsPeriod = 'month') => today.minus({ [period]: id });

export const getDateValue = (id: number, period: OperationsPeriod = 'month') => {
    const date = getDate(id, period);
    if (period === 'month') return date.toFormat('MMMM');
    return date.toFormat('MM/dd');
};

export const indexWeight = (idx: number, length: number) => (idx + 1) / ((length * (length + 1)) / 2);

export const getDateOptions = (period: OperationsPeriod) => range(6).map((i) => getDateValue(i, period));

export const currentMonth = getDate(0);
export const selectedDateAtom = atom<string | null>(null);

export const acuityBreakdownTableMock = [
    {
        startDate: { id: 5, value: getDate(5) },
        value: [
            { label: 'Portfolio', abbreviation: 'Portfolio', values: [48, 39, 7, 6] },
            { label: 'West Coast', abbreviation: 'West Coast', values: [48, 26, 12, 14] },
            { label: 'Southwest', abbreviation: 'Southwest', values: [38, 32, 15, 15] },
            { label: 'Mid Atlantic', abbreviation: 'Mid Atlantic', values: [34, 31, 19, 16] },
            { label: 'Southeast', abbreviation: 'Southeast', values: [53, 22, 13, 12] },
            { label: 'Midwest', abbreviation: 'Midwest', values: [68, 12, 9, 11] },
            { label: 'Hawaii', abbreviation: 'Hawaii', values: [29, 35, 22, 14] },
            { label: 'New England', abbreviation: 'New England', values: [29, 35, 22, 14] },
            { label: 'The Plains', abbreviation: 'The Plains', values: [46, 26, 18, 10] },
        ],
    },
    {
        startDate: { id: 4, value: getDate(4) },
        value: [
            { label: 'Portfolio', abbreviation: 'Portfolio', values: [45, 43, 7, 5] },
            { label: 'West Coast', abbreviation: 'West Coast', values: [52, 24, 15, 9] },
            { label: 'Southwest', abbreviation: 'Southwest', values: [41, 29, 19, 11] },
            { label: 'Mid Atlantic', abbreviation: 'Mid Atlantic', values: [36, 30, 18, 16] },
            { label: 'Southeast', abbreviation: 'Southeast', values: [50, 27, 11, 12] },
            { label: 'Midwest', abbreviation: 'Midwest', values: [72, 8, 12, 8] },
            { label: 'Hawaii', abbreviation: 'Hawaii', values: [31, 28, 24, 17] },
            { label: 'New England', abbreviation: 'New England', values: [29, 35, 22, 14] },
            { label: 'The Plains', abbreviation: 'The Plains', values: [47, 26, 12, 15] },
        ],
    },
    {
        startDate: { id: 3, value: getDate(3) },
        value: [
            { label: 'Portfolio', abbreviation: 'Portfolio', values: [47, 38, 8, 7] },
            { label: 'West Coast', abbreviation: 'West Coast', values: [49, 23, 16, 12] },
            { label: 'Southwest', abbreviation: 'Southwest', values: [37, 31, 21, 11] },
            { label: 'Mid Atlantic', abbreviation: 'Mid Atlantic', values: [33, 34, 17, 16] },
            { label: 'Southeast', abbreviation: 'Southeast', values: [54, 24, 10, 12] },
            { label: 'Midwest', abbreviation: 'Midwest', values: [69, 13, 10, 8] },
            { label: 'Hawaii', abbreviation: 'Hawaii', values: [32, 29, 23, 16] },
            { label: 'New England', abbreviation: 'New England', values: [29, 35, 22, 14] },
            { label: 'The Plains', abbreviation: 'The Plains', values: [48, 28, 16, 8] },
        ],
    },
    {
        startDate: { id: 2, value: getDate(2) },
        value: [
            { label: 'Portfolio', abbreviation: 'Portfolio', values: [44, 39, 9, 8] },
            { label: 'West Coast', abbreviation: 'West Coast', values: [47, 27, 14, 12] },
            { label: 'Southwest', abbreviation: 'Southwest', values: [42, 30, 20, 8] },
            { label: 'Mid Atlantic', abbreviation: 'Mid Atlantic', values: [35, 36, 15, 14] },
            { label: 'Southeast', abbreviation: 'Southeast', values: [56, 23, 12, 9] },
            { label: 'Midwest', abbreviation: 'Midwest', values: [71, 9, 10, 10] },
            { label: 'Hawaii', abbreviation: 'Hawaii', values: [30, 31, 26, 12] },
            { label: 'New England', abbreviation: 'New England', values: [29, 35, 22, 14] },
            { label: 'The Plains', abbreviation: 'The Plains', values: [45, 32, 18, 5] },
        ],
    },
    {
        startDate: { id: 1, value: getDate(1) },
        value: [
            { label: 'Portfolio', abbreviation: 'Portfolio', values: [43, 41, 7, 9] },
            { label: 'West Coast', abbreviation: 'West Coast', values: [50, 22, 18, 10] },
            { label: 'Southwest', abbreviation: 'Southwest', values: [39, 28, 21, 12] },
            { label: 'Mid Atlantic', abbreviation: 'Mid Atlantic', values: [32, 33, 19, 16] },
            { label: 'Southeast', abbreviation: 'Southeast', values: [52, 26, 14, 8] },
            { label: 'Midwest', abbreviation: 'Midwest', values: [67, 11, 11, 11] },
            { label: 'Hawaii', abbreviation: 'Hawaii', values: [28, 30, 27, 15] },
            { label: 'New England', abbreviation: 'New England', values: [29, 35, 22, 14] },
            { label: 'The Plains', abbreviation: 'The Plains', values: [40, 30, 20, 10] },
        ],
    },
    {
        startDate: { id: 0, value: currentMonth },
        value: [
            { label: 'Portfolio', abbreviation: 'Portfolio', values: [45, 35, 10, 10] },
            { label: 'West Coast', abbreviation: 'West Coast', values: [64, 22, 8, 6] },
            { label: 'Southwest', abbreviation: 'Southwest', values: [37, 38, 18, 7] },
            { label: 'Mid Atlantic', abbreviation: 'Mid Atlantic', values: [35, 35, 20, 10] },
            { label: 'Southeast', abbreviation: 'Southeast', values: [30, 22, 40, 8] },
            { label: 'Midwest', abbreviation: 'Midwest', values: [70, 20, 5, 5] },
            { label: 'Hawaii', abbreviation: 'Hawaii', values: [30, 30, 25, 15] },
            { label: 'New England', abbreviation: 'New England', values: [47, 32, 12, 9] },
            { label: 'The Plains', abbreviation: 'The Plains', values: [42, 35, 10, 13] },
        ],
    },
];

export const budgetedVsScheduledHoursDatasetMock = [
    {
        startDate: { id: 5, value: getDate(5) },
        value: [
            { key: 'Budgeted', value: 5000 },
            { key: 'Scheduled', value: 5100 },
        ],
    },
    {
        startDate: { id: 4, value: getDate(4) },
        value: [
            { key: 'Budgeted', value: 4990 },
            { key: 'Scheduled', value: 5087 },
        ],
    },
    {
        startDate: { id: 3, value: getDate(3) },
        value: [
            { key: 'Budgeted', value: 5100 },
            { key: 'Scheduled', value: 5029 },
        ],
    },
    {
        startDate: { id: 2, value: getDate(2) },
        value: [
            { key: 'Budgeted', value: 4950 },
            { key: 'Scheduled', value: 5100 },
        ],
    },
    {
        startDate: { id: 1, value: getDate(1) },
        value: [
            { key: 'Budgeted', value: 5100 },
            { key: 'Scheduled', value: 5208 },
        ],
    },
    {
        startDate: { id: 0, value: currentMonth },
        value: [
            { key: 'Budgeted', value: 5000 },
            { key: 'Scheduled', value: 5100 },
        ],
    },
];

export const budgetedVsScheduledHoursTableDataMock = [
    //Portfolio
    {
        startDate: { id: 5, value: getDate(5) },
        region: 'Portfolio',
        monthOverMonth: 0.03,
        budgeted: 4999,
        scheduled: 5305,
    },
    {
        startDate: { id: 4, value: getDate(4) },
        region: 'Portfolio',
        monthOverMonth: 0.03,
        budgeted: 5100,
        scheduled: 5208,
    },
    {
        startDate: { id: 3, value: getDate(3) },
        region: 'Portfolio',
        monthOverMonth: 0.03,
        budgeted: 4950,
        scheduled: 5100,
    },
    {
        startDate: { id: 2, value: getDate(2) },
        region: 'Portfolio',
        monthOverMonth: 0.03,
        budgeted: 5100,
        scheduled: 5029,
    },
    {
        startDate: { id: 1, value: getDate(1) },
        region: 'Portfolio',
        monthOverMonth: 0.03,
        budgeted: 4990,
        scheduled: 5087,
    },
    {
        startDate: { id: 0, value: currentMonth },
        region: 'Portfolio',
        monthOverMonth: 0.03,
        budgeted: 5000,
        scheduled: 5100,
    },

    // West Coast
    {
        startDate: { id: 5, value: getDate(5) },
        region: 'West Coast',
        monthOverMonth: -0.05,
        budgeted: 384,
        scheduled: 400,
    },
    {
        startDate: { id: 4, value: getDate(4) },
        region: 'West Coast',
        monthOverMonth: -0.05,
        budgeted: 416,
        scheduled: 410,
    },
    {
        startDate: { id: 3, value: getDate(3) },
        region: 'West Coast',
        monthOverMonth: -0.05,
        budgeted: 420,
        scheduled: 420,
    },
    {
        startDate: { id: 2, value: getDate(2) },
        region: 'West Coast',
        monthOverMonth: -0.05,
        budgeted: 420,
        scheduled: 435,
    },
    {
        startDate: { id: 1, value: getDate(1) },
        region: 'West Coast',
        monthOverMonth: -0.05,
        budgeted: 410,
        scheduled: 435,
    },
    {
        startDate: { id: 0, value: currentMonth },
        region: 'West Coast',
        monthOverMonth: -0.05,
        budgeted: 430,
        scheduled: 450,
    },

    // Southwest
    {
        startDate: { id: 5, value: getDate(5) },
        region: 'Southwest',
        monthOverMonth: -0.05,
        budgeted: 728,
        scheduled: 736,
    },
    {
        startDate: { id: 4, value: getDate(4) },
        region: 'Southwest',
        monthOverMonth: -0.05,
        budgeted: 720,
        scheduled: 736,
    },
    {
        startDate: { id: 3, value: getDate(3) },
        region: 'Southwest',
        monthOverMonth: -0.05,
        budgeted: 720,
        scheduled: 720,
    },
    {
        startDate: { id: 2, value: getDate(2) },
        region: 'Southwest',
        monthOverMonth: -0.05,
        budgeted: 736,
        scheduled: 720,
    },
    {
        startDate: { id: 1, value: getDate(1) },
        region: 'Southwest',
        monthOverMonth: -0.05,
        budgeted: 780,
        scheduled: 800,
    },
    {
        startDate: { id: 0, value: currentMonth },
        region: 'Southwest',
        monthOverMonth: -0.05,
        budgeted: 720,
        scheduled: 800,
    },

    // Mid Atlantic
    {
        startDate: { id: 5, value: getDate(5) },
        region: 'Mid Atlantic',
        monthOverMonth: 0.2,
        budgeted: 1012,
        scheduled: 1237,
    },
    {
        startDate: { id: 4, value: getDate(4) },
        region: 'Mid Atlantic',
        monthOverMonth: 0.2,
        budgeted: 1012,
        scheduled: 1247,
    },
    {
        startDate: { id: 3, value: getDate(3) },
        region: 'Mid Atlantic',
        monthOverMonth: 0.2,
        budgeted: 1012,
        scheduled: 1194,
    },
    {
        startDate: { id: 2, value: getDate(2) },
        region: 'Mid Atlantic',
        monthOverMonth: 0.2,
        budgeted: 1027,
        scheduled: 1209,
    },
    {
        startDate: { id: 1, value: getDate(1) },
        region: 'Mid Atlantic',
        monthOverMonth: 0.2,
        budgeted: 1027,
        scheduled: 1123,
    },
    {
        startDate: { id: 0, value: currentMonth },
        region: 'Mid Atlantic',
        monthOverMonth: 0.2,
        budgeted: 1050,
        scheduled: 1090,
    },

    // Southeast
    {
        startDate: { id: 5, value: getDate(5) },
        region: 'Southeast',
        monthOverMonth: -0.05,
        budgeted: 660,
        scheduled: 700,
    },
    {
        startDate: { id: 4, value: getDate(4) },
        region: 'Southeast',
        monthOverMonth: -0.05,
        budgeted: 685,
        scheduled: 690,
    },
    {
        startDate: { id: 3, value: getDate(3) },
        region: 'Southeast',
        monthOverMonth: -0.05,
        budgeted: 700,
        scheduled: 690,
    },
    {
        startDate: { id: 2, value: getDate(2) },
        region: 'Southeast',
        monthOverMonth: -0.05,
        budgeted: 695,
        scheduled: 680,
    },
    {
        startDate: { id: 1, value: getDate(1) },
        region: 'Southeast',
        monthOverMonth: -0.05,
        budgeted: 680,
        scheduled: 700,
    },
    {
        startDate: { id: 0, value: currentMonth },
        region: 'Southeast',
        monthOverMonth: -0.05,
        budgeted: 650,
        scheduled: 700,
    },

    // Midwest
    {
        startDate: { id: 5, value: getDate(5) },
        region: 'Midwest',
        monthOverMonth: 0.2,
        budgeted: 790,
        scheduled: 785,
    },
    {
        startDate: { id: 4, value: getDate(4) },
        region: 'Midwest',
        monthOverMonth: 0.2,
        budgeted: 808,
        scheduled: 790,
    },
    {
        startDate: { id: 3, value: getDate(3) },
        region: 'Midwest',
        monthOverMonth: 0.2,
        budgeted: 800,
        scheduled: 790,
    },
    {
        startDate: { id: 2, value: getDate(2) },
        region: 'Midwest',
        monthOverMonth: 0.2,
        budgeted: 700,
        scheduled: 830,
    },
    {
        startDate: { id: 1, value: getDate(1) },
        region: 'Midwest',
        monthOverMonth: 0.2,
        budgeted: 810,
        scheduled: 800,
    },
    {
        startDate: { id: 0, value: currentMonth },
        region: 'Midwest',
        monthOverMonth: 0.2,
        budgeted: 810,
        scheduled: 800,
    },

    // Hawaii
    {
        startDate: { id: 5, value: getDate(5) },
        region: 'Hawaii',
        monthOverMonth: 0.2,
        budgeted: 860,
        scheduled: 870,
    },
    {
        startDate: { id: 4, value: getDate(4) },
        region: 'Hawaii',
        monthOverMonth: 0.2,
        budgeted: 900,
        scheduled: 890,
    },
    {
        startDate: { id: 3, value: getDate(3) },
        region: 'Hawaii',
        monthOverMonth: 0.2,
        budgeted: 900,
        scheduled: 895,
    },
    {
        startDate: { id: 2, value: getDate(2) },
        region: 'Hawaii',
        monthOverMonth: 0.2,
        budgeted: 900,
        scheduled: 930,
    },
    {
        startDate: { id: 1, value: getDate(1) },
        region: 'Hawaii',
        monthOverMonth: 0.2,
        budgeted: 900,
        scheduled: 930,
    },
    {
        startDate: { id: 0, value: currentMonth },
        region: 'Hawaii',
        monthOverMonth: 0.2,
        budgeted: 900,
        scheduled: 940,
    },

    // New England
    {
        startDate: { id: 5, value: getDate(5) },
        region: 'New England',
        monthOverMonth: -0.05,
        budgeted: 380,
        scheduled: 385,
    },
    {
        startDate: { id: 4, value: getDate(4) },
        region: 'New England',
        monthOverMonth: -0.05,
        budgeted: 390,
        scheduled: 385,
    },
    {
        startDate: { id: 3, value: getDate(3) },
        region: 'New England',
        monthOverMonth: -0.05,
        budgeted: 390,
        scheduled: 410,
    },
    {
        startDate: { id: 2, value: getDate(2) },
        region: 'New England',
        monthOverMonth: -0.05,
        budgeted: 390,
        scheduled: 400,
    },
    {
        startDate: { id: 1, value: getDate(1) },
        region: 'New England',
        monthOverMonth: -0.05,
        budgeted: 400,
        scheduled: 380,
    },
    {
        startDate: { id: 0, value: currentMonth },
        region: 'New England',
        monthOverMonth: -0.05,
        budgeted: 400,
        scheduled: 360,
    },

    // The Plains
    {
        startDate: { id: 5, value: getDate(5) },
        region: 'The Plains',
        monthOverMonth: -0.05,
        budgeted: 0,
        scheduled: 0,
    },
    {
        startDate: { id: 4, value: getDate(4) },
        region: 'The Plains',
        monthOverMonth: -0.05,
        budgeted: 0,
        scheduled: 0,
    },
    {
        startDate: { id: 3, value: getDate(3) },
        region: 'The Plains',
        monthOverMonth: -0.05,
        budgeted: 0,
        scheduled: 0,
    },
    {
        startDate: { id: 2, value: getDate(2) },
        region: 'The Plains',
        monthOverMonth: -0.05,
        budgeted: 0,
        scheduled: 0,
    },
    {
        startDate: { id: 1, value: getDate(1) },
        region: 'The Plains',
        monthOverMonth: -0.05,
        budgeted: 0,
        scheduled: 0,
    },
    {
        startDate: { id: 0, value: currentMonth },
        region: 'The Plains',
        monthOverMonth: -0.05,
        budgeted: 0,
        scheduled: 0,
    },
];

export type BudgetedVsScheduledHoursData = (typeof budgetedVsScheduledHoursTableDataMock)[0];

export const weeklyRecurringOpenHoursDatasetMock = [
    {
        startDate: { id: 5, value: getDate(5) },
        value: [{ key: 'Open Hours', value: 3986 }],
    },
    {
        startDate: { id: 4, value: getDate(4) },
        value: [{ key: 'Open Hours', value: 3816 }],
    },
    {
        startDate: { id: 3, value: getDate(3) },
        value: [{ key: 'Open Hours', value: 3990 }],
    },
    {
        startDate: { id: 2, value: getDate(2) },
        value: [{ key: 'Open Hours', value: 3868 }],
    },
    {
        startDate: { id: 1, value: getDate(1) },
        value: [{ key: 'Open Hours', value: 4489 }],
    },
    {
        startDate: { id: 0, value: currentMonth },
        value: [{ key: 'Open Hours', value: 5200 }],
    },
];

export const weeklyRecurringOpenHoursTableDataMock = [
    // Portfolio
    {
        startDate: { id: 5, value: getDate(5) },
        region: 'Portfolio',
        openHours: 3986,
        openShifts: 419,
        staffNeeds: 92,
        monthOverMonth: 0.03,
    },
    {
        startDate: { id: 4, value: getDate(4) },
        region: 'Portfolio',
        openHours: 3816,
        openShifts: 402,
        staffNeeds: 81,
        monthOverMonth: 0.03,
    },
    {
        startDate: { id: 3, value: getDate(3) },
        region: 'Portfolio',
        openHours: 3990,
        openShifts: 420,
        staffNeeds: 92,
        monthOverMonth: 0.03,
    },
    {
        startDate: { id: 2, value: getDate(2) },
        region: 'Portfolio',
        openHours: 3868,
        openShifts: 408,
        staffNeeds: 82,
        monthOverMonth: 0.03,
    },
    {
        startDate: { id: 1, value: getDate(1) },
        region: 'Portfolio',
        openHours: 4489,
        openShifts: 425,
        staffNeeds: 93,
        monthOverMonth: 0.03,
    },
    {
        startDate: { id: 0, value: currentMonth },
        region: 'Portfolio',
        openHours: 5200,
        openShifts: 650,
        staffNeeds: 81,
        monthOverMonth: 0.03,
    },

    // West Coast
    {
        startDate: { id: 5, value: getDate(5) },
        region: 'West Coast',
        openHours: 1700,
        openShifts: 130,
        staffNeeds: 26,
        monthOverMonth: 0.03,
    },
    {
        startDate: { id: 4, value: getDate(4) },
        region: 'West Coast',
        openHours: 1600,
        openShifts: 125,
        staffNeeds: 25,
        monthOverMonth: 0.03,
    },
    {
        startDate: { id: 3, value: getDate(3) },
        region: 'West Coast',
        openHours: 1700,
        openShifts: 130,
        staffNeeds: 26,
        monthOverMonth: 0.03,
    },
    {
        startDate: { id: 2, value: getDate(2) },
        region: 'West Coast',
        openHours: 1600,
        openShifts: 125,
        staffNeeds: 25,
        monthOverMonth: 0.03,
    },
    {
        startDate: { id: 1, value: getDate(1) },
        region: 'West Coast',
        openHours: 1700,
        openShifts: 130,
        staffNeeds: 26,
        monthOverMonth: 0.03,
    },
    {
        startDate: { id: 0, value: currentMonth },
        region: 'West Coast',
        openHours: 1600,
        openShifts: 125,
        staffNeeds: 25,
        monthOverMonth: -0.05,
    },

    // Southwest
    {
        startDate: { id: 5, value: getDate(5) },
        region: 'Southwest',
        openHours: 680,
        openShifts: 85,
        staffNeeds: 25,
        monthOverMonth: -0.05,
    },
    {
        startDate: { id: 4, value: getDate(4) },
        region: 'Southwest',
        openHours: 600,
        openShifts: 75,
        staffNeeds: 15,
        monthOverMonth: -0.05,
    },
    {
        startDate: { id: 3, value: getDate(3) },
        region: 'Southwest',
        openHours: 680,
        openShifts: 85,
        staffNeeds: 25,
        monthOverMonth: -0.05,
    },
    {
        startDate: { id: 2, value: getDate(2) },
        region: 'Southwest',
        openHours: 600,
        openShifts: 75,
        staffNeeds: 15,
        monthOverMonth: -0.05,
    },
    {
        startDate: { id: 1, value: getDate(1) },
        region: 'Southwest',
        openHours: 680,
        openShifts: 85,
        staffNeeds: 25,
        monthOverMonth: -0.05,
    },
    {
        startDate: { id: 0, value: currentMonth },
        region: 'Southwest',
        openHours: 600,
        openShifts: 75,
        staffNeeds: 15,
        monthOverMonth: -0.05,
    },

    // Mid Atlantic
    {
        startDate: { id: 5, value: getDate(5) },
        region: 'Mid Atlantic',
        openHours: 576,
        openShifts: 72,
        staffNeeds: 15,
        monthOverMonth: 0.2,
    },
    {
        startDate: { id: 4, value: getDate(4) },
        region: 'Mid Atlantic',
        openHours: 576,
        openShifts: 72,
        staffNeeds: 15,
        monthOverMonth: 0.2,
    },
    {
        startDate: { id: 3, value: getDate(3) },
        region: 'Mid Atlantic',
        openHours: 560,
        openShifts: 70,
        staffNeeds: 14,
        monthOverMonth: 0.2,
    },
    {
        startDate: { id: 2, value: getDate(2) },
        region: 'Mid Atlantic',
        openHours: 576,
        openShifts: 72,
        staffNeeds: 15,
        monthOverMonth: 0.2,
    },
    {
        startDate: { id: 1, value: getDate(1) },
        region: 'Mid Atlantic',
        openHours: 576,
        openShifts: 72,
        staffNeeds: 15,
        monthOverMonth: 0.2,
    },
    {
        startDate: { id: 0, value: currentMonth },
        region: 'Mid Atlantic',
        openHours: 560,
        openShifts: 70,
        staffNeeds: 14,
        monthOverMonth: 0.2,
    },

    // Southeast
    {
        startDate: { id: 5, value: getDate(5) },
        region: 'Southeast',
        openHours: 440,
        openShifts: 55,
        staffNeeds: 11,
        monthOverMonth: -0.05,
    },
    {
        startDate: { id: 4, value: getDate(4) },
        region: 'Southeast',
        openHours: 400,
        openShifts: 50,
        staffNeeds: 10,
        monthOverMonth: -0.05,
    },
    {
        startDate: { id: 3, value: getDate(3) },
        region: 'Southeast',
        openHours: 400,
        openShifts: 50,
        staffNeeds: 10,
        monthOverMonth: -0.05,
    },
    {
        startDate: { id: 2, value: getDate(2) },
        region: 'Southeast',
        openHours: 440,
        openShifts: 55,
        staffNeeds: 11,
        monthOverMonth: -0.05,
    },
    {
        startDate: { id: 1, value: getDate(1) },
        region: 'Southeast',
        openHours: 400,
        openShifts: 50,
        staffNeeds: 10,
        monthOverMonth: -0.05,
    },
    {
        startDate: { id: 0, value: currentMonth },
        region: 'Southeast',
        openHours: 440,
        openShifts: 55,
        staffNeeds: 11,
        monthOverMonth: -0.05,
    },

    // Midwest
    {
        startDate: { id: 5, value: getDate(5) },
        region: 'Midwest',
        openHours: 210,
        openShifts: 30,
        staffNeeds: 6,
        monthOverMonth: 0.2,
    },
    {
        startDate: { id: 4, value: getDate(4) },
        region: 'Midwest',
        openHours: 280,
        openShifts: 35,
        staffNeeds: 7,
        monthOverMonth: 0.2,
    },
    {
        startDate: { id: 3, value: getDate(3) },
        region: 'Midwest',
        openHours: 210,
        openShifts: 30,
        staffNeeds: 6,
        monthOverMonth: 0.2,
    },
    {
        startDate: { id: 2, value: getDate(2) },
        region: 'Midwest',
        openHours: 288,
        openShifts: 36,
        staffNeeds: 7,
        monthOverMonth: 0.2,
    },
    {
        startDate: { id: 1, value: getDate(1) },
        region: 'Midwest',
        openHours: 288,
        openShifts: 36,
        staffNeeds: 7,
        monthOverMonth: 0.2,
    },
    {
        startDate: { id: 0, value: currentMonth },
        region: 'Midwest',
        openHours: 280,
        openShifts: 35,
        staffNeeds: 7,
        monthOverMonth: 0.2,
    },

    // Hawaii
    {
        startDate: { id: 5, value: getDate(5) },
        region: 'Hawaii',
        openHours: 240,
        openShifts: 30,
        staffNeeds: 6,
        monthOverMonth: 0.2,
    },
    {
        startDate: { id: 4, value: getDate(4) },
        region: 'Hawaii',
        openHours: 240,
        openShifts: 30,
        staffNeeds: 6,
        monthOverMonth: 0.2,
    },
    {
        startDate: { id: 3, value: getDate(3) },
        region: 'Hawaii',
        openHours: 280,
        openShifts: 35,
        staffNeeds: 7,
        monthOverMonth: 0.2,
    },
    {
        startDate: { id: 2, value: getDate(2) },
        region: 'Hawaii',
        openHours: 240,
        openShifts: 30,
        staffNeeds: 6,
        monthOverMonth: 0.2,
    },
    {
        startDate: { id: 1, value: getDate(1) },
        region: 'Hawaii',
        openHours: 280,
        openShifts: 35,
        staffNeeds: 7,
        monthOverMonth: 0.2,
    },
    {
        startDate: { id: 0, value: currentMonth },
        region: 'Hawaii',
        openHours: 200,
        openShifts: 25,
        staffNeeds: 5,
        monthOverMonth: 0.2,
    },

    // New England
    {
        startDate: { id: 5, value: getDate(5) },
        region: 'New England',
        openHours: 140,
        openShifts: 17,
        staffNeeds: 3,
        monthOverMonth: -0.05,
    },
    {
        startDate: { id: 4, value: getDate(4) },
        region: 'New England',
        openHours: 120,
        openShifts: 15,
        staffNeeds: 3,
        monthOverMonth: -0.05,
    },
    {
        startDate: { id: 3, value: getDate(3) },
        region: 'New England',
        openHours: 160,
        openShifts: 20,
        staffNeeds: 4,
        monthOverMonth: -0.05,
    },
    {
        startDate: { id: 2, value: getDate(2) },
        region: 'New England',
        openHours: 120,
        openShifts: 15,
        staffNeeds: 3,
        monthOverMonth: -0.05,
    },
    {
        startDate: { id: 1, value: getDate(1) },
        region: 'New England',
        openHours: 140,
        openShifts: 17,
        staffNeeds: 3,
        monthOverMonth: -0.05,
    },
    {
        startDate: { id: 0, value: currentMonth },
        region: 'New England',
        openHours: 160,
        openShifts: 20,
        staffNeeds: 4,
        monthOverMonth: -0.05,
    },

    // The Plains
    {
        startDate: { id: 5, value: getDate(5) },
        region: 'The Plains',
        openHours: 0,
        openShifts: 0,
        staffNeeds: 0,
        monthOverMonth: -0.05,
    },
    {
        startDate: { id: 4, value: getDate(4) },
        region: 'The Plains',
        openHours: 0,
        openShifts: 0,
        staffNeeds: 0,
        monthOverMonth: -0.05,
    },
    {
        startDate: { id: 3, value: getDate(3) },
        region: 'The Plains',
        openHours: 0,
        openShifts: 0,
        staffNeeds: 0,
        monthOverMonth: -0.05,
    },
    {
        startDate: { id: 2, value: getDate(2) },
        region: 'The Plains',
        openHours: 0,
        openShifts: 0,
        staffNeeds: 0,
        monthOverMonth: -0.05,
    },
    {
        startDate: { id: 1, value: getDate(1) },
        region: 'The Plains',
        openHours: 0,
        openShifts: 0,
        staffNeeds: 0,
        monthOverMonth: -0.05,
    },
    {
        startDate: { id: 0, value: currentMonth },
        region: 'The Plains',
        openHours: 0,
        openShifts: 0,
        staffNeeds: 0,
        monthOverMonth: -0.05,
    },
];

export type WeeklyRecurringOpenHoursData = (typeof weeklyRecurringOpenHoursTableDataMock)[0];

const residentsPercentageDetail = [
    {
        residentName: 'Mary Christoff',
        residentRoomNumber: '319',
        residentId: 961,
        cocTriggers: 3,
        events: [
            {
                type: 'Assist',
                description: '1 assist level changes 10% + weight loss in 90d',
            },
            {
                type: 'Hospital',
                description: '2 visits in 30d',
            },
            {
                type: 'PRN',
                description: "16 PRN's",
            },
        ],
    },
    {
        residentName: 'Patricia Crossen',
        residentRoomNumber: '333',
        residentId: 966,
        cocTriggers: 2,
        events: [
            {
                type: 'Assist',
                description: '1 assist level changes',
            },
            {
                type: 'Hospital',
                description: '1 visits in 30d',
            },
        ],
    },
];

export const residentsPercentageTableData = [
    {
        level: 1,
        residentsPorcentage: 0.5,
        count: 50,
        monthOverMonth: 0.05,
        isGood: true,
        details: residentsPercentageDetail,
        startDate: { id: 0, value: currentMonth },
    },
    {
        level: 2,
        residentsPorcentage: 0.2,
        count: 30,
        monthOverMonth: 0.2,
        isGood: true,
        details: residentsPercentageDetail,
        startDate: { id: 0, value: currentMonth },
    },
    {
        level: 3,
        residentsPorcentage: 0.3,
        count: 20,
        monthOverMonth: -0.05,
        isGood: false,
        details: residentsPercentageDetail,
        startDate: { id: 0, value: currentMonth },
    },
    {
        level: 4,
        residentsPorcentage: 0.5,
        count: 35,
        monthOverMonth: 0.2,
        isGood: false,
        details: residentsPercentageDetail,
        startDate: { id: 0, value: currentMonth },
    },

    {
        level: 1,
        residentsPorcentage: 0.1,
        count: 10,
        monthOverMonth: -0.05,
        isGood: true,
        details: residentsPercentageDetail,
        startDate: { id: 1, value: getDate(1) },
    },
    {
        level: 2,
        residentsPorcentage: 0.15,
        count: 15,
        monthOverMonth: 0.05,
        isGood: false,
        details: residentsPercentageDetail,
        startDate: { id: 1, value: getDate(1) },
    },
    {
        level: 3,
        residentsPorcentage: 0.3,
        count: 20,
        monthOverMonth: 0.15,
        isGood: false,
        details: residentsPercentageDetail,
        startDate: { id: 1, value: getDate(1) },
    },
    {
        level: 4,
        residentsPorcentage: 0.25,
        count: 18,
        monthOverMonth: -0.05,
        isGood: true,
        details: residentsPercentageDetail,
        startDate: { id: 1, value: getDate(1) },
    },

    {
        level: 1,
        residentsPorcentage: 0.5,
        count: 50,
        monthOverMonth: 0.05,
        isGood: true,
        details: residentsPercentageDetail,
        startDate: { id: 2, value: getDate(2) },
    },
    {
        level: 2,
        residentsPorcentage: 0.2,
        count: 30,
        monthOverMonth: 0.2,
        isGood: true,
        details: residentsPercentageDetail,
        startDate: { id: 2, value: getDate(2) },
    },
    {
        level: 3,
        residentsPorcentage: 0.25,
        count: 20,
        monthOverMonth: -0.05,
        isGood: false,
        details: residentsPercentageDetail,
        startDate: { id: 2, value: getDate(2) },
    },
    {
        level: 4,
        residentsPorcentage: 0.5,
        count: 35,
        monthOverMonth: 0.2,
        isGood: false,
        details: residentsPercentageDetail,
        startDate: { id: 2, value: getDate(2) },
    },

    {
        level: 1,
        residentsPorcentage: 0.1,
        count: 10,
        monthOverMonth: -0.05,
        isGood: true,
        details: residentsPercentageDetail,
        startDate: { id: 3, value: getDate(3) },
    },
    {
        level: 2,
        residentsPorcentage: 0.15,
        count: 15,
        monthOverMonth: 0.05,
        isGood: false,
        details: residentsPercentageDetail,
        startDate: { id: 3, value: getDate(3) },
    },
    {
        level: 3,
        residentsPorcentage: 0.35,
        count: 20,
        monthOverMonth: 0.15,
        isGood: false,
        details: residentsPercentageDetail,
        startDate: { id: 3, value: getDate(3) },
    },
    {
        level: 4,
        residentsPorcentage: 0.25,
        count: 18,
        monthOverMonth: -0.05,
        isGood: true,
        details: residentsPercentageDetail,
        startDate: { id: 3, value: getDate(3) },
    },

    {
        level: 1,
        residentsPorcentage: 0.4,
        count: 40,
        monthOverMonth: 0.2,
        isGood: false,
        details: residentsPercentageDetail,
        startDate: { id: 4, value: getDate(4) },
    },
    {
        level: 2,
        residentsPorcentage: 0.12,
        count: 13,
        monthOverMonth: -0.05,
        isGood: true,
        details: residentsPercentageDetail,
        startDate: { id: 4, value: getDate(4) },
    },
    {
        level: 3,
        residentsPorcentage: 0.3,
        count: 20,
        monthOverMonth: 0.15,
        isGood: false,
        details: residentsPercentageDetail,
        startDate: { id: 4, value: getDate(4) },
    },
    {
        level: 4,
        residentsPorcentage: 0.2,
        count: 20,
        monthOverMonth: 0.15,
        isGood: false,
        details: residentsPercentageDetail,
        startDate: { id: 4, value: getDate(4) },
    },

    {
        level: 1,
        residentsPorcentage: 0.1,
        count: 10,
        monthOverMonth: -0.05,
        isGood: true,
        details: residentsPercentageDetail,
        startDate: { id: 5, value: getDate(5) },
    },
    {
        level: 2,
        residentsPorcentage: 0.15,
        count: 15,
        monthOverMonth: 0.05,
        isGood: false,
        details: residentsPercentageDetail,
        startDate: { id: 5, value: getDate(5) },
    },
    {
        level: 3,
        residentsPorcentage: 0.45,
        count: 20,
        monthOverMonth: 0.15,
        isGood: false,
        details: residentsPercentageDetail,
        startDate: { id: 5, value: getDate(5) },
    },
    {
        level: 4,
        residentsPorcentage: 0.25,
        count: 18,
        monthOverMonth: -0.05,
        isGood: true,
        details: residentsPercentageDetail,
        startDate: { id: 5, value: getDate(5) },
    },
];

export type ResidentsPercentageData = (typeof residentsPercentageTableData)[0] & {
    isDetailRow?: boolean;
    id: number | string;
};

export const residentsPercentageDatasetMock = [
    {
        startDate: { id: 5, value: getDate(5) },
        value: [
            { key: 'Level 1', value: 10 },
            { key: 'Level 2', value: 15 },
            { key: 'Level 3', value: 45 },
            { key: 'Level 4', value: 25 },
        ],
    },
    {
        startDate: { id: 4, value: getDate(4) },
        value: [
            { key: 'Level 1', value: 40 },
            { key: 'Level 2', value: 12 },
            { key: 'Level 3', value: 30 },
            { key: 'Level 4', value: 20 },
        ],
    },
    {
        startDate: { id: 3, value: getDate(3) },
        value: [
            { key: 'Level 1', value: 10 },
            { key: 'Level 2', value: 15 },
            { key: 'Level 3', value: 35 },
            { key: 'Level 4', value: 25 },
        ],
    },
    {
        startDate: { id: 2, value: getDate(2) },
        value: [
            { key: 'Level 1', value: 50 },
            { key: 'Level 2', value: 20 },
            { key: 'Level 3', value: 25 },
            { key: 'Level 4', value: 50 },
        ],
    },
    {
        startDate: { id: 1, value: getDate(1) },
        value: [
            { key: 'Level 1', value: 10 },
            { key: 'Level 2', value: 15 },
            { key: 'Level 3', value: 30 },
            { key: 'Level 4', value: 25 },
        ],
    },
    {
        startDate: { id: 0, value: currentMonth },
        value: [
            { key: 'Level 1', value: 50 },
            { key: 'Level 2', value: 20 },
            { key: 'Level 3', value: 30 },
            { key: 'Level 4', value: 50 },
        ],
    },
];

export const scheduledOvertimeHoursDatasetMock = [
    {
        startDate: { id: 5, value: getDate(5) },
        value: [{ key: 'Scheduled Overtime', value: 720 }],
    },
    {
        startDate: { id: 4, value: getDate(4) },
        value: [{ key: 'Scheduled Overtime', value: 510 }],
    },
    {
        startDate: { id: 3, value: getDate(3) },
        value: [{ key: 'Scheduled Overtime', value: 520 }],
    },
    {
        startDate: { id: 2, value: getDate(2) },
        value: [{ key: 'Scheduled Overtime', value: 612 }],
    },
    {
        startDate: { id: 1, value: getDate(1) },
        value: [{ key: 'Scheduled Overtime', value: 488 }],
    },
    {
        startDate: { id: 0, value: currentMonth },
        value: [{ key: 'Scheduled Overtime', value: 565 }],
    },
];

export const scheduledOvertimeHoursTableMock = [
    {
        region: 'Portfolio',
        overtimeHours: 750,
        startDate: { id: 5, value: getDate(5) },
        monthOverMonth: 0.03,
        isGood: true,
    },
    {
        region: 'Portfolio',
        overtimeHours: 510,
        startDate: { id: 4, value: getDate(4) },
        monthOverMonth: 0.25,
        isGood: false,
    },
    {
        region: 'Portfolio',
        overtimeHours: 520,
        startDate: { id: 3, value: getDate(3) },
        monthOverMonth: 0.08,
        isGood: false,
    },
    {
        region: 'Portfolio',
        overtimeHours: 612,
        startDate: { id: 2, value: getDate(2) },
        monthOverMonth: -0.08,
        isGood: true,
    },
    {
        region: 'Portfolio',
        overtimeHours: 488,
        startDate: { id: 1, value: getDate(1) },
        monthOverMonth: 0.2,
        isGood: false,
    },
    {
        region: 'Portfolio',
        overtimeHours: 565,
        startDate: { id: 0, value: currentMonth },
        monthOverMonth: 0.2,
        isGood: false,
    },

    // West Coast
    {
        region: 'West Coast',
        overtimeHours: 3,
        startDate: { id: 5, value: getDate(5) },
        monthOverMonth: 0.5,
        isGood: false,
    },
    {
        region: 'West Coast',
        overtimeHours: 2,
        startDate: { id: 4, value: getDate(4) },
        monthOverMonth: -0.33,
        isGood: true,
    },
    {
        region: 'West Coast',
        overtimeHours: 0,
        startDate: { id: 3, value: getDate(3) },
        monthOverMonth: -1,
        isGood: true,
    },
    {
        region: 'West Coast',
        overtimeHours: 2,
        startDate: { id: 2, value: getDate(2) },
        monthOverMonth: 0,
        isGood: false,
    },
    {
        region: 'West Coast',
        overtimeHours: 3,
        startDate: { id: 1, value: getDate(1) },
        monthOverMonth: 0.5,
        isGood: false,
    },
    {
        region: 'West Coast',
        overtimeHours: 78,
        startDate: { id: 0, value: currentMonth },
        monthOverMonth: -0.11,
        isGood: true,
    },

    // Southwest
    {
        region: 'Southwest',
        overtimeHours: 5,
        startDate: { id: 5, value: getDate(5) },
        monthOverMonth: 0,
        isGood: false,
    },
    {
        region: 'Southwest',
        overtimeHours: 5,
        startDate: { id: 4, value: getDate(4) },
        monthOverMonth: 0,
        isGood: false,
    },
    {
        region: 'Southwest',
        overtimeHours: 3,
        startDate: { id: 3, value: getDate(3) },
        monthOverMonth: -0.6,
        isGood: true,
    },
    {
        region: 'Southwest',
        overtimeHours: 3,
        startDate: { id: 2, value: getDate(2) },
        monthOverMonth: 0,
        isGood: false,
    },
    {
        region: 'Southwest',
        overtimeHours: 1,
        startDate: { id: 1, value: getDate(1) },
        monthOverMonth: -0.67,
        isGood: true,
    },
    {
        region: 'Southwest',
        overtimeHours: 63,
        startDate: { id: 0, value: currentMonth },
        monthOverMonth: -0.03,
        isGood: true,
    },

    // Mid Atlantic
    {
        region: 'Mid Atlantic',
        overtimeHours: 199,
        startDate: { id: 5, value: getDate(5) },
        monthOverMonth: -0.1,
        isGood: true,
    },
    {
        region: 'Mid Atlantic',
        overtimeHours: 188,
        startDate: { id: 4, value: getDate(4) },
        monthOverMonth: -0.5,
        isGood: true,
    },
    {
        region: 'Mid Atlantic',
        overtimeHours: 147,
        startDate: { id: 3, value: getDate(3) },
        monthOverMonth: 0.167,
        isGood: false,
    },
    {
        region: 'Mid Atlantic',
        overtimeHours: 205,
        startDate: { id: 2, value: getDate(2) },
        monthOverMonth: 0.286,
        isGood: false,
    },
    {
        region: 'Mid Atlantic',
        overtimeHours: 199,
        startDate: { id: 1, value: getDate(1) },
        monthOverMonth: -0.11,
        isGood: true,
    },
    {
        region: 'Mid Atlantic',
        overtimeHours: 165,
        startDate: { id: 0, value: currentMonth },
        monthOverMonth: 1.2,
        isGood: false,
    },

    // Southeast
    {
        region: 'Southeast',
        overtimeHours: 0,
        startDate: { id: 5, value: getDate(5) },
        monthOverMonth: 0,
        isGood: true,
    },
    {
        region: 'Southeast',
        overtimeHours: 1,
        startDate: { id: 4, value: getDate(4) },
        monthOverMonth: 0,
        isGood: true,
    },
    {
        region: 'Southeast',
        overtimeHours: 2,
        startDate: { id: 3, value: getDate(3) },
        monthOverMonth: 1,
        isGood: false,
    },
    {
        region: 'Southeast',
        overtimeHours: 1,
        startDate: { id: 2, value: getDate(2) },
        monthOverMonth: -1,
        isGood: true,
    },
    {
        region: 'Southeast',
        overtimeHours: 3,
        startDate: { id: 1, value: getDate(1) },
        monthOverMonth: 2,
        isGood: false,
    },
    {
        region: 'Southeast',
        overtimeHours: 71,
        startDate: { id: 0, value: currentMonth },
        monthOverMonth: -0.05,
        isGood: true,
    },

    // Midwest
    {
        region: 'Midwest',
        overtimeHours: 0,
        startDate: { id: 5, value: getDate(5) },
        monthOverMonth: 0,
        isGood: true,
    },
    {
        region: 'Midwest',
        overtimeHours: 2,
        startDate: { id: 4, value: getDate(4) },
        monthOverMonth: 0,
        isGood: false,
    },
    {
        region: 'Midwest',
        overtimeHours: 4,
        startDate: { id: 3, value: getDate(3) },
        monthOverMonth: 1,
        isGood: false,
    },
    {
        region: 'Midwest',
        overtimeHours: 3,
        startDate: { id: 2, value: getDate(2) },
        monthOverMonth: -0.33,
        isGood: true,
    },
    {
        region: 'Midwest',
        overtimeHours: 5,
        startDate: { id: 1, value: getDate(1) },
        monthOverMonth: 0.67,
        isGood: false,
    },
    {
        region: 'Midwest',
        overtimeHours: 11,
        startDate: { id: 0, value: currentMonth },
        monthOverMonth: 0.04,
        isGood: false,
    },

    // Hawaii
    {
        region: 'Hawaii',
        overtimeHours: 0,
        startDate: { id: 5, value: getDate(5) },
        monthOverMonth: 0,
        isGood: true,
    },
    {
        region: 'Hawaii',
        overtimeHours: 6,
        startDate: { id: 4, value: getDate(4) },
        monthOverMonth: 0,
        isGood: false,
    },
    {
        region: 'Hawaii',
        overtimeHours: 5,
        startDate: { id: 3, value: getDate(3) },
        monthOverMonth: -0.2,
        isGood: true,
    },
    {
        region: 'Hawaii',
        overtimeHours: 3,
        startDate: { id: 2, value: getDate(2) },
        monthOverMonth: -0.67,
        isGood: true,
    },
    {
        region: 'Hawaii',
        overtimeHours: 5,
        startDate: { id: 1, value: getDate(1) },
        monthOverMonth: 0.4,
        isGood: false,
    },
    {
        region: 'Hawaii',
        overtimeHours: 56,
        startDate: { id: 0, value: currentMonth },
        monthOverMonth: -0.33,
        isGood: true,
    },

    // New England
    {
        region: 'New England',
        overtimeHours: 0,
        startDate: { id: 5, value: getDate(5) },
        monthOverMonth: 0,
        isGood: true,
    },
    {
        region: 'New England',
        overtimeHours: 3,
        startDate: { id: 4, value: getDate(4) },
        monthOverMonth: 0,
        isGood: false,
    },
    {
        region: 'New England',
        overtimeHours: 6,
        startDate: { id: 3, value: getDate(3) },
        monthOverMonth: 1,
        isGood: false,
    },
    {
        region: 'New England',
        overtimeHours: 4,
        startDate: { id: 2, value: getDate(2) },
        monthOverMonth: -0.33,
        isGood: true,
    },
    {
        region: 'New England',
        overtimeHours: 5,
        startDate: { id: 1, value: getDate(1) },
        monthOverMonth: 0.25,
        isGood: false,
    },
    {
        region: 'New England',
        overtimeHours: 52,
        startDate: { id: 0, value: currentMonth },
        monthOverMonth: -0.15,
        isGood: true,
    },

    // The Plains
    {
        region: 'The Plains',
        overtimeHours: 0,
        startDate: { id: 5, value: getDate(5) },
        monthOverMonth: 0,
        isGood: true,
    },
    {
        region: 'The Plains',
        overtimeHours: 0,
        startDate: { id: 4, value: getDate(4) },
        monthOverMonth: 0,
        isGood: false,
    },
    {
        region: 'The Plains',
        overtimeHours: 0,
        startDate: { id: 3, value: getDate(3) },
        monthOverMonth: 1,
        isGood: false,
    },
    {
        region: 'The Plains',
        overtimeHours: 0,
        startDate: { id: 2, value: getDate(2) },
        monthOverMonth: -0.33,
        isGood: true,
    },
    {
        region: 'The Plains',
        overtimeHours: 0,
        startDate: { id: 1, value: getDate(1) },
        monthOverMonth: -0.15,
        isGood: true,
    },
    {
        region: 'The Plains',
        overtimeHours: 69,
        startDate: { id: 0, value: currentMonth },
        monthOverMonth: 0.1,
        isGood: false,
    },
];

export type ScheduledOvertimeHoursType = (typeof scheduledOvertimeHoursTableMock)[0];

export const scheduledAgencyHoursDatasetMock = [
    {
        startDate: { id: 5, value: getDate(5) },
        value: [{ key: 'Agency Hours', value: 106 }],
    },
    {
        startDate: { id: 4, value: getDate(4) },
        value: [{ key: 'Agency Hours', value: 260 }],
    },
    {
        startDate: { id: 3, value: getDate(3) },
        value: [{ key: 'Agency Hours', value: 51 }],
    },
    {
        startDate: { id: 2, value: getDate(2) },
        value: [{ key: 'Agency Hours', value: 151 }],
    },
    {
        startDate: { id: 1, value: getDate(1) },
        value: [{ key: 'Agency Hours', value: 203 }],
    },
    {
        startDate: { id: 0, value: currentMonth },
        value: [{ key: 'Agency Hours', value: 77 }],
    },
];

export const scheduledAgencyHoursTableMock = [
    {
        region: 'Portfolio',
        agencyHours: 106,
        startDate: { id: 5, value: getDate(5) },
        monthOverMonth: 0.03,
        isGood: true,
    },
    {
        region: 'Portfolio',
        agencyHours: 260,
        startDate: { id: 4, value: getDate(4) },
        monthOverMonth: 0.25,
        isGood: false,
    },
    {
        region: 'Portfolio',
        agencyHours: 51,
        startDate: { id: 3, value: getDate(3) },
        monthOverMonth: 0.08,
        isGood: false,
    },
    {
        region: 'Portfolio',
        agencyHours: 151,
        startDate: { id: 2, value: getDate(2) },
        monthOverMonth: -0.08,
        isGood: true,
    },
    {
        region: 'Portfolio',
        agencyHours: 203,
        startDate: { id: 1, value: getDate(1) },
        monthOverMonth: 0.2,
        isGood: false,
    },
    {
        region: 'Portfolio',
        agencyHours: 77,
        startDate: { id: 0, value: currentMonth },
        monthOverMonth: 0.2,
        isGood: false,
    },

    // West Coast
    {
        region: 'West Coast',
        agencyHours: 0,
        startDate: { id: 5, value: getDate(5) },
        monthOverMonth: 0,
        isGood: false,
    },
    {
        region: 'West Coast',
        agencyHours: 0,
        startDate: { id: 4, value: getDate(4) },
        monthOverMonth: 0,
        isGood: true,
    },
    {
        region: 'West Coast',
        agencyHours: 0,
        startDate: { id: 3, value: getDate(3) },
        monthOverMonth: 0,
        isGood: true,
    },
    {
        region: 'West Coast',
        agencyHours: 0,
        startDate: { id: 2, value: getDate(2) },
        monthOverMonth: 0,
        isGood: false,
    },
    {
        region: 'West Coast',
        agencyHours: 0,
        startDate: { id: 1, value: getDate(1) },
        monthOverMonth: 0,
        isGood: false,
    },
    {
        region: 'West Coast',
        agencyHours: 41,
        startDate: { id: 0, value: currentMonth },
        monthOverMonth: -2.49,
        isGood: true,
    },

    // Southwest
    {
        region: 'Southwest',
        agencyHours: 5,
        startDate: { id: 5, value: getDate(5) },
        monthOverMonth: null,
        isGood: false,
    },
    {
        region: 'Southwest',
        agencyHours: 5,
        startDate: { id: 4, value: getDate(4) },
        monthOverMonth: null,
        isGood: false,
    },
    {
        region: 'Southwest',
        agencyHours: 3,
        startDate: { id: 3, value: getDate(3) },
        monthOverMonth: -0.6,
        isGood: true,
    },
    {
        region: 'Southwest',
        agencyHours: 3,
        startDate: { id: 2, value: getDate(2) },
        monthOverMonth: null,
        isGood: false,
    },
    {
        region: 'Southwest',
        agencyHours: 1,
        startDate: { id: 1, value: getDate(1) },
        monthOverMonth: null,
        isGood: true,
    },
    {
        region: 'Southwest',
        agencyHours: 0,
        startDate: { id: 0, value: currentMonth },
        monthOverMonth: null,
        isGood: true,
    },

    // Mid Atlantic
    {
        region: 'Mid Atlantic',
        agencyHours: 0,
        startDate: { id: 5, value: getDate(5) },
        monthOverMonth: null,
        isGood: true,
    },
    {
        region: 'Mid Atlantic',
        agencyHours: 0,
        startDate: { id: 4, value: getDate(4) },
        monthOverMonth: null,
        isGood: true,
    },
    {
        region: 'Mid Atlantic',
        agencyHours: 66,
        startDate: { id: 3, value: getDate(3) },
        monthOverMonth: null,
        isGood: true,
    },
    {
        region: 'Mid Atlantic',
        agencyHours: 60,
        startDate: { id: 2, value: getDate(2) },
        monthOverMonth: 0.07,
        isGood: false,
    },
    {
        region: 'Mid Atlantic',
        agencyHours: 43,
        startDate: { id: 1, value: getDate(1) },
        monthOverMonth: 0,
        isGood: false,
    },
    {
        region: 'Mid Atlantic',
        agencyHours: 36,
        startDate: { id: 0, value: currentMonth },
        monthOverMonth: 0.12,
        isGood: false,
    },

    // Southeast
    {
        region: 'Southeast',
        agencyHours: 0,
        startDate: { id: 5, value: getDate(5) },
        monthOverMonth: null,
        isGood: true,
    },
    {
        region: 'Southeast',
        agencyHours: 0,
        startDate: { id: 4, value: getDate(4) },
        monthOverMonth: null,
        isGood: true,
    },
    {
        region: 'Southeast',
        agencyHours: 0,
        startDate: { id: 3, value: getDate(3) },
        monthOverMonth: null,
        isGood: false,
    },
    {
        region: 'Southeast',
        agencyHours: 0,
        startDate: { id: 2, value: getDate(2) },
        monthOverMonth: null,
        isGood: true,
    },
    {
        region: 'Southeast',
        agencyHours: 0,
        startDate: { id: 1, value: getDate(1) },
        monthOverMonth: null,
        isGood: false,
    },
    {
        region: 'Southeast',
        agencyHours: 0,
        startDate: { id: 0, value: currentMonth },
        monthOverMonth: null,
        isGood: true,
    },

    // Midwest
    {
        region: 'Midwest',
        agencyHours: 0,
        startDate: { id: 5, value: getDate(5) },
        monthOverMonth: null,
        isGood: true,
    },
    {
        region: 'Midwest',
        agencyHours: 0,
        startDate: { id: 4, value: getDate(4) },
        monthOverMonth: null,
        isGood: false,
    },
    {
        region: 'Midwest',
        agencyHours: 0,
        startDate: { id: 3, value: getDate(3) },
        monthOverMonth: null,
        isGood: false,
    },
    {
        region: 'Midwest',
        agencyHours: 0,
        startDate: { id: 2, value: getDate(2) },
        monthOverMonth: null,
        isGood: true,
    },
    {
        region: 'Midwest',
        agencyHours: 0,
        startDate: { id: 1, value: getDate(1) },
        monthOverMonth: null,
        isGood: false,
    },
    {
        region: 'Midwest',
        agencyHours: 0,
        startDate: { id: 0, value: currentMonth },
        monthOverMonth: null,
        isGood: false,
    },

    // Hawaii
    {
        region: 'Hawaii',
        agencyHours: 0,
        startDate: { id: 5, value: getDate(5) },
        monthOverMonth: null,
        isGood: true,
    },
    {
        region: 'Hawaii',
        agencyHours: 0,
        startDate: { id: 4, value: getDate(4) },
        monthOverMonth: null,
        isGood: false,
    },
    {
        region: 'Hawaii',
        agencyHours: 0,
        startDate: { id: 3, value: getDate(3) },
        monthOverMonth: null,
        isGood: true,
    },
    {
        region: 'Hawaii',
        agencyHours: 0,
        startDate: { id: 2, value: getDate(2) },
        monthOverMonth: null,
        isGood: true,
    },
    {
        region: 'Hawaii',
        agencyHours: 0,
        startDate: { id: 1, value: getDate(1) },
        monthOverMonth: null,
        isGood: false,
    },
    {
        region: 'Hawaii',
        agencyHours: 0,
        startDate: { id: 0, value: currentMonth },
        monthOverMonth: null,
        isGood: false,
    },

    // New England
    {
        region: 'New England',
        agencyHours: 0,
        startDate: { id: 5, value: getDate(5) },
        monthOverMonth: null,
        isGood: true,
    },
    {
        region: 'New England',
        agencyHours: 0,
        startDate: { id: 4, value: getDate(4) },
        monthOverMonth: null,
        isGood: false,
    },
    {
        region: 'New England',
        agencyHours: 0,
        startDate: { id: 3, value: getDate(3) },
        monthOverMonth: null,
        isGood: false,
    },
    {
        region: 'New England',
        agencyHours: 0,
        startDate: { id: 2, value: getDate(2) },
        monthOverMonth: null,
        isGood: true,
    },
    {
        region: 'New England',
        agencyHours: 0,
        startDate: { id: 1, value: getDate(1) },
        monthOverMonth: null,
        isGood: false,
    },
    {
        region: 'New England',
        agencyHours: 0,
        startDate: { id: 0, value: currentMonth },
        monthOverMonth: null,
        isGood: false,
    },

    // The Plains
    {
        region: 'The Plains',
        agencyHours: 0,
        startDate: { id: 5, value: getDate(5) },
        monthOverMonth: null,
        isGood: true,
    },
    {
        region: 'The Plains',
        agencyHours: 0,
        startDate: { id: 4, value: getDate(4) },
        monthOverMonth: null,
        isGood: false,
    },
    {
        region: 'The Plains',
        agencyHours: 0,
        startDate: { id: 3, value: getDate(3) },
        monthOverMonth: null,
        isGood: false,
    },
    {
        region: 'The Plains',
        agencyHours: 0,
        startDate: { id: 2, value: getDate(2) },
        monthOverMonth: null,
        isGood: true,
    },
    {
        region: 'The Plains',
        agencyHours: 0,
        startDate: { id: 1, value: getDate(1) },
        monthOverMonth: null,
        isGood: false,
    },
    {
        region: 'The Plains',
        agencyHours: 0,
        startDate: { id: 0, value: currentMonth },
        monthOverMonth: null,
        isGood: false,
    },
];

export type ScheduledAgencyHoursType = (typeof scheduledAgencyHoursTableMock)[0];

export const branchMockData = [
    {
        regionId: 12,
        branchId: 1,
        budgetedVsScheduledHours: [
            {
                startDate: { id: 5, value: getDate(5) },
                monthOverMonth: 0.03,
                budgeted: 286,
                scheduled: 309,
            },
            {
                startDate: { id: 4, value: getDate(4) },
                monthOverMonth: 0.03,
                budgeted: 287,
                scheduled: 322,
            },
            {
                startDate: { id: 3, value: getDate(3) },
                monthOverMonth: 0.03,
                budgeted: 290,
                scheduled: 349,
            },
            {
                startDate: { id: 2, value: getDate(2) },
                monthOverMonth: 0.03,
                budgeted: 290,
                scheduled: 365,
            },
            {
                startDate: { id: 1, value: getDate(1) },
                monthOverMonth: 0.03,
                budgeted: 292,
                scheduled: 380,
            },
            {
                startDate: { id: 0, value: currentMonth },
                monthOverMonth: 0.23,
                budgeted: 292,
                scheduled: 388,
            },
        ],
        scheduledOvertimeHours: [
            {
                overtimeHours: 68,
                startDate: { id: 5, value: getDate(5) },
                monthOverMonth: 0.14,
                isGood: false,
            },
            {
                overtimeHours: 72,
                startDate: { id: 4, value: getDate(4) },
                monthOverMonth: 0.05,
                isGood: false,
            },
            {
                overtimeHours: 94,
                startDate: { id: 3, value: getDate(3) },
                monthOverMonth: 0.3,
                isGood: false,
            },
            {
                overtimeHours: 47,
                startDate: { id: 2, value: getDate(2) },
                monthOverMonth: -0.5,
                isGood: true,
            },
            {
                overtimeHours: 75,
                startDate: { id: 1, value: getDate(1) },
                monthOverMonth: 0.6,
                isGood: false,
            },
            {
                overtimeHours: 85,
                startDate: { id: 0, value: currentMonth },
                monthOverMonth: 0.13,
                isGood: false,
            },
        ],
        scheduledAgencyHours: [
            {
                agencyHours: 0,
                startDate: { id: 5, value: getDate(5) },
                monthOverMonth: null,
                isGood: true,
            },
            {
                agencyHours: 0,
                startDate: { id: 4, value: getDate(4) },
                monthOverMonth: null,
                isGood: true,
            },
            {
                agencyHours: 46,
                startDate: { id: 3, value: getDate(3) },
                monthOverMonth: null,
                isGood: true,
            },
            {
                agencyHours: 46,
                startDate: { id: 2, value: getDate(2) },
                monthOverMonth: 0,
                isGood: false,
            },
            {
                agencyHours: 23,
                startDate: { id: 1, value: getDate(1) },
                monthOverMonth: -0.15,
                isGood: true,
            },
            {
                agencyHours: 36,
                startDate: { id: 0, value: currentMonth },
                monthOverMonth: 0.55,
                isGood: false,
            },
        ],
        hiringNeeds: [
            {
                startDate: { id: 5, value: getDate(5) },
                openHours: 387,
                openShifts: 5,
                staffNeeds: 1,
                monthOverMonth: 0.03,
            },
            {
                startDate: { id: 4, value: getDate(4) },
                openHours: 317,
                openShifts: 5,
                staffNeeds: 1,
                monthOverMonth: 0.2,
            },
            {
                startDate: { id: 3, value: getDate(3) },
                openHours: 276,
                openShifts: 5,
                staffNeeds: 1,
                monthOverMonth: 0.03,
            },
            {
                startDate: { id: 2, value: getDate(2) },
                openHours: 356,
                openShifts: 6,
                staffNeeds: 1,
                monthOverMonth: 0.03,
            },
            {
                startDate: { id: 1, value: getDate(1) },
                openHours: 300,
                openShifts: 6,
                staffNeeds: 1,
                monthOverMonth: 0.03,
            },
            {
                startDate: { id: 0, value: currentMonth },
                openHours: 400,
                openShifts: 50,
                staffNeeds: 10,
                monthOverMonth: 0.33,
            },
        ],
        acuityBreakdown: [
            {
                startDate: { id: 5, value: getDate(5) },
                values: [49, 33, 10, 8],
            },
            {
                startDate: { id: 4, value: getDate(4) },
                values: [55, 24, 12, 9],
            },
            {
                startDate: { id: 3, value: getDate(3) },
                values: [47, 40, 8, 5],
            },
            {
                startDate: { id: 2, value: getDate(2) },
                values: [59, 25, 9, 7],
            },
            {
                startDate: { id: 1, value: getDate(1) },
                values: [59, 29, 8, 6],
            },
            {
                startDate: { id: 0, value: currentMonth },
                values: [64, 22, 8, 6],
            },
        ],
        residentesPercentage: [
            {
                level: 1,
                residentsPorcentage: 0.64,
                count: 128,
                monthOverMonth: 0.12,
                isGood: true,
                details: residentsPercentageDetail,
                startDate: { id: 0, value: currentMonth },
            },
            {
                level: 2,
                residentsPorcentage: 0.22,
                count: 44,
                monthOverMonth: 0.03,
                isGood: true,
                details: residentsPercentageDetail,
                startDate: { id: 0, value: currentMonth },
            },
            {
                level: 3,
                residentsPorcentage: 0.08,
                count: 16,
                monthOverMonth: -0.15,
                isGood: false,
                details: residentsPercentageDetail,
                startDate: { id: 0, value: currentMonth },
            },
            {
                level: 4,
                residentsPorcentage: 0.06,
                count: 12,
                monthOverMonth: 0.2,
                isGood: true,
                details: residentsPercentageDetail,
                startDate: { id: 0, value: currentMonth },
            },
        ],
    },
    {
        regionId: 12,
        branchId: 2,
        budgetedVsScheduledHours: [
            {
                startDate: { id: 5, value: getDate(5) },
                monthOverMonth: 0.03,
                budgeted: 384,
                scheduled: 398,
            },
            {
                startDate: { id: 4, value: getDate(4) },
                monthOverMonth: 0.03,
                budgeted: 384,
                scheduled: 396,
            },
            {
                startDate: { id: 3, value: getDate(3) },
                monthOverMonth: 0.03,
                budgeted: 381,
                scheduled: 400,
            },
            {
                startDate: { id: 2, value: getDate(2) },
                monthOverMonth: 0.03,
                budgeted: 378,
                scheduled: 402,
            },
            {
                startDate: { id: 1, value: getDate(1) },
                monthOverMonth: 0.03,
                budgeted: 378,
                scheduled: 395,
            },
            {
                startDate: { id: 0, value: currentMonth },
                monthOverMonth: -0.04,
                budgeted: 372,
                scheduled: 380,
            },
        ],
        scheduledOvertimeHours: [
            {
                overtimeHours: 27,
                startDate: { id: 5, value: getDate(5) },
                monthOverMonth: -0.28,
                isGood: true,
            },
            {
                overtimeHours: 28,
                startDate: { id: 4, value: getDate(4) },
                monthOverMonth: 0.05,
                isGood: false,
            },
            {
                overtimeHours: 23,
                startDate: { id: 3, value: getDate(3) },
                monthOverMonth: -0.17,
                isGood: true,
            },
            {
                overtimeHours: 28,
                startDate: { id: 2, value: getDate(2) },
                monthOverMonth: 0.25,
                isGood: false,
            },
            {
                overtimeHours: 31,
                startDate: { id: 1, value: getDate(1) },
                monthOverMonth: 0.12,
                isGood: false,
            },
            {
                overtimeHours: 28,
                startDate: { id: 0, value: currentMonth },
                monthOverMonth: -0.09,
                isGood: true,
            },
        ],
        scheduledAgencyHours: [
            {
                agencyHours: 0,
                startDate: { id: 5, value: getDate(5) },
                monthOverMonth: null,
                isGood: false,
            },
            {
                agencyHours: 0,
                startDate: { id: 4, value: getDate(4) },
                monthOverMonth: null,
                isGood: true,
            },
            {
                agencyHours: 3,
                startDate: { id: 3, value: getDate(3) },
                monthOverMonth: null,
                isGood: true,
            },
            {
                agencyHours: 5,
                startDate: { id: 2, value: getDate(2) },
                monthOverMonth: 0.67,
                isGood: false,
            },
            {
                agencyHours: 9,
                startDate: { id: 1, value: getDate(1) },
                monthOverMonth: 0.8,
                isGood: false,
            },
            {
                agencyHours: 0,
                startDate: { id: 0, value: currentMonth },
                monthOverMonth: null,
                isGood: true,
            },
        ],
        hiringNeeds: [
            {
                startDate: { id: 5, value: getDate(5) },
                openHours: 70,
                openShifts: 10,
                staffNeeds: 2,
                monthOverMonth: 0.03,
            },
            {
                startDate: { id: 4, value: getDate(4) },
                openHours: 93,
                openShifts: 11,
                staffNeeds: 2,
                monthOverMonth: 0.2,
            },
            {
                startDate: { id: 3, value: getDate(3) },
                openHours: 70,
                openShifts: 10,
                staffNeeds: 2,
                monthOverMonth: 0.03,
            },
            {
                startDate: { id: 2, value: getDate(2) },
                openHours: 96,
                openShifts: 12,
                staffNeeds: 3,
                monthOverMonth: 0.03,
            },
            {
                startDate: { id: 1, value: getDate(1) },
                openHours: 96,
                openShifts: 12,
                staffNeeds: 3,
                monthOverMonth: 0.03,
            },
            {
                startDate: { id: 0, value: currentMonth },
                openHours: 80,
                openShifts: 10,
                staffNeeds: 2,
                monthOverMonth: -0.5,
            },
        ],
        acuityBreakdown: [
            {
                startDate: { id: 5, value: getDate(5) },
                values: [46, 26, 18, 10],
            },
            {
                startDate: { id: 4, value: getDate(4) },
                values: [46, 26, 18, 10],
            },
            {
                startDate: { id: 3, value: getDate(3) },
                values: [46, 26, 18, 10],
            },
            {
                startDate: { id: 2, value: getDate(2) },
                values: [46, 26, 18, 10],
            },
            {
                startDate: { id: 1, value: getDate(1) },
                values: [46, 26, 18, 10],
            },
            {
                startDate: { id: 0, value: currentMonth },
                values: [37, 38, 18, 7],
            },
        ],
        residentesPercentage: [
            {
                level: 1,
                residentsPorcentage: 0.37,
                count: 50,
                monthOverMonth: 0.05,
                isGood: true,
                details: residentsPercentageDetail,
                startDate: { id: 0, value: currentMonth },
            },
            {
                level: 2,
                residentsPorcentage: 0.38,
                count: 30,
                monthOverMonth: 0.2,
                isGood: true,
                details: residentsPercentageDetail,
                startDate: { id: 0, value: currentMonth },
            },
            {
                level: 3,
                residentsPorcentage: 0.18,
                count: 20,
                monthOverMonth: -0.05,
                isGood: false,
                details: residentsPercentageDetail,
                startDate: { id: 0, value: currentMonth },
            },
            {
                level: 4,
                residentsPorcentage: 0.7,
                count: 35,
                monthOverMonth: 0.2,
                isGood: false,
                details: residentsPercentageDetail,
                startDate: { id: 0, value: currentMonth },
            },
        ],
    },
    {
        regionId: 12,
        branchId: 3,
        budgetedVsScheduledHours: [
            {
                startDate: { id: 5, value: getDate(5) },
                monthOverMonth: 0.03,
                budgeted: 393,
                scheduled: 340,
            },
            {
                startDate: { id: 4, value: getDate(4) },
                monthOverMonth: 0.03,
                budgeted: 396,
                scheduled: 337,
            },
            {
                startDate: { id: 3, value: getDate(3) },
                monthOverMonth: 0.03,
                budgeted: 390,
                scheduled: 322,
            },
            {
                startDate: { id: 2, value: getDate(2) },
                monthOverMonth: 0.03,
                budgeted: 390,
                scheduled: 310,
            },
            {
                startDate: { id: 1, value: getDate(1) },
                monthOverMonth: 0.03,
                budgeted: 385,
                scheduled: 328,
            },
            {
                startDate: { id: 0, value: currentMonth },
                monthOverMonth: 0.1,
                budgeted: 386,
                scheduled: 322,
            },
        ],
        scheduledOvertimeHours: [
            {
                overtimeHours: 24,
                startDate: { id: 5, value: getDate(5) },
                monthOverMonth: -0.17,
                isGood: true,
            },
            {
                overtimeHours: 14,
                startDate: { id: 4, value: getDate(4) },
                monthOverMonth: 0.71,
                isGood: true,
            },
            {
                overtimeHours: 32,
                startDate: { id: 3, value: getDate(3) },
                monthOverMonth: 1.28,
                isGood: false,
            },
            {
                overtimeHours: 48,
                startDate: { id: 2, value: getDate(2) },
                monthOverMonth: 0.5,
                isGood: false,
            },
            {
                overtimeHours: 42,
                startDate: { id: 1, value: getDate(1) },
                monthOverMonth: -0.125,
                isGood: true,
            },
            {
                overtimeHours: 52,
                startDate: { id: 0, value: currentMonth },
                monthOverMonth: 0.2,
                isGood: false,
            },
        ],
        scheduledAgencyHours: [
            {
                agencyHours: 0,
                startDate: { id: 5, value: getDate(5) },
                monthOverMonth: null,
                isGood: true,
            },
            {
                agencyHours: 0,
                startDate: { id: 4, value: getDate(4) },
                monthOverMonth: null,
                isGood: true,
            },
            {
                agencyHours: 17,
                startDate: { id: 3, value: getDate(3) },
                monthOverMonth: null,
                isGood: true,
            },
            {
                agencyHours: 9,
                startDate: { id: 2, value: getDate(2) },
                monthOverMonth: -0.47,
                isGood: true,
            },
            {
                agencyHours: 11,
                startDate: { id: 1, value: getDate(1) },
                monthOverMonth: 0.22,
                isGood: true,
            },
            {
                agencyHours: 0,
                startDate: { id: 0, value: currentMonth },
                monthOverMonth: null,
                isGood: true,
            },
        ],
        hiringNeeds: [
            {
                startDate: { id: 5, value: getDate(5) },
                openHours: 105,
                openShifts: 15,
                staffNeeds: 3,
                monthOverMonth: 0.03,
            },
            {
                startDate: { id: 4, value: getDate(4) },
                openHours: 140,
                openShifts: 17,
                staffNeeds: 3,
                monthOverMonth: 0.2,
            },
            {
                startDate: { id: 3, value: getDate(3) },
                openHours: 105,
                openShifts: 15,
                staffNeeds: 3,
                monthOverMonth: 0.03,
            },
            {
                startDate: { id: 2, value: getDate(2) },
                openHours: 144,
                openShifts: 18,
                staffNeeds: 3,
                monthOverMonth: 0.03,
            },
            {
                startDate: { id: 1, value: getDate(1) },
                openHours: 144,
                openShifts: 18,
                staffNeeds: 3,
                monthOverMonth: 0.03,
            },
            {
                startDate: { id: 0, value: currentMonth },
                openHours: 40,
                openShifts: 5,
                staffNeeds: 1,
                monthOverMonth: 1,
            },
        ],
        acuityBreakdown: [
            {
                startDate: { id: 5, value: getDate(5) },
                values: [46, 26, 18, 10],
            },
            {
                startDate: { id: 4, value: getDate(4) },
                values: [46, 26, 18, 10],
            },
            {
                startDate: { id: 3, value: getDate(3) },
                values: [46, 26, 18, 10],
            },
            {
                startDate: { id: 2, value: getDate(2) },
                values: [46, 26, 18, 10],
            },
            {
                startDate: { id: 1, value: getDate(1) },
                values: [46, 26, 18, 10],
            },
            {
                startDate: { id: 0, value: currentMonth },
                values: [44, 30, 16, 10],
            },
        ],
        residentesPercentage: [
            {
                level: 1,
                count: 50,
                monthOverMonth: 0.05,
                isGood: true,
                details: residentsPercentageDetail,
                startDate: { id: 0, value: currentMonth },
            },
            {
                level: 2,
                count: 30,
                monthOverMonth: 0.2,
                isGood: true,
                details: residentsPercentageDetail,
                startDate: { id: 0, value: currentMonth },
            },
            {
                level: 3,
                count: 20,
                monthOverMonth: -0.05,
                isGood: false,
                details: residentsPercentageDetail,
                startDate: { id: 0, value: currentMonth },
            },
            {
                level: 4,
                count: 35,
                monthOverMonth: 0.2,
                isGood: false,
                details: residentsPercentageDetail,
                startDate: { id: 0, value: currentMonth },
            },
        ],
    },
];
