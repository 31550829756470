import { FormControl, InputAdornment, StandardTextFieldProps, SxProps, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';

import langDictionary from '~/app-strings';
import { InputSize, InputVariant } from '~/types/inputs';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles({
    fullWidth: {
        width: '100%',
    },
    autoWidth: {
        width: 'auto',
    },
});

type Props = {
    autoFocus?: boolean;
    id?: string;
    className?: string;
    customErrorMessage?: string;
    disabled?: boolean;
    endAdornment?: ReactNode;
    fullWidth?: boolean;
    focused?: boolean;
    label: string;
    rows?: number;
    maxRows?: number;
    multiline?: boolean;
    placeholder?: string;
    required?: boolean;
    size?: InputSize;
    startAdornment?: ReactNode;
    sx?: SxProps<Theme>;
    type?: string;
    value?: string;
    variant?: InputVariant;
    inputProps?: { [key: string]: unknown };
    InputProps?: StandardTextFieldProps['InputProps'];
    onBlur?: (hasErrors?: boolean) => void;
    onChange: (value: string) => void;
    onKeyDown?: (event) => void;
    onKeyUpCapture?: (event) => void;
};

export const CustomTextField = (props: Props) => {
    const classes = useStyles();
    const {
        id,
        className,
        customErrorMessage,
        disabled,
        endAdornment,
        focused,
        fullWidth,
        label,
        rows = 1,
        maxRows = 1,
        multiline,
        placeholder,
        required,
        size = 'small',
        startAdornment,
        sx = [],
        type = 'text',
        value,
        variant = 'outlined',
        inputProps = {},
        InputProps = {},
        onBlur = () => null,
        onChange,
        onKeyDown,
        onKeyUpCapture,
    } = props;
    const { error } = langDictionary;
    const [inputValue, setInputValue] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isDirty, setIsDirty] = useState<boolean>(false);

    if (startAdornment) {
        InputProps.startAdornment = <InputAdornment position="start">{startAdornment}</InputAdornment>;
    }

    if (endAdornment) {
        InputProps.endAdornment = <InputAdornment position="end">{endAdornment}</InputAdornment>;
    }

    const validateMissingValue = (isInputDirty: boolean) => {
        if (!required) {
            return false;
        }

        return isEmpty(inputValue) && isInputDirty;
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setInputValue(newValue);
        onChange(newValue);
    };

    const handleBlur = () => {
        const isValueMissing = validateMissingValue(true);

        if (!isValueMissing) {
            onBlur();
            return;
        }

        setIsDirty(true);
        setErrorMessage(error.emptyValue);
        onBlur(true);
    };

    useEffect(() => {
        if (value) {
            setInputValue(value);
        }

        const isValueMissing = validateMissingValue(isDirty);

        if (isValueMissing) {
            setErrorMessage(error.emptyValue);
        } else {
            setErrorMessage('');
        }
    }, [value]);

    return (
        <FormControl className={fullWidth ? classes.fullWidth : classes.autoWidth} sx={sx}>
            <TextField
                sx={sx}
                id={id}
                type={type}
                className={className}
                placeholder={placeholder}
                label={label}
                value={inputValue}
                multiline={multiline}
                rows={rows}
                maxRows={maxRows}
                size={size}
                variant={variant}
                InputProps={InputProps}
                inputProps={{ 'data-hj-allow': true, ...inputProps }} // eslint-disable-line react/jsx-no-duplicate-props
                InputLabelProps={{ shrink: true }}
                required={required}
                disabled={disabled}
                error={!!customErrorMessage || errorMessage !== ''}
                helperText={errorMessage || customErrorMessage}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={onKeyDown}
                onKeyUpCapture={onKeyUpCapture}
                focused={focused}
            />
        </FormControl>
    );
};
