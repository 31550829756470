import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';

import { STANDARDIZED_BUDGET_STATUS_TYPES } from '@allie/utils/src/constants/scheduling/budget-stats.contants';

import { useGetLocations } from '~/scheduling/api/queries/locations/getLocations';
import { useGetMasterSchedule } from '~/scheduling/api/queries/master-schedule/getMasterSchedule';
import { useGetBudget } from '~/scheduling/api/queries/master-schedule/useGetBudget';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';
import { teamIdAtom } from '~/scheduling/atoms';

import { scheduledBudgetAtom } from './atom';
import { calculateScheduledBudget, createEmptySchedule, fillEmptySchedule } from './helpers';
import { StaffOptimizations } from './types';

export const useBudgetedShifts = () => {
    const [scheduled, setScheduled] = useAtom(scheduledBudgetAtom);
    const teamId = useAtomValue(teamIdAtom);

    const { data: rolesData } = useGetRoles(teamId);
    const { data: locationsData } = useGetLocations();
    const { data: masterSchedule } = useGetMasterSchedule(teamId);
    const { data: budgetData, isLoading: isBudgetLoading } = useGetBudget(teamId);

    const budgetedShifts: StaffOptimizations.BudgetedShift[] = useMemo(() => {
        const emptySchedule = createEmptySchedule(rolesData, locationsData);
        return masterSchedule ? fillEmptySchedule(emptySchedule, masterSchedule) : emptySchedule;
    }, [rolesData, locationsData, masterSchedule]);

    const status =
        !budgetData || budgetData.budget === scheduled
            ? STANDARDIZED_BUDGET_STATUS_TYPES.ON_BUDGET
            : scheduled > budgetData.budget
              ? STANDARDIZED_BUDGET_STATUS_TYPES.OVER
              : STANDARDIZED_BUDGET_STATUS_TYPES.UNDER;

    useEffect(() => {
        if (!scheduled) {
            setScheduled(calculateScheduledBudget(budgetedShifts));
        }
    }, [scheduled, budgetedShifts, setScheduled]);

    const updateScheduledBudget = useCallback(
        (newScheduledBudget: number) => {
            setScheduled((prev) => prev + newScheduledBudget);
        },
        [setScheduled]
    );

    const constants = useMemo(
        () => ({
            budgetedShifts,
            budgetOverviewStats: { ...(budgetData ?? {}), scheduled, isLoading: isBudgetLoading },
            budgetStatus: status,
        }),
        [budgetedShifts, isBudgetLoading, scheduled]
    );

    return {
        ...constants,
        updateScheduledBudget,
    };
};
