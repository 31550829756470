import React from 'react';

import { SectionDetail, SectionsWithCollapsebleDetailItemTypes } from '~/types/operationsDashboard';

import {
    ChangeOfConditionCollapsibleComponent,
    FallsCollapsibleComponent,
    MedicineMissedCollapsibleComponent,
    ResidentNotesCollapsibleComponent,
    ShowerCollapsibleComponent,
    UnscheduledCareCollapsibleComponent,
} from './collapsible-components';

type DetailsRowProps = {
    detailsData: SectionDetail[];
    dataType: SectionsWithCollapsebleDetailItemTypes;
};

export const DetailsRow = ({ dataType, detailsData }: DetailsRowProps) => {
    const CollapsibleComponentMapper: Record<
        SectionsWithCollapsebleDetailItemTypes,
        (props: { rows: SectionDetail[] }) => React.ReactNode
    > = {
        CHANGE_OF_CONDITION: ChangeOfConditionCollapsibleComponent,
        FALLS: FallsCollapsibleComponent,
        RESIDENT_NOTES: ResidentNotesCollapsibleComponent,
        SHOWER: ShowerCollapsibleComponent,
        UNSCHEDULED_CARE: UnscheduledCareCollapsibleComponent,
        MEDS_REFUSED: MedicineMissedCollapsibleComponent,
    };

    const CollapsibleComponent = CollapsibleComponentMapper[dataType];

    return <CollapsibleComponent rows={detailsData} />;
};
