import Box from '@mui/material/Box';
import React, { useState } from 'react';

import { pxToRem } from '~/components/theme/typography';
import { Section, SectionData } from '~/pages/OperationsDashboard/constants';
import { OperationsLevel, OperationsModuleData, OperationsPeriod, SelectOptionsId } from '~/types/operationsDashboard';

import { SectionDataModule } from '../Module/SectionDataModule';

import { CategorySelector } from './CategorySelector';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    noRightPadding?: boolean;
}

const CustomTabPanel = ({ children, value, index, noRightPadding, ...other }: TabPanelProps) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
    >
        {value === index && (
            <Box
                sx={{
                    p: { xs: pxToRem(24), lg: pxToRem(16) },
                    pr: {
                        xs: noRightPadding ? 0 : pxToRem(24),
                        lg: pxToRem(16),
                    },
                    pb: {
                        xs: pxToRem(index === 1 ? 200 : 100),
                        lg: pxToRem(16),
                    },
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {children}
            </Box>
        )}
    </div>
);

type Props = {
    moduleData: OperationsModuleData;
    sectionsToShow: Record<Section, SectionData>;
    mobileTabSelected: 0 | 1;
    region: SelectOptionsId;
    branch: SelectOptionsId;
    careType: string;
    operationsPeriod: OperationsPeriod;
    level: OperationsLevel;
    onMobileTabChange: (newValue: number) => void;
};

const MobileDashboardTabPanels = ({
    moduleData,
    sectionsToShow,
    mobileTabSelected,
    region,
    branch,
    careType,
    operationsPeriod,
    level,
    onMobileTabChange,
}: Props) => {
    const [selectedSection, setSelectedSection] = useState<Section>('DOCUMENTATION_RATE');

    const handleClick = (section: Section) => {
        setSelectedSection(section);
        window.scrollTo(0, 0);
        onMobileTabChange(1);
    };

    return (
        <>
            <CustomTabPanel value={mobileTabSelected} index={0}>
                {Object.keys(sectionsToShow).map((section: Section) => (
                    <SectionDataModule
                        key={section}
                        moduleData={moduleData}
                        dataType={section}
                        level={level}
                        operationsPeriod={operationsPeriod}
                        regionId={region}
                        branchId={branch}
                        careType={careType}
                        miniDisplay="chart"
                        onClick={() => handleClick(section)}
                    />
                ))}
            </CustomTabPanel>

            <CustomTabPanel value={mobileTabSelected} index={1} noRightPadding>
                <SectionDataModule
                    key={selectedSection}
                    moduleData={moduleData}
                    dataType={selectedSection}
                    level={level}
                    operationsPeriod={operationsPeriod}
                    regionId={region}
                    branchId={branch}
                    careType={careType}
                    miniDisplay="table"
                />
                <CategorySelector
                    sectionsToShow={sectionsToShow}
                    selectedSection={selectedSection}
                    setSelectedSection={setSelectedSection}
                />
            </CustomTabPanel>
        </>
    );
};

export default MobileDashboardTabPanels;
