import { BottomNavigation, BottomNavigationAction, Stack } from '@mui/material';
import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { ComponentProps } from 'react';
import { useMatches, useNavigate } from 'react-router-dom';

import { SingleLineTypography } from '~/components/Shared/SingleLineTypography';
import { isSoftMatch } from '~/router/helpers';

import { InnerDestination } from './shared';
import { useFilteredDestinations } from './useFilteredDestinations';

const MobileBottomNavigationContainer = styled(Stack)({
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    boxShadow: '0 -2px 8px rgba(0, 0, 0, 0.1)',
});

const CustomMobileBottomNavigation = styled(BottomNavigation)({
    height: '64px',
    gap: '8px',
    padding: '0 8px',
});

const CustomMobileBottomNavigationActionLabel = styled(SingleLineTypography)({
    fontSize: '11px',
    fontWeight: 400,
    lineHeight: '14px',
});

const CustomMobileBottomNavigationAction = ({
    destination: { title, shortTitle, path, startIcon: StartIcon, startIconWeightActive },
    isSelected,
    sx,
    ...props
}: {
    destination: InnerDestination;
    isSelected: boolean;
} & ComponentProps<typeof BottomNavigationAction>) => {
    const { palette } = useTheme();
    const navigate = useNavigate();

    return (
        <BottomNavigationAction
            label={
                <CustomMobileBottomNavigationActionLabel fontWeight={isSelected ? 700 : 400}>
                    {shortTitle ?? title}
                </CustomMobileBottomNavigationActionLabel>
            }
            icon={<StartIcon size={24} weight={isSelected ? (startIconWeightActive ?? 'fill') : 'regular'} />}
            onClick={() => navigate(path, { replace: true })}
            sx={{
                color: palette.grey[300],
                minWidth: 0,
                gap: '4px',
                p: 0,
                ...sx,
            }}
            {...props}
        />
    );
};

const MobileBottomNavigation = () => {
    const matches = useMatches();

    const filteredDestinations = useFilteredDestinations({ platform: 'mobile' });
    const remappedDestinations = filteredDestinations.flatMap(({ innerDestinations }) => innerDestinations);

    if (remappedDestinations.length <= 1) return null;

    const currentDestinationIndex = remappedDestinations.findIndex(({ path }) => isSoftMatch(matches, path));

    return (
        <MobileBottomNavigationContainer>
            <CustomMobileBottomNavigation value={currentDestinationIndex} showLabels>
                {remappedDestinations.map((destination, index) => (
                    <CustomMobileBottomNavigationAction
                        key={index}
                        value={index}
                        destination={destination}
                        isSelected={index === currentDestinationIndex}
                    />
                ))}
            </CustomMobileBottomNavigation>
        </MobileBottomNavigationContainer>
    );
};

export default MobileBottomNavigation;
