import { Box, Typography } from '@mui/material';
import React from 'react';
import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

const CustomMultilineChartTooltip = ({
    active,
    payload,
    unitOfMeasurement,
}: TooltipProps<ValueType, NameType> & { unitOfMeasurement?: string }) => {
    if (active && payload && payload.length) {
        const { period, ...keys } = payload[0].payload as { period: string; [key: string]: string };

        const [, month, day] = period.split('-');
        const formattedPeriod = month && day ? `${month}/${day}` : period;

        return (
            <Box
                sx={{
                    border: 'none',
                    backgroundColor: '#FFF',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 4px rgba(139, 139, 139, 0.25)',
                    fontFamily: 'Open Sans',
                    color: '#6F6F79',
                    px: '8px',
                    py: '4px',
                }}
            >
                <Typography sx={{ fontSize: { xs: '12px' }, marginBottom: '4px' }}>
                    Period: <span style={{ fontWeight: 700 }}>{formattedPeriod}</span>
                </Typography>
                {Object.entries(keys).map(([key, value]) => (
                    <Typography key={key} fontSize={{ xs: '12px' }}>
                        {key}:{' '}
                        <span style={{ fontWeight: 700 }}>
                            {value}
                            {unitOfMeasurement ?? ''}
                        </span>
                    </Typography>
                ))}
            </Box>
        );
    }

    return null;
};

export default CustomMultilineChartTooltip;
