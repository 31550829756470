import { Box, Typography } from '@mui/material';
import React, { SyntheticEvent, useEffect, useState } from 'react';

import { pxToRem } from '~/components/theme/typography';

type Props = {
    count: number;
};

export const TaskCounter = (props: Props) => {
    const { count } = props;

    return (
        <Box
            sx={{
                width: pxToRem(32),
                height: pxToRem(32),
                borderRadius: pxToRem(32),
                backgroundColor: 'secondary.main',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                marginRight: pxToRem(12),
            }}
        >
            <Typography
                sx={{
                    margin: 'auto',
                }}
            >
                {count}
            </Typography>
        </Box>
    );
};
