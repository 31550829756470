import React from 'react';
import { Helmet } from 'react-helmet';

import { APP_NAME } from '~/constants/app';
import { useRouteHandle } from '~/router';

const PageTitleHandler = () => {
    const { title } = useRouteHandle();

    return (
        <Helmet>
            <title>{title ? `${title} | ${APP_NAME}` : APP_NAME}</title>
        </Helmet>
    );
};

export default PageTitleHandler;
