import { Box, Button, Typography } from '@mui/material';
import React from 'react';

import langDictionary from '~/app-strings';
import { CustomMobileDialog } from '~/components/Custom/CustomMobileDialog';
import { pxToRem } from '~/components/theme/typography';
import { EARLY_IN_SHIFT_DIALOG_ANALYTICS } from '~/constants/analytics';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    shiftName: string;
    onAccept: () => void;
};

const EarlyInShiftConfirmationDialog = (props: Props) => {
    const { isOpen, onClose, shiftName, onAccept } = props;

    const {
        earlyInShiftConfirmationDialogTitle: earlyInShiftConfirmationDialogTitleTxt,
        earlyInShiftConfirmationDialogContent: earlyInShiftConfirmationDialogContentTxt,
        no: noTxt,
        yes: yesTxt,
    } = langDictionary.shared;

    return (
        <CustomMobileDialog
            closeable
            open={isOpen}
            title={earlyInShiftConfirmationDialogTitleTxt}
            width="100%"
            onClose={onClose}
            content={
                <>
                    <Typography textAlign="left">
                        {earlyInShiftConfirmationDialogContentTxt.replace('{SHIFT_NAME}', shiftName)}
                    </Typography>
                    <Box
                        sx={{
                            mt: pxToRem(20),
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            gap: pxToRem(12),
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={onClose}
                            sx={{
                                width: '100%',
                                p: pxToRem(12),
                                fontSize: pxToRem(14),
                            }}
                            data-analytics-id={EARLY_IN_SHIFT_DIALOG_ANALYTICS.NO_BUTTON}
                        >
                            {noTxt}
                        </Button>
                        <Button
                            color="primary"
                            onClick={onAccept}
                            sx={{
                                width: '100%',
                                p: pxToRem(12),
                                fontSize: pxToRem(14),
                            }}
                            data-analytics-id={EARLY_IN_SHIFT_DIALOG_ANALYTICS.YES_BUTTON}
                        >
                            {yesTxt}
                        </Button>
                    </Box>
                </>
            }
        />
    );
};

export default EarlyInShiftConfirmationDialog;
