import { Box, Button, Typography } from '@mui/material';
import React from 'react';

import AuthButton from '~/components/Auth/AuthButton';
import CustomSimpleDialog from '~/components/Custom/CustomSimpleDialog';
import { pxToRem } from '~/components/theme/typography';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    description: string;
    icon: React.ReactNode;
    onPrimaryAction: () => void;
    primaryActionLabel: string;
    onCancel: () => void;
    isLoading?: boolean;
};

const ActionPromptDialog = ({
    isOpen,
    onClose,
    title,
    description,
    icon,
    onPrimaryAction,
    primaryActionLabel,
    onCancel,
    isLoading,
}: Props) => (
    <CustomSimpleDialog
        content={
            <Box
                sx={{
                    maxWidth: { xs: pxToRem(279), lg: pxToRem(400) },
                    display: 'grid',
                    textAlign: 'center',
                }}
            >
                {icon}
                <Typography
                    sx={{
                        fontSize: { xs: pxToRem(20) },
                        lineHeight: 1.6,
                        fontWeight: 700,
                        color: '#2C2D3B',
                        mb: pxToRem(8),
                        mt: pxToRem(24),
                        textAlign: 'center',
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    sx={{
                        color: '#6F6F79',
                        fontSize: { xs: pxToRem(14) },
                        lineHeight: 1.7,
                        mb: pxToRem(24),
                        textAlign: 'center',
                    }}
                >
                    {description}
                </Typography>
                <AuthButton
                    label={primaryActionLabel}
                    onClick={onPrimaryAction}
                    isDisabled={false}
                    isLoading={isLoading}
                />
                <Button
                    variant="text"
                    sx={{
                        '&.MuiButton-root': {
                            bgcolor: 'transparent',
                            color: '#6F6F79',
                            textDecoration: 'underline',
                            fontSize: {
                                xs: pxToRem(14),
                            },
                            lineHeight: 1.7,
                            '&:focus-visible': {
                                outline: '1px solid #6F6F79',
                                outlineOffset: '-1px',
                            },
                        },
                        p: pxToRem(12),
                        mt: pxToRem(8),
                    }}
                    disableRipple
                    onClick={onCancel}
                >
                    Not now
                </Button>
            </Box>
        }
        open={isOpen}
        onClose={onClose}
        disableBackdropClick
    />
);

export default ActionPromptDialog;
