import { Box, Typography, styled, useTheme } from '@mui/material';
import React from 'react';

import { useGetCommunitySettings } from '~/scheduling/api/queries/community-settings/getCommunitySettings';
import { GetRoles } from '~/scheduling/api/types/staff-roles/getRoles';
import { getWeekDaysOrder } from '~/scheduling/utils/dates';

import { StaffOptimizations } from '../../types';

import { BudgetSlot } from './BudgetSlot';

const RowWrapper = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: '8px',
    display: 'flex',
}));

const WeekColumn = styled(Box)(({ theme }) => ({
    flex: 1,
    minHeight: '100%',
    padding: '12px',
    textAlign: 'center',
    backgroundColor: theme.palette.grey[25],
    borderColor: theme.palette.grey[100],
    borderStyle: 'solid',
    borderWidth: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
}));

const WEEK_DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const WeekRow = () => {
    const { palette } = useTheme();

    const { data: communitySettings } = useGetCommunitySettings();
    const firstDayOfWeek = communitySettings?.firstDayOfWeek;

    const weekDays = React.useMemo(
        () => (firstDayOfWeek ? getWeekDaysOrder(firstDayOfWeek).map((day) => WEEK_DAYS[day]) : []),
        [firstDayOfWeek]
    );

    if (!weekDays.length) return null;

    return (
        <RowWrapper>
            <WeekColumn
                sx={{
                    maxWidth: '42px',
                    backgroundColor: palette.primary[100],
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                }}
            />
            {weekDays.map((day, index) => (
                <WeekColumn
                    key={day}
                    sx={{
                        borderLeftWidth: '1px',
                        backgroundColor: 'white',
                        ...(index === weekDays.length - 1 && {
                            borderTopRightRadius: '8px',
                            borderBottomRightRadius: '8px',
                        }),
                    }}
                >
                    <Typography
                        textTransform="uppercase"
                        variant="body1"
                        lineHeight="20px"
                        fontSize="15px"
                        color={palette.grey[600]}
                    >
                        {day}
                    </Typography>
                </WeekColumn>
            ))}
        </RowWrapper>
    );
};

interface ShiftRowsProps {
    shift: GetRoles.StaffRoleShift;
    days: StaffOptimizations.ShiftCalendarDay[];
}

export const ShiftRows = (props: ShiftRowsProps) => {
    const { palette } = useTheme();

    const { data: communitySettings } = useGetCommunitySettings();
    const firstDayOfWeek = communitySettings?.firstDayOfWeek;

    const days = React.useMemo(
        () =>
            firstDayOfWeek
                ? getWeekDaysOrder(firstDayOfWeek)
                      .map((weekDay) => props.days.find(({ day }) => day.weekday === weekDay + 1))
                      .filter((day) => !!day)
                : [],
        [firstDayOfWeek, props.days]
    );

    if (!days.length) return null;

    return (
        <RowWrapper flex={1}>
            <WeekColumn
                sx={{
                    paddingBlock: '8px',
                    maxWidth: '42px',
                    backgroundColor: palette.primary[100],
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                }}
            >
                <Typography
                    // https://stackoverflow.com/a/66772193
                    sx={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr' }}
                    variant="body1"
                    lineHeight="16px"
                    fontSize="13px"
                    fontWeight={600}
                    color={palette.grey[600]}
                >
                    {props.shift.name}
                </Typography>
            </WeekColumn>
            {days.map((day, dayIndex) => (
                <WeekColumn key={day.day.toSeconds()} sx={{ borderLeftWidth: '1px', padding: 0 }}>
                    {day.slots.map((slot, idx) => (
                        <BudgetSlot
                            dayIndex={dayIndex}
                            key={slot.location.id}
                            styles={{ borderBottomWidth: idx !== day.slots.length - 1 ? '1px' : 0 }}
                            slot={slot}
                            day={day.day}
                            shift={props.shift}
                        />
                    ))}
                </WeekColumn>
            ))}
        </RowWrapper>
    );
};
