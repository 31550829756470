import { Box, Typography, styled, useTheme } from '@mui/material';
import { useAtom } from 'jotai';
import React from 'react';
import { UseFormReturn, useController } from 'react-hook-form';
import { PiCheckBold, PiWarningCircleFill } from 'react-icons/pi';

import { primaryStaffRoleAtom } from '../../atom';
import { StaffDetailsFormFields } from '../../types';

const StaffRoleItemWrapper = styled(Box)<{ $isPrimary?: boolean; disabled: boolean }>(
    ({ theme: { palette }, $isPrimary, disabled }) => ({
        borderRadius: '8px',
        border: `1px solid ${$isPrimary ? palette.primary[200] : palette.grey[100]}`,
        position: 'relative',
        backgroundColor: $isPrimary ? palette.primary[400] : palette.grey[25],
        userSelect: 'none',
        ...(!$isPrimary && {
            // TODO: Fix hovering children triggering this
            '&:hover': {
                backgroundColor: palette.grey[50],
            },
        }),
        ...(disabled && {
            opacity: 0.5,
            pointerEvents: 'none',
        }),
    })
);

const StaffRoleLabelWrapper = styled(Box)<{ $check: boolean; selectable?: boolean }>(
    ({ theme: { palette }, $check, selectable }) => ({
        borderRadius: '8px',
        border: $check ? `1px solid ${palette.primary[400]}` : undefined,
        padding: '16px',
        backgroundColor: $check ? palette.primary[50] : 'white',
        cursor: selectable ? 'pointer !important' : undefined,
        zIndex: 1,
        ...(selectable && {
            '&:hover': {
                backgroundColor: $check ? (palette.primary[25] as string) : palette.grey[25],
            },
        }),
    })
);

const FancyUncheck = styled(Box)<{ selectable?: boolean }>(({ theme, selectable }) => ({
    position: 'absolute',
    top: '-8px',
    right: '-8px',
    backgroundColor: 'white',
    width: '22px',
    height: '22px',
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary[100]}`,
    boxShadow: '0px 5px 8px 0px rgba(57, 57, 57, 0.12)',
    cursor: selectable ? 'pointer' : undefined,
    ...(selectable && {
        '&:hover': {
            border: `1px solid ${theme.palette.primary[500]}`,
        },
    }),
}));

const FancyCheck = styled(PiCheckBold)<{ selectable?: boolean }>(({ theme, selectable }) => ({
    position: 'absolute',
    top: '-8px',
    right: '-8px',
    backgroundColor: theme.palette.primary[500],
    width: '22px',
    color: 'white',
    height: '22px',
    padding: '6px',
    borderRadius: '50%',
    border: `1px solid white`,
    boxShadow: '0px 5px 8px 0px rgba(57, 57, 57, 0.12)',
    cursor: selectable ? 'pointer' : undefined,
    ...(selectable && {
        '&:hover': {
            backgroundColor: theme.palette.primary[600] as string,
        },
    }),
}));

interface StaffRoleItemProps {
    id: number;
    label: string;
    selectedRolesLength: number;
    defaultValue?: boolean;
    onChange: (id: number) => void;
    deselectable?: boolean;
    disabled: boolean;
}

const StaffRoleItem = (props: StaffRoleItemProps) => {
    const { palette } = useTheme();

    const [check, setCheck] = React.useState(props.defaultValue ?? false);
    const [primaryStaffRole, setPrimaryStaffRole] = useAtom(primaryStaffRoleAtom);

    const isPrimary = primaryStaffRole === props.id;

    const handleClick = () => {
        props.onChange(props.id);

        if (props.deselectable) return setCheck((prev) => !prev);

        setCheck(true);
    };

    const handleSetAsPrimary = () => {
        setPrimaryStaffRole(props.id);
    };

    return (
        <StaffRoleItemWrapper $isPrimary={isPrimary} disabled={props.disabled}>
            {check ? (
                <FancyCheck selectable={props.deselectable || !check} aria-checked={true} onClick={handleClick} />
            ) : (
                <FancyUncheck selectable={props.deselectable || !check} aria-checked={false} onClick={handleClick} />
            )}
            <StaffRoleLabelWrapper selectable={props.deselectable || !check} $check={check} onClick={handleClick}>
                <Typography variant="body1" textAlign="center" fontWeight={600} color={palette.grey[900]}>
                    {props.label}
                </Typography>
            </StaffRoleLabelWrapper>
            {check && props.selectedRolesLength > 1 && (
                <Box padding="8px 12px" sx={isPrimary ? {} : { cursor: 'pointer' }} onClick={handleSetAsPrimary}>
                    {isPrimary ? (
                        <Typography variant="body1" textAlign="center" fontWeight={600} color="white">
                            Primary Role
                        </Typography>
                    ) : (
                        <Typography
                            variant="body1"
                            textAlign="center"
                            fontWeight={600}
                            color={palette.grey[900]}
                            sx={{ textDecoration: 'underline', cursor: 'pointer !important' }}
                        >
                            Set as Primary Role
                        </Typography>
                    )}
                </Box>
            )}
        </StaffRoleItemWrapper>
    );
};

type Option = { id: number; label: string };

interface StaffRoleSelectorProps {
    options: Option[];
    form: UseFormReturn<StaffDetailsFormFields>;
    primaryRole?: string;
    name: keyof StaffDetailsFormFields;
    deselectable?: boolean;
    defaultValue?: number[];
    disabled: boolean;
}

export const StaffRoleSelector = (props: StaffRoleSelectorProps) => {
    const { palette } = useTheme();
    const [selectedRoles, setSelectedRoles] = React.useState<number[]>(props.defaultValue ?? []);
    const [primaryStaffRole, setPrimaryStaffRole] = useAtom(primaryStaffRoleAtom);
    const {
        field: { onChange },
        fieldState,
    } = useController({
        name: props.name,
        control: props.form.control,
        rules: {
            validate: (value: number[]) =>
                !value || value.every((v) => !v) ? 'Select at least one staff role' : undefined,
        },
    });

    const handleChange = (id: number) => {
        if (!props.deselectable && selectedRoles.includes(id)) return;

        let newRoles: number[] = [];
        // if the field can be diselected and the same field is selected, deselect it
        if (selectedRoles.includes(id)) {
            newRoles = selectedRoles.filter((role) => role !== id);
        } else {
            newRoles = [...selectedRoles, id];
        }

        setSelectedRoles(newRoles);
        onChange(newRoles);

        // setting the first role as primary to ensure
        // it has always a value for the primary role
        if (newRoles.length === 1) {
            setPrimaryStaffRole(newRoles[0]);
        } else {
            // if the previous primary role is deselected then, set it as null
            if (primaryStaffRole && !newRoles.includes(primaryStaffRole)) {
                setPrimaryStaffRole(null);
            }
        }
    };

    return (
        <>
            <Box display="flex" gap="12px">
                <Typography
                    component="label"
                    sx={{ fontSize: '12px', fontWeight: 700, color: 'grey.900', marginBottom: '4px' }}
                >
                    Staff role*
                </Typography>
                {fieldState.error && (
                    <Typography
                        variant="body1"
                        color={palette.error[500] as string}
                        fontWeight={400}
                        fontSize="12px"
                        display="flex"
                        gap="4px"
                        alignItems="center"
                        marginBottom="4px"
                    >
                        <PiWarningCircleFill display="inline-flex" size={12} color={palette.error[500] as string} />{' '}
                        {fieldState.error.message}
                    </Typography>
                )}
            </Box>
            <Box display="flex" gap="12px">
                {props.options.map((option) => (
                    <Box key={option.id} flex={1}>
                        <StaffRoleItem
                            defaultValue={selectedRoles.includes(option.id)}
                            deselectable={props.deselectable}
                            selectedRolesLength={selectedRoles?.length}
                            onChange={handleChange}
                            id={option.id}
                            label={option.label}
                            disabled={props.disabled}
                        />
                    </Box>
                ))}
            </Box>
        </>
    );
};
