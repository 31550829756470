import {
    Unstable_NumberInput as BaseNumberInput,
    NumberInputProps as MuiNumberInputProps,
} from '@mui/base/Unstable_NumberInput';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { styled, useTheme } from '@mui/material';
import * as React from 'react';

const StyledInputRoot = styled('div')(({ theme }) => ({
    fontWeight: 400,
    color: theme.palette.grey[300],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const StyledInput = styled('input')(({ theme }) => ({
    borderStyle: 'solid',
    borderWidth: ' 0 1px 0 1px',
    borderColor: theme.palette.grey[100],
    padding: '4px',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20px',
    fontFamily: 'Inter',
    outline: 0,
    width: '100%',
    textAlign: 'center',
}));

const StyledButton = styled('button')(({ theme }) => ({
    fontFamily: 'Inter',
    lineHeight: 1.5,
    border: 'none',
    backgroundColor: '#FFF',
    color: theme.palette.grey[900],
    width: '16px',
    height: '16px',
    padding: '16px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover': {
        cursor: 'pointer',
    },
    '&.increment': {
        order: 1,
    },
}));

interface NumberInputProps extends MuiNumberInputProps {
    value: number;
    label: string;
    onChange: (event: unknown, value: number) => void;
}

function CustomNumberInput(props: NumberInputProps, ref: React.ForwardedRef<HTMLDivElement>) {
    const { palette } = useTheme();
    return (
        <BaseNumberInput
            style={{
                border: `1px solid ${palette.grey[100]}`,
                borderRadius: '6px',
                backgroundColor: '#fff',
                overflow: 'hidden',
                width: '200px',
            }}
            slots={{
                root: StyledInputRoot,
                input: (props) => <StyledInput {...props} readOnly />,
                incrementButton: StyledButton,
                decrementButton: StyledButton,
            }}
            slotProps={{
                incrementButton: {
                    children: <AddIcon fontSize="small" />,
                    className: 'increment',
                },
                decrementButton: {
                    children: <RemoveIcon fontSize="small" />,
                },
                input: {
                    value: `${props.value} ${props.label}`,
                    onChange: (event) => props.onChange(undefined, +event.target.value.split(' ')[0]),
                },
            }}
            {...props}
            ref={ref}
        />
    );
}

export const NumberInput = React.forwardRef(CustomNumberInput);
