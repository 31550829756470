import { DateTime } from 'luxon';
import { useCallback } from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/api/common';
import { GetFirstAvailableDay } from '~/scheduling/api/types/shift-slot/getFirstAvailableDay';

export const useGetFirstAvailableDay = (params: GetFirstAvailableDay.Params) => {
    const branchId = useBranchId()!;
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = { headers: { Authorization: token }, params };
        const { data } = await api.get<GetFirstAvailableDay.Response>(
            `/scheduling/${branchId}/shift-slot/first-available-day`,
            options
        );

        return data.response ? (DateTime.fromISO(data.response) as DateTime<true>) : null;
    }, [branchId, token, params]);

    return useErrorHandledQuery({
        queryKey: ['first-available-day', branchId, params],
        queryFn,
        staleTime: 30000,
        enabled: !!branchId && !!params.fromDay,
    });
};
