import { App } from '@capacitor/app';
import { PluginListenerHandle } from '@capacitor/core';
import { usePostHog } from 'posthog-js/react';
import React, { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';

import { api } from '~/api';
import { hideToast, showToast } from '~/components/Shared/Alerting/Toast/utils/showToast';
import useInterval from '~/hooks/useInterval';

/**
 * Holds configuration of the polling request
 */
const pollingConfig = {
    /**
     * Polling interval set to 30 seconds
     */
    pollingInterval: 30 * 1000,
    /**
     * Number of polling attempts before showing the alert
     */
    maxPollingAttempts: 3,
    /**
     * Timeout for the polling request. After 3 seconds processing, the request will be aborted and rejected.
     */
    pollingRequestTimeout: 3 * 1000,
};

// Counter to keep track of the number of polling attempts
let pollingAttemptsCounter = 0;

enum NetworkState {
    ONLINE = 'online',
    OFFLINE = 'offline',
}

type NetworkContextType = {
    state: NetworkState;
};

type NetworkProviderProps = {
    children: ReactNode;
};

let requestController: AbortController | undefined;

const ONLINE_TOAST_ID = 'network_context:toast:online';
const OFFLINE_TOAST_ID = 'network_context:toast:offline';

async function polling(params: { onError: () => void; onSuccess: () => void }) {
    try {
        // To resume requests we need to create a new instance of AbortController
        requestController = new AbortController();

        // In Slow-3g this request takes 2s to complete
        // In a normal network, this request takes less than 10ms to complete
        // So we are setting the timeout to 3s to filter for networks worse than Slow-3g
        const response = await api.get('/network-check', {
            timeout: pollingConfig.pollingRequestTimeout,
            signal: requestController.signal,
        });
        if (response.status === 200) {
            params.onSuccess();
        }
    } catch (error) {
        params.onError();
    }
}

export const NetworkContext = createContext<NetworkContextType | null>(null);

const NetworkProvider: React.FC<NetworkProviderProps> = ({ children }) => {
    const [networkState, setNetworkState] = useState(NetworkState.ONLINE);
    const [stopPolling, setStopPolling] = useState(false);
    const [offlineToastShown, setOfflineToastShown] = useState(false);
    const posthog = usePostHog();

    const updateNetworkStatusOnline = useCallback((type: 'polling' | 'networkToggle') => {
        if (type === 'networkToggle' && navigator.onLine) {
            setNetworkState(NetworkState.ONLINE);
            posthog.capture('network_context:online');
            hideToast(OFFLINE_TOAST_ID);
            showToast({
                message: 'You are back online',
                type: 'info',
                id: ONLINE_TOAST_ID,
                duration: 3000,
            });
        } else {
            hideToast(OFFLINE_TOAST_ID);
            setNetworkState((prevState) => {
                if (prevState === NetworkState.OFFLINE) {
                    posthog.capture('network_context:online');
                    showToast({
                        message: 'You are back online',
                        type: 'info',
                        id: ONLINE_TOAST_ID,
                        duration: 3000,
                    });
                }
                return NetworkState.ONLINE;
            });
        }
        pollingAttemptsCounter = 0;
        setOfflineToastShown(false);
    }, []);

    const updateNetworkStatusOffline = useCallback(() => {
        setNetworkState(NetworkState.OFFLINE);

        if (pollingAttemptsCounter < pollingConfig.maxPollingAttempts) {
            pollingAttemptsCounter += 1;
        } else if (!offlineToastShown) {
            posthog.capture('network_context:offline');
            showToast({
                message:
                    'We are having trouble connecting to the internet. Please move closer to the nursing station in order to document.',
                type: 'warning',
                duration: 15000,
                id: OFFLINE_TOAST_ID,
            });
            setOfflineToastShown(true);
        }
    }, [offlineToastShown]);

    useInterval(() => {
        if (stopPolling) {
            return;
        }
        return polling({
            onSuccess: () => updateNetworkStatusOnline('polling'),
            onError: updateNetworkStatusOffline,
        });
    }, pollingConfig.pollingInterval);

    useEffect(() => {
        window.addEventListener('online', () => updateNetworkStatusOnline('networkToggle'));
        window.addEventListener('offline', updateNetworkStatusOffline);

        let resumeListener: PluginListenerHandle;
        let pauseListener: PluginListenerHandle;

        const registerAppStateListeners = async () => {
            /**
             * This listener is called when the App switch from background to foreground.
             * The listener is also called on the Web when the tab is made active again.
             */
            resumeListener = await App.addListener('resume', () => {
                setStopPolling(false);
            });

            /**
             * This listener is called when the App switch from foreground to background.
             * The listener is also called on the Web when the user leaves the tab(switch to another tab).
             */
            pauseListener = await App.addListener('pause', () => {
                setStopPolling(true);
                if (requestController) {
                    // Abort the current ongoing polling request
                    requestController.abort();
                }
            });
        };

        const removeAppStateListeners = async () => {
            if (resumeListener) {
                await resumeListener.remove();
            }

            if (pauseListener) {
                await pauseListener.remove();
            }
        };

        registerAppStateListeners();

        return () => {
            window.removeEventListener('online', () => updateNetworkStatusOnline('networkToggle'));
            window.removeEventListener('offline', updateNetworkStatusOffline);

            // Remove the app state listeners one by one
            removeAppStateListeners();
        };
    }, [updateNetworkStatusOffline, updateNetworkStatusOnline]);

    return (
        <NetworkContext.Provider
            value={{
                state: networkState,
            }}
        >
            {children}
        </NetworkContext.Provider>
    );
};

const useNetwork = (): NetworkContextType => {
    const context = useContext(NetworkContext);
    if (!context) {
        throw new Error('useNetwork must be used within a NetworkProvider');
    }
    return context;
};

export { NetworkProvider, useNetwork };
