import { useMediaQuery } from '@mui/material';
import React from 'react';
import { Toaster } from 'sonner';

import theme from '~/theme';

export default function GlobalToast() {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Toaster
            visibleToasts={2}
            richColors
            position={isMobile ? 'top-center' : 'bottom-left'}
            closeButton
            toastOptions={{
                style: {
                    fontSize: '16px',
                    marginTop: isMobile ? '50px' : undefined,
                    fontFamily: 'Inter',
                },
            }}
        />
    );
}
