import React from 'react';

import { api } from '~/api';
import { queryClient, useBranchId, useErrorHandledMutation, useToken } from '~/api/common';
import { CreateStaff } from '~/scheduling/api/types/staff/createStaff';

export const useCreateStaff = () => {
    const branchId = useBranchId()!;
    const token = useToken();

    const mutationFn = React.useCallback(
        async (data: CreateStaff.Params) => {
            const options = { headers: { Authorization: token } };
            const response = await api.post<CreateStaff.Response>(`/scheduling/${branchId}/staff`, data, options);

            return response.data.response.id;
        },
        [branchId, token]
    );

    const onSuccess = React.useCallback(async () => {
        await queryClient.invalidateQueries({ queryKey: ['staff-list', branchId] });
        await queryClient.invalidateQueries({ queryKey: ['suggested-staff', branchId] });
    }, [branchId]);

    return useErrorHandledMutation({ mutationFn, onSuccess });
};
