import CustomAreaChart from './components/Charts/CustomAreaChart';
import CustomBarChart from './components/Charts/CustomBarChart';
import CustomLineChart from './components/Charts/CustomLineChart';

export const TIME_PERIOD_NAMES = {
    day: 'Selected day',
    week: 'Selected week',
    month: 'Selected month',
};

export const CHANGE_PERIOD_NAMES = {
    day: 'Day over day',
    week: 'Week over week',
    month: 'Month over month',
};

export const LEVEL_COLUMN_NAMES = {
    company: 'Region',
    region: 'Community',
};

export type Section =
    | 'DOCUMENTATION_RATE'
    | 'CHANGE_OF_CONDITION'
    | 'FALL_DOCUMENTATION_INCOMPLETE'
    | 'FALLS'
    | 'HOSPITAL_TRANSFER'
    | 'OVERDUE_ASSESSMENT'
    | 'SHOWER'
    | 'UNSCHEDULED_CARE'
    | 'RESIDENT_NOTES'
    | 'MEDS_REFUSED';

export type SchedulingSection =
    | 'BUDGETED_SCHEDULED_HOURS'
    | 'SCHEDULED_OVERTIME_HOURS'
    | 'SCHEDULED_AGENCY_HOURS'
    | 'RECURRING_OPEN_HOURS';

export const schedulingSections = [
    'BUDGETED_SCHEDULED_HOURS',
    'SCHEDULED_OVERTIME_HOURS',
    'SCHEDULED_AGENCY_HOURS',
    'RECURRING_OPEN_HOURS',
];

export type SectionData = {
    title: string;
    chartType: 'line' | 'bar' | 'stackedBar' | 'area';
    ChartComponent: typeof CustomLineChart | typeof CustomBarChart | typeof CustomAreaChart;
};

export type BranchSectionData = SectionData & {
    chartValue: string;
    chartValue2: string;
};

export const OVERALL_SECTIONS_MAP: Record<Section | SchedulingSection, SectionData> = {
    // Scheduling Sections
    // TODO - find a way to get rid of these declarations since scheduling sections don't use this object
    BUDGETED_SCHEDULED_HOURS: {
        title: '',
        chartType: 'line',
        ChartComponent: CustomLineChart,
    },
    SCHEDULED_OVERTIME_HOURS: {
        title: '',
        chartType: 'line',
        ChartComponent: CustomLineChart,
    },
    SCHEDULED_AGENCY_HOURS: {
        title: '',
        chartType: 'line',
        ChartComponent: CustomLineChart,
    },
    RECURRING_OPEN_HOURS: {
        title: '',
        chartType: 'line',
        ChartComponent: CustomLineChart,
    },

    // Care Revinue Sections
    DOCUMENTATION_RATE: {
        title: 'Documentation Rate',
        chartType: 'line',
        ChartComponent: CustomLineChart,
    },
    UNSCHEDULED_CARE: {
        title: 'PRN/Unscheduled Care',
        chartType: 'area',
        ChartComponent: CustomAreaChart,
    },
    RESIDENT_NOTES: {
        title: 'Resident Notes',
        chartType: 'bar',
        ChartComponent: CustomBarChart,
    },
    CHANGE_OF_CONDITION: {
        title: 'Change of Condition',
        chartType: 'stackedBar',
        ChartComponent: CustomBarChart,
    },
    FALLS: {
        title: 'Falls',
        chartType: 'line',
        ChartComponent: CustomLineChart,
    },
    HOSPITAL_TRANSFER: {
        title: 'Medical Transfer',
        chartType: 'line',
        ChartComponent: CustomLineChart,
    },
    FALL_DOCUMENTATION_INCOMPLETE: {
        title: 'Fall Documentation Incomplete',
        chartType: 'line',
        ChartComponent: CustomLineChart,
    },
    OVERDUE_ASSESSMENT: {
        title: 'Overdue Assessment',
        chartType: 'bar',
        ChartComponent: CustomBarChart,
    },
    SHOWER: {
        title: 'Missing Shower',
        chartType: 'line',
        ChartComponent: CustomLineChart,
    },
    MEDS_REFUSED: {
        title: 'Meds Refused',
        chartType: 'bar',
        ChartComponent: CustomBarChart,
    },
};

export const BRANCH_SECTIONS_MAP: Record<Section | SchedulingSection, BranchSectionData> = {
    // Scheduling Sections
    // TODO - find a way to get rid of these declarations since scheduling sections don't use this object
    BUDGETED_SCHEDULED_HOURS: {
        title: '',
        chartType: 'line',
        chartValue: '',
        chartValue2: '',
        ChartComponent: CustomLineChart,
    },
    SCHEDULED_OVERTIME_HOURS: {
        title: '',
        chartType: 'line',
        chartValue: '',
        chartValue2: '',
        ChartComponent: CustomLineChart,
    },
    SCHEDULED_AGENCY_HOURS: {
        title: '',
        chartType: 'line',
        chartValue: '',
        chartValue2: '',
        ChartComponent: CustomLineChart,
    },
    RECURRING_OPEN_HOURS: {
        title: '',
        chartType: 'line',
        chartValue: '',
        chartValue2: '',
        ChartComponent: CustomLineChart,
    },

    // Care Revinue Sections
    DOCUMENTATION_RATE: {
        title: 'Documentation Rate',
        chartType: 'line',
        chartValue: 'tasksCompleteCount',
        chartValue2: 'totalTasksCount',
        ChartComponent: CustomLineChart,
    },
    UNSCHEDULED_CARE: {
        title: 'PRN/Unscheduled Care',
        chartType: 'area',
        chartValue: 'unscheduledCareCount',
        chartValue2: '',
        ChartComponent: CustomAreaChart,
    },
    RESIDENT_NOTES: {
        title: 'Resident Notes',
        chartType: 'bar',
        chartValue: 'residentNoteCount',
        chartValue2: '',
        ChartComponent: CustomBarChart,
    },
    CHANGE_OF_CONDITION: {
        title: 'Change of Condition',
        chartType: 'stackedBar',
        chartValue: 'withAdlChanges',
        chartValue2: 'withoutAdlChanges',
        ChartComponent: CustomBarChart,
    },
    FALLS: {
        title: 'Falls',
        chartType: 'line',
        chartValue: 'fallCount',
        chartValue2: '',
        ChartComponent: CustomLineChart,
    },
    HOSPITAL_TRANSFER: {
        title: 'Medical Transfer',
        chartType: 'line',
        chartValue: 'hospitalVisitCount',
        chartValue2: '',
        ChartComponent: CustomLineChart,
    },
    FALL_DOCUMENTATION_INCOMPLETE: {
        title: 'Fall Documentation Incomplete',
        chartType: 'line',
        chartValue: 'incompleteCount',
        chartValue2: '',
        ChartComponent: CustomLineChart,
    },
    OVERDUE_ASSESSMENT: {
        title: 'Overdue Assessment',
        chartType: 'bar',
        chartValue: 'overdueAssessmentCount',
        chartValue2: '',
        ChartComponent: CustomBarChart,
    },
    SHOWER: {
        title: 'Missing Shower',
        chartType: 'line',
        chartValue: 'residentMissedShowerCount',
        chartValue2: '',
        ChartComponent: CustomLineChart,
    },
    MEDS_REFUSED: {
        title: 'Meds Refused',
        chartType: 'bar',
        chartValue: 'residentMedicationRefusedCount',
        chartValue2: '',
        ChartComponent: CustomBarChart,
    },
};

export type BranchSection = keyof typeof BRANCH_SECTIONS_MAP;

export interface StandardDataset {
    startDate: string;
    value: number;
}
