import { InputAdornment, TextField as TextFieldMUI, useTheme } from '@mui/material';
import React, { ComponentProps } from 'react';
import { PiWarningCircleFill } from 'react-icons/pi';

interface TextFieldProps extends ComponentProps<typeof TextFieldMUI> {
    value?: unknown;
    label?: string;
    placeholder?: string;
    icon?: React.ReactNode;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    InputProps?: ComponentProps<typeof TextFieldMUI>['InputProps'];
}

export const TextField = ({ label, placeholder, icon, onChange, value, helperText, ...props }: TextFieldProps) => {
    const { palette } = useTheme();
    return (
        <TextFieldMUI
            {...props}
            placeholder={placeholder}
            helperText={helperText}
            size="small"
            error={!!helperText}
            onChange={onChange}
            InputLabelProps={{ shrink: false }}
            label={label}
            value={value as string}
            variant="outlined"
            fullWidth
            InputProps={{
                ...props.InputProps,
                startAdornment: icon && <InputAdornment position="start">{icon}</InputAdornment>,
                endAdornment: !!helperText && (
                    <InputAdornment position="end">
                        <PiWarningCircleFill
                            size={16}
                            color={palette.error[500] as string}
                            style={{ marginRight: '12px', marginLeft: '8px' }}
                        />
                    </InputAdornment>
                ),
            }}
        />
    );
};
