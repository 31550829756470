import { atom } from 'jotai';

import { UnscheduledTaskDialogSteps } from '~/pages/Home/components/NewUnscheduledTaskDialog/types/unscheduledTaskDialogSteps';
import { ResidentResponse } from '~/types/residents';

import { PrnTaskCategories } from '../steps/category/types/prnTaskCategories';
import { PrnTaskDetails } from '../steps/taskDetails/types/prnTaskDetails';

export const unscheduledTaskModalOpenAtom = atom(false);

export const unscheduledTaskStepAtom = atom<UnscheduledTaskDialogSteps>(UnscheduledTaskDialogSteps.SELECT_RESIDENT);

export const unscheduledTaskShiftIdAtom = atom<number | null>(null);

export const unscheduledTaskResidentAtom = atom<Pick<
    ResidentResponse,
    'residentId' | 'firstName' | 'lastName' | 'photo' | 'roomNumber'
> | null>(null);

export const unscheduledTaskCategoriesAtom = atom<PrnTaskCategories.Category[]>([]);

export const unscheduledTaskDetailsAtom = atom<PrnTaskDetails.TaskDetails[]>([]);

export const additionalCommentAtom = atom<{ comment: string; categoryId: number }[]>([]);

export const unscheduledTaskIsMissingRequiredCommentAtom = atom(false);
