import { Box } from '@mui/material';
import { useSetAtom } from 'jotai';
import React from 'react';

import { useWindowSize } from '~/scheduling/components/useWindowSize';

import { handledRequestsAtom, skippedRequestsAtom } from '../../atom';
import { ShiftApproval } from '../../types';

import { Card } from './Card';

export const ShiftApprovalCards = ({ shiftsToApprove }: { shiftsToApprove: ShiftApproval.CardProps[] }) => {
    const setHandledRequests = useSetAtom(handledRequestsAtom);
    const skippedRequests = useSetAtom(skippedRequestsAtom);
    const [currentCardIndex, setCurrentCardIndex] = React.useState(0);
    const numberOfCards = shiftsToApprove.length;

    const [width] = useWindowSize();
    const windowWidth = width || window.innerWidth;

    const handleCardResolve = () => {
        if (currentCardIndex !== numberOfCards - 1) {
            setCurrentCardIndex((prev) => prev + 1);
        }

        setHandledRequests((prev) => prev + 1);
    };

    const handleSkipRequest = () => {
        handleCardResolve();
        skippedRequests((prev) => prev + 1);
    };

    return (
        <Box width="100%" height="100%" paddingBlock="16px" overflow="hidden" position="relative">
            <Box
                position="absolute"
                display="grid"
                gridTemplateColumns={`repeat(${numberOfCards}, 1fr)`}
                gap="32px"
                height="calc(100% - 32px)"
                marginInline="16px"
                left={-(currentCardIndex * windowWidth) + 'px'}
                sx={{ transition: 'left 0.5s' }}
            >
                {shiftsToApprove.map((shiftRequest, index) => (
                    <Card key={index} {...shiftRequest} onResolve={handleCardResolve} onSkip={handleSkipRequest} />
                ))}
            </Box>
        </Box>
    );
};
