import { Typography } from '@mui/material';
import React from 'react';

import { pxToRem } from '~/components/theme/typography';

export const PhoneDisclaimer = () => (
    <Typography
        sx={{
            fontSize: {
                xs: pxToRem(12),
            },
            lineHeight: {
                xs: 1.7,
            },
            color: '#6F6F79',
            textAlign: 'left',
            mb: pxToRem(8),
        }}
    >
        <Typography
            sx={{
                fontSize: {
                    xs: pxToRem(12),
                },
                lineHeight: {
                    xs: 1.7,
                },
                display: 'inline',
            }}
        >
            By continuing, you will receive an SMS. Message and data rates may apply.
        </Typography>
    </Typography>
);
