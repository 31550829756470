import { Button, Stack, Typography, styled } from '@mui/material';
import React from 'react';

type HeaderChipProps = {
    title: string;
    label: string;
    isDisabled?: boolean;
    onClick?: () => void;
};

export const ChipContainer = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary[100],
    [theme.breakpoints.up('sm')]: {
        backgroundColor: 'transparent',
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
    },
    height: '32px',
    borderRadius: '100px',
    padding: '0px 8px',
    display: 'inline-flex',
    border: '1px solid',
    borderColor: 'transparent',
    gap: '4px',
    flex: 1,
    flexShrink: 0,
    '&:disabled': {
        backgroundColor: theme.palette.grey[50],
        borderColor: 'transparent',
        color: theme.palette.grey[300],
        [theme.breakpoints.down('sm')]: {
            backgroundColor: 'transparent',
            borderColor: theme.palette.primary[200] as string,
            color: theme.palette.primary[200] as string,
        },
    },
    '&:hover': {
        backgroundColor: theme.palette.primary[200] as string,
        color: theme.palette.primary.main,
        [theme.breakpoints.up('sm')]: {
            backgroundColor: theme.palette.primary[50] as string,
            opacity: 0.8,
        },
    },
    '&:active': {
        backgroundColor: theme.palette.primary[100] as string,
        color: theme.palette.primary.main,
        [theme.breakpoints.up('sm')]: {
            opacity: 0.8,
        },
    },
}));

export const ChipText = styled(Typography)(() => ({
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '130%',
    verticalAlign: 'middle',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    overflow: 'hidden',
}));

export default function HeaderChip({ label, isDisabled, title, onClick }: HeaderChipProps) {
    return (
        <Stack width="100%" direction="row" alignItems="center" gap={'8px'}>
            <Typography
                fontSize="16px"
                fontWeight={500}
                sx={{
                    textWrap: 'nowrap',
                    color: {
                        xs: 'white',
                        sm: 'text.primary',
                    },
                }}
            >
                {title}
            </Typography>
            <ChipContainer disabled={isDisabled} onClick={onClick}>
                <ChipText>{label}</ChipText>
            </ChipContainer>
        </Stack>
    );
}
