import { DateTime } from 'luxon';
import { useCallback } from 'react';

import { api } from '~/api';
import { queryClient, useBranchId, useErrorHandledMutation, useToken } from '~/api/common';
import { ChangeSlotTime } from '~/scheduling/api/types/shift-slot/changeSlotTime';

const toISO = (dateTime: DateTime<true>) => dateTime.toISO({ suppressMilliseconds: true });

export const useChangeSlotTime = () => {
    const branchId = useBranchId()!;
    const token = useToken();

    const mutationFn = useCallback(
        async ({ slotId, data: { startTime, endTime } }: ChangeSlotTime.Params) => {
            const data = { startTime: toISO(startTime), endTime: toISO(endTime) };
            const options = { headers: { Authorization: token } };
            await api.patch(`/scheduling/${branchId}/shift-slot/${slotId}/time`, data, options);
        },
        [branchId, token]
    );

    const onSuccess = useCallback(async () => {
        // Schedule
        await queryClient.invalidateQueries({ queryKey: ['full-schedule', branchId] });

        // Dashboard
        await queryClient.invalidateQueries({ queryKey: ['budgeted-scheduled', branchId] });

        // Reports
        await queryClient.invalidateQueries({ queryKey: ['overtime-staff', branchId] });
        await queryClient.invalidateQueries({ queryKey: ['scheduled-hours', branchId] });
    }, [branchId]);

    return useErrorHandledMutation({ mutationFn, onSuccess });
};
