import { ExpandCircleDown as ExpandCircleDownIcon } from '@mui/icons-material';
import { Box, Divider, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import momentTz from 'moment-timezone';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { DateShift, getPreviousShift, getShiftAtDateTimeUtc } from '@allie/utils/src/shifts';
import { ShiftDay } from '@allie/utils/src/utils/day';

import { useBranchShifts } from '~/api/queries/branch';
import {
    useResidentShiftNotesDeleteMutation,
    useResidentShiftNotesQuery,
    useResidentShiftNotesUpdateMutation,
} from '~/api/queries/shiftNotes/shiftNotes';
import { DESKTOP_DRAWER_WIDTH } from '~/components/Layout/shared';
import DeleteConfirmationDialog from '~/components/Shared/DeleteConfirmationDialog';
import { pxToRem } from '~/components/theme/typography';
import { getDateInUtc } from '~/lib/date';
import ShiftNoteCard from '~/pages/Residents/Details/components/ShiftNoteCard';
import ShiftNoteDialog from '~/pages/Residents/Details/components/ShiftNoteDialog';
import { usePermissions } from '~/permissions/utils';
import { ReduxStore } from '~/types/redux';
import { ResidentShiftNotesResponse } from '~/types/residentShiftNotes';

// Show the last 3 days of shift notes
const SHIFT_NOTES_PREVIOUS_DAYS_COUNT = 3;

const TitleStyle = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(8),
        fontSize: pxToRem(20),
        fontWeight: 700,
    })
) as typeof Typography;

const shiftNoteMinWidthPx = 320;

const SectionStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        width: '100%',
        display: 'grid',
        gridTemplateColumns: {
            xs: '1fr',
            lg: `repeat(auto-fill, minmax(${pxToRem(shiftNoteMinWidthPx)}, 1fr))`,
        },
        columnGap: pxToRem(16),
        transition: 'height 0.15s ease-out',
    })
);

const ExpandCollapseIconContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        position: 'relative',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
    })
);

const ExpandCollapseIcon = styled(ExpandCircleDownIcon)(({ theme }) =>
    theme.unstable_sx({
        p: pxToRem(6),
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
        borderRadius: '50%',
        fontSize: pxToRem(32),
        position: 'absolute',
        top: pxToRem(-32),
        boxShadow: `0 0 ${pxToRem(8)} ${alpha(theme.palette.grey[500], 0.5)}`,
    })
);

const DividerStyle = styled(Divider)(({ theme }) =>
    theme.unstable_sx({
        my: pxToRem(16),
    })
);

type Props = {
    residentId: number;
    branchId: number;
};

const ShiftNotes = ({ residentId, branchId }: Props) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
    const [isShiftNoteDialogOpen, setIsShiftNoteDialogOpen] = useState<boolean>(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
    const [currentShiftNoteId, setCurrentShiftNoteId] = useState<number>(0);
    const [currentReport, setCurrentReport] = useState<string>('');
    const [currentShiftNoteCategoryId, setCurrentShiftNoteCategoryId] = useState<number>(0);

    const {
        sessionData: { userId },
        timezone,
    } = useSelector((state: ReduxStore) => state.session);

    const hasPermission = usePermissions();

    const branchShifts = useBranchShifts(branchId);
    const { shift: currentShift, shiftDayDate: currentShiftDayDate } = getShiftAtDateTimeUtc(
        getDateInUtc(new Date()),
        timezone,
        branchShifts
    );

    // get all the shifts for the last 3 days by stepping through the previous shifts one at a time
    const residentNoteDateShifts = useMemo(() => {
        const startShiftDay = new ShiftDay(currentShiftDayDate);
        const endShiftDay = startShiftDay.subDays(SHIFT_NOTES_PREVIOUS_DAYS_COUNT);
        let currentShiftDay = startShiftDay;
        let currentShiftId = currentShift.id;

        const dayShiftCombos: DateShift[] = [];

        while (!(currentShiftDay.equals(endShiftDay) && currentShiftId === currentShift.id)) {
            dayShiftCombos.push({ shiftId: currentShiftId as number, shiftDayDate: currentShiftDay.toString() });
            const { shiftOption, shiftDayDate } = getPreviousShift(
                currentShiftId,
                branchShifts,
                currentShiftDay.toString()
            );

            currentShiftId = shiftOption.id;
            currentShiftDay = new ShiftDay(shiftDayDate!);
        }

        return dayShiftCombos;
    }, [currentShift.id, currentShiftDayDate]);

    const { data: residentShiftNotes } = useResidentShiftNotesQuery({
        residentId,
        branchId,
        dateShifts: residentNoteDateShifts,
    });

    const { mutate: residentShiftNoteUpdate } = useResidentShiftNotesUpdateMutation(userId);
    const { mutateAsync: residentShiftNoteDelete } = useResidentShiftNotesDeleteMutation(userId);

    const getShiftNoteData = (shiftNote: ResidentShiftNotesResponse) => {
        const {
            residentShiftNoteId: key,
            caregiver,
            shiftNoteCategoryId,
            shiftNoteCategory,
            report,
            userId: caregiverId,
            reportedAtUtc,
        } = shiftNote;
        const elapsedTimeLabel = momentTz.utc(reportedAtUtc).fromNow();

        return {
            caregiver,
            caregiverId,
            categoryId: shiftNoteCategoryId,
            category: shiftNoteCategory,
            elapsedTimeLabel,
            key,
            report,
        };
    };

    const handleCollapsibleSectionToggle = () => {
        setIsCollapsed(!isCollapsed);
    };

    const handleDialogClose = () => {
        setIsShiftNoteDialogOpen(false);
        setIsDeleteDialogOpen(false);
    };

    const handleEdit = (shiftNoteId: number, categoryId: number, reportContent: string) => () => {
        if (!hasPermission('Community', 'update-resident-action')) return;

        setCurrentShiftNoteId(shiftNoteId);
        setCurrentShiftNoteCategoryId(categoryId);
        setCurrentReport(reportContent);
        setIsShiftNoteDialogOpen(true);
    };

    const handleShiftNoteSubmit = async (report: string, newShiftNoteCategory: number) => {
        residentShiftNoteUpdate({
            id: currentShiftNoteId,
            residentId,
            branchId: branchId!,
            userId,
            shiftNoteCategoryId: newShiftNoteCategory,
            report,
        });
    };

    const handleDelete = (shiftNoteId: number) => () => {
        if (!hasPermission('Community', 'update-resident-action')) return;

        setCurrentShiftNoteId(shiftNoteId);
        setIsDeleteDialogOpen(true);
    };

    const handleShiftNoteDelete = async () => {
        await residentShiftNoteDelete({ id: currentShiftNoteId, residentId, branchId: branchId! });
        setIsDeleteDialogOpen(false);
    };

    if (!residentShiftNotes?.length) return null;

    const bodyWidth = window.innerWidth - DESKTOP_DRAWER_WIDTH; // TODO: Use ref to get the width
    const shiftNotesToShowCount = isCollapsed
        ? Math.max(1, Math.floor(bodyWidth / shiftNoteMinWidthPx)) // Show as many shift notes as possible in the preview
        : residentShiftNotes.length;
    const shiftNotesToShow = residentShiftNotes.slice(0, shiftNotesToShowCount);

    return (
        <>
            <TitleStyle>Shift Notes</TitleStyle>
            <SectionStyle>
                {shiftNotesToShow.map((shiftNote) => {
                    const { caregiver, caregiverId, category, categoryId, elapsedTimeLabel, key, report } =
                        getShiftNoteData(shiftNote);

                    return (
                        <ShiftNoteCard
                            key={key}
                            caregiver={caregiver}
                            caregiverId={caregiverId}
                            category={category}
                            elapsedTimeLabel={elapsedTimeLabel}
                            report={report}
                            onEdit={handleEdit(key, categoryId, report)}
                            onDelete={handleDelete(key)}
                        />
                    );
                })}
            </SectionStyle>
            {residentShiftNotes.length > 1 && (
                <ExpandCollapseIconContainer>
                    <ExpandCollapseIcon
                        onClick={handleCollapsibleSectionToggle}
                        sx={{ transform: isCollapsed ? undefined : 'rotate(180deg)' }}
                    />
                </ExpandCollapseIconContainer>
            )}
            <DividerStyle />
            <ShiftNoteDialog
                isOpen={isShiftNoteDialogOpen}
                reportContent={currentReport}
                shiftNoteCategoryId={currentShiftNoteCategoryId.toString()}
                onClose={handleDialogClose}
                onSubmit={handleShiftNoteSubmit}
            />
            <DeleteConfirmationDialog
                isOpen={isDeleteDialogOpen}
                onClose={handleDialogClose}
                onDelete={handleShiftNoteDelete}
            />
        </>
    );
};

export default ShiftNotes;
