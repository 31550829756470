import { Icon, Person, User, Users } from '@phosphor-icons/react';

import { PrnTaskDetails } from '../types/prnTaskDetails';

export default function withIcon(assistLevel: PrnTaskDetails.AssistLevel): PrnTaskDetails.AssistLevel & { icon: Icon } {
    const iconMap: Record<string, Icon> = {
        '1 Person': User,
        '2 Person': Users,
        'Stand By': Person,
    };

    return {
        ...assistLevel,
        icon: iconMap[assistLevel.label] || Person,
    };
}
