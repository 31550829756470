import { usePostHog } from 'posthog-js/react';
import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { useCompanyCarePlansListQuery } from '~/api/queries/companyCarePlans';
import { useResidentQuery } from '~/api/queries/residents';
import { useCreateDailyTask } from '~/api/queries/tasks/dailyTasks';
import { useUserFraudStatusQuery } from '~/api/queries/user';
import { CustomMobileDialog } from '~/components/Custom/CustomMobileDialog';
import { showToast } from '~/components/Shared/Alerting/Toast/utils/showToast';
import Loading from '~/components/Shared/Loading';
import { CompanyCarePlanResponse } from '~/types/companyCarePlans';
import { DailyTasksCreateParams } from '~/types/dailyTasks';
import { ReduxStore } from '~/types/redux';

import NurseCallsCheckbox from './NurseCallsCheckbox';
import NurseCallsCheckboxPrompt from './NurseCallsCheckboxPrompt';
import UnscheduledTasksConfirmation from './UnscheduledTasksConfirmation';

type Props = {
    branchId: number;
    isOpen: boolean;
    residentId: number;
    caregiverId: number;
    date: string;
    shift: number;
    onClose: () => void;
};

const NURSE_CALLS_DIALOG_STEP = {
    PROMPT: 'PROMPT',
    CHECKBOX: 'CHECKBOX',
    CONFIRMATION: 'CONFIRMATION',
};

const NurseCallsDialog = (props: Props) => {
    const { branchId, isOpen, onClose, residentId, date, shift, caregiverId } = props;

    const posthog = usePostHog();

    const createTaskMutation = useCreateDailyTask();

    const [currentStep, setCurrentStep] = useState(NURSE_CALLS_DIALOG_STEP.PROMPT);

    const [selectedValues, setSelectedValues] = useState<number[]>([]);

    const [isCreateLoading, setIsCreateLoading] = useState(false);

    const {
        data: residentData,
        isLoading: residentDataIsLoading,
        isError: residentDataIsError,
    } = useResidentQuery(residentId, branchId);

    const { data: userFraud } = useUserFraudStatusQuery(branchId, shift);

    const { data: carePlanList } = useCompanyCarePlansListQuery(branchId);

    const carePlanById: Record<number, CompanyCarePlanResponse> = useMemo(
        () => Object.fromEntries(carePlanList?.map((carePlan) => [carePlan.companyCarePlanId, carePlan]) ?? []),
        [carePlanList]
    );

    // A selection is 'suspicious' if the user has selected:
    // a) 7 or more tasks
    // b) more than one assist level from the same key service
    const isSelectionSuspicious = useMemo(() => {
        if (selectedValues.length >= 7) return true; // a)

        const selectedAssistLevelsByKeyService: Record<string, Set<number>> = {};

        // eslint-disable-next-line no-restricted-syntax
        for (const value of selectedValues) {
            const carePlan = carePlanById[value];
            const keyService = carePlan.keyService!;

            const keyServiceAssistLevels = selectedAssistLevelsByKeyService[keyService] ?? new Set();
            keyServiceAssistLevels.add(carePlan.assistLevel!);

            if (keyServiceAssistLevels.size > 1) return true; // b)

            selectedAssistLevelsByKeyService[keyService] = keyServiceAssistLevels;
        }

        return false;
    }, [selectedValues, carePlanById]);

    if (residentDataIsLoading) {
        return <Loading />;
    }

    if (residentDataIsError || !residentData) {
        return null;
    }

    const handleNurseCallsSubmit = async () => {
        if (selectedValues.length === 0) {
            showToast({
                message: 'You need to select at least one task.',
                type: 'error',
            });
            return;
        }

        setIsCreateLoading(true);

        await Promise.all(
            selectedValues.map(async (companyCarePlanId) => {
                const params: DailyTasksCreateParams = {
                    branchId,
                    residentId,
                    companyCarePlanId,
                    date,
                    shiftId: shift,
                };

                await createTaskMutation.mutateAsync(params);
            })
        );

        setIsCreateLoading(false);

        onClose();
    };

    const shouldShowConfirmationDialog = isSelectionSuspicious || (userFraud && userFraud.status !== null);

    const residentFirstName = residentData?.firstName;
    const residentName = `${residentFirstName} ${residentData?.lastName}`;

    const titleByStep = {
        [NURSE_CALLS_DIALOG_STEP.PROMPT]: `Great job completing all ${residentName}'s tasks!`,
        [NURSE_CALLS_DIALOG_STEP.CHECKBOX]: `Select ${residentName}'s call light requests this shift`,
    };

    const contentByStep = {
        [NURSE_CALLS_DIALOG_STEP.PROMPT]: (
            <NurseCallsCheckboxPrompt
                residentName={residentName}
                onAccept={() => setCurrentStep(NURSE_CALLS_DIALOG_STEP.CHECKBOX)}
            />
        ),
        [NURSE_CALLS_DIALOG_STEP.CHECKBOX]: (
            <NurseCallsCheckbox
                branchId={branchId}
                onClose={onClose}
                selectedValues={selectedValues}
                onSubmit={
                    shouldShowConfirmationDialog
                        ? () => setCurrentStep(NURSE_CALLS_DIALOG_STEP.CONFIRMATION)
                        : handleNurseCallsSubmit
                }
                changeSelectedValues={setSelectedValues}
            />
        ),
        [NURSE_CALLS_DIALOG_STEP.CONFIRMATION]: (
            <UnscheduledTasksConfirmation
                loading={isCreateLoading}
                onCancel={() => {
                    posthog.capture('nurse_calls_confirmation:cancel_button_click', {
                        user_id: caregiverId,
                        care_plan_ids: selectedValues,
                        care_plan_count: selectedValues.length,
                    });

                    onClose();
                }}
                onConfirm={() => {
                    posthog.capture('nurse_calls_confirmation:confirm_button_click', {
                        user_id: caregiverId,
                        care_plan_ids: selectedValues,
                        care_plan_count: selectedValues.length,
                    });

                    handleNurseCallsSubmit();
                }}
                residentFirstName={residentFirstName}
                completedTasksNumber={selectedValues.length}
            />
        ),
    };

    return (
        <CustomMobileDialog
            closeable={currentStep !== NURSE_CALLS_DIALOG_STEP.CONFIRMATION}
            open={isOpen}
            title={titleByStep[currentStep]}
            onClose={onClose}
            width="100%"
            content={contentByStep[currentStep]}
        />
    );
};

const mapStateToProps = ({ session }: ReduxStore) => {
    const {
        sessionData: { branchId, userId: caregiverId },
    } = session;

    return { branchId, caregiverId };
};

const mapDispatchToProps = () => ({});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedNurseCallsDialog: any = connect(mapStateToProps, mapDispatchToProps)(NurseCallsDialog);

export default ConnectedNurseCallsDialog;
