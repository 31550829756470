import { api } from '~/api';
import { formatApiParams } from '~/lib/common';
import actionTypes from '~/redux/actionTypes';
import loading from '~/redux/actions/loading';
import { throwError } from '~/redux/actions/messages';
import '~/redux/reducers/zones';
import { ZoneResponse, ZonesListReadParams } from '~/types/zones';

/**
 * Fetches the list of Zones based on a Company Branch.
 * @param jsonParams the JSON object with the params for fetching the Zones list.
 */
export const readZones =
    (jsonParams: ZonesListReadParams, actionType = actionTypes.ZONE_READ_LIST) =>
    async (dispatch, getState) => {
        try {
            // Identify if the current alert message (if any displayed) should be hidden.
            const shouldHideAlert = actionType === actionTypes.ZONE_READ_LIST;

            // Enable the loading process.
            dispatch(loading(actionTypes.ZONE_LOADING, true, shouldHideAlert));

            // Get the token.
            const { token } = getState().session.sessionData;

            // Encode the params to be sent to the API.
            const params = formatApiParams(jsonParams);

            // Fetch the Zones List.
            const zonesList: ZoneResponse[] = (
                await api.get('/zones', {
                    headers: {
                        authorization: token,
                    },
                    params,
                })
            ).data.response;

            dispatch({
                type: actionType,
                payload: zonesList,
            });
        } catch (error) {
            dispatch(throwError(error));
        } finally {
            // Disable the loading process.
            dispatch(loading(actionTypes.ZONE_LOADING, false));
        }
    };
