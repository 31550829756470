import { Box, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';

import { StaffOptimizations } from '../../types';
import { useSlotOnHover } from '../../useSlotOnHover';

const gradientStepsForOverBudget = ['#EC3232', '#891D1D', '#F7A7A7', '#F05959', '#EC3232'];

const gradientStepsForUnderBudget = ['#91BFC4', '#004249', '#C2DBDE', '#91BFC4', '#004249', '#C2DBDE', '#91BFC4'];

export const HighlightedBox = (props: {
    shiftId: number;
    locationId: number;
    day: DateTime;
    action?: StaffOptimizations.RecommendationActionType;
    children?: React.ReactNode;
}) => {
    const { palette } = useTheme();
    const { getBorderStyle } = useSlotOnHover();

    const borderStyle = getBorderStyle({
        day: props.day,
        shiftId: props.shiftId,
        locationId: props.locationId,
    });

    const gradientColors = props.action === 'increase' ? gradientStepsForUnderBudget : gradientStepsForOverBudget;

    return (
        <Box
            height="100%"
            overflow="hidden"
            sx={{
                borderStyle: 'solid',
                borderWidth: '0px',
                borderColor: 'transparent',
                ...(borderStyle
                    ? {
                          background: `conic-gradient(from 0deg, ${
                              borderStyle.reversedGradient
                                  ? [...gradientColors].reverse().join(', ')
                                  : gradientColors.join(', ')
                          })`,
                          backgroundOrigin: 'border-box',
                          borderRadius: borderStyle.borderRadius,
                          borderWidth: borderStyle.borderWidth,
                      }
                    : {}),
            }}
        >
            <Box
                display="flex"
                height="100%"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                gap="4px"
                minHeight="100px"
                sx={{
                    backgroundColor: (props.action === 'increase'
                        ? palette.primary[50]
                        : props.action === 'decrease'
                          ? palette.error[50]
                          : palette.grey[25]) as string,
                    '&:hover': {
                        backgroundColor: (props.action === 'increase'
                            ? palette.primary[100]
                            : props.action === 'decrease'
                              ? palette.error[100]
                              : palette.grey[100]) as string,
                        '& > p': {
                            color: !props.action ? `${palette.grey[600]} !important` : undefined,
                        },
                        '& > div > div': {
                            backgroundColor: palette.grey[400],
                        },
                    },
                    '&:active': {
                        backgroundColor: (props.action === 'increase'
                            ? palette.primary[200]
                            : props.action === 'decrease'
                              ? palette.error[200]
                              : palette.grey[100]) as string,
                    },
                }}
            >
                {props.children}
            </Box>
        </Box>
    );
};
