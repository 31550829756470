import { atom } from 'jotai';
import { DateTime } from 'luxon';

import { Scheduling } from '../../types';
import { atomWithToggle } from '../../utils/atoms';

export const isPublishNewScheduleModalOpenAtom = atomWithToggle(false);
export const isStaffVacationModalOpenAtom = atomWithToggle(false);
export const isPrintScheduleModalOpenAtom = atomWithToggle(false);

const today = DateTime.now();
const nextWeek = today.plus({ week: 1 });

export const startDate: Scheduling.CalendarData = {
    day: today.day,
    month: today.month - 1,
    year: today.year,
};
export const endDate: Scheduling.CalendarData = {
    day: nextWeek.day,
    month: nextWeek.month - 1,
    year: nextWeek.year,
};

export const publishNextScheduleModalDateRange = atom<Scheduling.DateRange>({ startDate, endDate });
export const vacationModalDateRange = atom<Scheduling.DateRange>({ startDate, endDate });
export const printScheduleDateRange = atom<Scheduling.DateRange>({ startDate, endDate });

export const selectedRequestsAtom = atom<number[]>([]);
export const isReviewOpenShiftsModalOpenAtom = atomWithToggle(false);
