import React from 'react';

import Base from '~/components/Svg/Base';

type Props = {
    className?: string;
    size?: number;
};

// eslint-disable-next-line import/prefer-default-export
export const LikeIcon = ({ className, size }: Props) => (
    <Base className={className} size={size}>
        <path
            d="M8.39014 18.4907V8.33071C8.39014 7.93071 8.51014 7.54071 8.73014 7.21071L11.4601 3.15071C11.8901 2.50071 12.9601 2.04071 13.8701 2.38071C14.8501 2.71071 15.5001 3.81071 15.2901 4.79071L14.7701 8.06071C14.7301 8.36071 14.8101 8.63071 14.9801 8.84071C15.1501 9.03071 15.4001 9.15071 15.6701 9.15071H19.7801C20.5701 9.15071 21.2501 9.47071 21.6501 10.0307C22.0301 10.5707 22.1001 11.2707 21.8501 11.9807L19.3901 19.4707C19.0801 20.7107 17.7301 21.7207 16.3901 21.7207H12.4901C11.8201 21.7207 10.8801 21.4907 10.4501 21.0607L9.17014 20.0707C8.68014 19.7007 8.39014 19.1107 8.39014 18.4907Z"
            fill="#FA9C7A"
        />
        <path
            d="M5.21 6.37988H4.18C2.63 6.37988 2 6.97988 2 8.45988V18.5199C2 19.9999 2.63 20.5999 4.18 20.5999H5.21C6.76 20.5999 7.39 19.9999 7.39 18.5199V8.45988C7.39 6.97988 6.76 6.37988 5.21 6.37988Z"
            fill="#FA9C7A"
        />
    </Base>
);
