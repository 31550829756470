export const MOBILE_SELECTOR_STYLES = {
    '& .MuiOutlinedInput-root': {
        color: { xs: '#fff' },
        bgcolor: { xs: 'transparent' },
        '&.Mui-focused fieldset': {
            borderColor: { xs: '#DEDEE0' },
        },
        '& fieldset': {
            borderColor: { xs: '#DEDEE0 !important' },
        },
        '&:hover fieldset': {
            borderColor: { xs: '#DEDEE0' },
        },
    },
    '& .MuiSelect-icon.Mui-disabled': {
        opacity: 0,
    },
    '& label, & .MuiSelect-icon': {
        color: { xs: '#DEDEE0 !important' },
        '&.Mui-focused': {
            color: { xs: '#DEDEE0' },
        },
    },
    '& .MuiOutlinedInput-input': {
        '&.Mui-disabled': {
            textFillColor: '#DEDEE0',
        },
    },
};

export const SELECTOR_STYLES = {
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
        opacity: 0.5,
    },
};
