import { useCallback } from 'react';

import { api } from '~/api';
import { queryClient, useBranchId, useErrorHandledMutation, useToken } from '~/api/common';
import { SwapSlotStaff } from '~/scheduling/api/types/shift-slot/swapSlotStaff';

export const useSwapSlotStaff = () => {
    const branchId = useBranchId()!;
    const token = useToken();

    const mutationFn = useCallback(
        async ({ slotId1, slotId2 }: SwapSlotStaff.Params) => {
            const options = { headers: { Authorization: token } };
            await api.post(
                `/scheduling/${branchId}/shift-slot/${slotId1}/swap-staff-with/${slotId2}`,
                undefined,
                options
            );
        },
        [branchId, token]
    );

    const onSuccess = useCallback(async () => {
        // Schedule
        await queryClient.invalidateQueries({ queryKey: ['full-schedule', branchId] });
        await queryClient.invalidateQueries({ queryKey: ['suggested-staff', branchId] });

        // Dashboard
        await queryClient.invalidateQueries({ queryKey: ['dashboard-alerts', branchId] });
        await queryClient.invalidateQueries({ queryKey: ['budgeted-scheduled', branchId] });

        // Reports
        await queryClient.invalidateQueries({ queryKey: ['overtime-staff', branchId] });
        await queryClient.invalidateQueries({ queryKey: ['scheduled-hours', branchId] });
    }, [branchId]);

    return useErrorHandledMutation({ mutationFn, onSuccess });
};
