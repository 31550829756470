import { Box, Typography } from '@mui/material';
import { format, parse } from 'date-fns';
import React from 'react';

import { pxToRem } from '~/components/theme/typography';

import { AvatarWithName } from './AvatarWithName';
import { DateAndTime } from './DateAndTime';

type Props = {
    row: Fall;
};

const getFormattedDate = (date: string) => {
    const parsedDate = parse(date, 'yyyy-MM-dd HH:mm:ss', new Date());
    const formattedDate = format(parsedDate, 'MM/dd/yy h:mm a');
    return formattedDate;
};

export const FallRowContent = ({ row }: Props) => {
    const { caregiver, datetime, fallType, isHeader } = row;

    return (
        <Box
            key={datetime.toString()}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={pxToRem(4)}
        >
            {isHeader ? (
                <>
                    <Typography
                        fontSize={{
                            xs: pxToRem(10),
                            md: pxToRem(11),
                        }}
                        fontWeight={700}
                        lineHeight={1.3}
                        color="#6F6F79"
                    >
                        {fallType}
                    </Typography>
                    <Typography
                        fontSize={{
                            xs: pxToRem(10),
                            md: pxToRem(11),
                        }}
                        fontWeight={700}
                        lineHeight={1.3}
                        color="#6F6F79"
                    >
                        {datetime}
                    </Typography>
                </>
            ) : (
                <>
                    <Box display="flex" alignItems="baseline" gap={pxToRem(4)} flexWrap="wrap">
                        {!!caregiver && <AvatarWithName caregiver={caregiver} />}
                        <Typography
                            fontSize={{
                                xs: pxToRem(10),
                                md: pxToRem(11),
                            }}
                            lineHeight={1.3}
                            color="#6F6F79"
                        >
                            {caregiver ? `(${fallType})` : fallType}
                        </Typography>
                    </Box>
                    <DateAndTime timeStamp={getFormattedDate(datetime)} />
                </>
            )}
        </Box>
    );
};
