import { Button, styled } from '@mui/material';
import { Box, Drawer, Stack, Typography } from '@mui/material';
import React from 'react';

import { SHIFT_SLOT_STAFF_NOTIFICATION_STATUS } from '@allie/utils/src/constants/scheduling/shift-slot.constants';

import { useGetMyNotifications } from '~/scheduling/api/queries/shift-slot/getMyNotifications';
import { useUpdateSlotStaffNotification } from '~/scheduling/api/queries/shift-slot/updateSlotStaffNotification';
import { HorizontalSeparator } from '~/scheduling/components/shared';

import GlowingBox from './GlowingBox';
import SlotItemDetails from './SlotItem/SlotItemDetails';

const SlotDetailsBox = styled(Box)(({ theme: { palette } }) => ({
    backgroundColor: 'white',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
    padding: '10px 14px',
    borderRadius: '7px',
    border: `1px solid ${palette.grey[100]}`,
    userSelect: 'none',
    cursor: 'pointer',
    '&:hover': { backgroundColor: palette.grey[25] },
    '&:active': { backgroundColor: palette.grey[50] },
}));

const NotificationsDrawer = () => {
    const { data: myNotifications } = useGetMyNotifications();
    const { mutate: updateNotification, isPending } = useUpdateSlotStaffNotification();

    const notification = myNotifications?.[0]; // TODO: Batch processing

    if (!notification) return null;

    const { id: notificationId, slot, createdBy } = notification;

    const handleUpdate = (status: SHIFT_SLOT_STAFF_NOTIFICATION_STATUS) =>
        updateNotification({ slotId: slot.id, notificationId, data: { status } });

    return (
        <Drawer anchor="bottom" open>
            <Stack bgcolor="white">
                <Box p="20px 24px">
                    <Typography fontSize="16px" fontWeight={700}>
                        Shift Requested
                    </Typography>
                </Box>
                <HorizontalSeparator />
                <Stack p="20px 24px" spacing="8px">
                    <Typography fontSize="16px" fontWeight={600}>
                        {createdBy} <span style={{ fontWeight: 400 }}>asked you to work a shift:</span>
                    </Typography>
                    <GlowingBox
                        position="relative"
                        overflow="hidden"
                        p="2px" // Border width
                        borderRadius="8px"
                        zIndex={0}
                    >
                        <SlotDetailsBox>
                            <SlotItemDetails slot={slot} />
                        </SlotDetailsBox>
                    </GlowingBox>
                </Stack>
                <HorizontalSeparator />
                <Stack direction="row" p="20px 24px" spacing="8px">
                    <Button
                        size="large"
                        color="error"
                        onClick={() => handleUpdate(SHIFT_SLOT_STAFF_NOTIFICATION_STATUS.DECLINED)}
                        disabled={isPending}
                        fullWidth
                    >
                        Decline
                    </Button>
                    <Button
                        size="large"
                        disabled={isPending}
                        onClick={() => handleUpdate(SHIFT_SLOT_STAFF_NOTIFICATION_STATUS.ACCEPTED)}
                        fullWidth
                    >
                        Accept
                    </Button>
                </Stack>
            </Stack>
        </Drawer>
    );
};

export default NotificationsDrawer;
