import moment from 'moment';

import { DailyTasksShiftDetail, ResidentTasks } from '~/types/dailyTasks';

export const ALL_ZONES_ID = -1;
export const ALL_ASSIGNMENTS_ID = -1;
export const NO_RESIDENT_SELECTED_ID = -1;

interface FilterTasksByNameParams {
    assignmentId?: number;
    zoneId: number;
    tasks: DailyTasksShiftDetail[];
    searchValue: string;
    filterBy: 'zoneId' | 'assignmentId';
}

export function filterTasksByName(params: FilterTasksByNameParams): DailyTasksShiftDetail[] {
    const { searchValue, zoneId, tasks, filterBy, assignmentId } = params;

    if (!searchValue) return tasks;

    const searchInLowerCase = searchValue.toLowerCase();

    return tasks.filter((task) => {
        const residentFullName = `${task.resident.firstName} ${task.resident.lastName}`.toLowerCase();

        const taskIncludesNameOfResident =
            task.resident.firstName.toLowerCase().includes(searchInLowerCase) ||
            task.resident.lastName.toLowerCase().includes(searchInLowerCase) ||
            residentFullName.includes(searchInLowerCase);

        const taskIncludesRoomOfResident = task.resident.roomNumber?.toLowerCase().includes(searchInLowerCase);

        const taskIncludedInZone = task.zoneId === zoneId;

        const taskIncludesAssignment = assignmentId === task.assignmentId;

        if (filterBy === 'zoneId' && zoneId !== ALL_ZONES_ID) {
            return (taskIncludesNameOfResident || taskIncludesRoomOfResident) && taskIncludedInZone;
        }

        if (filterBy === 'assignmentId' && assignmentId !== ALL_ASSIGNMENTS_ID) {
            return (taskIncludesNameOfResident || taskIncludesRoomOfResident) && taskIncludesAssignment;
        }

        return taskIncludesNameOfResident || taskIncludesRoomOfResident;
    });
}

export function groupTasksByResidentId(tasks: DailyTasksShiftDetail[]): Record<number, ResidentTasks> {
    const residentsTasks: Record<number, ResidentTasks> = {};

    tasks.forEach((task) => {
        if (!residentsTasks[task.resident.id]) {
            residentsTasks[task.resident.id] = {
                ...task.resident,
                tasks: [],
            };
        }

        residentsTasks[task.resident.id].tasks.push(task);
    });

    return residentsTasks;
}

// Using strings to not break local/demo builds with mismatched ids
const companiesWithAssignments = ['Solera', 'Sunny Hills', 'Sodalis Senior Living', 'App Store Sandbox'];
const branchesWithAssignments = [
    // Dev
    'AllieHealth',

    // Solera
    'Lake Austin',
    'Evanston',
    'Clearwater',
    // 'Cherry Creek',
    // 'Edgeworth', // https://alliehealthworkspace.slack.com/archives/C073HGB98UD/p1720524571455549
    // 'Kensington', // https://alliehealthworkspace.slack.com/archives/C073HGB98UD/p1720466514765959
    // 'Lumina', // https://alliehealthworkspace.slack.com/archives/C073HGB98UD/p1721412180173189
    'Hunters Woods',

    // Sunny Hills
    // 'Grossmont',

    // Sodalis
    'Sodalis - Tallahassee', // https://alliehealthworkspace.slack.com/archives/C07L3KHT7T8/p1731609269311729
    'Sodalis - Largo', // https://alliehealthworkspace.slack.com/archives/C07L3KHT7T8/p1731609269311729
];

// TODO: Move to a community settings structure
export const shouldUseAssignments = (company: string, branch: string) =>
    // Testing both company and branch names to avoid false positives
    companiesWithAssignments.some((c) => company.includes(c)) &&
    branchesWithAssignments.some((b) => branch.includes(b));

export const taskRecordSorter = (a: DailyTasksShiftDetail, b: DailyTasksShiftDetail) => {
    const aDateTime = moment(`${a.taskDate}T${a.taskTime}:00`, 'YYYY-MM-DDTHH:mm:ss');
    const bDateTime = moment(`${b.taskDate}T${b.taskTime}:00`, 'YYYY-MM-DDTHH:mm:ss');
    if (aDateTime.isBefore(bDateTime)) {
        return -1;
    }

    if (aDateTime.isAfter(bDateTime)) {
        return 1;
    }

    return 0;
};
