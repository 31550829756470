import { Stack } from '@mui/material';
import { Box, Button } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { CustomMobileDialog, CustomRadioGroupOptions } from '~/components/Custom';
import TranscriptionTextField from '~/components/Shared/TranscriptionTextField';
import { pxToRem } from '~/components/theme/typography';
import { EDIT_TASK_DIALOG_ANALYTICS, REJECT_TASK_DIALOG_ANALYTICS } from '~/constants/analytics';
import { ONBOARDING } from '~/constants/onboarding';
import { DialogType } from '~/constants/shared';
import { ReduxStore } from '~/types/redux';
import { ResidentDailyTasksResponse } from '~/types/residents';

type Props = {
    onboardingId?: string;
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (selectedValue: number, notes: string) => void;
    dialogType: DialogType;
    selectedRecord?: ResidentDailyTasksResponse;
    taskNotes?: string;
};

const EditTaskStatusDialog = (props: Props) => {
    const { onboardingId, isOpen, onClose, onSubmit, dialogType, selectedRecord, taskNotes } = props;

    const { taskStatusesList } = useSelector((state: ReduxStore) => state.taskStatuses);

    const [selectedValue, setSelectedValue] = useState<number | null>(null);
    const [notes, setNotes] = useState<string>('');
    const [showActivateMicConfirmation, setShowActivateMicConfirmation] = useState(false);

    useEffect(() => {
        if (!isOpen) return;

        if (isReject) {
            setNotes(taskNotes || '');
            setSelectedValue(null);
            return;
        }

        if (selectedRecord) {
            setNotes(taskNotes || selectedRecord.caregiverNotes);
            setSelectedValue(selectedRecord.taskStatusId);
        }
    }, [isOpen]);

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const taskStatusId = +event.target.value;
        setSelectedValue(Number.isNaN(taskStatusId) ? null : taskStatusId);
    };

    const handleNoteChange = (newNote: string) => setNotes(newNote);

    const handleClose = () => {
        setShowActivateMicConfirmation(false);
        onClose();
        setSelectedValue(null);
        setNotes('');
    };

    const handleSubmit = () => {
        if (selectedValue) {
            onSubmit(selectedValue, notes);
            handleClose();
        }
    };

    const isReject = dialogType === DialogType.Reject;

    // ASCENT HACK: hard code the reject task statuses
    const editTaskStatusStatuses = taskStatusesList
        .filter(
            // exclude complete and undocumented statuses when rejecting
            // and exclude unknown and overdue statuses always
            (taskStatus) =>
                !(
                    (isReject && (taskStatus.id === 2 || taskStatus.id === 1)) ||
                    taskStatus.id === 3 ||
                    taskStatus.id === 8
                )
        )
        .map((taskStatus) => (taskStatus.id === 1 ? { ...taskStatus, name: 'Undocumented' } : taskStatus));

    const dialogTitle = isReject ? 'Choose a Reason' : 'Edit Task Status';

    const TASK_STATUS = {
        NOT_APPLICABLE: 7,
    };

    return (
        <CustomMobileDialog
            closeable
            open={isOpen}
            title={showActivateMicConfirmation ? '' : dialogTitle}
            width="100%"
            onClose={handleClose}
            content={
                <Stack spacing={pxToRem(16)}>
                    {!showActivateMicConfirmation && (
                        <RadioGroup
                            id={onboardingId}
                            name={isReject ? 'choose-reject-reason' : 'choose-status'}
                            defaultValue={isReject ? '' : selectedRecord?.taskStatusId}
                            onChange={handleRadioChange}
                            value={selectedValue}
                            sx={{
                                display: 'grid',
                                gap: pxToRem(12),
                                m: 0,
                            }}
                        >
                            {editTaskStatusStatuses.map(({ id, name }) => (
                                <CustomRadioGroupOptions
                                    key={id}
                                    value={id.toString()}
                                    label={name}
                                    selectedValue={(selectedValue ?? '').toString()}
                                />
                            ))}
                        </RadioGroup>
                    )}
                    {selectedValue && (
                        <Box mt={pxToRem(4)}>
                            <TranscriptionTextField
                                onboardingId={ONBOARDING.REFUSE_TASK_COMMENT.TARGET}
                                placeholder="Add a comment"
                                showActivateMicConfirmation={showActivateMicConfirmation}
                                text={notes}
                                analyticsIdText={
                                    isReject
                                        ? REJECT_TASK_DIALOG_ANALYTICS.TRANSCRIPTION_BUTTON
                                        : EDIT_TASK_DIALOG_ANALYTICS.TRANSCRIPTION_BUTTON
                                }
                                onChange={handleNoteChange}
                                toggleShowActivateMicConfirmation={(show: boolean) =>
                                    setShowActivateMicConfirmation(show)
                                }
                                isRequired={selectedValue === TASK_STATUS.NOT_APPLICABLE}
                            />
                        </Box>
                    )}
                    {!showActivateMicConfirmation && (
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: '40% 1fr',
                                gap: pxToRem(12),
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={handleClose}
                                sx={{
                                    width: '100%',
                                    p: pxToRem(12),
                                    fontSize: pxToRem(14),
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                id={ONBOARDING.REFUSE_TASK_COMMENT_SUBMIT.TARGET}
                                color="primary"
                                onClick={handleSubmit}
                                disabled={!selectedValue || (selectedValue === TASK_STATUS.NOT_APPLICABLE && !notes)}
                                sx={{
                                    width: '100%',
                                    p: pxToRem(12),
                                    fontSize: pxToRem(14),
                                    '&:disabled': {
                                        bgcolor: '#C7C8CB',
                                    },
                                }}
                            >
                                Submit
                            </Button>
                        </Box>
                    )}
                </Stack>
            }
        />
    );
};

export default EditTaskStatusDialog;
