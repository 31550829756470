import { Box, Menu, MenuItem, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { IoIosArrowDown } from 'react-icons/io';

import { pxToRem } from '~/components/theme/typography';

const formatOption = (date: string) => {
    const parsedDate = DateTime.fromFormat(date, 'yyyy-MM-dd');

    if (parsedDate.isValid) {
        return parsedDate.toFormat('MM/dd');
    }

    return date;
};

type ButtonProps = {
    selectedDate: string;
    dateOptions: string[];
    onDateChange: (date: string) => void;
};

const Button = ({ selectedDate, dateOptions, onDateChange }: ButtonProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = (option?: string) => {
        if (option) {
            onDateChange(option);
        }
        setAnchorEl(null);
    };

    return (
        <>
            <Box
                onClick={handleClick}
                sx={{
                    backgroundColor: '#F2F8F8',
                    paddingTop: pxToRem(4),
                    paddingBottom: pxToRem(4),
                    paddingLeft: pxToRem(8),
                    paddingRight: pxToRem(8),
                    color: '#006B75',
                    fontWeight: 600,
                    fontSize: pxToRem(14),
                    borderRadius: pxToRem(4),
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                }}
            >
                <Box sx={{ marginRight: pxToRem(2), fontFamily: 'Open Sans, sans-serif' }}>
                    {formatOption(selectedDate)}
                </Box>
                <IoIosArrowDown />
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
                slotProps={{
                    paper: {
                        style: {
                            maxHeight: pxToRem(200),
                            maxWidth: pxToRem(200),
                        },
                    },
                }}
                sx={{
                    '& .MuiPaper-root': {
                        padding: 0,
                        fontSize: pxToRem(14),
                        borderRadius: pxToRem(4),
                    },
                    '& .MuiMenuItem-root': {
                        padding: `${pxToRem(8)} ${pxToRem(16)} ${pxToRem(8)} ${pxToRem(8)} !important`, // for some reason only way to set the padding on the menu items
                        fontSize: pxToRem(14),
                    },
                }}
            >
                {dateOptions.map((option) => (
                    <MenuItem key={option} onClick={() => handleClose(option)} disableRipple>
                        {formatOption(option)}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

type Props = {
    sectionTitle: string;
    dateOptions: string[];
    selectedDate: string;
    onDateChange: (date: string) => void;
    sideComponent?: React.ReactNode;
};

export const DateGroupSelector = ({ sectionTitle, dateOptions, selectedDate, onDateChange, sideComponent }: Props) => (
    <Box
        sx={{
            alignItems: 'center',
            display: 'flex',
            lineHeight: pxToRem(24),
            justifyContent: 'space-between',
        }}
    >
        <Box display="flex" gap="4px" alignItems="center">
            <Typography
                variant="body1"
                fontSize="16px"
                fontWeight={700}
                lineHeight="120%"
                marginRight="8px"
                color="#2C2D3B"
            >
                {sectionTitle} of
            </Typography>

            <Button selectedDate={selectedDate} dateOptions={dateOptions} onDateChange={onDateChange} />
        </Box>

        {sideComponent ?? null}
    </Box>
);
