import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';

import { showToast } from '~/components/Shared/Alerting/Toast/utils/showToast';
import actionTypes from '~/redux/actionTypes';
import { signOut } from '~/redux/actions/session';
import '~/redux/reducers/messages';
import { ErrorProps, MessageProps } from '~/types/messages';
import { AppDispatch, ReduxStore } from '~/types/redux';

export const showAlert = (message: MessageProps) => (dispatch: AppDispatch, getState: () => ReduxStore) => {
    const { messages: messagesState } = getState();

    if (messagesState.timer) clearTimeout(messagesState.timer);
    if (!messagesState.open) {
        dispatch({
            type: actionTypes.MESSAGE_SHOW,
            payload: {
                ...message,
                timer: window.setTimeout(() => dispatch(hideAlert()), 10000),
            },
        });
    }
};

export const hideAlert = () => (dispatch: AppDispatch, getState: () => ReduxStore) => {
    const { messages: messagesState } = getState();

    if (messagesState.timer) clearTimeout(messagesState.timer);
    if (messagesState.open && process.env.REACT_APP_IS_MAINTENANCE !== 'true')
        dispatch({ type: actionTypes.MESSAGE_HIDE });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const throwError =
    (error: any, showAlertOnError = true, shouldCaptureException = true) =>
    (dispatch: AppDispatch) => {
        const errorCode = ((error as AxiosError).status ||
            error.response?.data?.code ||
            error.response?.status) as number;
        const errorMessage = (error.response?.data?.error || error.message) as string;

        // If the error is because a required Authorization (possible expired token), sign-out the user.
        if (errorCode === 401) {
            const currentPath = window.location.pathname;
            if (currentPath.includes('/login/verify') || currentPath.includes('/signup/verify')) {
                if (errorMessage && showAlertOnError) {
                    showToast({
                        message: errorMessage,
                        type: 'error',
                    });
                }
                return;
            }

            void dispatch(signOut(currentPath));

            return;
        } else {
            // don't send 401 errors to Sentry. They are expected
            if (shouldCaptureException) Sentry.captureException(error);
        }

        if (errorMessage && showAlertOnError) {
            showToast({
                message: errorMessage,
                type: 'error',
            });
        }
    };

export const handleError =
    (errorProps: ErrorProps, showAlertOnError = true) =>
    () => {
        // Extract the error props.
        const { error, consoleMessage, alertMessage } = errorProps;

        Sentry.captureMessage(consoleMessage);

        if (showAlertOnError) {
            showToast({
                message: alertMessage || error.message,
                type: 'error',
            });
        }
    };
