import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { Box, Button, CircularProgress, IconButton, Stack, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { VoiceRecorder } from 'capacitor-voice-recorder';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { isChrome } from 'react-device-detect';
import { connect } from 'react-redux';

import { useAudioTranscribeMutation } from '~/api/queries/audioTranscribe/useAudioTranscribe';
import audioWave from '~/assets/audio-wave2.gif';
import { CustomTextField } from '~/components/Custom';
import { showToast } from '~/components/Shared/Alerting/Toast/utils/showToast';
import { MicrophoneIcon } from '~/components/Svg';
import { pxToRem } from '~/components/theme/typography';
import { readUser } from '~/redux/actions/users';
import { AppDispatch, ReduxStore } from '~/types/redux';
import { UserResponse } from '~/types/users';

import ActivateMicConfirmation from './ActivateMicConfirmation';

const useStyles = makeStyles((theme: Theme) => ({
    micIcon: {
        color: theme.palette.primary.main,
    },
}));

interface StopRecordingProps {
    discard?: boolean;
}

type Props = {
    onboardingId?: string;
    label?: string;
    placeholder: string;
    showActivateMicConfirmation: boolean;
    text: string;
    analyticsIdText: string;
    user: UserResponse;
    onChange: (text: string) => void;
    toggleShowActivateMicConfirmation: (show: boolean) => void;
    dispatchReadUser: () => void;
    isRequired?: boolean;
    hideDescriptionMessage?: boolean;
    fullHeight?: boolean;
};

const TranscriptionTextField = (props: Props) => {
    const {
        onboardingId,
        label,
        placeholder,
        showActivateMicConfirmation,
        text,
        analyticsIdText,
        user,
        onChange,
        toggleShowActivateMicConfirmation,
        dispatchReadUser,
        isRequired,
        hideDescriptionMessage,
        fullHeight,
    } = props;

    const displayTranscriptionButton = Capacitor.isNativePlatform() || isChrome;

    const classes = useStyles();

    const [recording, setRecording] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_deviceLanguage, setDeviceLanguage] = useState<string>('en');

    const { mutateAsync: audioTranscribeMutation, isLoading } = useAudioTranscribeMutation();

    useEffect(() => {
        // If there is no data for the current user, then fetch it.
        if (isEmpty(user)) {
            // Fetch the information from the user.
            dispatchReadUser();
        }

        void Device.getLanguageCode().then(({ value }) => {
            setDeviceLanguage(value);
        });
    }, []);

    // eslint-disable-next-line arrow-body-style
    useEffect(() => {
        //
        return () => {
            void stopRecording({ discard: true });
        };
    }, []);

    useEffect(() => {
        if (recording) {
            // Discard recording after 2 minutes to avoid accidental long recordings
            const timer = setTimeout(() => {
                void stopRecording({ discard: true });
            }, 120000);

            return () => clearTimeout(timer);
        }

        return undefined;
    }, [recording]);

    const checkPermission = async () => {
        const { value: hasPermission } = await VoiceRecorder.hasAudioRecordingPermission();

        if (hasPermission) {
            await startRecording();
        } else {
            toggleShowActivateMicConfirmation(true);
        }
    };

    const requestPermission = async () => {
        const { value: grantedPermission } = await VoiceRecorder.requestAudioRecordingPermission();

        if (grantedPermission) {
            await startRecording();
        } else {
            showToast({
                message: 'You need to allow microphone access to record your message.',
                type: 'error',
            });
        }
    };

    const startRecording = async () => {
        setRecording(true);
        await VoiceRecorder.startRecording();
    };

    const stopRecording = async ({ discard = false }: StopRecordingProps) => {
        setRecording(false);

        const { status } = await VoiceRecorder.getCurrentStatus();
        if (status === 'NONE') {
            return;
        }

        const { recordDataBase64, mimeType } = (await VoiceRecorder.stopRecording()).value;

        if (discard) return;

        // https://stackoverflow.com/a/36183085
        const blob = await fetch(`data:${mimeType};base64,${recordDataBase64}`).then((res) => res.blob());

        const form = new FormData();
        form.append('file', blob);
        form.append('language', 'en'); // TODO [AH-522]: support more than english using deviceLanguage

        const data = await audioTranscribeMutation(form);

        const newText = text?.length > 0 ? `${text} ${data}` : data;

        onChange(newText);
    };

    const renderRecordButton = () => {
        if (!displayTranscriptionButton) {
            return null;
        }

        if (isLoading) {
            return <CircularProgress color="inherit" size={20} />;
        }

        if (recording) {
            return (
                <Button
                    sx={{
                        fontWeight: 600,
                    }}
                    color="primary"
                    size="small"
                    onClick={() => stopRecording({ discard: false })}
                    data-analytics-id={`${analyticsIdText}-stop-recording`}
                >
                    <img src={audioWave} alt="transcribing" style={{ height: '20px', marginRight: '5px' }} />
                    End Recording
                </Button>
            );
        }

        return (
            <IconButton
                sx={{
                    color: '#6F6F79',
                    bgcolor: 'transparent',
                    '&:hover': {
                        bgcolor: 'transparent',
                    },
                }}
                onClick={checkPermission}
                data-analytics-id={`${analyticsIdText}-start-recording`}
            >
                <MicrophoneIcon className={classes.micIcon} />
            </IconButton>
        );
    };

    const handleAllowMicClick = async () => {
        toggleShowActivateMicConfirmation(false);
        await requestPermission();
    };

    if (showActivateMicConfirmation) {
        return <ActivateMicConfirmation showConfirmation={showActivateMicConfirmation} onAllow={handleAllowMicClick} />;
    }

    return (
        <Stack spacing="16px" flexGrow={fullHeight ? 1 : undefined}>
            <Box
                sx={[
                    { position: 'relative' },
                    fullHeight ? { height: '100%', display: 'flex', flexDirection: 'column' } : {},
                ]}
            >
                <CustomTextField
                    sx={fullHeight ? { flex: 1 } : undefined}
                    InputProps={
                        fullHeight
                            ? {
                                  sx: {
                                      flexGrow: 1,
                                      alignItems: 'start',
                                      overflow: 'hidden',
                                      padding: '10px 12px !important',
                                      flexDirection: 'column',
                                  },
                              }
                            : undefined
                    }
                    inputProps={fullHeight ? { style: { height: 'unset', flexGrow: 1, padding: 0 } } : undefined}
                    id={onboardingId}
                    label={label || ''}
                    placeholder={placeholder}
                    value={text}
                    fullWidth
                    multiline
                    rows={3}
                    onChange={onChange}
                    customErrorMessage={isRequired && !text ? 'You need to provide a comment' : ''}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        right: { xs: '8px', lg: '16px' },
                        bottom: { xs: '8px', lg: '16px' },
                    }}
                >
                    {renderRecordButton()}
                </Box>
            </Box>
            {displayTranscriptionButton && !hideDescriptionMessage && (
                <Box
                    sx={{
                        bgcolor: (theme) => theme.palette.grey[50],
                        px: pxToRem(10),
                        py: pxToRem(6),
                        borderLeft: (theme) => `2px solid ${theme.palette.grey[100]}`,
                    }}
                >
                    <Typography
                        align="left"
                        sx={{
                            fontSize: `${pxToRem(11.5)} !important`,
                            lineHeight: 1.3,
                        }}
                    >
                        You can document your note by speaking to the phone in English!{' '}
                        <span style={{ fontWeight: 'bold' }}>Click the green microphone button to start.</span> Make
                        sure to <span style={{ fontWeight: 'bold' }}>double check</span> the text is correct before
                        submitting.
                    </Typography>
                </Box>
            )}
        </Stack>
    );
};

const mapStateToProps = ({ users }: ReduxStore) => {
    const { user } = users;
    return {
        user,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    dispatchReadUser: () => dispatch(readUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TranscriptionTextField);
