import React from 'react';

import Base from '~/components/Svg/Base';

type Props = {
    className?: string;
    size?: number | string;
};

export const ThumbsUpIcon = ({ className, size }: Props) => (
    <Base className={className} size={size} viewBox="0 0 24 25">
        <path
            d="M21.9375 8.01125C21.7263 7.77193 21.4666 7.58028 21.1757 7.44903C20.8847 7.31778 20.5692 7.24994 20.25 7.25H15V5.75C15 4.75544 14.6049 3.80161 13.9017 3.09835C13.1984 2.39509 12.2446 2 11.25 2C11.1107 1.9999 10.9741 2.03862 10.8555 2.11181C10.7369 2.185 10.6411 2.28977 10.5787 2.41438L7.03687 9.5H3C2.60218 9.5 2.22064 9.65804 1.93934 9.93934C1.65804 10.2206 1.5 10.6022 1.5 11V19.25C1.5 19.6478 1.65804 20.0294 1.93934 20.3107C2.22064 20.592 2.60218 20.75 3 20.75H19.125C19.6732 20.7502 20.2025 20.5503 20.6137 20.1878C21.0249 19.8253 21.2896 19.3251 21.3581 18.7812L22.4831 9.78125C22.523 9.4644 22.495 9.14268 22.4009 8.8375C22.3068 8.53232 22.1488 8.25066 21.9375 8.01125ZM3 11H6.75V19.25H3V11Z"
            fill="#006B75"
        />
    </Base>
);
