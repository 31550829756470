import { Box, Typography } from '@mui/material';
import Pluralize from 'pluralize';
import React from 'react';

import { pxToRem } from '~/components/theme/typography';

import { CaregiverAvatarGroup } from './CaregiverAvatarGroup';

type Props = {
    title: string;
    tasksCount: number;
    caregivers: CaregiverData[];
    icon: React.ReactNode;
};

export const TasksRowContent = ({ title, tasksCount, caregivers, icon }: Props) => {
    const ICON_SIZE = 40;

    return (
        <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap={1}>
                <Box
                    width={pxToRem(ICON_SIZE)}
                    height={pxToRem(ICON_SIZE)}
                    bgcolor="#F4F4F5"
                    color="#424350"
                    p={pxToRem(10)}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="50%"
                >
                    {icon}
                </Box>
                <Box>
                    <Typography
                        fontSize={{
                            xs: pxToRem(12),
                            md: pxToRem(14),
                        }}
                        fontWeight={700}
                        lineHeight={1.7}
                        textAlign="left"
                    >
                        {title}
                    </Typography>
                    <Typography
                        fontSize={{
                            xs: pxToRem(10),
                            md: pxToRem(11),
                        }}
                        lineHeight={1.3}
                        color="#6F6F79"
                    >
                        {`${tasksCount} ${Pluralize('Task', tasksCount)}`}
                    </Typography>
                </Box>
            </Box>
            <CaregiverAvatarGroup caregivers={caregivers} />
        </Box>
    );
};
