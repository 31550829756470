import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import range from 'lodash/range';
import React from 'react';

import { useGetOpenShifts } from '~/scheduling/api/queries/dashboard/getOpenShifts';
import { teamIdAtom } from '~/scheduling/atoms';

import Table, { ColumnType, RowCellWrapper } from './Table';

const Cell = ({
    value,
    subValue,
    exceedsThreshold,
    type,
}: {
    value: string;
    subValue: string;
    exceedsThreshold: boolean;
    type: ColumnType;
}) => {
    return (
        <RowCellWrapper type={type} exceedsThreshold={exceedsThreshold}>
            {value}
            {subValue && <small>&nbsp;{subValue}</small>}
        </RowCellWrapper>
    );
};

const OpenShiftTable = () => {
    const teamId = useAtomValue(teamIdAtom);
    const { data, isPending, error } = useGetOpenShifts(teamId);

    const headers = ['', ...Object.keys(data?.[0].openShifts ?? {})];

    const tableRows = React.useMemo(
        () =>
            data?.map(({ title, openShifts }) => [
                { value: title, exceedsThreshold: false },
                ...Object.values(openShifts).map(({ value, exceedsThreshold }) => ({
                    value: value.toString(),
                    exceedsThreshold,
                })),
            ]) ?? [],
        [data]
    );

    if (error) {
        return null;
    }

    return (
        <Box sx={{ p: '24px', backgroundColor: '#fff', borderRadius: '8px' }}>
            <Typography variant="body1" fontWeight={700} fontSize="16px">
                Open Shifts
            </Typography>
            <Box sx={{ height: '24px', backgroundColor: '#fff' }} />
            {isPending ? (
                <Stack spacing="8px">
                    {range(5).map((i) => (
                        <Skeleton key={i} width="100%" height="40px" />
                    ))}
                </Stack>
            ) : (
                <Table
                    schema={['regular', 'regular', 'neutralFull', 'neutralFull', 'neutralFull']}
                    headers={headers}
                    data={tableRows}
                    CellComponent={Cell}
                />
            )}
        </Box>
    );
};

export default OpenShiftTable;
