import { Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid-old';
import { format, subDays } from 'date-fns';
import { uniqBy } from 'lodash';
import React from 'react';
import { BsArrowLeft as ReturnIcon } from 'react-icons/bs';
import { IoCloseOutline as CloseIcon } from 'react-icons/io5';

import { pxToRem } from '~/components/theme/typography';
import { MEDICATION_MODULES } from '~/pages/ResidentDashboard/constants';

import PersonAvatar from '../PersonAvatar';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    data: RefusedMedicationRowData[];
};

export const RefusedMedicationDialog = ({ isOpen, onClose, data }: Props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const tasksDialogTitle = MEDICATION_MODULES[0].title;

    const yesterday = subDays(new Date(), 1);
    const defaultStartDate = subDays(yesterday, 30);
    const startDate = format(defaultStartDate, 'MM/dd/yy');
    const endDate = format(yesterday, 'MM/dd/yy');

    const refusedMedicationData = data.flatMap((dayEntry) =>
        dayEntry.medications.map((medication) => ({
            localDate: dayEntry.localDate,
            medicationName: medication.name,
            quantity: medication.quantity,
            recordedBy: uniqBy(medication.recordedByUsers, 'id'),
        }))
    );

    if (!isOpen || refusedMedicationData.length === 0) {
        return null;
    }

    const rows = [...refusedMedicationData]
        .map((refusedMedicationByDay, index) => ({
            ...refusedMedicationByDay,
            id: index,
        }))
        .sort((a, b) => {
            const dateA = new Date(a.localDate);
            const dateB = new Date(b.localDate);
            return dateB.getTime() - dateA.getTime();
        });

    const columns: GridColDef[] = [
        {
            field: 'localDate',
            headerName: 'Date',
            width: 120,
            valueFormatter: (params: GridValueGetterParams) => {
                const [year, month, day] = (params.value as string).split('-');
                return `${month}/${day}/${year}`;
            },
        },
        {
            field: 'medicationName',
            headerName: 'Medication Name',
            width: 300,
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            width: 100,
        },
        {
            field: 'recordedBy',
            headerName: 'Recorded By',
            width: 250,
            renderCell(params) {
                return (
                    <Box display="flex" gap="4px">
                        {params.value?.length > 0 ? (
                            params.value.map((caregiver, index) => (
                                <PersonAvatar name={caregiver.name as string} key={`${caregiver.name}-${index}`} />
                            ))
                        ) : (
                            <PersonAvatar name={'Unknown'} key={'Unknown User'} isDisabled />
                        )}
                    </Box>
                );
            },
        },
    ];

    const mobileColumns: GridColDef[] = [
        { ...columns[0], width: 100 }, // Date
        { ...columns[1], width: 250 }, // Medication Name
        { ...columns[2], width: 100 }, // Quantity
        { ...columns[3], width: 300 }, // Recorded By
    ];

    return (
        <Dialog
            open={isOpen}
            maxWidth="xl"
            onClose={onClose}
            fullScreen={isMobile}
            sx={{
                '& .MuiDialog-paper': {
                    p: {
                        xs: pxToRem(16),
                        md: pxToRem(24),
                    },
                    pt: {
                        xs: pxToRem(24),
                    },
                },
                '& .MuiDialog-container .MuiPaper-root.MuiDialog-paper .MuiTypography-root.MuiDialogTitle-root': {
                    p: 0,
                },
                '& .MuiDialog-container .MuiPaper-root.MuiDialog-paper .MuiDialogContent-root': {
                    p: 0,
                },
                '& .MuiDialog-container': {
                    '& .MuiPaper-root.MuiDialog-paper': {
                        m: {
                            xs: 0,
                            md: pxToRem(16),
                        },
                    },
                },
            }}
        >
            <DialogTitle mb={pxToRem(24)}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center" gap={pxToRem(12)}>
                        <IconButton onClick={onClose} size="large" sx={{ border: '1px solid #DEDEE0' }}>
                            <ReturnIcon size={isMobile ? pxToRem(12) : pxToRem(16)} color="#6F6F79" />
                        </IconButton>
                        <Box>
                            {tasksDialogTitle}
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                display="block"
                                fontSize={{ xs: pxToRem(12), md: pxToRem(14) }}
                            >
                                {startDate} - {endDate}
                            </Typography>
                        </Box>
                    </Box>
                    {!isMobile && (
                        <IconButton sx={{ border: '1px solid #DEDEE0' }} onClick={onClose}>
                            <CloseIcon color="#6F6F79" size={isMobile ? pxToRem(14) : pxToRem(20)} />
                        </IconButton>
                    )}
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box width="100%">
                    <Divider
                        sx={{
                            mb: pxToRem(32),
                        }}
                    />
                    <DataGrid
                        autoHeight
                        rows={rows}
                        columns={isMobile ? mobileColumns : columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        disableRowSelectionOnClick
                        disableColumnMenu
                        sx={{
                            '& .MuiDataGrid-columnHeader': {
                                backgroundColor: '#F4F4F5',
                            },
                            '& .MuiDataGrid-iconButtonContainer': {
                                visibility: 'visible',
                                ml: { xs: 0, md: pxToRem(6) },
                            },
                            '& .MuiDataGrid-sortIcon': {
                                opacity: '.6 !important',
                            },
                        }}
                    />
                </Box>
            </DialogContent>
        </Dialog>
    );
};
