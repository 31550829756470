import { Box } from '@mui/material';
import { format, subDays } from 'date-fns';
import React from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { pxToRem } from '~/components/theme/typography';

import CustomTooltip from './CustomTooltip';

type Props = {
    refusedTasksData?: RefusedTasksPerDate[];
    prnTasksData?: PRNTasksPerDate[];
};

const TasksChart = ({ refusedTasksData, prnTasksData }: Props) => {
    if (!refusedTasksData?.length && !prnTasksData?.length) {
        return null;
    }
    const yesterday = subDays(new Date(), 1);
    const last30Days = Array.from({ length: 30 }, (_, index) =>
        format(subDays(yesterday, index), 'yyyy-MM-dd')
    ).reverse();

    const groupedTasks = last30Days.map((date) => {
        let tasksData: RefusedTasksPerDate[] | PRNTasksPerDate[] = [];

        if (prnTasksData) {
            tasksData = prnTasksData;
        } else if (refusedTasksData) {
            tasksData = refusedTasksData;
        }

        const tasksForDate: RefusedTasksPerDate[] | PRNTasksPerDate[] = tasksData.filter((task) => task.date === date);

        return {
            date,
            amountOfTasks: tasksForDate[0]?.tasks.length || 0,
            tasks: tasksForDate,
        };
    });

    const maxTasks = Math.max(...groupedTasks.map((group) => group.amountOfTasks));
    const numTicks = Math.max(6, maxTasks + 1);

    return (
        <Box position="relative" width={1} height={pxToRem(194)} mb={pxToRem(24)}>
            <Box
                height={1}
                mr={{ xs: pxToRem(24) }}
                position="absolute"
                sx={{
                    inset: 0,
                    left: '-15px',
                }}
            >
                <ResponsiveContainer width="99%" height="100%">
                    <LineChart data={groupedTasks}>
                        <CartesianGrid strokeDasharray="4 4" vertical={false} strokeOpacity={0.5} />
                        <XAxis
                            dataKey="date"
                            fontSize={12}
                            axisLine={false}
                            tickLine={false}
                            tickMargin={11}
                            interval="preserveEnd"
                            tickCount={last30Days.length}
                            tickFormatter={(date) => {
                                const [year, month, day] = date.split('-');
                                return `${month}/${day}`;
                            }}
                        />
                        <YAxis
                            dataKey="amountOfTasks"
                            fontSize={12}
                            axisLine={false}
                            tickLine={false}
                            tickMargin={11}
                            domain={[0, maxTasks]}
                            ticks={Array.from({ length: numTicks }, (_, index) => index)}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Line
                            type="linear"
                            dataKey="amountOfTasks"
                            stroke="#006B75"
                            dot={{ fill: 'transparent', stroke: 'transparent' }}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </Box>
        </Box>
    );
};

export default TasksChart;
