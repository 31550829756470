import { Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import React from 'react';

import { pxToRem } from '~/components/theme/typography';

type Props = {
    sx?: SxProps<Theme>;
    timeStamp: string;
};

export const DateAndTime = ({ sx = [], timeStamp }: Props) => (
    <Typography
        fontSize={{
            xs: pxToRem(10),
            md: pxToRem(11),
        }}
        lineHeight={1.3}
        color="#6F6F79"
        textTransform="uppercase"
        sx={[...(Array.isArray(sx) ? sx : [sx])]}
        flexShrink={0}
    >
        {timeStamp}
    </Typography>
);
