import { Box, FormControlLabel, Radio } from '@mui/material';
import React from 'react';
import { BsCheck as CheckIcon } from 'react-icons/bs';

import { pxToRem } from '~/components/theme/typography';

type Props = {
    value: string;
    label: string;
    selectedValue: string;
};

export const CustomRadioGroupOptions = (props: Props) => {
    const { value, label, selectedValue } = props;

    const isChecked = selectedValue === value;

    return (
        <FormControlLabel
            key={value}
            value={value}
            label={label}
            sx={{
                paddingBlock: pxToRem(12),
                pl: pxToRem(12),
                bgcolor: isChecked ? '#F2F8F8' : 'grey.50',
                borderRadius: pxToRem(8),
                border: `1px solid ${isChecked ? '#006b75' : 'transparent'}`,
                m: '0 !important',
                '& .MuiFormControlLabel-label': {
                    fontWeight: 700,
                },
                textAlign: 'left',
            }}
            control={
                <Radio
                    sx={{
                        p: 0,
                        mr: pxToRem(8),
                    }}
                    icon={
                        <Box
                            sx={{
                                width: pxToRem(16),
                                height: pxToRem(16),
                                borderRadius: '50%',
                                bgcolor: 'grey.200',
                            }}
                        />
                    }
                    checkedIcon={
                        <CheckIcon
                            fill="#F2F8F8"
                            style={{
                                backgroundColor: '#006b75',
                                borderRadius: '50%',
                            }}
                        />
                    }
                />
            }
        />
    );
};
