import { Box, Button, Typography, styled, useTheme } from '@mui/material';
import { useSetAtom } from 'jotai';
import React from 'react';
import { PiSparkleFill } from 'react-icons/pi';

import { budgetRecommendationOnHoverAtom } from '../../atom';
import { StaffOptimizations } from '../../types';
import { useUpdateBudgets } from '../../useUpdateBudgets';

const Sparkle = styled(PiSparkleFill)(({ theme }) => ({
    width: '24px',
    height: '24px',
    color: theme.palette.grey[900],
}));

interface TagProps extends React.ComponentProps<typeof Typography> {
    type: StaffOptimizations.RecommendationActionType | 'neutral';
    children: React.ReactNode;
}

const Tag = ({ type, children, ...styles }: TagProps) => {
    const { palette } = useTheme();

    const colors =
        type === 'decrease'
            ? {
                  backgroundColor: palette.error[50] as string,
                  color: palette.error[500] as string,
                  borderColor: palette.error[500] as string,
              }
            : type === 'increase'
              ? {
                    backgroundColor: palette.primary[50] as string,
                    color: palette.primary[400] as string,
                    borderColor: palette.primary[400] as string,
                }
              : {
                    backgroundColor: 'white',
                    color: palette.grey[600],
                    borderColor: palette.grey[600],
                };

    return (
        <Typography
            variant="body1"
            {...styles}
            sx={{
                ...colors,
                display: 'inline-block',
                padding: '2px 6px',
                borderRadius: '4px',
                textTransform: 'uppercase',
                fontWeight: 600,
                borderWidth: '1px',
                marginRight: '4px',
                borderStyle: 'solid',
                ...(styles?.sx ?? {}),
            }}
        >
            {children}
        </Typography>
    );
};
interface RecommendationCardProps {
    data: StaffOptimizations.BudgetRecommendation;
    onFinish: () => void;
}

export const RecommendationCard = ({ onFinish, data }: RecommendationCardProps) => {
    const { palette } = useTheme();
    const setBudgetRecommendationOnHover = useSetAtom(budgetRecommendationOnHoverAtom);
    const { applyRecommendation } = useUpdateBudgets();

    return (
        <Box
            display="flex"
            padding="16px"
            gap="12px"
            onMouseEnter={() => setBudgetRecommendationOnHover(data.id)}
            onMouseLeave={() => setBudgetRecommendationOnHover(null)}
            sx={{
                cursor: 'pointer',
                '&:hover': { backgroundColor: palette.grey[25] },
            }}
        >
            <Sparkle />
            <Box>
                <Tag type={data.action}>{data.action === 'decrease' ? 'Decrease' : 'Increase'}</Tag>
                <Tag type={data.action}>{data.location.abbreviation}</Tag>
                <Tag type={data.action}>{data.role.name}</Tag>
                <Typography display="inline-block">by</Typography>
                <Tag type={data.action} marginLeft="4px">
                    {data.recommendationAmount}
                </Tag>
                <Typography display="inline-block">staff:</Typography>
                <Box marginBlock="8px" bgcolor={palette.grey[25]} padding="10px 12px" borderRadius="6px">
                    {data.recommendations.map((recommendation, idx) => (
                        <React.Fragment key={idx}>
                            <Box>
                                <Tag type="neutral">{recommendation.day.toFormat('cccc', { locale: 'en' })}</Tag>
                                <Tag type="neutral">{recommendation.shift.name}</Tag>
                                <Typography display="inline-block">shift by</Typography>

                                <Tag type={data.action} marginLeft="4px">
                                    {recommendation.quantity}
                                </Tag>
                            </Box>
                            {idx !== data.recommendations.length - 1 && (
                                <Box marginBlock="12px" borderBottom={`1px solid ${palette.grey[100]}`} />
                            )}
                        </React.Fragment>
                    ))}
                </Box>
                <Typography variant="body1" color={palette.grey[500]} fontSize="13px" fontWeight={600}>
                    {data.reason}
                </Typography>
                <Box display="flex" gap="8px" marginTop="8px">
                    <Button
                        size="small"
                        onClick={() => {
                            applyRecommendation(data);
                            onFinish();
                        }}
                    >
                        <Sparkle sx={{ marginRight: '4px', width: '16px', height: '16px', color: 'white' }} />
                        Apply Recommendation
                    </Button>
                    <Button size="small" variant="outlined" onClick={onFinish}>
                        Decline
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};
