import { ComponentOverride } from '.';

export const MuiTab: ComponentOverride<'MuiTab'> = {
    defaultProps: {
        disableRipple: true, // Without margins this looks weird, and there's already an indicator
    },
    styleOverrides: {
        root: ({ theme: { palette } }) => ({
            color: `${palette.grey[900]} !important`,
            minWidth: 0,
            minHeight: 0,
            padding: 0,
            fontSize: 14,
            lineHeight: '20px',
            textTransform: 'none',
            letterSpacing: 0,
            '&.Mui-selected': { fontWeight: 600 },
            '&:not(.Mui-selected)': { opacity: 0.8 },
        }),
    },
};

export const MuiTabs: ComponentOverride<'MuiTabs'> = {
    defaultProps: {},
    styleOverrides: {
        root: {
            minHeight: '32px',
        },
        indicator: ({ theme: { palette } }) => ({
            backgroundColor: palette.grey[900],
        }),
    },
};
