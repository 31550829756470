import { Button } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAssistLevelChangeMutation } from '~/api/queries/tasks/documentationActions';
import { CustomMobileDialog, CustomRadioGroupOptions } from '~/components/Custom';
import { pxToRem } from '~/components/theme/typography';
import { AssistLevelChange } from '~/types/dailyTasks';
import { ReduxStore } from '~/types/redux';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    assistLevelChangeData: AssistLevelChange;
    residentId: number;
};

const AssistLevelChangeDialog = ({ isOpen, onClose, assistLevelChangeData, residentId }: Props) => {
    const [selectedValue, setSelectedValue] = useState('');
    const [currentStep, setCurrentStep] = useState(0);
    const [categories, setCategories] = useState<string[]>([]);

    const { mutate: assistLevelChangeMutation } = useAssistLevelChangeMutation();

    const { branchId } = useSelector((state: ReduxStore) => state.session.sessionData);

    // Extract categories from assistLevelChangeData
    useEffect(() => {
        if (!assistLevelChangeData) return;
        setCategories(Object.keys(assistLevelChangeData));
    }, [assistLevelChangeData]);

    if (!isOpen || categories.length === 0) {
        return null;
    }

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.value);
    };

    const handleClose = () => {
        onClose();
        setSelectedValue('');
        setCurrentStep(0);
    };

    const advanceStep = () => {
        if (currentStep < categories.length - 1) {
            setCurrentStep((prev) => prev + 1);
        } else {
            handleClose();
        }
        setSelectedValue('');
    };

    const handleSubmit = () => {
        if (!selectedValue) {
            return;
        }

        if (!branchId) return;

        assistLevelChangeMutation({
            residentId,
            carePlanId: Number(selectedValue),
            branchId,
        });

        advanceStep();
    };

    const category = categories[currentStep];

    const assistLevelOptions = category ? assistLevelChangeData[category] : [];

    if (!assistLevelOptions || assistLevelOptions.length === 0) {
        return null;
    }

    return (
        <CustomMobileDialog
            closeable
            open={isOpen}
            title={`Which assist level did you provide for ${category}?`}
            width="100%"
            onClose={handleClose}
            content={
                <>
                    <RadioGroup
                        name="choose-assist-level"
                        defaultValue=""
                        onChange={handleRadioChange}
                        value={selectedValue}
                        sx={{
                            display: 'grid',
                            gap: pxToRem(12),
                            m: 0,
                            mb: pxToRem(16),
                        }}
                    >
                        {assistLevelOptions.map(({ carePlanId, carePlanName }) => (
                            <CustomRadioGroupOptions
                                key={carePlanId}
                                value={carePlanId.toString()}
                                label={carePlanName}
                                selectedValue={selectedValue}
                            />
                        ))}
                    </RadioGroup>

                    <Button
                        color="primary"
                        onClick={handleSubmit}
                        disabled={!selectedValue}
                        sx={{
                            width: '100%',
                            p: pxToRem(12),
                            mt: pxToRem(8),
                            fontSize: pxToRem(14),
                            '&:disabled': {
                                bgcolor: '#C7C8CB',
                            },
                        }}
                    >
                        Submit
                    </Button>
                </>
            }
        />
    );
};

export default AssistLevelChangeDialog;
