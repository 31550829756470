import { ComponentOverride } from '.';

export const MuiRadio: ComponentOverride<'MuiRadio'> = {
    defaultProps: {},
    styleOverrides: {
        root: ({ theme: { palette } }) => ({
            padding: 0,
            '& svg': {
                width: '20px',
                height: '20px',
                '& path': {
                    stroke: 'white',
                    strokeWidth: '1px',
                },
            },
            '&.Mui-checked svg:nth-of-type(2)': {
                transform: 'scale(1.25)',
            },
            '&:not(.Mui-checked) svg path': {
                fill: palette.grey[300],
            },
        }),
    },
};
