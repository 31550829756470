import { AppBar, Box, Dialog, Divider, IconButton, Slide, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import React, { Fragment } from 'react';
import { BsArrowLeft as ReturnIcon } from 'react-icons/bs';
import { IoCloseOutline as CloseIcon } from 'react-icons/io5';

import { pxToRem } from '~/components/theme/typography';
import { RewardTypeDialogData } from '~/types/myRewards';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    rewardTypeDialogData: RewardTypeDialogData;
};

const Transition = React.forwardRef(
    (
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref: React.Ref<unknown>
    ) => <Slide direction="up" ref={ref} {...props} />
);

export const RewardTypeDetailsDialog = ({ isOpen, onClose, rewardTypeDialogData }: Props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const {
        type,
        data: { title, items },
    } = rewardTypeDialogData;

    return (
        <Dialog open={isOpen} onClose={onClose} fullScreen={isMobile} maxWidth="md" TransitionComponent={Transition}>
            <AppBar
                sx={{
                    position: 'sticky',
                    boxShadow: 'none',
                    paddingTop: 'env(safe-area-inset-top)',
                    backgroundColor: 'primary.main',
                }}
            >
                <Toolbar>
                    {isMobile && (
                        <IconButton onClick={onClose} size="large" edge="start" color="inherit" aria-label="close">
                            <ReturnIcon size={24} />
                        </IconButton>
                    )}
                    <Typography
                        sx={{
                            fontWeight: 700,
                            flex: 1,
                            fontSize: { xs: pxToRem(16), md: pxToRem(20) },
                            textAlign: 'left',
                        }}
                    >
                        {title}
                    </Typography>
                    {!isMobile && (
                        <IconButton onClick={onClose} size="large" edge="end" color="inherit" aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>
            <Box
                sx={{
                    pt: {
                        xs: pxToRem(24),
                    },
                    pb: {
                        xs: pxToRem(32),
                    },
                    display: 'grid',
                    gap: pxToRem(24),
                }}
            >
                {items.map(({ subtitle, content, list }) => (
                    <Fragment key={subtitle}>
                        <Box
                            sx={{
                                px: {
                                    xs: pxToRem(24),
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    color: 'primary.main',
                                    fontSize: {
                                        xs: pxToRem(16),
                                        md: pxToRem(18),
                                    },
                                    lineHeight: {
                                        xs: 1.7,
                                    },

                                    mb: pxToRem(16),
                                    textAlign: 'left',
                                }}
                            >
                                {subtitle}
                            </Typography>
                            {content &&
                                content.map((phrase: string, index: number) => (
                                    <Typography
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={index}
                                        sx={{
                                            textAlign: 'left',
                                            fontSize: {
                                                xs: pxToRem(14),
                                                md: pxToRem(16),
                                            },
                                            fontWeight: 400,
                                            color: '#6F6F79',
                                            alignItems: 'baseline',
                                        }}
                                    >
                                        {phrase}
                                    </Typography>
                                ))}
                            {list && (
                                <Box component="ol" sx={{ ml: pxToRem(20) }}>
                                    {list.map((item: string, index: number) => (
                                        <Typography
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={index}
                                            component="li"
                                            sx={{
                                                textAlign: 'left',
                                                fontSize: {
                                                    xs: pxToRem(14),
                                                    md: pxToRem(16),
                                                },
                                                fontWeight: 400,
                                                color: '#6F6F79',
                                            }}
                                        >
                                            {item}
                                        </Typography>
                                    ))}
                                </Box>
                            )}
                        </Box>
                        <Divider
                            sx={{
                                '&:last-of-type': {
                                    display: 'none',
                                },
                            }}
                        />
                    </Fragment>
                ))}
            </Box>
        </Dialog>
    );
};
