import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import React from 'react';

import { MedicineMissedSectionDetail } from '~/types/operationsDashboard';

type Props = {
    rows: MedicineMissedSectionDetail[];
};

export const MedicineMissedCollapsibleComponent = ({ rows }: Props) => {
    function renderMedication(medication: MedicineMissedSectionDetail, index: number) {
        return (
            <TableRow key={`${medication.name}-${index}`}>
                <TableCell align="left" width="34px" />
                <TableCell align="left">{medication.name}</TableCell>
                <TableCell align="left">{`${medication.quantity} dose(s)`}</TableCell>
            </TableRow>
        );
    }

    return (
        <TableContainer>
            <Table aria-label="details row" sx={{ tableLayout: 'fixed' }}>
                <TableBody>{rows.map((row, index) => renderMedication(row, index))}</TableBody>
            </Table>
        </TableContainer>
    );
};
