import { Capacitor } from '@capacitor/core';
import { Box, Button } from '@mui/material';
import React from 'react';

import { pxToRem } from '~/components/theme/typography';
import { Section, SectionData } from '~/pages/OperationsDashboard/constants';

type Props = {
    sectionsToShow: Record<Section, SectionData>;
    selectedSection: string;
    setSelectedSection: (section: Section) => void;
};

export const CategorySelector = ({ sectionsToShow, selectedSection, setSelectedSection }: Props) => {
    const isIos = Capacitor.getPlatform() === 'ios';

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: isIos ? `calc(env(safe-area-inset-bottom) + ${pxToRem(50)})` : pxToRem(62),
                left: 0,
                right: 0,
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                justifyContent: 'center',
                height: pxToRem(52),
                borderTop: '1px solid #F4F4F5',
                boxShadow: '0px -2px 4px 0px rgba(44, 45, 59, 0.04)',
                backgroundColor: '#fff',
                zIndex: 12,
                paddingX: pxToRem(8),
                gap: pxToRem(4),
                overflow: 'auto',
                scrollbarWidth: 'thin',
            }}
        >
            {Object.entries(sectionsToShow).map(([key, value]) => (
                <Button
                    key={key}
                    variant={selectedSection === key ? 'contained' : 'outlined'}
                    onClick={() => setSelectedSection(key as Section)}
                >
                    {value.title}
                </Button>
            ))}
        </Box>
    );
};
