import { Avatar, Box, Tooltip, Typography, useTheme } from '@mui/material';
import React from 'react';

import { CustomAvatar } from '~/components/Custom/CustomAvatar';

export default function PersonAvatar({ name, isDisabled }: { name: string; isDisabled?: boolean }) {
    const { palette } = useTheme();
    const [firstName, ...lastNameParts] = name.split(' ');
    const lastName = lastNameParts.join(' ');

    return (
        <Tooltip
            title={
                <Box display="flex" padding="4px">
                    <Typography variant="body1" color={palette.grey[400]} fontSize="13px">
                        {firstName} {lastName}
                    </Typography>
                </Box>
            }
            key={`${firstName} ${lastName}`}
        >
            <Avatar
                alt={`${firstName} ${lastName}`}
                sx={{
                    width: 20,
                    height: 20,
                }}
            >
                <CustomAvatar
                    firstName={firstName}
                    lastName={lastName}
                    photo={''}
                    size={20}
                    {...(isDisabled && { backgroundColor: palette.grey[200] })}
                />
            </Avatar>
        </Tooltip>
    );
}
