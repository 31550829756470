import { Stack, styled } from '@mui/material';
import { Box } from '@mui/material';
import { CaretRight } from '@phosphor-icons/react';
import React, { ComponentProps } from 'react';
import { useNavigate } from 'react-router';

import GlowingBox from '../GlowingBox';

import SlotItemDetails from './SlotItemDetails';

const SlotDetailsStack = styled(Stack)(({ theme: { palette } }) => ({
    backgroundColor: 'white',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
    border: `1px solid ${palette.grey[100]}`,
    userSelect: 'none',
    cursor: 'pointer',
    '&:hover': { backgroundColor: palette.grey[25] },
    '&:active': { backgroundColor: palette.grey[50] },
}));

const SlotItem = (props: ComponentProps<typeof SlotItemDetails>) => {
    const navigate = useNavigate();

    const { slot, renderFlags } = props;
    const { id, flags } = slot;
    const hasFlags = !!flags.length;
    const ItemBox = hasFlags && renderFlags ? GlowingBox : Box;

    return (
        <Stack>
            <ItemBox
                position="relative"
                onClick={() => navigate(`./${id}`)}
                sx={
                    hasFlags
                        ? {
                              overflow: 'hidden',
                              borderRadius: '8px',
                              p: '2px', // Border width
                          }
                        : {}
                }
            >
                <SlotDetailsStack
                    sx={{
                        p: hasFlags ? '10px 14px' : '12px 16px',
                        borderRadius: hasFlags ? '7px' : '8px',
                    }}
                >
                    <SlotItemDetails {...props} />
                    <CaretRight fontSize="16px" weight="bold" />
                </SlotDetailsStack>
            </ItemBox>
        </Stack>
    );
};

export default SlotItem;
