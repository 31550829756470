import { useCallback } from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/api/common';
import { GetSlotSuggestedStaff } from '~/scheduling/api/types/shift-slot/getSlotSuggestedStaff';

export type GetSlotSuggestedStaffResult = NonNullable<ReturnType<typeof useGetSlotSuggestedStaff>['data']>;

export const useGetSlotSuggestedStaff = (slotId: number | null) => {
    const branchId = useBranchId()!;
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = { headers: { Authorization: token } };
        const { data } = await api.get<GetSlotSuggestedStaff.Response>(
            `/scheduling/${branchId}/shift-slot/${slotId}/suggested-staff`,
            options
        );

        const suggestedStaff = data.response.map(remapSuggestedStaff);
        const suggestedStaffById = new Map(suggestedStaff.map((staff) => [staff.id, staff]));

        return { suggestedStaff, suggestedStaffById };
    }, [branchId, slotId, token]);

    return useErrorHandledQuery({
        queryKey: ['suggested-staff', branchId, slotId],
        queryFn,
        enabled: !!slotId,
        staleTime: 30000,
    });
};

const remapSuggestedStaff = ({ name, ...suggestedStaff }: GetSlotSuggestedStaff.StaffSuggestion) => {
    const split = name.split(' ');

    return {
        ...suggestedStaff,
        name,
        firstName: split[0],
        lastName: split[1],
    };
};
