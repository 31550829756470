import { IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { PiArrowsDownUp } from 'react-icons/pi';

export const SortButton = ({ onClick }: { onClick: () => void }) => {
    const { palette, breakpoints } = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const color = isMobile ? 'white' : palette.grey[900];

    return (
        <IconButton size="large" sx={{ gap: '4px' }} onClick={onClick}>
            <Typography variant="body1" color={color}>
                Sort
            </Typography>
            <PiArrowsDownUp size={20} color={color} />
        </IconButton>
    );
};
