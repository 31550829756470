import { atom, useAtom } from 'jotai';
import { useCallback, useMemo } from 'react';

import { useEmergencyFlow } from './useEmergencyFlow';

const seedAtom = atom<number>(0);

const randomMessages: { title: string; description: string; answer: string }[] = [
    {
        title: "You're amazing!",
        description: "Thank you for making resident's day brighter.",
        answer: "I know I'm amazing!",
    },
    {
        title: 'You rock!',
        description: 'Thank you for caring for the resident and sharing critical insights.',
        answer: "You're welcome",
    },
    {
        title: 'That was so kind!',
        description: "Thank you for spreading love and brightening resident's day.",
        answer: "It's what I do",
    },
];

export const useRandomSuccessMessage = () => {
    const { hasEmergencyCategory } = useEmergencyFlow();
    const [seed, setSeed] = useAtom(seedAtom);

    const message = useMemo(
        () => (hasEmergencyCategory ? randomMessages[1] : randomMessages[seed % randomMessages.length]),
        [hasEmergencyCategory, seed]
    );

    const resetSeed = useCallback(() => {
        const randomSeed = Math.floor(Math.random() * randomMessages.length);

        setSeed(randomSeed);
    }, [setSeed]);

    return { message, resetSeed };
};
