import { Box } from '@mui/material';
import React from 'react';

import { pxToRem } from '~/components/theme/typography';

import { TERMS_OF_SERVICE } from './termsOfService';

const TermsOfService = () => <Box sx={{ p: pxToRem(32) }} dangerouslySetInnerHTML={{ __html: TERMS_OF_SERVICE }} />;

export default TermsOfService;
