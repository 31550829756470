import { Box, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { pxToRem } from '~/components/theme/typography';

const RowStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        py: pxToRem(8),
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.grey[500]}`,
        width: '100%',
        '&:first-of-type': {
            pt: pxToRem(4),
        },
        '&:last-of-type': {
            borderBottom: 'none',
        },
    })
);

const ResidentDetailsContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        width: `calc(100% - ${pxToRem(50)})`,
    })
);

const SkeletonAvatar = styled(Skeleton)(({ theme }) =>
    theme.unstable_sx({
        width: pxToRem(56),
        height: pxToRem(56),
    })
);

type Props = {
    totalItems: number;
};

const ResidentRowSkeleton = (props: Props) => {
    const { totalItems = 1 } = props;

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {() => {
                for (let index = 1; index <= totalItems; index++)
                    <RowStyle key={`skeleton-${index}`}>
                        <SkeletonAvatar />
                        <ResidentDetailsContainer>
                            <Skeleton width="60%" />
                            <Skeleton width="60%" />
                        </ResidentDetailsContainer>
                    </RowStyle>;
            }}
        </>
    );
};

export default ResidentRowSkeleton;
