import React from 'react';

import { OperationsPeriod, SelectOptionsId } from '~/types/operationsDashboard';

import { SchedulingSection, SectionData } from '../../constants';

import BudgetedVsScheduledHoursSection from './BudgetedVsScheduledHoursSection';
import ScheduledAgencyHoursSection from './ScheduledAgencyHoursSection';
import ScheduledOvertimeHoursSection from './ScheduledOvertimeHoursSection';
import WeeklyRecurringOpenHoursSection from './WeeklyRecurringOpenHoursSection';
import { SectionProps } from './shared';

interface SchedulingTablesSectionProps {
    regionId: SelectOptionsId;
    branchId: SelectOptionsId;
    operationsPeriod: OperationsPeriod;
    sectionsToShow: Record<SchedulingSection, SectionData>;
    dateOptions: string[];
}

const SchedulingSections = ({
    regionId,
    branchId,
    operationsPeriod,
    sectionsToShow,
    dateOptions,
}: SchedulingTablesSectionProps) => {
    const selectedRegion = regionId !== 'all' ? regionId : undefined;
    const selectedBranch = branchId !== 'all' ? branchId : undefined;

    const mapSectionToComponent: Record<SchedulingSection, (props: SectionProps) => React.ReactNode> = {
        BUDGETED_SCHEDULED_HOURS: BudgetedVsScheduledHoursSection,
        SCHEDULED_OVERTIME_HOURS: ScheduledOvertimeHoursSection,
        SCHEDULED_AGENCY_HOURS: ScheduledAgencyHoursSection,
        RECURRING_OPEN_HOURS: WeeklyRecurringOpenHoursSection,
    };

    return (
        <>
            {Object.keys(sectionsToShow).map((section: SchedulingSection) => {
                const Section = mapSectionToComponent[section];

                if (!Section) return;

                return (
                    <Section
                        key={section}
                        regionId={selectedRegion}
                        branchId={selectedBranch}
                        operationsPeriod={operationsPeriod}
                        dateOptions={dateOptions}
                    />
                );
            })}
        </>
    );
};

export default SchedulingSections;
