// import { Close as CloseIcon } from '@mui/icons-material';
import { Alert, PaletteOptions, ThemeOptions, ThemeProvider, createTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useSelector } from 'react-redux';

import { pxToRem } from '~/components/theme/typography';
// import { useAppDispatch } from '~/constants/redux';
// import { hideAlert } from '~/redux/actions/messages';
import typography from '~/theme/typography';
import { ReduxStore } from '~/types/redux';

const AlertStyle = styled(Alert)(({ theme }) =>
    theme.unstable_sx({
        width: `calc(100% - ${pxToRem(16)})`,
        position: 'fixed',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        mt: 'calc(env(safe-area-inset-top) + 8px)',
        zIndex: 10000,
        borderRadius: pxToRem(8),
        boxShadow: '0px 0px 16px 2px rgba(0,0,0,0.5)',
        transition: 'all 0.2s ease',
    })
) as typeof Alert;

// const severityToCloseButtonColor: Record<AlertColor, string> = {
//     success: 'rgba(255,255,255,0.5)',
//     info: 'rgba(0,0,0,0.25)',
//     warning: 'rgba(255,255,255,0.5)',
//     error: 'rgba(0,0,0,0.25)',
// };

const palette = {
    success: { main: '#71e140' },
    info: { main: '#3390e7' },
    warning: { main: '#ffca2c' },
    error: { main: '#ff4842' },
} as PaletteOptions;

const theme = createTheme({ palette, typography } as ThemeOptions);

const Messages = () => {
    // const dispatch = useAppDispatch();
    // const dispatchHideAlert = () => dispatch(hideAlert());

    const { open, message, alertSeverity } = useSelector((state: ReduxStore) => state.messages);

    return (
        // Using a separate ThemeProvider for redefining some colors
        <ThemeProvider theme={theme}>
            <AlertStyle
                variant="filled"
                severity={alertSeverity}
                // action={
                //     <IconButton
                //         color="inherit"
                //         size="small"
                //         onClick={dispatchHideAlert}
                //         sx={{ backgroundColor: severityToCloseButtonColor[alertSeverity] }}
                //     >
                //         <CloseIcon fontSize="inherit" />
                //     </IconButton>
                // }
                sx={{
                    pointerEvents: open ? 'all' : 'none',
                    opacity: open ? 1 : 0,
                }}
            >
                {message}
            </AlertStyle>
        </ThemeProvider>
    );
};

export default Messages;
