import { TabList } from '@mui/lab';
import { Tab } from '@mui/material';
import { useAtom } from 'jotai';
import React, { useEffect } from 'react';

import MobileHeader from '~/components/Layout/MobileHeader';
import WithHeader from '~/components/Layout/WithHeader';
import MobileTabContext from '~/scheduling/components/MobileTabContext';

import { selectedTabAtom } from './atoms';
import FilledSlotsTabPanel from './components/FilledSlotsTabPanel';
import NotificationsDrawer from './components/NotificationsDrawer';
import OpenSlotsTabPanel from './components/OpenSlotsTabPanel';

const Tabs = () => {
    const [selectedTab, setSelectedTab] = useAtom(selectedTabAtom);

    useEffect(() => {
        if (!selectedTab) {
            if (window.location.hash === '#open') setSelectedTab('open');
            else setSelectedTab('filled'); // Default tab

            window.location.hash = ''; // Clear hash to not make it weird when navigating to a different tab
        }
    }, [selectedTab, setSelectedTab]);

    if (!selectedTab) return null;

    return (
        <MobileTabContext value={selectedTab}>
            <TabList onChange={(_, newTab: typeof selectedTab) => setSelectedTab(newTab)} sx={{ px: '24px' }}>
                <Tab label="Scheduled" value="filled" />
                <Tab label="Open" value="open" />
            </TabList>

            {selectedTab === 'filled' && <FilledSlotsTabPanel />}
            {selectedTab === 'open' && <OpenSlotsTabPanel />}
        </MobileTabContext>
    );
};

const Schedule = () => (
    <WithHeader
        mobileHeader={
            // TODO: Uncomment after reverting https://linear.app/alliehealth/issue/AH-1324
            <MobileHeader /* actions={<HeaderNotificationButton />} */ />
        }
    >
        <Tabs />
        <NotificationsDrawer />
    </WithHeader>
);

export default Schedule;
