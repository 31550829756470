import { useAtom, useSetAtom } from 'jotai';
import { useFeatureFlagEnabled } from 'posthog-js/react';

import { documentationResidentDataAtom } from '~/pages/Home/eCallComponents/atom';

import { unscheduledTaskModalOpenAtom } from '../state/atom';

export default function useNewPrnFlow() {
    const flagEnabled = useFeatureFlagEnabled('new-prn-flow');
    const [isUnscheduledTaskDialogOpen, setIsUnscheduledTaskDialogOpen] = useAtom(unscheduledTaskModalOpenAtom);
    const setResidentData = useSetAtom(documentationResidentDataAtom);

    return {
        isEnabled: flagEnabled ?? false,
        isUnscheduledTaskDialogOpen,
        setIsUnscheduledTaskDialogOpen,
        setResidentData,
    };
}
