import { Box, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { ReactNode, useEffect } from 'react';
import { BsArrowLeft as ReturnIcon } from 'react-icons/bs';

import { CustomSelect } from '~/components/Custom';
import { MOBILE_SELECTOR_STYLES, SELECTOR_STYLES } from '~/components/Filtering/constants';
import { pxToRem } from '~/components/theme/typography';
import { SelectStructure } from '~/types/inputs';
import { OperationsPeriod, SelectOptionsId } from '~/types/operationsDashboard';

import BranchSelector from '../BranchSelector';
import MobileDashboardTabs from '../Mobile/MobileDashboardTabs';
import RegionSelector from '../RegionSelector';

import { PeriodSelector } from './PeriodSelector';

type Props = {
    region: SelectOptionsId;
    branch: SelectOptionsId;
    careType: string;
    showDay?: boolean;
    showBackButton?: boolean;
    showCareType?: boolean;
    operationsPeriod: OperationsPeriod;
    onRegionChange: (newRegion: SelectOptionsId) => void;
    onBranchChange: (newBranch: SelectOptionsId) => void;
    onCareTypeChange: (newCareType: string) => void;
    onPeriodChange: React.Dispatch<React.SetStateAction<OperationsPeriod>>;
    mobileTabSelected: number;
    onMobileTabChange: (newValue: number) => void;
    regionOptions: SelectStructure[];
    branchesOptions: SelectStructure[];
    careTypeOptions: SelectStructure[];
    hasCompanyAccess: boolean;
};

export const OperationsDashboardHeader = ({
    region,
    branch,
    careType,
    showDay = true,
    showBackButton = true,
    showCareType = true,
    operationsPeriod,
    onRegionChange,
    onBranchChange,
    onCareTypeChange,
    onPeriodChange,
    mobileTabSelected,
    onMobileTabChange,
    regionOptions,
    branchesOptions,
    careTypeOptions,
    hasCompanyAccess,
}: Props) => {
    useEffect(() => {
        if (showCareType && careTypeOptions[0]?.value) {
            onCareTypeChange(careTypeOptions[0].value);
        }
    }, [branch, region, showCareType]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    const handleRegionChange = (newRegion: SelectOptionsId) => {
        onBranchChange('all');
        onRegionChange(newRegion);
    };

    const hasRegions = regionOptions.length > 0;
    const hasBranches = hasRegions || branchesOptions.length > 2; // There'll always be at least 'All' and one branch

    const handleReturn = () => {
        if (hasRegions && region === 'all') return;
        if (branch !== 'all') {
            onBranchChange('all');
        } else if (hasCompanyAccess) {
            onRegionChange('all');
        }
    };

    let returnToPageText: 'Region' | 'Company' | 'Report' = 'Report';

    if (branch !== 'all' && regionOptions.length > 0) {
        returnToPageText = 'Region';
    } else if (region !== 'all' && hasCompanyAccess) {
        returnToPageText = 'Company';
    } else {
        returnToPageText = 'Report';
    }

    let renderReturnToPage: ReactNode;

    if (showBackButton) {
        if (returnToPageText !== 'Report') {
            renderReturnToPage = (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: { xs: pxToRem(4), sm: pxToRem(12) },
                        mt: { xs: 0, sm: pxToRem(12) },
                        mb: { sm: pxToRem(12) },
                    }}
                >
                    <IconButton
                        onClick={handleReturn}
                        size={isMobile ? 'small' : 'large'}
                        color="inherit"
                        aria-label="return"
                        sx={{
                            border: isMobile ? 'none' : '1px solid #DEDEE0',
                        }}
                    >
                        <ReturnIcon size={isMobile ? 24 : 16} color={isMobile ? '#FFF' : '#6F6F79'} />
                    </IconButton>
                    <Box>
                        <Typography
                            sx={{
                                color: { xs: '#91BFC4', sm: '#6F6F79' },
                                fontSize: { xs: pxToRem(10), sm: pxToRem(14) },
                            }}
                        >
                            Back to
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: { xs: pxToRem(14), sm: pxToRem(20) },
                                lineHeight: { xs: 1.7, sm: 1.3 },
                                fontWeight: 700,
                            }}
                        >
                            {returnToPageText}
                        </Typography>
                    </Box>
                </Box>
            );
        } else {
            renderReturnToPage = (
                <Typography
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: { xs: pxToRem(39), sm: pxToRem(47) },
                        fontSize: { xs: pxToRem(16), sm: pxToRem(20) },
                        lineHeight: 1.3,
                        fontWeight: 700,
                        mb: { sm: pxToRem(20) },
                    }}
                >
                    {returnToPageText}
                </Typography>
            );
        }
    }

    return (
        <Box
            sx={{
                px: { xs: pxToRem(12), md: pxToRem(16) },
                pt: { xs: 0, sm: pxToRem(16), lg: pxToRem(16) },
                pb: { xs: 0, sm: pxToRem(8), lg: 0 },
                color: { xs: 'common.white', sm: '#2C2D3B' },
                backgroundColor: { xs: '#006B75', sm: 'common.white' },
                zIndex: 2,
            }}
        >
            {isDesktop && renderReturnToPage}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', lg: 'row' },
                    gap: pxToRem(16),
                }}
            >
                <Box
                    sx={{
                        display: {
                            xs: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                        },
                        minWidth: { lg: hasRegions || hasBranches ? '400px' : undefined },
                        minHeight: { xs: 'unset', lg: '48px' },
                        gap: 1,
                        order: { xs: 1, lg: 0 },
                    }}
                >
                    {hasRegions && (
                        <RegionSelector
                            onChange={handleRegionChange}
                            currentRegion={region}
                            disabled={!hasCompanyAccess && regionOptions.length < 2}
                            regionOptions={regionOptions}
                        />
                    )}
                    {hasBranches && (
                        <BranchSelector
                            onChange={onBranchChange}
                            currentBranch={hasRegions && region === 'all' ? 'all' : branch}
                            disabled={(hasRegions && region === 'all') || !hasRegions}
                            branchesOptions={branchesOptions}
                        />
                    )}
                </Box>
                {showCareType && !!careTypeOptions.length && (
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: { xs: 'unset', lg: pxToRem(280) },
                            minHeight: { xs: 'unset', lg: '48px' },
                            mr: { lg: pxToRem(16) },
                            order: { xs: 2, lg: 1 },
                        }}
                    >
                        <CustomSelect
                            id="care-type"
                            label="Care Type"
                            value={careType}
                            options={careTypeOptions}
                            onChange={onCareTypeChange}
                            disabled={careTypeOptions.length < 2}
                            sx={{
                                ...(isMobile ? MOBILE_SELECTOR_STYLES : {}),
                                ...SELECTOR_STYLES,
                                mr: 'auto',
                                mb: pxToRem(8),
                            }}
                            fullWidth
                        />
                    </Box>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: { xs: 'center', lg: 'stretch' },
                        ml: { lg: 'auto' },
                        mb: { xs: pxToRem(12), lg: 0 },
                        order: { xs: 0, lg: 2 },
                    }}
                >
                    {!isDesktop && renderReturnToPage}
                    <PeriodSelector
                        key={region}
                        onPeriodChange={onPeriodChange}
                        operationsPeriod={operationsPeriod}
                        showDay={showDay}
                    />
                </Box>
            </Box>
            {isMobile && <MobileDashboardTabs mobileTabSelected={mobileTabSelected} onChange={onMobileTabChange} />}
        </Box>
    );
};
