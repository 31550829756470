import { Assignment as NotesIcon } from '@mui/icons-material';
import { Box, Theme, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import langDictionary from '~/app-strings';
import { ActivityIndicator } from '~/components/Shared/ActivityIndicator';
import { pxToRem } from '~/components/theme/typography';
import ResidentPhotoContainer from '~/pages/Residents/components/ResidentPhotoContainer';
import { usePermissions } from '~/permissions/utils';
import { ResidentsListResponse } from '~/types/residents';

const useStyles = makeStyles((theme: Theme) => ({
    done: {
        color: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, 0.08),
    },
    pending: {
        color: theme.palette.grey[800],
        backgroundColor: alpha(theme.palette.grey[600], 0.08),
    },
    inBuilding: {
        color: theme.palette.primary.main,
    },
    outOfBuilding: {
        color: theme.palette.grey[500],
    },
}));

const RowStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        py: pxToRem(16),
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.grey[100]}`,
        width: '100%',
        pl: pxToRem(24),
        '&:last-of-type': {
            borderBottom: 'none',
        },
    })
);

const ResidentDetailsContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        gap: pxToRem(4),
    })
);

const ResidentName = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        fontSize: pxToRem(16),
        fontWeight: 700,
    })
);

const NotesIconStyle = styled(NotesIcon)(({ theme }) =>
    theme.unstable_sx({
        ml: pxToRem(4),
        color: theme.palette.primary.main,
        fontSize: pxToRem(14),
    })
);

const TasksAndRoom = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        gap: pxToRem(4),
    })
);

const TasksCounter = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        px: pxToRem(6),
        fontWeight: 700,
        borderRadius: pxToRem(4),
    })
);

const RoomNumber = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.secondary.main,
        fontSize: pxToRem(14),
        fontWeight: 700,
    })
);

const BuildingStatusContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
    })
);

type Props = {
    resident: ResidentsListResponse;
    branchId: number;
};

const ResidentRowData = (props: Props) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const {
        resident: {
            residentId,
            firstName,
            lastName,
            photo,
            roomNumber,
            buildingStatus,
            totalTasks,
            totalTasksDone,
            totalShiftNotes,
            hasShower,
            showerComplete,
            hasLaundry,
            laundryComplete,
            isOnHospice,
        },
    } = props;
    const { residents: residentsDictionary } = langDictionary;

    const residentName = `${firstName} ${lastName}`;
    const residentLocation = `${residentsDictionary.room} ${roomNumber ?? 'not set'}`;

    const areAllTasksCompleted = totalTasks === totalTasksDone;

    const hasPermission = usePermissions();

    const goToResidentDetails = () => {
        let url = `/residents/${residentId}`;
        if (hasPermission('Community', 'read-resident-deep-dive')) url += '/deep-dive';
        navigate(url);
    };

    return (
        <RowStyle
            key={residentId}
            onClick={goToResidentDetails}
            sx={{
                backgroundColor: areAllTasksCompleted ? 'primary.50' : undefined,
            }}
        >
            <ResidentPhotoContainer
                residentName={residentName}
                residentPhoto={photo}
                totalTasks={totalTasks}
                totalTasksDone={totalTasksDone}
            />
            <ResidentDetailsContainer>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: pxToRem(8),
                    }}
                >
                    {hasShower && (
                        <ActivityIndicator
                            needsShower={hasShower}
                            showerComplete={showerComplete}
                            isOnHospice={isOnHospice}
                        />
                    )}
                    {hasLaundry && <ActivityIndicator laundryComplete={laundryComplete} />}
                </Box>
                <ResidentName>
                    {residentName}
                    {totalShiftNotes > 0 && <NotesIconStyle />}
                </ResidentName>
                <TasksAndRoom>
                    <TasksCounter variant="caption" className={areAllTasksCompleted ? classes.done : classes.pending}>
                        {totalTasksDone}/{totalTasks}
                    </TasksCounter>
                    <RoomNumber>{residentLocation ?? 'Apt not set'}</RoomNumber>
                </TasksAndRoom>
            </ResidentDetailsContainer>
            <BuildingStatusContainer>
                <Typography
                    variant="caption"
                    className={buildingStatus === 'In Building' ? classes.inBuilding : classes.outOfBuilding}
                >
                    {buildingStatus}
                </Typography>
            </BuildingStatusContainer>
        </RowStyle>
    );
};

export default ResidentRowData;
