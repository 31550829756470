import { Device } from '@capacitor/device';
import { Dialog } from '@capacitor/dialog';
import { ActionPerformed, PushNotificationSchema, PushNotifications } from '@capacitor/push-notifications';
import * as Sentry from '@sentry/react';
import { formatISO, isBefore, subDays } from 'date-fns';
import { usePostHog } from 'posthog-js/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { usePermissionsQuery } from '~/api/queries/permissions';
import { useUserDeviceMetadataMutation } from '~/api/queries/user';
import { PUSH_NOTIFICATION_TOKEN_LAST_UPDATED } from '~/constants/localStorage';
import ActivateNotificationsDialog from '~/pages/Signup/ActivateNotificationsDialog';
import { usePermissions } from '~/permissions/utils';
import { ReduxStore } from '~/types/redux';

type NotificationData = {
    navigateTo: string;
};

const NotificationsHandler = () => {
    const posthog = usePostHog();

    const {
        isSignedIn,
        sessionData: { userId },
    } = useSelector((state: ReduxStore) => state.session);

    const { isSuccess: isPermitLoaded } = usePermissionsQuery();
    const hasPermission = usePermissions();

    const navigate = useNavigate();
    const { mutate: mutateUserDeviceMetadata } = useUserDeviceMetadataMutation();

    const [pushNotificationToken, setPushNotificationToken] = useState<string | null>(null);
    const [isNotificationsDialogOpen, setIsNotificationsDialogOpen] = useState(false);
    const [askForNotificationPermission, setAskForNotificationPermission] = useState(true);

    // always listen for push notifications so that we don't have to wait
    // for permissions to load to react to a push notification deep link
    useEffect(() => {
        addPushNotificationListeners();
    }, []);

    useEffect(() => {
        if (isSignedIn && isPermitLoaded && hasPermission('Community', 'receive-notification')) {
            registerNotifications();
        }
    }, [isSignedIn, isPermitLoaded, hasPermission]);

    useEffect(() => {
        updateDeviceMetadata();
    }, [pushNotificationToken]);

    const shouldUpdateDeviceMetadata = () => {
        const lastUpdated = localStorage.getItem(PUSH_NOTIFICATION_TOKEN_LAST_UPDATED);
        if (!lastUpdated) return true;

        const lastUpdatedDate = new Date(lastUpdated);
        if (isBefore(lastUpdatedDate, subDays(new Date(), 7))) {
            return true;
        }

        return false;
    };

    const updateDeviceMetadata = async () => {
        if (!shouldUpdateDeviceMetadata()) {
            return;
        }

        if (pushNotificationToken) {
            const { identifier } = await Device.getId();
            mutateUserDeviceMetadata(
                { token: pushNotificationToken, deviceId: identifier },
                {
                    onError: (error) => {
                        Sentry.captureException(error);
                    },
                    onSuccess: () => {
                        const now = formatISO(new Date());
                        localStorage.setItem(PUSH_NOTIFICATION_TOKEN_LAST_UPDATED, now);
                    },
                }
            );
        }
    };

    const registerNotifications = async () => {
        const permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'granted') {
            await PushNotifications.register();
        } else if (askForNotificationPermission && permStatus.receive === 'prompt') {
            // If the permission hasn't been accepted or denied yet, ask for it every time the app is opened
            setIsNotificationsDialogOpen(true);
        }
    };

    const addPushNotificationListeners = () => {
        PushNotifications.addListener('registration', (token) => {
            setPushNotificationToken(token.value);
        });

        PushNotifications.addListener('registrationError', (err) => {
            Sentry.captureMessage('Push notification registration error');
        });

        // Notification received with the app running
        PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema) => {
            const { title, body } = notification;
            const { data }: { data: NotificationData } = notification;

            let message = body || '';

            if (data.navigateTo) {
                message += '\nNavigate to related page?';
                const { value: confirmed } = await Dialog.confirm({
                    title,
                    message,
                });

                if (confirmed) {
                    if (data.navigateTo) {
                        navigate(data.navigateTo);
                    }
                }
                return;
            }

            Dialog.alert({
                title,
                message,
            });
        });

        // Notification received with the app closed or in background
        PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
            const { data } = notification.notification;
            if (data.navigateTo) {
                navigate(data.navigateTo);
            }
        });
    };

    const toggleNotificationsDialog = () => {
        setIsNotificationsDialogOpen((prev) => !prev);
    };

    const handleActivateNotifications = async () => {
        await PushNotifications.requestPermissions();

        setTimeout(async () => {
            const newPermStatus = await PushNotifications.checkPermissions();

            if (newPermStatus.receive === 'granted') {
                await PushNotifications.register();
            } else posthog.capture('onboarding:notification_permission_denied', { user_id: userId });
        }, 3000);
        toggleNotificationsDialog();
    };

    const handleNotificationsPermissionCancel = () => {
        toggleNotificationsDialog();
        setAskForNotificationPermission(false);
    };

    return (
        <ActivateNotificationsDialog
            isOpen={isNotificationsDialogOpen}
            onClose={toggleNotificationsDialog}
            onCancel={handleNotificationsPermissionCancel}
            handleActivateNotifications={handleActivateNotifications}
        />
    );
};

export default NotificationsHandler;
