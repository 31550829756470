import { Box } from '@mui/system';
import React, { useState } from 'react';
import { FaMobile } from 'react-icons/fa';

import {
    useConfirmDeleteAccountMutation,
    useRequestDeleteAccountMutation,
} from '~/api/queries/deleteAccount/deleteAccount';
import AuthButton from '~/components/Auth/AuthButton';
import AuthCtaLink from '~/components/Auth/AuthCtaLink';
import AuthIcon from '~/components/Auth/AuthIcon';
import AuthTitle from '~/components/Auth/AuthTitle';
import CodeInput from '~/components/Auth/CodeInput';
import FormContainer from '~/components/Auth/FormContainer';
import MainContainer from '~/components/Auth/MainContainer';
import { pxToRem } from '~/components/theme/typography';
import { VERIFICATION_DIGITS_LENGTH } from '~/constants/shared';
import PageStructure from '~/pages/PageStructure';

const DeleteAccountVerifyStep = () => {
    const [verificationCode, setVerificationCode] = useState('');

    const { mutate: requestDeleteAccountMutation } = useRequestDeleteAccountMutation();
    const { mutate: confirmDeleteAccountMutation, isLoading } = useConfirmDeleteAccountMutation();

    const handleCodeChange = (code: string) => {
        setVerificationCode(code);
    };

    const requestDeleteAccount = () => {
        requestDeleteAccountMutation();
    };

    const handleConfirmDelete = () => {
        confirmDeleteAccountMutation({ code: verificationCode });
    };

    return (
        <PageStructure>
            <MainContainer
                sx={{
                    position: { xs: 'absolute', lg: 'relative' },
                    backgroundImage: 'none',
                    bgcolor: 'none',
                    mb: { xs: pxToRem(64), lg: 0 },
                }}
            >
                <FormContainer sx={{ bgcolor: { lg: '#F9FBFB' } }}>
                    <Box
                        sx={{
                            mb: {
                                lg: pxToRem(44),
                            },
                            pt: 'env(safe-area-inset-top)',
                            mt: pxToRem(64),
                        }}
                    >
                        <AuthIcon icon={<FaMobile size={36} />} />
                        <Box
                            sx={{
                                mt: {
                                    xs: pxToRem(48),
                                },
                                mb: {
                                    xs: pxToRem(24),
                                },
                            }}
                        >
                            <AuthTitle
                                title="Code Verification"
                                subtitle="A verification code has been sent to your phone
                            number"
                                align="center"
                            />
                        </Box>
                        <CodeInput onCodeChange={handleCodeChange} code={verificationCode} />
                        <AuthCtaLink
                            question="Didn't receive the code?"
                            linkLabel="Send again."
                            onClick={requestDeleteAccount}
                        />
                    </Box>
                    <AuthButton
                        label="Proceed and Delete Account"
                        onClick={handleConfirmDelete}
                        isDisabled={verificationCode.length !== VERIFICATION_DIGITS_LENGTH}
                        isLoading={isLoading}
                    />
                </FormContainer>
            </MainContainer>
        </PageStructure>
    );
};

export default DeleteAccountVerifyStep;
