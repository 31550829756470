import { Box, BoxProps, keyframes } from '@mui/material';
import React from 'react';

const gradientSteps = [
    '#0E8C98 0deg',
    '#2FE0F1 45deg',
    '#A9EBF1 60deg',
    '#5DC8D2 75deg',
    '#B3F3F9 105deg',
    '#31C6D3 135deg',
    '#0E8C98 180deg',
    '#2FE0F1 225deg',
    '#A9EBF1 240deg',
    '#5DC8D2 255deg',
    '#B3F3F9 285deg',
    '#31C6D3 315deg',
    '#0E8C98 360deg',
].join(', ');

const rotateKeyframes = keyframes`
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
`;

const GlowingBox = ({ isAnimated, sx, ...props }: { isAnimated?: true } & BoxProps) => (
    <Box
        position="relative"
        sx={{
            '&::before': {
                content: '""',
                backgroundImage: `conic-gradient(${gradientSteps})`,
                width: '9999px',
                height: '9999px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                // Slight rotation to make it more natural when static
                transform: `translate(-50%, -50%) rotate(${isAnimated ? '0deg' : '-45deg'})`,
                ...(isAnimated && { animation: `${rotateKeyframes} 5s linear infinite` }),
                zIndex: -1,
            },
            ...sx,
        }}
        {...props}
    />
);

export default GlowingBox;
