import { Box, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { AmIcon } from '~/components/Svg/AmIcon';
import { NocIcon } from '~/components/Svg/NocIcon';
import { PmIcon } from '~/components/Svg/PmIcon';
import { pxToRem } from '~/components/theme/typography';
import { ReduxStore } from '~/types/redux';

const Title = ({ amount, title }: { amount: string; title: string }) => (
    <Typography>
        <Typography
            component="span"
            sx={{
                fontSize: { lg: pxToRem(18), xs: pxToRem(16) },
                fontWeight: 700,
                lineHeight: 1.35,
            }}
        >
            {amount}{' '}
        </Typography>
        <Typography
            component="span"
            sx={{
                fontSize: { lg: pxToRem(14), xs: pxToRem(12) },
                lineHeight: 1.45,
                color: '#6F6F79',
            }}
        >
            {title}
        </Typography>
    </Typography>
);

type Shift = {
    shiftName: string;
    incompleteTasks: number;
};

type ShiftType = {
    completionPercentage: number;
    shifts: Shift[];
};

type BranchDocumentationRateStatsCardsProps = {
    selectedCareType?: string;
    assistedLivingShifts: ShiftType;
    memoryCareShifts: ShiftType;
    enhancedIndependentLivingCareShifts?: ShiftType;
    renderDateSelector: () => JSX.Element | null;
};

const BranchDocumentationRateStatsCards = ({
    selectedCareType,
    assistedLivingShifts,
    memoryCareShifts,
    enhancedIndependentLivingCareShifts,
    renderDateSelector,
}: BranchDocumentationRateStatsCardsProps) => {
    const icons = {
        AM: <AmIcon />,
        PM: <PmIcon />,
        NOC: <NocIcon />,
    };

    const data = [
        {
            title: 'Assisted Living',
            code: 'AL',
            amount: assistedLivingShifts.completionPercentage,
            shifts: assistedLivingShifts.shifts,
        },
        {
            title: 'Memory Care',
            code: 'MC',
            amount: memoryCareShifts.completionPercentage,
            shifts: memoryCareShifts.shifts,
        },
    ];

    const { company } = useSelector((state: ReduxStore) => state.session.sessionData);

    // TODO: Make this dynamic (AH-876)
    if (enhancedIndependentLivingCareShifts && company === 'LCB') {
        // if enhanced independent living exists it should come before assisted living
        data.unshift({
            title: 'Enhanced Independent Living',
            code: 'EIL',
            amount: enhancedIndependentLivingCareShifts.completionPercentage,
            shifts: enhancedIndependentLivingCareShifts.shifts,
        });
    }

    return (
        <>
            {renderDateSelector()}
            <Box
                sx={{
                    display: 'grid',
                    gap: pxToRem(24),
                    mr: { xs: pxToRem(24), lg: 0 },
                    mb: { xs: pxToRem(100), lg: 0 },
                }}
            >
                {data
                    .filter(({ code }) => selectedCareType === 'all' || code === selectedCareType)
                    .map(({ title, amount, shifts }) => (
                        <Box
                            key={title}
                            sx={{
                                display: 'grid',
                                gap: pxToRem(12),
                            }}
                        >
                            <Title title={title} amount={`${amount}%`} />
                            <Box
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
                                    gap: pxToRem(12),
                                }}
                            >
                                {shifts.map(({ shiftName, incompleteTasks }) => (
                                    <Box
                                        key={`${shiftName}${incompleteTasks}`}
                                        sx={{
                                            borderRadius: pxToRem(8),
                                            border: '1px solid',
                                            borderColor: incompleteTasks > 0 ? '#EC3232' : '#F9F9FB',
                                            backgroundColor: incompleteTasks > 0 ? '#FEF5F5' : '#F9F9FB',
                                            p: pxToRem(12),
                                            display: 'grid',
                                            placeItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: {
                                                    lg: pxToRem(20),
                                                    xs: pxToRem(18),
                                                },
                                                lineHeight: 1.2,
                                                fontWeight: 600,
                                                mb: pxToRem(8),
                                                color: incompleteTasks > 0 ? '#EC3232' : 'inherit',
                                            }}
                                        >
                                            {incompleteTasks}
                                        </Typography>
                                        <Box
                                            sx={{
                                                mb: pxToRem(4),
                                                color: incompleteTasks > 0 ? '#EC3232' : 'inherit',
                                            }}
                                        >
                                            {icons[shiftName]}
                                        </Box>
                                        <Typography
                                            sx={{
                                                fontSize: {
                                                    lg: pxToRem(14),
                                                    xs: pxToRem(12),
                                                },
                                                lineHeight: 1.45,
                                                textTransform: 'uppercase',
                                                fontWeight: 500,
                                                color: incompleteTasks > 0 ? '#EC3232' : 'inherit',
                                            }}
                                        >
                                            {shiftName}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    ))}
            </Box>
        </>
    );
};
export default BranchDocumentationRateStatsCards;
