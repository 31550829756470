import { Box, Button, Typography, useTheme } from '@mui/material';
import { useSetAtom } from 'jotai';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { PiToilet } from 'react-icons/pi';

import { startDocumentationFlowAtom } from '../atom';

interface PublicLocationCallCardProps {
    location: string;
    name: string;
    callTime: DateTime;
}

export const PublicLocationCallCard = (props: PublicLocationCallCardProps) => {
    const { palette } = useTheme();
    const [showDetails, setShowDetails] = useState(false);
    const toggleDocumentationFlow = useSetAtom(startDocumentationFlowAtom);

    const handleClick = () => {
        toggleDocumentationFlow();
    };

    return (
        <Box display="flex" width="100%" flexDirection="column" borderBottom={`1px solid ${palette.grey[100]}`}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="12px"
                padding="16px"
                boxShadow={showDetails ? '0px 4px 14.1px 0px rgba(0, 0, 0, 0.10)' : 'none'}
                onClick={() => setShowDetails((prev) => !prev)}
            >
                <Box bgcolor={palette.grey[25]} borderRadius="32px" padding="16px">
                    <PiToilet size={32} color={palette.secondary[500] as string} />
                </Box>
                <Box display="flex" flex={1} flexDirection="column">
                    <Box flex={1} display="flex" gap="4px" alignItems="center">
                        <Typography fontSize="16px" color={palette.grey[900]} textAlign="left" fontWeight={700}>
                            {props.name}
                        </Typography>
                    </Box>
                    <Typography color={palette.secondary.main} fontWeight={700}>
                        {props.location}
                    </Typography>
                </Box>
                {showDetails ? (
                    <FaChevronUp size={20} color={palette.grey[300]} />
                ) : (
                    <FaChevronDown size={20} color={palette.grey[300]} />
                )}
            </Box>

            {showDetails && (
                <Box
                    margin="16px"
                    bgcolor={palette.grey[50]}
                    border={`1px solid ${palette.grey[200]}`}
                    padding="16px"
                    borderRadius="8px"
                >
                    <Typography
                        variant="body1"
                        color={palette.grey[900]}
                        fontWeight={700}
                        lineHeight="24px"
                        fontSize="16px"
                    >
                        Fill in request documentation
                    </Typography>
                    <Typography variant="body1" color={palette.grey[900]} fontWeight={400} lineHeight="24px">
                        Call time {props.callTime.toLocaleString(DateTime.TIME_SIMPLE)}
                    </Typography>
                    <Box height="16px" />
                    <Button fullWidth onClick={handleClick}>
                        Start
                    </Button>
                </Box>
            )}
        </Box>
    );
};
