import React from 'react';

import { Selector } from '~/components/Shared/Selector';
import { ONBOARDING } from '~/constants/onboarding';
import { TaskLoadingState } from '~/types/dailyTasks';
import { TaskCompletionOptions } from '~/types/residents';

import { TaskDetails } from '../TaskDetails';
import { TaskRowContainer } from '../TaskRowContainer';

import { TaskOptions } from './TaskOptions';

type Props = {
    taskName: string;
    taskTime: string;
    taskType: 'Scheduled' | 'Added';
    taskNotes: string;
    taskCompletionOptions?: TaskCompletionOptions;
    isSelected?: boolean;
    completionOptionValue?: number;
    loadingState?: TaskLoadingState;
    userTaskNotes?: string;
    caregiver?: {
        firstName: string;
        lastName: string;
    };
    completedDateTime?: string;
    onToggle?: () => void;
    onToggleNotes: () => void;
    onToggleEditAddTaskNotes: () => void;
    onToggleRejectDialog: () => void;
    onCompletionOptionsChange: (value: number) => void;
    onConfirmTask: () => void;
};

export const ResidentRowTask = (props: Props) => {
    const {
        taskName,
        taskTime,
        taskType,
        taskNotes,
        taskCompletionOptions,
        isSelected,
        completionOptionValue,
        loadingState,
        userTaskNotes,
        caregiver,
        completedDateTime,
        onToggle,
        onToggleNotes,
        onToggleEditAddTaskNotes,
        onToggleRejectDialog,
        onCompletionOptionsChange,
        onConfirmTask,
    } = props;

    const isPrnTask = taskType === 'Added';

    return (
        <TaskRowContainer isPrn={isPrnTask}>
            {onToggle && (
                <Selector id={ONBOARDING.DOCUMENT_TASKS_PRO_TIP.TARGET} isSelected={isSelected!} onSelect={onToggle} />
            )}
            <TaskDetails
                taskName={isPrnTask ? `[PRN] ${taskName}` : taskName}
                taskTime={taskTime}
                taskNotes={taskNotes}
                userTaskNotes={userTaskNotes}
                taskCompletionOptions={taskCompletionOptions}
                caregiver={caregiver}
                completedDateTime={completedDateTime}
                onToggleNotes={onToggleNotes}
                onToggleEditAddTaskNotes={isPrnTask ? undefined : onToggleEditAddTaskNotes}
                onCompletionOptionsChange={onCompletionOptionsChange}
                completionOptionValue={completionOptionValue}
            />
            <TaskOptions
                onToggleRejectDialog={onToggleRejectDialog}
                onConfirmTask={onConfirmTask}
                loadingState={loadingState}
                isPrnTask={isPrnTask}
            />
        </TaskRowContainer>
    );
};
