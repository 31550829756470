import actionTypes from '~/redux/actionTypes';
import { AppDispatch } from '~/types/redux';

/**
 * Toggles the resident party value
 */
export const toggleResidentParty = () => (dispatch: AppDispatch) =>
    dispatch({
        type: actionTypes.RESIDENT_TOGGLE_DISPLAY_PARTY,
    });
