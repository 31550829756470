import { DateTime, Duration } from 'luxon';

import { SHIFT_SLOT_TEAM_NOTIFICATION_GROUP } from '@allie/utils/src/constants/scheduling/shift-slot.constants';

export const NOTIFY_STAFF_GROUPS: SHIFT_SLOT_TEAM_NOTIFICATION_GROUP[] = [
    SHIFT_SLOT_TEAM_NOTIFICATION_GROUP.PRN_PART_TIME,
    SHIFT_SLOT_TEAM_NOTIFICATION_GROUP.FULL_TIME,
    SHIFT_SLOT_TEAM_NOTIFICATION_GROUP.OVERTIME,
];

export const NOTIFY_STAFF_TITLE_BY_GROUP: Record<SHIFT_SLOT_TEAM_NOTIFICATION_GROUP, string> = {
    [SHIFT_SLOT_TEAM_NOTIFICATION_GROUP.PRN_PART_TIME]: 'PRN/Part-time',
    [SHIFT_SLOT_TEAM_NOTIFICATION_GROUP.FULL_TIME]: 'Full-time',
    [SHIFT_SLOT_TEAM_NOTIFICATION_GROUP.OVERTIME]: 'Overtime',
};

export type NotifyStaffTimeOption = {
    value: number;
    label?: string;
    message: string;
    caption?: string;
    color?: string;
};

export const NOTIFY_STAFF_TIME_OPTIONS: Record<
    'now' | 'pastRelative' | 'pastAbsolute' | 'pastSkip' | 'futureRelative' | 'futureAbsolute' | 'futureSkip',
    NotifyStaffTimeOption
> = {
    now: {
        value: -Infinity,
        label: 'Now',
        message: 'will be notified',
        caption: 'immediately',
    },
    pastRelative: {
        value: 0,
        message: 'were notified',
    },
    pastAbsolute: {
        value: 0,
        message: 'were notified at',
    },
    pastSkip: {
        value: 0,
        message: 'were',
        caption: 'skipped',
        color: 'error.600',
    },
    futureRelative: {
        value: 0,
        message: 'will be notified',
    },
    futureAbsolute: {
        value: 0,
        message: 'will be notified at',
    },
    futureSkip: {
        value: Infinity,
        label: 'Skip',
        message: 'will be',
        caption: 'skipped',
        color: 'error.600',
    },
};

// Minimum is 2 (previous group option + next interval option)
export const NOTIFY_STAFF_DYNAMIC_OPTIONS_TO_SHOW = 3;

export const NOTIFY_STAFF_TIME_FORMAT_BY_DAY = {
    same: 'h:mm a',
    yesterday: "'Yesterday', h:mm a",
    today: "'Today', h:mm a",
    tomorrow: "'Tomorrow', h:mm a",
    other: 'MMM d, h:mm a',
};

// Arbitrary, just for looking good in the UI, but we may want to not round at all in the future
const roundToNextHour = (time: DateTime) => time.plus({ hour: 1 }).set({ minute: 0, second: 0, millisecond: 0 });

const getNotifyStaffDynamicOptions = (from: DateTime, number: number, interval: Duration) => {
    const startOfNextHour = roundToNextHour(from);
    const intervalMillis = interval.toMillis();
    return Array.from({ length: number }, (_, index) => startOfNextHour.plus(intervalMillis * index));
};

export const getNotifyStaffDefaultValues = (interval: Duration) => [
    NOTIFY_STAFF_TIME_OPTIONS.now.value, // First option is 'now'...
    ...getNotifyStaffDynamicOptions(
        DateTime.now(),
        NOTIFY_STAFF_GROUPS.length - 1, // ...and remaining ones are ascending times based on the...
        interval // ...community-set interval
    ).map((timeOption) => timeOption.toMillis()),
];

export const getNotifyStaffTimeOptions = (interval: Duration) => {
    const now = DateTime.now();
    const tomorrow = now.plus({ day: 1 });

    // "Worst case" is: first group has all NOTIFY_STAFF_DYNAMIC_OPTIONS_TO_SHOW options and each following group
    // has the previous group's selected option plus at most NOTIFY_STAFF_DYNAMIC_OPTIONS_TO_SHOW - 1 new options
    const numberOfOptions = NOTIFY_STAFF_GROUPS.length * (NOTIFY_STAFF_DYNAMIC_OPTIONS_TO_SHOW - 1) + 1;
    const dateTimeValueOptions = getNotifyStaffDynamicOptions(now, numberOfOptions, interval);

    const areAllSameDay = dateTimeValueOptions.every((option) => option.hasSame(now, 'day'));

    return [
        NOTIFY_STAFF_TIME_OPTIONS.now,
        ...dateTimeValueOptions.map((timeOption) => {
            let format: keyof typeof NOTIFY_STAFF_TIME_FORMAT_BY_DAY;

            if (areAllSameDay) format = 'same';
            else if (timeOption.hasSame(now, 'day')) format = 'today';
            else if (timeOption.hasSame(tomorrow, 'day')) format = 'tomorrow';
            else format = 'other';

            const label = timeOption.toFormat(NOTIFY_STAFF_TIME_FORMAT_BY_DAY[format]);

            return {
                ...(format === 'same' || format === 'other'
                    ? NOTIFY_STAFF_TIME_OPTIONS.futureAbsolute
                    : NOTIFY_STAFF_TIME_OPTIONS.futureRelative),
                value: timeOption.toMillis(),
                label: label,
                caption: label,
            } satisfies NotifyStaffTimeOption;
        }),
        NOTIFY_STAFF_TIME_OPTIONS.futureSkip,
    ];
};

export const notifyStaffTimeValueToDateTime = (timeMillis: number) => {
    if (timeMillis === NOTIFY_STAFF_TIME_OPTIONS.now.value) return DateTime.now();
    if (timeMillis === NOTIFY_STAFF_TIME_OPTIONS.futureSkip.value) return null;
    return DateTime.fromMillis(timeMillis) as DateTime<true>;
};
