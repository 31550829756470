import { SESSION_DEFAULT_STATE } from '~/constants/redux';
import actionTypes from '~/redux/actionTypes';
import { Action, SessionState } from '~/types/redux';

const session = (state: SessionState = SESSION_DEFAULT_STATE, action: Action) => {
    switch (action.type) {
        case actionTypes.SESSION_VALIDATE_SESSION:
        case actionTypes.SESSION_SIGN_IN:
        case actionTypes.SESSION_SIGN_OUT:
            return {
                ...state,
                isSignedIn: action.payload.isSignedIn,
                sessionData: action.payload.sessionData,
            };
        case actionTypes.SESSION_UPDATE_TIMEZONE:
            return {
                ...state,
                timezone: action.payload,
            };
        case actionTypes.SESSION_UPDATE_PRIOR_PARENT_PAGE:
            return {
                ...state,
                priorParentPage: action.payload,
            };
        case actionTypes.SESSION_POSTHOG_LOADED:
            return {
                ...state,
                isPostHogLoaded: action.payload,
            };
        default:
            return state;
    }
};

export default session;
