import { useAtom, useSetAtom } from 'jotai';
import React, { useState } from 'react';
import { PiSmileyWink } from 'react-icons/pi';

import { useAppDispatch } from '~/constants/redux';
import { signOut } from '~/redux/actions/session';

import { isAssignmentsModalOpenAtom, isOnCurrentShiftAtom, isOnShiftConfirmModalOpenAtom } from '../atom';
import { AssignmentsModal } from '../modal/AssignmentsModal';
import { MotivationMessageModal } from '../modal/MotivationMessageModal';
import { OnShiftConfirmationModal } from '../modal/OnShiftConfirmationModal';

export const NotificationFlow = () => {
    const dispatch = useAppDispatch();
    const setIsOnCurrentShift = useSetAtom(isOnCurrentShiftAtom);

    const [isOnShiftConfirmModalOpen, toggleOnShiftConfirmModal] = useAtom(isOnShiftConfirmModalOpenAtom);
    const [isAssignmentsModalOpen, toggleAssignmentsModal] = useAtom(isAssignmentsModalOpenAtom);
    const [isSuccessMessageModalOpen, toggleSuccessMessageModal] = useState(false);

    const handleConfirm = () => {
        toggleOnShiftConfirmModal();
        toggleAssignmentsModal();
        setIsOnCurrentShift(true);
    };

    const handleAssignmentSubmit = () => {
        toggleAssignmentsModal();
        toggleSuccessMessageModal(true);
    };

    const handleSignout = async () => {
        await dispatch(signOut());
    };

    return (
        <>
            <OnShiftConfirmationModal
                isOpen={isOnShiftConfirmModalOpen}
                onClose={() => {
                    toggleOnShiftConfirmModal();
                    setIsOnCurrentShift(false);
                }}
                onConfirm={handleConfirm}
                onLogout={handleSignout}
            />
            <AssignmentsModal
                isOpen={isAssignmentsModalOpen}
                onClose={() => {
                    toggleAssignmentsModal();
                    setIsOnCurrentShift(false);
                }}
                onSubmit={handleAssignmentSubmit}
            />
            <MotivationMessageModal
                isOpen={isSuccessMessageModalOpen}
                onContinue={() => toggleSuccessMessageModal(false)}
                title="You got this!"
                description="Your hard work and care makes all the difference."
                buttonText="I know"
                icon={PiSmileyWink}
            />
        </>
    );
};
