import { FormControl, MenuItem, Select } from '@mui/material';
import { useAtom } from 'jotai';
import React, { useMemo } from 'react';

import { useGetTeams } from '~/scheduling/api/queries/teams/getTeams';

import { teamIdAtom } from '../atoms';

const TeamSelector = () => {
    const [teamId, setTeamId] = useAtom(teamIdAtom);

    const { data: teamData } = useGetTeams();
    const teams = teamData?.teams ?? [];

    const teamOptions = useMemo(() => teams.map(({ id, name }) => ({ value: id, label: name })), [teams]);

    React.useEffect(() => {
        teamData?.teams.length && !teamId && setTeamId(teamData.teams[0].id);
    }, [teamData, setTeamId]);

    if (!teamOptions.length) return null;

    return (
        <FormControl size="small">
            <Select
                defaultValue={teamOptions[0].value}
                value={teamId}
                onChange={(e) => setTeamId(Number(e.target.value))}
                sx={{ fontWeight: 500 }}
            >
                {teamOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default TeamSelector;
