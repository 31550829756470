import { Box, Chip, Skeleton, Typography, styled, useTheme } from '@mui/material';
import { range } from 'lodash';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { PiCheckCircleFill, PiCircle } from 'react-icons/pi';

import { useGetStaff } from '~/scheduling/api/queries/staff/getStaff';
import { formatTimeDiff } from '~/scheduling/utils/dates';

import { ShiftApproval } from '../../types';

const FormWrapper = styled(Box)(({ theme }) => ({
    borderStyle: 'solid',
    borderColor: theme.palette.grey[200],
    borderWidth: '1px 0 1px 0',
    flex: 1,
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
    width: '100%',
    borderRadius: '8px',
    bgcolor: 'white',
    overflow: 'auto',
}));

interface StaffCardProps {
    selected?: boolean;
    request: ShiftApproval.Request;
    onClick: VoidFunction;
}

const StaffCard = ({ selected, request, onClick }: StaffCardProps) => {
    const { palette } = useTheme();

    const { data: staff, isLoading, error } = useGetStaff(request.staffId);

    if (error || (!staff && !isLoading)) return;

    return (
        <Box
            width="100%"
            bgcolor={selected ? (palette.primary[50] as string) : 'white'}
            padding="16px 12px"
            display="flex"
            alignItems="center"
            gap="12px"
            borderBottom={`1px solid ${palette.grey[200]}`}
            onClick={onClick}
        >
            {selected ? (
                <PiCheckCircleFill
                    style={{ flexShrink: 0 }}
                    aria-checked="true"
                    size={24}
                    color={palette.primary[500] as string}
                />
            ) : (
                <PiCircle
                    style={{ flexShrink: 0 }}
                    aria-checked="false"
                    size={24}
                    color={palette.primary[500] as string}
                />
            )}
            <Box flex={1}>
                <Box display="flex" alignItems="center" gap="4px">
                    {isLoading ? (
                        <>
                            <Skeleton width="110px" height="20px" />
                            <Skeleton width="81px" height="20px" />
                        </>
                    ) : (
                        <>
                            <Typography variant="body1" fontWeight={700} fontSize="16px" color={palette.grey[900]}>
                                {staff!.name}
                            </Typography>
                            <Typography
                                variant="body1"
                                fontWeight={500}
                                fontSize="13px"
                                color={palette.primary[500] as string}
                            >
                                ({formatTimeDiff(request.createdAt.diffNow('hours').hours * -1)} ago)
                            </Typography>
                        </>
                    )}
                </Box>
                <Box marginTop="6px" display="flex" alignItems="center" gap="4px" flexWrap="wrap">
                    {isLoading
                        ? range(3).map((i) => <Skeleton key={i} width="80px" height="26px" />)
                        : request.flags.map((flag, index) => (
                              <Chip sx={{ borderRadius: '100px' }} key={index} size="small" label={flag.text} />
                          ))}
                </Box>
            </Box>
        </Box>
    );
};

interface CardFormProps {
    multiSelect: boolean;
    requests: ShiftApproval.Request[];
    form: UseFormReturn<ShiftApproval.FormData>;
}

export const CardForm = (props: CardFormProps) => {
    const getCurrentValue = (value: number | null, prevValue: number | null | number[]) => {
        if (Array.isArray(prevValue) && value) {
            return prevValue.includes(value) ? prevValue.filter((v) => v !== value) : [...prevValue, value];
        }

        return value === prevValue ? null : value;
    };

    return (
        <>
            {props.multiSelect ? (
                <Controller
                    name="multipleRequestsIds"
                    control={props.form.control}
                    render={({ field }) => (
                        <FormWrapper>
                            {props.requests.map((request) => (
                                <StaffCard
                                    key={request.id}
                                    request={request}
                                    selected={field.value?.includes(request.id)}
                                    onClick={() => field.onChange(getCurrentValue(request.id, field.value))}
                                />
                            ))}
                        </FormWrapper>
                    )}
                />
            ) : (
                <Controller
                    name="singleRequestId"
                    control={props.form.control}
                    render={({ field }) => (
                        <FormWrapper>
                            {props.requests.map((request) => (
                                <StaffCard
                                    key={request.id}
                                    request={request}
                                    selected={field.value === request.id}
                                    onClick={() => field.onChange(getCurrentValue(request.id, field.value))}
                                />
                            ))}
                        </FormWrapper>
                    )}
                />
            )}
        </>
    );
};
