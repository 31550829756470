import React from 'react';
import { useSelector } from 'react-redux';

import Loading from '~/components/Shared/Loading';
import { OVERALL_SECTIONS_MAP, Section } from '~/pages/OperationsDashboard/constants';
import { useBranchDataItems } from '~/pages/OperationsDashboard/data-helpers/branch';
import { useOverallDataItems } from '~/pages/OperationsDashboard/data-helpers/overall';
import { OperationsLevel, OperationsModuleData, OperationsPeriod, SelectOptionsId } from '~/types/operationsDashboard';
import { ReduxStore } from '~/types/redux';

import { DateGroupSelector } from '../DateGroupSelector';

import EmptySectionModule from './EmptySectionModule';
import { SectionModule } from './SectionModule';

type SectionModuleProps = {
    moduleData: OperationsModuleData;
    level: OperationsLevel;
    operationsPeriod: OperationsPeriod;
    regionId: SelectOptionsId;
    branchId: SelectOptionsId;
    careType: string;
    dataType: Section;
    miniDisplay?: 'chart' | 'table';
    onClick?: () => void;
};

export const SectionDataModule = ({
    moduleData,
    level,
    operationsPeriod,
    regionId,
    branchId,
    careType,
    dataType,
    miniDisplay,
    onClick,
}: SectionModuleProps) => {
    const sectionTitle = OVERALL_SECTIONS_MAP[dataType].title;

    const { status: dataStatus, overallData, branchData, branchesByRegion } = moduleData;

    const { selectedStartDate } = useSelector((state: ReduxStore) => state.opsDashboard);

    const overallDateOptions = moduleData.dateOptions;

    const { OverallChartComponent, overallChartProps, OverallTableComponent, overallTableProps, overallStat } =
        useOverallDataItems(overallData, branchesByRegion, dataType, level, regionId, selectedStartDate);

    const { BranchChartComponent, branchChartProps, branchDateOptions, branchStat, renderTableComponent } =
        useBranchDataItems(branchData, dataType, branchId, careType, selectedStartDate);

    if (dataStatus === 'loading') {
        return <Loading />;
    }

    if (dataStatus === 'error') {
        return null;
    }

    if (
        (level !== 'branch' &&
            (!overallChartProps ||
                !OverallChartComponent ||
                !overallTableProps ||
                !OverallTableComponent ||
                !overallDateOptions)) ||
        (level === 'branch' && (!branchChartProps || !BranchChartComponent || !branchDateOptions))
    ) {
        return <EmptySectionModule sectionTitle={sectionTitle} miniDisplay={miniDisplay} />;
    }
    const renderDateSelector = () =>
        selectedStartDate ? (
            <DateGroupSelector
                sectionTitle={sectionTitle}
                selectedDate={selectedStartDate}
                dateOptions={(level === 'branch' && branchDateOptions ? branchDateOptions : overallDateOptions) ?? []}
                selectedTimePeriod={operationsPeriod}
            />
        ) : null;

    let renderChart = () => <></>;
    let renderTable = () => <></>;

    if (level !== 'branch' && OverallChartComponent && OverallTableComponent) {
        renderChart = () => <OverallChartComponent {...overallChartProps} />;
        renderTable = () => (
            <OverallTableComponent
                {...overallTableProps}
                timePeriod={operationsPeriod}
                renderDateSelector={renderDateSelector}
            />
        );
    } else if (level === 'branch' && BranchChartComponent) {
        renderChart = () => <BranchChartComponent {...branchChartProps} />;
        renderTable = () => renderTableComponent(renderDateSelector);
    }

    return (
        <SectionModule
            sectionTitle={sectionTitle}
            dataType={dataType}
            operationsPeriod={operationsPeriod}
            overallStat={String(level === 'branch' ? branchStat : overallStat)}
            renderChart={renderChart}
            renderTable={renderTable}
            miniDisplay={miniDisplay}
            onClick={onClick}
        />
    );
};
