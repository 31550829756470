import { ComponentOverride } from '.';

export const MuiTextField: ComponentOverride<'MuiTextField'> = {
    defaultProps: {
        variant: 'outlined',
    },
    styleOverrides: {
        root: ({ theme: { palette } }) => ({
            '& .MuiInputBase-root': {
                backgroundColor: 'white',
                fontWeight: 400,
                padding: 0,
                borderRadius: 8,
            },
            '& .MuiInputBase-input': {
                '&.MuiInputBase-inputSizeSmall': {
                    padding: '8px 10px',
                },
                '&::placeholder': {
                    color: palette.grey[300],
                    opacity: 1,
                },
            },
            '& .MuiInputLabel-root:not(.Mui-focused, .MuiInputLabel-shrink)': {
                transform: 'translate(10px, 10px) scale(1)',
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: palette.grey[100],
                '&:hover': {
                    borderColor: palette.primary.main,
                },
            },
            '& .MuiInputBase-adornedStart': {
                paddingLeft: '12px',
            },
            '& .MuiInputAdornment-root': {
                margin: 0,
            },
            '& .MuiFormHelperText-root': {
                margin: '4px 10px 0',
            },
        }),
    },
};
