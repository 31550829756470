import { AvatarGroup, Box, Typography } from '@mui/material';
import { uniqBy } from 'lodash';
import pluralize from 'pluralize';
import React from 'react';

import { pxToRem } from '~/components/theme/typography';

import PersonAvatar from '../PersonAvatar';

function formatDate(date: string) {
    const [year, month, day] = date.split('-');
    return `${month}/${day}/${year}`;
}

export default function RefusedMedicationRow({ row }: { row: RefusedMedicationRowData }) {
    const recordedByUsers = uniqBy(row.medications.map((medication) => medication.recordedByUsers).flat(), 'id');
    return (
        <Box>
            <Box flexDirection="column" gap="4px">
                {row.medications.map((medication, index) => (
                    <Box key={`${row.localDate}-${index}`} flexDirection="row">
                        <Typography
                            fontSize={pxToRem(12)}
                            fontWeight={700}
                            lineHeight={1.7}
                            mb={pxToRem(2)}
                            component="span"
                            textAlign="left"
                        >
                            {medication.name}
                        </Typography>
                        <Typography
                            fontSize={pxToRem(12)}
                            fontWeight={700}
                            lineHeight={1.7}
                            mb={pxToRem(2)}
                            ml="2px"
                            component="span"
                            textAlign="left"
                            color="#6F6F79"
                        >
                            ({medication.quantity} {pluralize('dose', medication.quantity)})
                        </Typography>
                    </Box>
                ))}
                <AvatarGroup
                    max={4}
                    sx={[
                        recordedByUsers.length > 4 && {
                            '& .MuiAvatarGroup-avatar:first-of-type': {
                                width: 20,
                                height: 20,
                                fontSize: pxToRem(14),
                                lineHeight: 1.7,
                                color: '#2C2D3B',
                                backgroundColor: 'transparent',
                                ml: pxToRem(-4),
                            },
                        },
                    ]}
                >
                    {recordedByUsers.map((caregiver, index) => (
                        <PersonAvatar name={caregiver.name} key={`${caregiver.name}-${index}`} />
                    ))}
                </AvatarGroup>
            </Box>
            <Typography
                fontSize={{
                    xs: pxToRem(10),
                    md: pxToRem(11),
                }}
                lineHeight={1.3}
                color="#6F6F79"
                display="inline"
            >
                {formatDate(row.localDate)}
            </Typography>
        </Box>
    );
}
