import { useCallback } from 'react';

import { api } from '~/api';
import { queryClient, useBranchId, useErrorHandledMutation, useToken } from '~/api/common';
import { CreateShiftRequest } from '~/scheduling/api/types/shift-requests/createShiftRequest';

export const useCreateShiftRequest = () => {
    const branchId = useBranchId()!;
    const token = useToken();

    const mutationFn = useCallback(
        async (data: CreateShiftRequest.Params) => {
            const options = { headers: { Authorization: token } };
            await api.post(`/scheduling/${branchId}/shift-requests/`, data, options);
        },
        [branchId, token]
    );

    const onSuccess = useCallback(async () => {
        await queryClient.invalidateQueries({ queryKey: ['my-schedule', branchId] }); // Also invalidates 'other-staff'
    }, [branchId]);

    return useErrorHandledMutation({ mutationFn, onSuccess });
};
