import { Box, useMediaQuery } from '@mui/material';
import React from 'react';
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { CategoricalChartState } from 'recharts/types/chart/types';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { ContentType } from 'recharts/types/component/Tooltip';
import { AxisDomain } from 'recharts/types/util/types';

import { pxToRem } from '~/components/theme/typography';

import CustomMultilineChartTooltip from './CustomMultilineChartTooltip';

export const LINE_COLORS = ['#006B75', '#FA9C7A', '#87F5FB', '#48E5C2', '#F7EE7F', '#A7ABDD', '#C1DBB3', '#7EBC89'];

interface StandardDataset {
    startDate: string;
    value: {
        key: string;
        value: number;
    }[];
}

export interface CustomMultilineChartProps {
    data: StandardDataset[];
    selectedStartDate: string | null;
    onPointClick: (period: string) => void;
    selectedLineKey?: string | number;
    lineColors?: string[];
    legend?: boolean;
    unitOfMeasurement?: string;
    tooltipComponent?: ContentType<ValueType, NameType>;
    yDomain?: AxisDomain;
}

const prepareChartData = (data: StandardDataset[]) =>
    data.map((dataset) => ({
        period: dataset.startDate,
        ...dataset.value.reduce(
            (acc, { key, value }) => ({
                ...acc,
                [key]: value,
            }),
            {}
        ),
    }));

const CustomMultilineChart = ({
    data,
    selectedStartDate,
    selectedLineKey,
    lineColors,
    legend,
    onPointClick,
    unitOfMeasurement = '',
    tooltipComponent,
    yDomain,
}: CustomMultilineChartProps) => {
    const isPercentage = unitOfMeasurement === '%';
    const isMobile = useMediaQuery('(max-width:1024px)');

    const yAxisWidth = {
        mobile: {
            hasPercentage: 35,
            noPercentage: 25,
        },
        desktop: {
            hasPercentage: 45,
            noPercentage: 35,
        },
    };

    const getYAxisWidth = (mobile: boolean) => {
        if (mobile) {
            return isPercentage ? yAxisWidth.mobile.hasPercentage : yAxisWidth.mobile.noPercentage;
        }

        return isPercentage ? yAxisWidth.desktop.hasPercentage : yAxisWidth.desktop.noPercentage;
    };
    const chartData = prepareChartData(data);

    const handlePointClick = (res: CategoricalChartState) => {
        if (res.activeLabel) {
            onPointClick(res.activeLabel);
        }
    };

    return (
        <Box width={1} height={{ xs: pxToRem(158), lg: pxToRem(336) }}>
            <ResponsiveContainer width="99%" height="100%">
                <LineChart data={chartData} onClick={handlePointClick}>
                    <CartesianGrid strokeDasharray="4 4" strokeOpacity={0.5} />
                    <XAxis
                        dataKey="period"
                        fontSize={isMobile ? 9 : 10}
                        fontFamily="Inter"
                        color="#6F6F79"
                        axisLine={false}
                        tickLine={false}
                        tickMargin={7}
                        tickCount={8}
                        tickFormatter={(date: string) => {
                            if (date.split('-').length < 3) return date;

                            const [, month, day] = date.split('-');
                            return `${month}/${day}`;
                        }}
                    />
                    <YAxis
                        fontSize={isMobile ? 10 : 12}
                        fontFamily="Inter"
                        color="#6F6F79"
                        axisLine={false}
                        tickLine={false}
                        domain={yDomain ?? (isPercentage ? [0, 100] : ['auto', 'auto'])}
                        tickMargin={isMobile ? 3 : 6}
                        tickFormatter={(value) => `${value}${unitOfMeasurement}`}
                        width={getYAxisWidth(isMobile)}
                    />
                    <Tooltip
                        content={
                            tooltipComponent ?? <CustomMultilineChartTooltip unitOfMeasurement={unitOfMeasurement} />
                        }
                    />
                    {data[0]?.value.map(({ key }, i) => (
                        <Line
                            key={key}
                            type="linear"
                            strokeDasharray={key.toString() === selectedLineKey?.toString() ? '3 3' : '0 0'}
                            dataKey={key}
                            stroke={(lineColors ?? LINE_COLORS)[i % (lineColors ?? LINE_COLORS).length]}
                            strokeWidth={2}
                            dot={{ r: 2 }}
                            pointerEvents="none"
                        />
                    ))}
                    {selectedStartDate && !isMobile && (
                        <ReferenceLine
                            x={selectedStartDate}
                            stroke="#C2DBDE"
                            ifOverflow="extendDomain"
                            strokeWidth={2}
                        />
                    )}
                    {legend && (
                        <Legend
                            iconSize={12}
                            iconType="circle"
                            wrapperStyle={{
                                fontSize: '11px',
                                fontFamily: 'Inter',
                                lineHeight: 1.35,
                            }}
                            formatter={(value) => <span style={{ color: '#6F6F79' }}>{value}</span>}
                        />
                    )}
                </LineChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default CustomMultilineChart;
