import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useBranchesByRegionQuery } from '~/api/queries/companyInfo';
import { useCaregiverPerformanceDetails } from '~/api/queries/dashboard/caregiverPerformance';
import { BranchSelector } from '~/components/Filtering';
import Loading from '~/components/Shared/Loading';
import { pxToRem } from '~/components/theme/typography';
import PageStructure from '~/pages/PageStructure';
import { ReduxStore } from '~/types/redux';

import { OverviewModule } from './OverviewModule';
import { PerformanceTableModule } from './PerformanceTableModule';

const PageTopFixedBar = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        width: '100%',
        backgroundColor: { xs: '#006B75', md: 'unset' },
        position: { xs: 'fixed', md: 'unset' },
        top: pxToRem(48), // Avoid app bar
        left: 0,
        right: 0,
        zIndex: 2,
        px: { xs: pxToRem(12), md: pxToRem(24) },
        pt: 'env(safe-area-inset-top)', // Avoid OS status bar
        pb: { xs: pxToRem(8), md: 0 },
    })
);

const OverviewContainer = ({ children }) => (
    <Box
        sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: pxToRem(24),
        }}
    >
        {children}
    </Box>
);

const PerformanceDashboard = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // TODO: figure out best approach to handling picking dates
    const startDate = Date();
    const endDate = Date();

    const sessionData = useSelector((state: ReduxStore) => state.session.sessionData);
    const { branchId, regionId, companyId } = sessionData;

    const [selectedBranchId, setSelectedBranchId] = useState<number>(branchId ?? 0);

    const {
        data: branchesByRegionData,
        isLoading: branchesByRegionIsLoading,
        isError: branchesByRegionIsError,
    } = useBranchesByRegionQuery({ branchId, regionId, companyId });

    const branchesWithAccess = branchesByRegionData?.branches.filter((branch) => branch.hasAccess) ?? [];

    const params =
        startDate && endDate
            ? {
                  branchId: selectedBranchId,
                  startPeriod: startDate && moment(startDate).format('YYYY-MM-DD'),
                  endPeriod: endDate && moment(endDate).format('YYYY-MM-DD'),
              }
            : { branchId: selectedBranchId };

    const {
        isLoading: caregiverPerformanceIsLoading,
        isError: caregiverPerformanceIsError,
        data: caregiverPerformanceData,
    } = useCaregiverPerformanceDetails(params);

    if (caregiverPerformanceIsLoading) {
        return <Loading />;
    }

    return (
        <>
            {branchesWithAccess.length > 1 && (
                <>
                    <PageTopFixedBar>
                        {isMobile && <Box sx={{ height: pxToRem(8) }} /* Extra padding */ />}
                        <BranchSelector
                            value={selectedBranchId}
                            options={branchesWithAccess.map((branch) => ({
                                label: branch.branchName,
                                value: branch.branchId.toString(),
                            }))}
                            onChange={(newBranchId) => setSelectedBranchId(newBranchId)}
                            isHeader
                            fullWidth
                        />
                    </PageTopFixedBar>
                    {isMobile && <Box sx={{ height: pxToRem(64) }} /* Spacer for the fixed header */ />}
                </>
            )}
            <PageStructure>
                {caregiverPerformanceData && (
                    <Box sx={{ m: isMobile ? pxToRem(12) : pxToRem(24) }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: isMobile ? 'column' : 'row',
                                gap: pxToRem(24),
                            }}
                        >
                            <OverviewContainer>
                                <OverviewModule
                                    title="Assisted Living Overall"
                                    residentsRatio={caregiverPerformanceData.stats.al.overall.residentsRatio}
                                    tasksRatio={caregiverPerformanceData.stats.al.overall.scheduledTasksRatio}
                                    billableMinutesRatio={
                                        caregiverPerformanceData.stats.al.overall.billableMinutesRatio
                                    }
                                    completedTaskCount={caregiverPerformanceData.stats.al.overall.completedTaskCount}
                                    refusedTaskCount={caregiverPerformanceData.stats.al.overall.refusedTaskCount}
                                    otherIncompleteTaskCount={
                                        caregiverPerformanceData.stats.al.overall.otherIncompleteTaskCount
                                    }
                                    undocumentedTaskCount={
                                        caregiverPerformanceData.stats.al.overall.undocumentedTaskCount
                                    }
                                    caregiverShiftsCount={
                                        caregiverPerformanceData.stats.al.overall.caregiverShiftsCount
                                    }
                                />
                                <OverviewModule
                                    title="AL AM"
                                    residentsRatio={caregiverPerformanceData.stats.al.shifts.am.residentsRatio}
                                    tasksRatio={caregiverPerformanceData.stats.al.shifts.am.scheduledTasksRatio}
                                    billableMinutesRatio={
                                        caregiverPerformanceData.stats.al.shifts.am.billableMinutesRatio
                                    }
                                    completedTaskCount={caregiverPerformanceData.stats.al.shifts.am.completedTaskCount}
                                    refusedTaskCount={caregiverPerformanceData.stats.al.shifts.am.refusedTaskCount}
                                    otherIncompleteTaskCount={
                                        caregiverPerformanceData.stats.al.shifts.am.otherIncompleteTaskCount
                                    }
                                    undocumentedTaskCount={
                                        caregiverPerformanceData.stats.al.shifts.am.undocumentedTaskCount
                                    }
                                    caregiverShiftsCount={
                                        caregiverPerformanceData.stats.al.shifts.am.caregiverShiftsCount
                                    }
                                />
                                <OverviewModule
                                    title="AL PM"
                                    residentsRatio={caregiverPerformanceData.stats.al.shifts.mid.residentsRatio}
                                    tasksRatio={caregiverPerformanceData.stats.al.shifts.mid.scheduledTasksRatio}
                                    billableMinutesRatio={
                                        caregiverPerformanceData.stats.al.shifts.mid.billableMinutesRatio
                                    }
                                    completedTaskCount={caregiverPerformanceData.stats.al.shifts.mid.completedTaskCount}
                                    refusedTaskCount={caregiverPerformanceData.stats.al.shifts.mid.refusedTaskCount}
                                    otherIncompleteTaskCount={
                                        caregiverPerformanceData.stats.al.shifts.mid.otherIncompleteTaskCount
                                    }
                                    undocumentedTaskCount={
                                        caregiverPerformanceData.stats.al.shifts.mid.undocumentedTaskCount
                                    }
                                    caregiverShiftsCount={
                                        caregiverPerformanceData.stats.al.shifts.mid.caregiverShiftsCount
                                    }
                                />
                                <OverviewModule
                                    title="AL NOC"
                                    residentsRatio={caregiverPerformanceData.stats.al.shifts.pm.residentsRatio}
                                    tasksRatio={caregiverPerformanceData.stats.al.shifts.pm.scheduledTasksRatio}
                                    billableMinutesRatio={
                                        caregiverPerformanceData.stats.al.shifts.pm.billableMinutesRatio
                                    }
                                    completedTaskCount={caregiverPerformanceData.stats.al.shifts.pm.completedTaskCount}
                                    refusedTaskCount={caregiverPerformanceData.stats.al.shifts.pm.refusedTaskCount}
                                    otherIncompleteTaskCount={
                                        caregiverPerformanceData.stats.al.shifts.pm.otherIncompleteTaskCount
                                    }
                                    undocumentedTaskCount={
                                        caregiverPerformanceData.stats.al.shifts.pm.undocumentedTaskCount
                                    }
                                    caregiverShiftsCount={
                                        caregiverPerformanceData.stats.al.shifts.pm.caregiverShiftsCount
                                    }
                                />
                            </OverviewContainer>
                            <OverviewContainer>
                                <OverviewModule
                                    title="Memory Care Overall"
                                    residentsRatio={caregiverPerformanceData.stats.mc.overall.residentsRatio}
                                    tasksRatio={caregiverPerformanceData.stats.mc.overall.scheduledTasksRatio}
                                    billableMinutesRatio={
                                        caregiverPerformanceData.stats.mc.overall.billableMinutesRatio
                                    }
                                    completedTaskCount={caregiverPerformanceData.stats.mc.overall.completedTaskCount}
                                    refusedTaskCount={caregiverPerformanceData.stats.mc.overall.refusedTaskCount}
                                    otherIncompleteTaskCount={
                                        caregiverPerformanceData.stats.mc.overall.otherIncompleteTaskCount
                                    }
                                    undocumentedTaskCount={
                                        caregiverPerformanceData.stats.mc.overall.undocumentedTaskCount
                                    }
                                    caregiverShiftsCount={
                                        caregiverPerformanceData.stats.mc.overall.caregiverShiftsCount
                                    }
                                />
                                <OverviewModule
                                    title="MC AM"
                                    residentsRatio={caregiverPerformanceData.stats.mc.shifts.am.residentsRatio}
                                    tasksRatio={caregiverPerformanceData.stats.mc.shifts.am.scheduledTasksRatio}
                                    billableMinutesRatio={
                                        caregiverPerformanceData.stats.mc.shifts.am.billableMinutesRatio
                                    }
                                    completedTaskCount={caregiverPerformanceData.stats.mc.shifts.am.completedTaskCount}
                                    refusedTaskCount={caregiverPerformanceData.stats.mc.shifts.am.refusedTaskCount}
                                    otherIncompleteTaskCount={
                                        caregiverPerformanceData.stats.mc.shifts.am.otherIncompleteTaskCount
                                    }
                                    undocumentedTaskCount={
                                        caregiverPerformanceData.stats.mc.shifts.am.undocumentedTaskCount
                                    }
                                    caregiverShiftsCount={
                                        caregiverPerformanceData.stats.mc.shifts.am.caregiverShiftsCount
                                    }
                                />
                                <OverviewModule
                                    title="MC PM"
                                    residentsRatio={caregiverPerformanceData.stats.mc.shifts.mid.residentsRatio}
                                    tasksRatio={caregiverPerformanceData.stats.mc.shifts.mid.scheduledTasksRatio}
                                    billableMinutesRatio={
                                        caregiverPerformanceData.stats.mc.shifts.mid.billableMinutesRatio
                                    }
                                    completedTaskCount={caregiverPerformanceData.stats.mc.shifts.mid.completedTaskCount}
                                    refusedTaskCount={caregiverPerformanceData.stats.mc.shifts.mid.refusedTaskCount}
                                    otherIncompleteTaskCount={
                                        caregiverPerformanceData.stats.mc.shifts.mid.otherIncompleteTaskCount
                                    }
                                    undocumentedTaskCount={
                                        caregiverPerformanceData.stats.mc.shifts.mid.undocumentedTaskCount
                                    }
                                    caregiverShiftsCount={
                                        caregiverPerformanceData.stats.mc.shifts.mid.caregiverShiftsCount
                                    }
                                />
                                <OverviewModule
                                    title="MC NOC"
                                    residentsRatio={caregiverPerformanceData.stats.mc.shifts.pm.residentsRatio}
                                    tasksRatio={caregiverPerformanceData.stats.mc.shifts.pm.scheduledTasksRatio}
                                    billableMinutesRatio={
                                        caregiverPerformanceData.stats.mc.shifts.pm.billableMinutesRatio
                                    }
                                    completedTaskCount={caregiverPerformanceData.stats.mc.shifts.pm.completedTaskCount}
                                    refusedTaskCount={caregiverPerformanceData.stats.mc.shifts.pm.refusedTaskCount}
                                    otherIncompleteTaskCount={
                                        caregiverPerformanceData.stats.mc.shifts.pm.otherIncompleteTaskCount
                                    }
                                    undocumentedTaskCount={
                                        caregiverPerformanceData.stats.mc.shifts.pm.undocumentedTaskCount
                                    }
                                    caregiverShiftsCount={
                                        caregiverPerformanceData.stats.mc.shifts.pm.caregiverShiftsCount
                                    }
                                />
                            </OverviewContainer>
                        </Box>
                        <PerformanceTableModule
                            rows={caregiverPerformanceData.caregiverPerformance.map((caregiver) => ({
                                firstName: caregiver.caregiverFirstName,
                                lastName: caregiver.caregiverLastName,
                                shiftName: caregiver.shiftName,
                                shiftCount: caregiver.shiftCount,
                                residentsDocumented: caregiver.residentsDocumented,
                                tasksDocumented: caregiver.tasksDocumented,
                                billableMinutesDocumented: caregiver.billableMinutesDocumented,
                                tasksCompleted: caregiver.completedTasksCount,
                                tasksRefused: caregiver.refusedTasksCount,
                                tasksOtherIncomplete: caregiver.otherIncompleteTaskCount,
                            }))}
                        />
                    </Box>
                )}
            </PageStructure>
        </>
    );
};

export default PerformanceDashboard;
