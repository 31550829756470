import * as Sentry from '@sentry/browser';

import { showToast } from '~/components/Shared/Alerting/Toast/utils/showToast';

// From https://stackoverflow.com/a/63965930/5699461
export const downloadBlob = (blob: Blob, filename: string) => {
    try {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url); // Clean up memory
    } catch (error) {
        Sentry.captureException(error);
        showToast({ message: 'Failed to download file', duration: 10000, type: 'error' });
    }
};
