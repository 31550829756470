import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { IoMdPrint } from 'react-icons/io';

import { pxToRem } from '~/components/theme/typography';

import { REACT_PRINT_CLASSNAMES } from './constants';

type Props = {
    amount: string | number;
    subtitle: React.ReactElement;
    onPrint: () => void;
};

export const ModuleSubtitle = ({ amount, subtitle, onPrint }: Props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            sx={{
                py: { xs: pxToRem(8), lg: pxToRem(20) },
                px: { xs: pxToRem(12), lg: pxToRem(20) },
                borderBottom: '1px solid #ECECF3',
                display: 'flex',
            }}
        >
            <Box sx={{ flexGrow: '1' }}>
                <Typography
                    variant="h2"
                    sx={{
                        fontSize: { xs: pxToRem(16), lg: pxToRem(24) },
                        fontWeight: 'bold',
                        lineHeight: { xs: 1.5, lg: 1 },
                        color: '#2C2D3B',
                        mb: pxToRem(4),
                    }}
                >
                    {amount}
                </Typography>
                <Typography
                    sx={{
                        fontSize: { lg: pxToRem(14) },
                        color: '#6F6F79',
                        lineHeight: 1.45,
                    }}
                >
                    {subtitle}
                </Typography>
            </Box>
            {!isMobile && (
                <Button
                    onClick={onPrint}
                    variant="outlined"
                    className={REACT_PRINT_CLASSNAMES.MODULE_PRINT_BUTTON}
                    sx={{ color: 'primary.400' }}
                >
                    <IoMdPrint onClick={onPrint} />
                </Button>
            )}
        </Box>
    );
};
