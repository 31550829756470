import {
    ArrowsLeftRight,
    Chat,
    CirclesFour,
    ForkKnife,
    Icon,
    Pill,
    Question,
    Scissors,
    ShieldPlus,
    Shower,
    SmileySad,
    TShirt,
    Toilet,
    WarningCircle,
    Wheelchair,
    Wrench,
    XCircle,
} from '@phosphor-icons/react';

import { PrnTaskCategories } from '../types/prnTaskCategories';

export default function withIcon(category: PrnTaskCategories.Category): PrnTaskCategories.Category & { icon: Icon } {
    const iconMap: Record<string, Icon> = {
        Emergency: WarningCircle,
        Showering: Shower,
        Grooming: Scissors,
        Transfer: ArrowsLeftRight,
        Mobility: Wheelchair,
        'Eating/Feeding': ForkKnife,
        Dressing: TShirt,
        Toileting: Toilet,
        'Safety ADL': ShieldPlus,
        Services: Wrench,
        Health: Pill,
        'Not feeling well': SmileySad,
        'Question/Ask': Question,
        'Accidentally pressed': XCircle,
        'General comment': Chat,
    };

    return {
        ...category,
        icon: iconMap[category.label] || CirclesFour,
    };
}
