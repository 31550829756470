import { Box, Typography, styled } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import { Reports } from '../../types';
import { useReportData } from '../../useReportData';

import { Cell, Header, Row, Table, TableLink } from './Table';

const ChevronUp = styled(FiChevronUp)(({ theme }) => ({
    color: theme.palette.grey[900],
    width: '20px',
    height: '20px',
}));

const ChevronDown = styled(FiChevronDown)(({ theme }) => ({
    color: theme.palette.grey[900],
    width: '20px',
    height: '20px',
}));

type CollapsibleRowProps = {
    data: Reports.CallOffPatternDetails[];
};

const CollapsibleRow = (props: CollapsibleRowProps) => {
    return (
        <>
            {props.data.map((row, idx) => (
                <Row key={`${row.date.toISOString()}${idx}`}>
                    <Cell sx={{ backgroundColor: '#FCFCFD', color: 'grey.500' }}>
                        {DateTime.fromJSDate(row.date).toFormat('cccc, MM/dd/yyyy')}
                    </Cell>
                    <Cell sx={{ backgroundColor: '#FCFCFD', color: 'grey.500' }}>{row.shift} Shift</Cell>
                    <Cell sx={{ backgroundColor: '#FCFCFD', color: 'grey.500' }}>{row.reason}</Cell>
                </Row>
            ))}
        </>
    );
};

type MainRowProps = {
    data: Omit<Reports.CallOffPatternTableData, 'details'>;
    isOpen: boolean;
    onRowClick: (staffName: string) => void;
};

const MainRow = (props: MainRowProps) => {
    return (
        <Row sx={{ border: 'none' }}>
            <Cell
                sx={{
                    flex: 1,
                    alignItems: 'center',
                    display: 'flex',
                    cursor: 'pointer',
                    gap: '8px',
                }}
                onClick={() => props.onRowClick(props.data.staffName)}
            >
                {props.isOpen ? <ChevronUp /> : <ChevronDown />}
                {props.data.staffName}
            </Cell>
            <Cell sx={{ flex: 1 }}>{props.data.role.name}</Cell>
            <Cell sx={{ flex: 4, display: 'flex', gap: '4px' }}>
                {props.data.patterns.map((pattern, idx) => (
                    <Typography
                        key={`${pattern}${idx}`}
                        variant="body1"
                        fontWeight={600}
                        fontSize={13}
                        color="grey.600"
                        lineHeight="16px"
                        padding="4px 8px"
                        bgcolor="primary.50"
                        borderRadius="12px"
                    >
                        {pattern}
                    </Typography>
                ))}
            </Cell>
        </Row>
    );
};

export const CallOffPatternTable = () => {
    const { callOffPatternData } = useReportData();
    const [openRows, setOpenRows] = React.useState<Record<string, boolean>>({});

    React.useEffect(() => {
        setOpenRows(
            callOffPatternData.reduce<Record<string, boolean>>((agg, row) => ({ ...agg, [row.staffName]: false }), {})
        );
    }, [JSON.stringify(callOffPatternData)]);

    const handleRowClick = (staffName: string) => {
        setOpenRows((prev) => ({
            ...prev,
            [staffName]: !prev[staffName],
        }));
    };

    return (
        <Box>
            <Box display="flex" justifyContent="space-between">
                <Typography variant="body1" fontWeight={700} fontSize="16px">
                    Call Off Patterns
                </Typography>
                <TableLink href="/scheduling/full-schedule">{"This Week's Schedule"}</TableLink>
            </Box>
            <Box height="16px" />
            <Box padding="24px" bgcolor="#FFF" borderRadius="8px">
                <Table
                    headers={
                        <Row header>
                            <Header sx={{ flex: 1 }}>Staff Name</Header>
                            <Header sx={{ flex: 1 }}>Role</Header>
                            <Header sx={{ flex: 4 }}>Patterns</Header>
                        </Row>
                    }
                >
                    {callOffPatternData.map(({ staffName, role, patterns, details }, idx) => {
                        return (
                            <React.Fragment key={idx}>
                                <MainRow
                                    data={{ staffName, role, patterns }}
                                    onRowClick={handleRowClick}
                                    isOpen={openRows[staffName]}
                                />
                                {openRows[staffName] && <CollapsibleRow data={details} />}
                            </React.Fragment>
                        );
                    })}
                </Table>
            </Box>
        </Box>
    );
};
