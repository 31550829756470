import {
    Assignment as AssignmentIcon,
    CheckCircleOutlined as CheckIcon,
    CancelOutlined as RejectIcon,
} from '@mui/icons-material';
import { Box, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { addMinutes } from 'date-fns';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import useSound from 'use-sound';

import { getShiftAtDateTimeUtc } from '@allie/utils/src/shifts';

import { useBranchShifts } from '~/api/queries/branch';
import { useResidentDailyTaskMutation, useResidentDailyTasksQuery } from '~/api/queries/residents';
import { useDocumentationActionsQuery } from '~/api/queries/tasks/documentationActions';
import langDictionary from '~/app-strings';
import notificationSound from '~/assets/notification-sound.mp3';
import { CustomTabPanel as TabPanel } from '~/components/Custom';
import { showToast } from '~/components/Shared/Alerting/Toast/utils/showToast';
import AssistLevelChangeDialog from '~/components/Shared/AssistLevelChangeDialog';
import EditTaskStatusDialog from '~/components/Shared/EditTaskStatusDialog';
import Loading from '~/components/Shared/Loading';
import NurseCallsDialog from '~/components/Shared/NurseCallsDialog';
import RecentUnscheduledTasksDialog from '~/components/Shared/RecentUnscheduledTasksDialog';
import { AddTaskNotesDialog } from '~/components/Shared/Task/AddTaskNotesDialog';
import { TaskDetails } from '~/components/Shared/Task/TaskDetails';
import { TaskNotesViewDialog as InstructionsViewDialog } from '~/components/Shared/Task/TaskNotesViewDialog';
import { TaskRowContainer } from '~/components/Shared/Task/TaskRowContainer';
import TasksCompletionCountDialog from '~/components/Shared/TasksCompletionCountDialog';
import { pxToRem } from '~/components/theme/typography';
import { CHECKBOX_WIDTH, EARLY_IN_SHIFT_MINUTES } from '~/constants/home';
import { DialogType } from '~/constants/shared';
import {
    checkIfEarlyInShift,
    getCurrentTimeAndShiftStartTime,
    setEarlyInShiftConfirmationExpireTime,
} from '~/lib/common';
import { getDateInUtc } from '~/lib/date';
import { usePermissions } from '~/permissions/utils';
import { toggleResidentParty } from '~/redux/actions/residents';
import { readTaskStatuses } from '~/redux/actions/tasksStatuses';
import { DailyTasksByTab, DailyTasksUpdateParams } from '~/types/dailyTasks';
import { ReduxStore } from '~/types/redux';
import { ResidentDailyTasksResponse } from '~/types/residents';
import { TaskStatusesReadParams, TaskStatusesResponse } from '~/types/taskStatuses';

import AllTasksCompletedIndicator from './components/AllTasksCompletedIndicator';
import EarlyInShiftConfirmationDialog from './components/EarlyInShiftConfirmationDialog';

const TabsStyle = styled(Tabs)(({ theme }) =>
    theme.unstable_sx({
        width: '100%',
        '& .MuiButtonBase-root.MuiTab-root': {
            width: '50%',
        },
    })
);

const TasksContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mt: pxToRem(8),
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        gap: pxToRem(16),
    })
);

const TaskIconContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(8),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: pxToRem(32),
        height: pxToRem(32),
        borderRadius: '50%',
    })
);

const TaskDescriptionContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        width: `calc(100% - ${pxToRem(40 + CHECKBOX_WIDTH)})`,
    })
);

const CheckboxContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        pl: pxToRem(8),
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        color: theme.palette.primary.main,
        width: pxToRem(CHECKBOX_WIDTH),
    })
);

type Props = {
    residentId: number;
    timezone: string;
    dispatchToggleResidentParty: () => void;
    dispatchReadTaskStatuses: (params: TaskStatusesReadParams) => void;
    taskStatusesList: TaskStatusesResponse[];
    residentName: string;
    branchId: number;
};

const ResidentTasksContainer = (props: Props) => {
    const {
        residentId,
        branchId,
        timezone,
        taskStatusesList,
        residentName,
        dispatchToggleResidentParty,
        dispatchReadTaskStatuses,
    } = props;
    const { home, error: errorDictionary } = langDictionary;
    const [selectedRecord, setSelectedRecord] = useState<ResidentDailyTasksResponse>();
    const [isEditTaskStatusDialogOpen, setIsEditTaskStatusDialogOpen] = useState<boolean>(false);
    const [isEarlyInShiftConfirmationDialogOpen, setIsEarlyInShiftConfirmationDialogOpen] = useState<boolean>(false);
    const [dialogType, setDialogType] = useState<DialogType>(DialogType.Reject);
    const [selectedInstructions, setSelectedInstructions] = useState<string>();
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [tasksByTab, setTasksByTab] = useState<DailyTasksByTab>([]);
    const [playCoinSound] = useSound(notificationSound);
    const [viewInstructionsIdNotes, setViewInstructionsIdNotes] = useState<number | null>(null);
    const [showTaskIdAddNotesDialog, setShowTaskIdAddNotesDialog] = useState<number | null>(null);
    const [selectedTaskStatusId, setSelectedTaskStatusId] = useState<number>(0);
    const [lastCompletedTaskId, setLastCompletedTaskId] = useState<number>(0);
    const [taskIdCompletionOptions, setTaskIdCompletionOptions] = useState<{
        [key: string]: number;
    }>({});
    const [handleCompleteTaskParams, setHandleCompleteTaskParams] = useState<{
        dailyTaskRecordId: number;
        params: DailyTasksUpdateParams;
    } | null>(null);
    const [handleEditTaskStatusDialogOpenParams, setHandleEditTaskStatusDialogOpenParams] = useState<{
        newDailyTaskRecord: ResidentDailyTasksResponse;
        newDialogType: DialogType;
    } | null>(null);
    const [isNurseCallsDialogOpen, setIsNurseCallsDialogOpen] = useState<boolean>(false);
    const [showRecentUnscheduledTasksDialog, setShowRecentUnscheduledTasksDialog] = useState<boolean>(false);

    const [taskIdNotes, setTaskIdNotes] = useState<{ [key: number]: string }>({});

    const [isTasksCompletionCountDialogOpen, setIsTasksCompletionCountDialogOpen] = useState(false);

    const [isAssistLevelChangeDialogOpen, setIsAssistLevelChangeDialogOpen] = useState(false);

    const { mutateAsync: residentDailyTaskMutation } = useResidentDailyTaskMutation();

    const { userId } = useSelector((state: ReduxStore) => state.session.sessionData);

    const {
        data: residentDailyTasks,
        isLoading: residentDailyTasksIsLoading,
        isError: residentDailyTasksIsError,
    } = useResidentDailyTasksQuery(residentId, branchId);

    const pendingTaskRecords = residentDailyTasks?.pendingTaskRecords || [];
    const confirmedTaskRecords = residentDailyTasks?.confirmedTaskRecords || [];

    const branchShifts = useBranchShifts(branchId);

    const {
        shift: { id: currentShiftId, name: currentShiftName },
        shiftDayDate: currentDate,
    } = getShiftAtDateTimeUtc(getDateInUtc(new Date()), timezone, branchShifts);

    const pendingShiftTasks = useMemo(
        () => pendingTaskRecords?.filter((task) => task.taskBranchShiftId === currentShiftId) || [],
        [currentShiftId, pendingTaskRecords]
    );

    const { data: documentationActionsData } = useDocumentationActionsQuery({
        branchId,
        taskIds: [lastCompletedTaskId],
    });

    const checkAssistLevelChangeDialog = () => {
        if (documentationActionsData && !isEmpty(documentationActionsData.showAssistLevelChange)) {
            setIsAssistLevelChangeDialogOpen(true);
        }
    };

    const checkTaskCompletionCountDialog = () => {
        if (documentationActionsData && documentationActionsData.showTaskCompletionCount.length > 0) {
            setIsTasksCompletionCountDialogOpen(true);
        } else {
            checkAssistLevelChangeDialog();
        }
    };

    useEffect(() => {
        if (!documentationActionsData) return;

        if (documentationActionsData.recentUnscheduledTasks.length > 0) {
            setShowRecentUnscheduledTasksDialog(true);
            return;
        }

        if (documentationActionsData.showNurseCallsActions) {
            setIsNurseCallsDialogOpen(true);
        } else {
            checkTaskCompletionCountDialog();
        }
    }, [lastCompletedTaskId, documentationActionsData]);

    const hasPermission = usePermissions();

    const permissionError = () => {
        showToast({
            message: errorDictionary.onlyDirectorsCareGivers,
            type: 'error',
        });
    };

    const editTaskStatusDialogOpen = (newDailyTaskRecord: ResidentDailyTasksResponse, newDialogType: DialogType) => {
        setSelectedRecord(newDailyTaskRecord);

        setDialogType(newDialogType);

        setIsEditTaskStatusDialogOpen(true);

        setHandleEditTaskStatusDialogOpenParams(null);
    };

    const handleEditTaskStatusDialogOpen = (
        newDailyTaskRecord: ResidentDailyTasksResponse,
        newDialogType: DialogType
    ) => {
        if (!hasPermission('Community', 'update-resident-action')) {
            permissionError();
            return;
        }

        if (checkIfEarlyInShift(timezone, branchShifts)) {
            setHandleEditTaskStatusDialogOpenParams({
                newDailyTaskRecord,
                newDialogType,
            });
            setHandleCompleteTaskParams(null);
            toggleEarlyInShiftConfirmationDialog();
            return;
        }

        editTaskStatusDialogOpen(newDailyTaskRecord, newDialogType);
    };

    const toggleEarlyInShiftConfirmationDialog = () => {
        setIsEarlyInShiftConfirmationDialogOpen((prevState) => !prevState);
    };

    const handleDialogClose = () => {
        setViewInstructionsIdNotes(null);
        setShowTaskIdAddNotesDialog(null);
        setIsEditTaskStatusDialogOpen(false);
        setSelectedInstructions('');
    };

    const getParams = (taskId: number, taskStatusId: number, notes?: string) => {
        const params: DailyTasksUpdateParams = {
            userId: Number(userId),
            taskStatusId,
            caregiverNotes: notes || taskIdNotes[taskId],
            taskCompletionSelection: taskIdCompletionOptions[taskId]?.toString(),
        };

        return params;
    };

    const completeTask = async (dailyTaskRecordId: number, params: DailyTasksUpdateParams) => {
        // Mark the user has viewed the Resident's Profile.
        params.residentProfileViewed = true; // eslint-disable-line no-param-reassign

        // Trigger the action for updating the Task as Complete.
        await residentDailyTaskMutation({
            taskId: dailyTaskRecordId,
            residentId,
            branchId: branchId,
            jsonParams: params,
        });

        setLastCompletedTaskId(dailyTaskRecordId);

        // checks if the status had changed to pending to clear the task notes
        if (params.taskStatusId === 1) {
            setTaskIdNotes({
                ...taskIdNotes,
                [dailyTaskRecordId]: '',
            });
        } else if (params.caregiverNotes) {
            setTaskIdNotes({
                ...taskIdNotes,
                [dailyTaskRecordId]: params.caregiverNotes,
            });
        }

        // if this is the last task to be complete for the shift then show the resident party
        if (pendingShiftTasks.length === 1 && pendingShiftTasks[0].dailyTaskRecordId === dailyTaskRecordId) {
            dispatchToggleResidentParty();
        }

        if (hasPermission('Community', 'update-reward') && params.taskStatusId === 2) {
            // Trigger the sound for earning points.
            playCoinSound();
        }
        setHandleCompleteTaskParams(null);
    };

    const handleCompleteTaskClick = (dailyTaskRecordId: number, params: DailyTasksUpdateParams) => {
        // If the user is not a Caregiver, exit the function.
        if (!hasPermission('Community', 'update-resident-action')) {
            return;
        }

        if (checkIfEarlyInShift(timezone, branchShifts)) {
            setHandleCompleteTaskParams({ dailyTaskRecordId, params });
            setHandleEditTaskStatusDialogOpenParams(null);
            toggleEarlyInShiftConfirmationDialog();
            return;
        }

        void completeTask(dailyTaskRecordId, params);
    };

    const handleRejectDialogSubmit = async (selectedValue: number, notes: string) => {
        const { dailyTaskRecordId } = selectedRecord as ResidentDailyTasksResponse;

        if (!hasPermission('Community', 'update-resident-action')) return;

        const params = getParams(dailyTaskRecordId, selectedValue, notes);

        // checks if the status had changed to pending to clear the task notes
        if (selectedValue === 1) {
            setTaskIdNotes({
                ...taskIdNotes,
                [dailyTaskRecordId]: '',
            });
        }
        if (notes) {
            setTaskIdNotes({
                ...taskIdNotes,
                [dailyTaskRecordId]: notes,
            });
        }

        params.residentProfileViewed = true; // eslint-disable-line no-param-reassign

        await residentDailyTaskMutation({
            taskId: dailyTaskRecordId,
            residentId,
            branchId: branchId,
            jsonParams: params,
        });

        setLastCompletedTaskId(dailyTaskRecordId);

        if (pendingShiftTasks.length === 1 && pendingShiftTasks[0].dailyTaskRecordId === dailyTaskRecordId) {
            dispatchToggleResidentParty();
        }
    };

    const handleTabChange = (event: SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    const taskRecordSorter = (a: ResidentDailyTasksResponse, b: ResidentDailyTasksResponse) => {
        const aDateTime = moment(`${a.taskDate}T${a.taskTime}:00`, 'YYYY-MM-DDTHH:mm:ss');
        const bDateTime = moment(`${b.taskDate}T${b.taskTime}:00`, 'YYYY-MM-DDTHH:mm:ss');
        if (aDateTime.isBefore(bDateTime)) {
            return -1;
        }

        if (aDateTime.isAfter(bDateTime)) {
            return 1;
        }

        return 0;
    };

    const toggleNurseCallsDialog = () => {
        setIsNurseCallsDialogOpen((prevState) => !prevState);
    };

    const toggleRecentUnscheduledTasksDialog = () => {
        setShowRecentUnscheduledTasksDialog((prevState) => !prevState);
    };

    const toggleTasksCompletionCountDialog = () => {
        setIsTasksCompletionCountDialogOpen((prevState) => !prevState);
    };

    const toggleAssistLevelChangeDialog = () => {
        setIsAssistLevelChangeDialogOpen((prevState) => !prevState);
    };

    useEffect(() => {
        if (!branchId) return;

        if (!taskStatusesList.length) {
            const params: TaskStatusesReadParams = {
                startingFromId: 1,
                branchId,
            };

            dispatchReadTaskStatuses(params);
        }
    }, [branchId, taskStatusesList]);

    useEffect(() => {
        if (pendingTaskRecords) {
            pendingTaskRecords.sort(taskRecordSorter);
        }

        if (confirmedTaskRecords) {
            confirmedTaskRecords.sort(taskRecordSorter);
        }

        setTasksByTab([
            {
                key: 0,
                tabName: 'pending',
                tasks: pendingTaskRecords,
            },
            {
                key: 1,
                tabName: 'nonPending',
                tasks: confirmedTaskRecords,
            },
        ]);
    }, [residentDailyTasks]);

    if (residentDailyTasksIsLoading) {
        return <Loading />;
    }

    if (residentDailyTasksIsError || !residentDailyTasks) {
        return null;
    }

    const updateConfirmedTaskNotes = async (dailyTaskRecordId: number, params: DailyTasksUpdateParams) => {
        await residentDailyTaskMutation({
            taskId: dailyTaskRecordId,
            residentId,
            branchId: branchId,
            jsonParams: params,
        });
    };

    const onSubmitEditAddTaskNotes = (taskNotes: string) => {
        // To make TS happy that it isn't null
        if (!showTaskIdAddNotesDialog) {
            return;
        }

        const newTaskNotes = {
            ...taskIdNotes,
            [showTaskIdAddNotesDialog]: taskNotes,
        };

        setTaskIdNotes(newTaskNotes);

        if (selectedTaskStatusId !== 1) {
            void updateConfirmedTaskNotes(showTaskIdAddNotesDialog, {
                userId: Number(userId),
                taskStatusId: selectedTaskStatusId,
                caregiverNotes: taskNotes,
                taskCompletionSelection: taskIdCompletionOptions[showTaskIdAddNotesDialog]?.toString(),
            });
        }

        setShowTaskIdAddNotesDialog(null);
    };

    const renderTasks = (isPendingTab: boolean, noTasksLeft: boolean, taskList: ResidentDailyTasksResponse[]) => {
        if (isPendingTab && noTasksLeft) {
            return <AllTasksCompletedIndicator residentName={residentName} />;
        }

        return taskList.map((taskRecord: ResidentDailyTasksResponse) => {
            const {
                dailyTaskRecordId,
                taskName,
                taskTime,
                taskStatusId,
                taskNotes: instructions,
                caregiverNotes: userTaskNotes,
                taskCompletionOptions,
                taskType,
                caregiver,
                taskStatusDateTime,
            } = taskRecord;

            const onCompletionOptionsChange = (taskId: number, completionValue: number) => {
                setTaskIdCompletionOptions({
                    ...taskIdCompletionOptions,
                    [taskId]: completionValue,
                });
            };

            const completionOptionValue = taskIdCompletionOptions[dailyTaskRecordId];

            const ICON_SIZE = { height: pxToRem(50), width: pxToRem(50) };

            const ICON_COLORS = {
                pendingTab: {
                    complete: 'primary.main',
                    reject: 'common.black',
                },
                confirmedTab: {
                    complete: 'primary.main',
                    reject: 'error.main',
                    disabled: 'grey.300',
                },
            };

            const confirmedIconColor = (statusId: number, isReject?: boolean) => {
                if (isReject && statusId === 2) {
                    return ICON_COLORS.confirmedTab.disabled;
                }
                if (isReject) {
                    return ICON_COLORS.confirmedTab.reject;
                }
                if (statusId === 2) {
                    return ICON_COLORS.confirmedTab.complete;
                }
                return ICON_COLORS.confirmedTab.disabled;
            };

            const isPrnTask = taskType === 'Added';

            return (
                <TaskRowContainer key={dailyTaskRecordId} isPrn={isPrnTask}>
                    <TaskIconContainer>
                        <AssignmentIcon />
                    </TaskIconContainer>
                    <TaskDescriptionContainer>
                        <TaskDetails
                            taskName={isPrnTask ? `[PRN] ${taskName}` : taskName}
                            taskTime={taskTime}
                            taskNotes={instructions}
                            userTaskNotes={taskIdNotes[dailyTaskRecordId] || userTaskNotes}
                            taskCompletionOptions={taskCompletionOptions}
                            caregiver={caregiver}
                            completedDateTime={taskStatusDateTime ?? undefined}
                            onToggleNotes={() => {
                                setSelectedInstructions(instructions);
                                setViewInstructionsIdNotes(dailyTaskRecordId);
                            }}
                            onToggleEditAddTaskNotes={() => {
                                setSelectedTaskStatusId(taskStatusId);
                                setShowTaskIdAddNotesDialog(dailyTaskRecordId);
                                if (!taskIdNotes[dailyTaskRecordId]) {
                                    setTaskIdNotes({
                                        ...taskIdNotes,
                                        [dailyTaskRecordId]: userTaskNotes,
                                    });
                                }
                            }}
                            onCompletionOptionsChange={(completionValue) =>
                                onCompletionOptionsChange(dailyTaskRecordId, completionValue)
                            }
                            completionOptionValue={completionOptionValue}
                        />
                    </TaskDescriptionContainer>
                    <CheckboxContainer aria-disabled={isPrnTask}>
                        <CheckIcon
                            onClick={() => {
                                if (isPrnTask) return;
                                if (isPendingTab) {
                                    handleCompleteTaskClick(dailyTaskRecordId, getParams(dailyTaskRecordId, 2));
                                } else {
                                    if (taskStatusId !== 2) {
                                        return;
                                    }

                                    handleEditTaskStatusDialogOpen(taskRecord, DialogType.Edit);
                                }
                            }}
                            sx={{
                                color: isPrnTask
                                    ? ICON_COLORS.confirmedTab.disabled
                                    : isPendingTab
                                      ? ICON_COLORS.pendingTab.complete
                                      : confirmedIconColor(taskStatusId),
                                ...ICON_SIZE,
                            }}
                        />
                        <RejectIcon
                            onClick={() => {
                                if (isPrnTask) return;

                                if (taskStatusId === 2) {
                                    return;
                                }

                                handleEditTaskStatusDialogOpen(
                                    taskRecord,
                                    isPendingTab ? DialogType.Reject : DialogType.Edit
                                );
                            }}
                            sx={{
                                color: isPrnTask
                                    ? ICON_COLORS.confirmedTab.disabled
                                    : isPendingTab
                                      ? ICON_COLORS.pendingTab.reject
                                      : confirmedIconColor(taskStatusId, true),
                                ...ICON_SIZE,
                            }}
                        />
                    </CheckboxContainer>
                </TaskRowContainer>
            );
        });
    };

    return (
        <>
            <TasksContainer>
                <TabsStyle value={selectedTab} onChange={handleTabChange}>
                    {tasksByTab.map((tabTask) => {
                        const { key, tabName } = tabTask;
                        return <Tab key={key} label={home[tabName]} />;
                    })}
                </TabsStyle>
                {tasksByTab.map((tabTask) => {
                    const { key, tasks } = tabTask;

                    return (
                        <TabPanel key={key} value={selectedTab} index={key}>
                            {renderTasks(key === 0, tasks.length === 0, tasks)}
                        </TabPanel>
                    );
                })}
            </TasksContainer>
            <EditTaskStatusDialog
                isOpen={isEditTaskStatusDialogOpen}
                onClose={handleDialogClose}
                onSubmit={handleRejectDialogSubmit}
                dialogType={dialogType}
                selectedRecord={selectedRecord}
                taskNotes={
                    selectedRecord?.dailyTaskRecordId ? taskIdNotes[selectedRecord.dailyTaskRecordId] : undefined
                }
            />
            <EarlyInShiftConfirmationDialog
                isOpen={isEarlyInShiftConfirmationDialogOpen}
                onClose={toggleEarlyInShiftConfirmationDialog}
                shiftName={currentShiftName}
                onAccept={() => {
                    toggleEarlyInShiftConfirmationDialog();
                    const { shiftStartTime } = getCurrentTimeAndShiftStartTime(timezone, branchShifts);
                    setEarlyInShiftConfirmationExpireTime(addMinutes(shiftStartTime, EARLY_IN_SHIFT_MINUTES).getTime());

                    if (handleCompleteTaskParams) {
                        void completeTask(handleCompleteTaskParams.dailyTaskRecordId, handleCompleteTaskParams.params);
                        return;
                    }
                    if (handleEditTaskStatusDialogOpenParams) {
                        editTaskStatusDialogOpen(
                            handleEditTaskStatusDialogOpenParams.newDailyTaskRecord,
                            handleEditTaskStatusDialogOpenParams.newDialogType
                        );
                    }
                }}
            />
            <InstructionsViewDialog
                isOpen={viewInstructionsIdNotes !== null}
                taskNote={selectedInstructions ?? ''}
                onClose={handleDialogClose}
            />
            <AddTaskNotesDialog
                isOpen={showTaskIdAddNotesDialog !== null}
                taskNote={showTaskIdAddNotesDialog ? taskIdNotes[showTaskIdAddNotesDialog] : ''}
                onSubmit={onSubmitEditAddTaskNotes}
                onClose={handleDialogClose}
            />
            <NurseCallsDialog
                isOpen={isNurseCallsDialogOpen}
                onClose={() => {
                    toggleNurseCallsDialog();
                    checkTaskCompletionCountDialog();
                }}
                residentId={residentId}
                date={currentDate}
                shift={currentShiftId}
            />

            {residentId && (
                <RecentUnscheduledTasksDialog
                    isOpen={showRecentUnscheduledTasksDialog}
                    tasks={documentationActionsData?.recentUnscheduledTasks ?? []}
                    onClose={() => {
                        toggleRecentUnscheduledTasksDialog();
                        checkTaskCompletionCountDialog();
                    }}
                    residentId={residentId}
                    date={currentDate}
                    shift={Number(currentShiftId)}
                />
            )}

            {documentationActionsData?.showTaskCompletionCount && (
                <TasksCompletionCountDialog
                    isOpen={isTasksCompletionCountDialogOpen}
                    onClose={() => {
                        toggleTasksCompletionCountDialog();
                        checkAssistLevelChangeDialog();
                    }}
                    tasksCompletionCountData={documentationActionsData.showTaskCompletionCount}
                    residentId={residentId}
                    date={currentDate}
                    shift={currentShiftId as number}
                />
            )}
            {documentationActionsData?.showAssistLevelChange && (
                <AssistLevelChangeDialog
                    isOpen={isAssistLevelChangeDialogOpen}
                    onClose={() => {
                        toggleAssistLevelChangeDialog();
                    }}
                    assistLevelChangeData={documentationActionsData.showAssistLevelChange}
                    residentId={residentId}
                />
            )}
        </>
    );
};

const mapStateToProps = ({ session, taskStatuses }: ReduxStore) => {
    const { timezone } = session;
    const { taskStatusesList } = taskStatuses;

    return {
        taskStatusesList,
        timezone,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatchToggleResidentParty: () => dispatch(toggleResidentParty()),
    dispatchReadTaskStatuses: (params: TaskStatusesReadParams) => dispatch(readTaskStatuses(params)),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConnectedResidentTasksContainer: any = connect(mapStateToProps, mapDispatchToProps)(ResidentTasksContainer);

export default ConnectedResidentTasksContainer;
