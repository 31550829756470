import { Button, Drawer, Stack, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SignOut } from '@phosphor-icons/react';
import { atom, useAtom } from 'jotai';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import logo from '~/assets/logo.svg';
import { SingleLineTypography } from '~/components/Shared/SingleLineTypography';
import { useAppDispatch } from '~/constants/redux';
import { signOut } from '~/redux/actions/session';

import MobileDrawerDestinations from './MobileDrawerDestinations';

const DRAWER_WIDTH = '280px';

export const isDrawerOpenAtom = atom(false);

const DrawerContainer = styled(Stack)({
    width: DRAWER_WIDTH,
    height: '100%',
});

const MobileDrawerHeader = () => {
    const { palette } = useTheme();

    return (
        <Stack
            sx={{
                flexDirection: 'row',
                alignItems: 'center',
                gap: '3.2px',
                padding: '32px 16px 16px',
                userSelect: 'none',
            }}
        >
            <img src={logo} alt="AllieHealth" style={{ width: '32px', height: '32px' }} />
            <SingleLineTypography
                sx={{
                    color: palette.primary.main,
                    fontSize: '22.4px',
                    fontWeight: 700,
                }}
            >
                Allie<span style={{ color: palette.secondary.main, fontWeight: 400 }}>Health</span>
            </SingleLineTypography>
        </Stack>
    );
};

const MobileDrawerSupport = () => {
    const { palette } = useTheme();

    return (
        <Stack
            sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: '16px',
                p: '16px',
            }}
        >
            <SingleLineTypography sx={{ color: palette.grey[400], fontWeight: 400 }}>
                support@alliehealth.com
            </SingleLineTypography>
            <Link to="/privacy-policy" target="_blank">
                <SingleLineTypography
                    sx={{
                        color: palette.grey[400],
                        fontWeight: 400,
                        textDecoration: 'underline',
                    }}
                >
                    Privacy
                </SingleLineTypography>
            </Link>
        </Stack>
    );
};

const MobileDrawerFooter = () => {
    const { palette } = useTheme();

    const dispatch = useAppDispatch();
    const [isSignOutDisabled, setIsSignOutDisabled] = useState(false);

    return (
        <Stack
            sx={{
                flexDirection: 'row',
                alignItems: 'center',
                gap: '4px',
                p: '16px',
                borderTop: `1px solid ${palette.grey[50]}`,
            }}
        >
            <Button
                variant="text"
                onClick={async () => {
                    setIsSignOutDisabled(true);
                    await dispatch(signOut());
                    setIsSignOutDisabled(false);
                }}
                sx={{
                    color: palette.error[600] as string,
                    flex: 1,
                    gap: '8px',
                    p: '8px',
                    '& > svg': { fontSize: '20px !important', flexShrink: 0 },
                }}
                disabled={isSignOutDisabled}
            >
                <SignOut weight="bold" />
                <SingleLineTypography sx={{ fontWeight: 600, mr: 'auto' }}>Sign out</SingleLineTypography>
            </Button>
        </Stack>
    );
};

const MobileDrawer = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useAtom(isDrawerOpenAtom);

    return (
        <Drawer
            anchor="left"
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            PaperProps={{
                sx: {
                    // Avoid status/navigation bar cutouts
                    pt: 'env(safe-area-inset-top)',
                    pb: 'env(safe-area-inset-bottom)',
                },
            }}
        >
            <DrawerContainer>
                <MobileDrawerHeader />
                <MobileDrawerDestinations />
                <MobileDrawerSupport />
                <MobileDrawerFooter />
            </DrawerContainer>
        </Drawer>
    );
};

export default MobileDrawer;
