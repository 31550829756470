import { groupBy } from 'lodash';
import { DateTime } from 'luxon';

import { STANDARDIZED_STAFF_ROLE_SHIFT_TYPES } from '@allie/utils/src/constants/scheduling/staff-roles.constants';

import { GetLocationsResult } from '~/scheduling/api/queries/locations/getLocations';
import { GetRolesResult } from '~/scheduling/api/queries/staff-roles/getRoles';
import { GetLocations } from '~/scheduling/api/types/locations/getLocations';
import { CreateMasterSchedule } from '~/scheduling/api/types/master-schedule/createMasterSchedule';
import { GetMasterSchedule } from '~/scheduling/api/types/master-schedule/getMasterSchedule';
import { GetRoles } from '~/scheduling/api/types/staff-roles/getRoles';

import { StaffOptimizations } from './types';

export const isTheSameSlot = (
    slot1: { shiftType: STANDARDIZED_STAFF_ROLE_SHIFT_TYPES; shiftIndex: number; day: DateTime; locationId: number },
    slot2: { shiftType: STANDARDIZED_STAFF_ROLE_SHIFT_TYPES; shiftIndex: number; day: DateTime; locationId: number }
) => {
    return (
        slot1.shiftIndex === slot2.shiftIndex &&
        slot1.day.hasSame(slot2.day, 'day') &&
        slot1.locationId === slot2.locationId
    );
};

const buildKey = ({
    locationId,
    roleId,
    shiftIndex,
    shiftType,
    weekday,
}: {
    locationId: number;
    roleId: number;
    shiftIndex: number;
    shiftType: STANDARDIZED_STAFF_ROLE_SHIFT_TYPES;
    weekday: number;
}) => `${locationId}-${roleId}-${shiftIndex}-${weekday}-${shiftType}`;

export function fillEmptySchedule(
    emptySchedule: StaffOptimizations.BudgetedShift[],
    masterSchedule: GetMasterSchedule.MasterSchedule
): StaffOptimizations.BudgetedShift[] {
    // Create lookup tables for quick access
    const shiftLookup = new Map<
        string,
        { shiftSlotsAmount: number; staffRoleShift: GetMasterSchedule.StaffRoleShift }
    >();

    masterSchedule.shifts.forEach((shift) => {
        const key = buildKey({
            locationId: shift.location.id,
            roleId: shift.staffRole.id,
            shiftIndex: shift.staffRoleShift.index,
            shiftType: shift.staffRoleShift.shiftType,
            weekday: shift.weekday,
        });
        shiftLookup.set(key, {
            shiftSlotsAmount: shift.shiftSlotsAmount,
            staffRoleShift: shift.staffRoleShift,
        });
    });

    // Process the emptySchedule with precomputed values
    return emptySchedule.map((shift) => ({
        ...shift,
        days: shift.days.map((day) => ({
            ...day,
            slots: day.slots.map((slot) => ({
                ...slot,
                budgets: slot.budgets.map((budget) => {
                    const key = buildKey({
                        locationId: slot.location.id,
                        roleId: budget.role.id,
                        shiftIndex: budget.shift.index,
                        shiftType: budget.shift.shiftType,
                        weekday: day.day.weekday,
                    });
                    const shiftData = shiftLookup.get(key) || { shiftSlotsAmount: 0, staffRoleShift: budget.shift };

                    return {
                        ...budget,
                        budget: shiftData.shiftSlotsAmount,
                        shift: shiftData.staffRoleShift,
                    };
                }),
            })),
        })),
    }));
}

export function createEmptySchedule(
    rolesData?: GetRolesResult,
    locationsData?: GetLocationsResult
): StaffOptimizations.BudgetedShift[] {
    if (!rolesData || !locationsData) return [];
    const startAt = DateTime.now();

    const weekDays = [...Array(7).keys()].map((dayOffset) => startAt.plus({ days: dayOffset }));
    const { roleShifts, roles } = rolesData;

    const shiftsByType = groupBy(roleShifts, 'shiftType');

    return Object.entries(shiftsByType).flatMap(
        ([shiftType, shifts]: [STANDARDIZED_STAFF_ROLE_SHIFT_TYPES, GetRoles.StaffRoleShift[]]) =>
            processShiftsByType(shiftType, shifts, weekDays, roles, locationsData.locations)
    );
}

function processShiftsByType(
    shiftType: STANDARDIZED_STAFF_ROLE_SHIFT_TYPES,
    shifts: GetRoles.StaffRoleShift[],
    weekDays: DateTime[],
    roles: GetRoles.Role[],
    locations: GetLocations.Location[]
): StaffOptimizations.BudgetedShift[] {
    const sortedShifts = shifts.sort((a, b) => a.index - b.index);
    const shiftsByIndex = groupBy(sortedShifts, 'index');

    return Object.keys(shiftsByIndex).map((shiftIndex) =>
        createBudgetedShift(shiftType, +shiftIndex, shifts, weekDays, roles, locations)
    );
}

function createBudgetedShift(
    shiftType: STANDARDIZED_STAFF_ROLE_SHIFT_TYPES,
    shiftIndex: number,
    shifts: GetRoles.StaffRoleShift[],
    weekDays: DateTime[],
    roles: GetRoles.Role[],
    locations: GetLocations.Location[]
): StaffOptimizations.BudgetedShift {
    const locationBudgets = createLocationBudgets(shiftType, shiftIndex, roles, locations);
    const days = weekDays.map((day) => ({ day, slots: locationBudgets }));
    const shift = shifts.find((s) => s.index === +shiftIndex)!;

    return { shift, days };
}

function createLocationBudgets(
    shiftType: STANDARDIZED_STAFF_ROLE_SHIFT_TYPES,
    shiftIndex: number,
    roles: GetRoles.Role[],
    locations: GetLocations.Location[]
): StaffOptimizations.Slot[] {
    return locations.map((location) => {
        const rolesForShift = roles.filter((role) =>
            role.staffRoleShifts.some((shift) => shift.index === shiftIndex && shift.shiftType === shiftType)
        );

        const budgets = rolesForShift.map((role) => {
            const shift = role.staffRoleShifts.find((s) => s.index === shiftIndex && s.shiftType === shiftType)!;

            return { budget: 0, role, shift };
        });

        return { location, budgets };
    });
}

export const createMasterScheduleInput = (
    startAt: DateTime,
    shiftsToUpdate: StaffOptimizations.BudgetToUpdate[],
    masterSchedule: StaffOptimizations.BudgetedShift[]
): CreateMasterSchedule.Params => {
    const shiftLookup = new Map<string, StaffOptimizations.BudgetToUpdate>();

    shiftsToUpdate.forEach((shift) => {
        const key = buildKey({
            locationId: shift.locationId,
            roleId: shift.roleId,
            shiftIndex: shift.shiftIndex,
            shiftType: shift.shiftType,
            weekday: shift.day.weekday,
        });
        shiftLookup.set(key, shift);
    });

    return {
        startAt: startAt.toFormat('yyyy-MM-dd'),
        shifts: masterSchedule.flatMap((shift) =>
            shift.days.flatMap((day) =>
                day.slots.flatMap((slot) =>
                    slot.budgets.map((budget) => {
                        const key = buildKey({
                            locationId: slot.location.id,
                            roleId: budget.role.id,
                            shiftIndex: budget.shift.index,
                            shiftType: budget.shift.shiftType,
                            weekday: day.day.weekday,
                        });
                        const shiftData = shiftLookup.get(key);

                        if (shiftData) {
                            return {
                                locationId: shiftData.locationId,
                                staffRoleId: shiftData.roleId,
                                staffRoleShiftId: shiftData.shiftId,
                                shiftSlotsAmount: shiftData.budget,
                                weekday: shiftData.day.weekday,
                            };
                        }

                        return {
                            locationId: slot.location.id,
                            staffRoleId: budget.role.id,
                            staffRoleShiftId: budget.shift.id,
                            shiftSlotsAmount: budget.budget,
                            weekday: day.day.weekday,
                        };
                    })
                )
            )
        ),
    };
};

export const getHoursDuration = (startTime: DateTime, endTime: DateTime) => endTime.diff(startTime, 'hours').hours;

export const getShiftDuration = ({
    shiftStartTime,
    shiftEndTime,
}: {
    shiftStartTime: string;
    shiftEndTime: string;
}): number => {
    const startShiftDataTime = DateTime.fromISO(shiftStartTime);
    const endShiftDataTime = DateTime.fromISO(shiftEndTime);

    if (endShiftDataTime < startShiftDataTime) {
        return getHoursDuration(startShiftDataTime, endShiftDataTime.plus({ day: 1 }));
    }

    return getHoursDuration(startShiftDataTime, endShiftDataTime);
};

export const calculateScheduledBudget = (budgetedShifts: StaffOptimizations.BudgetedShift[]): number => {
    const allSlots = budgetedShifts.flatMap((shift) => shift.days.flatMap((day) => day.slots));
    const allBudgets = allSlots.flatMap((slot) => slot.budgets);

    return allBudgets.reduce((acc, budget) => {
        const totalBudget = getShiftDuration(budget.shift) * budget.budget;

        return acc + totalBudget;
    }, 0);
};

export const thereAreNewChanges = (
    budgetsToChange: StaffOptimizations.BudgetToUpdate[],
    budgetedShifts: StaffOptimizations.BudgetedShift[]
): boolean => {
    const previousSlots = budgetedShifts.flatMap((shift) =>
        shift.days.flatMap((day) =>
            day.slots.flatMap((slot) =>
                slot.budgets.map((budget) => ({
                    locationId: slot.location.id,
                    roleId: budget.role.id,
                    shiftType: budget.shift.shiftType,
                    shiftIndex: budget.shift.index,
                    budget: budget.budget,
                    day: day.day,
                }))
            )
        )
    );

    const haveNewChanges = previousSlots.some((previousSlot) =>
        budgetsToChange.find(
            (change) =>
                isTheSameSlot(change, previousSlot) &&
                change.roleId === previousSlot.roleId &&
                change.budget !== previousSlot.budget
        )
    );

    return haveNewChanges;
};
