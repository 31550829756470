import { CancelOutlined, CheckCircleOutlined } from '@mui/icons-material';
import { Box, CircularProgress, styled } from '@mui/material';
import React from 'react';

import { TASK_STATUS, TASK_STATUS_ID_TO_TASK_STATUS } from '@allie/utils/src/constants/task-records.constants';

import { pxToRem } from '~/components/theme/typography';
import { TaskLoadingState } from '~/types/dailyTasks';

const REJECTED_TASK_STATUSES = [
    TASK_STATUS.RESIDENT_REFUSED,
    TASK_STATUS.RESIDENT_NOT_AVAILABLE,
    TASK_STATUS.SELF_COMPLETED,
    TASK_STATUS.NOT_APPLICABLE,
    TASK_STATUS.UNKNOWN,
];

const ButtonsColumn = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    })
);

const ButtonContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        width: pxToRem(54),
        height: pxToRem(54),
        position: 'relative',
    })
);

const ButtonLoading = styled(CircularProgress)(({ theme }) =>
    theme.unstable_sx({
        position: 'absolute',
        margin: pxToRem(4),
        pointerEvents: 'none',
    })
);

type Props = {
    taskStatusId: number;
    onToggleRejectDialog: () => void;
    loadingState?: TaskLoadingState;
    isPrnTask?: boolean;
};

export const TaskOptions = (props: Props) => {
    const { taskStatusId, onToggleRejectDialog, isPrnTask, loadingState = null } = props;

    const taskStatus = TASK_STATUS_ID_TO_TASK_STATUS[taskStatusId] as keyof typeof TASK_STATUS;
    const isRejected = REJECTED_TASK_STATUSES.includes(taskStatus);

    const buttonStyles = {
        height: pxToRem(54),
        width: pxToRem(54),
    };

    return (
        <ButtonsColumn>
            <ButtonContainer>
                {loadingState === 'confirm' && <ButtonLoading size={46} thickness={4.8} />}
                <CheckCircleOutlined
                    onClick={() => !isPrnTask && !isRejected && !loadingState && onToggleRejectDialog()}
                    sx={{
                        ...buttonStyles,
                        color: !isPrnTask && !isRejected && !loadingState ? 'primary.main' : 'grey.200',
                    }}
                />
            </ButtonContainer>
            <ButtonContainer>
                {loadingState === 'reject' && <ButtonLoading size={46} thickness={4.8} />}
                <CancelOutlined
                    onClick={() => !isPrnTask && isRejected && !loadingState && onToggleRejectDialog()}
                    sx={{
                        ...buttonStyles,
                        color: !isPrnTask && isRejected && !loadingState ? 'error.main' : 'grey.200',
                    }}
                />
            </ButtonContainer>
        </ButtonsColumn>
    );
};
