import { Box } from '@mui/material';
import React, { ReactNode } from 'react';

import { pxToRem } from '~/components/theme/typography';

type Props = { children: ReactNode; isPrn: boolean };

export const TaskRowContainer = ({ children, isPrn }: Props) => (
    <Box
        sx={{
            display: 'flex',
            border: 1,
            borderColor: isPrn ? 'secondary.300' : 'grey.200',
            padding: pxToRem(16),
            backgroundColor: isPrn ? '#FFFAF8' : '#F8F8F9',
            borderRadius: pxToRem(8),
            marginBottom: pxToRem(8),
        }}
    >
        {children}
    </Box>
);
