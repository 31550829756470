import { api } from '~/api';
import { useV4ErrorHandledQuery } from '~/hooks/useErrorHandledQuery';
import { formatApiParams, useToken } from '~/lib/common';
import { ExportShiftNotesCsvParams } from '~/types/residentShiftNotes';

export const useExportShiftNotesCsvQuery = (jsonParams: ExportShiftNotesCsvParams) => {
    const token = useToken();
    return useV4ErrorHandledQuery(
        ['export-shift-notes-csv', jsonParams],
        async () => {
            const { data } = await api.get<ArrayBuffer>(`/resident-shift-notes/export-csv`, {
                headers: { Authorization: token },
                params: formatApiParams(jsonParams),
                responseType: 'blob',
            });
            return data;
        },
        { enabled: false }
    );
};
