import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import range from 'lodash/range';
import { DateTime } from 'luxon';
import React from 'react';

import { useGetBudgetedScheduled } from '~/scheduling/api/queries/dashboard/getBudgetedScheduled';
import { teamIdAtom } from '~/scheduling/atoms';

import Table, { ColumnType, RowCellWrapper } from './Table';

const formatDateRange = (dateRange: { start: string; end: string }) =>
    `(${DateTime.fromISO(dateRange.start).toFormat('MM/dd')} - ${DateTime.fromISO(dateRange.end).toFormat('MM/dd')})`;

const Cell = ({
    value,
    subValue,
    exceedsThreshold,
    type,
}: {
    value: string;
    subValue: string;
    exceedsThreshold: boolean;
    type: ColumnType;
}) => {
    return (
        <RowCellWrapper type={type} exceedsThreshold={exceedsThreshold}>
            {value}
            {subValue && <small>&nbsp;{subValue}</small>}
        </RowCellWrapper>
    );
};

const BudgetTable = () => {
    const teamId = useAtomValue(teamIdAtom);
    const { data, isPending, error } = useGetBudgetedScheduled(teamId);

    const headers = ['', 'Budgeted Hours', 'Scheduled Hours', 'Agency Hours', 'Overtime Hours'];
    const rows = React.useMemo(
        () =>
            data?.map(({ title, dateRange, values }) => [
                { value: title, subValue: formatDateRange(dateRange), exceedsThreshold: false },
                { value: values.budgetedHours.toString(), exceedsThreshold: false },
                { value: values.scheduledHours.toString(), exceedsThreshold: false },
                { value: values.agencyHours.toString(), exceedsThreshold: false },
                { value: values.overtimeHours.toString(), exceedsThreshold: false },
            ]) ?? [],
        [data]
    );

    if (error) {
        return null;
    }

    return (
        <Box sx={{ p: '24px', backgroundColor: '#fff', borderRadius: '8px' }}>
            <Typography variant="body1" fontWeight={700} fontSize="16px">
                Budgeted vs. Scheduled
            </Typography>
            <Box sx={{ height: '24px', backgroundColor: '#fff' }} />
            {isPending ? (
                <Stack spacing="8px">
                    {range(3).map((i) => (
                        <Skeleton key={i} width="100%" height="40px" />
                    ))}
                </Stack>
            ) : (
                <Table
                    schema={['regular', 'regular', 'regular', 'attention', 'attention']}
                    headers={headers}
                    data={rows}
                    CellComponent={Cell}
                />
            )}
        </Box>
    );
};

export default BudgetTable;
