import { Box, Typography, styled, useTheme } from '@mui/material';
import React from 'react';
import { PiClipboard } from 'react-icons/pi';

import { useGetChosenCareLocations } from '~/api/queries/assignments/getChoseCareLocations';
import { useSaveChosenCareLocations } from '~/api/queries/assignments/saveChosenCareLocations';
import { ALL_ASSIGNMENTS_ID } from '~/constants/filters';
import { TabKey } from '~/types/dailyTasks.d';

import CustomDrawer from '../../components/CustomDrawer';
import { LocationSelector } from '../../components/LocationSelectorDialog/LocationSelector';

const IconWrapper = styled(Box)(({ theme }) => ({
    width: 'fit-content',
    padding: '32px',
    backgroundColor: theme.palette.secondary[50] as string,
    borderRadius: '50%',
    marginBottom: '48px',
    marginInline: 'auto',
}));

const Content = styled(Box)(() => ({
    padding: '24px',
    overflowY: 'auto',
}));

interface AssignmentsModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: () => void;
}

export const AssignmentsModal = ({ isOpen, onClose, onSubmit }: AssignmentsModalProps) => {
    const { palette } = useTheme();

    const { data: careLocationsIds } = useGetChosenCareLocations();
    const { mutateAsync: saveChosenAssignments, isPending } = useSaveChosenCareLocations();

    const handleSubmit = async (selectedIds: number[]) => {
        await saveChosenAssignments(selectedIds.filter((id) => id !== ALL_ASSIGNMENTS_ID));
        onSubmit();
    };

    return (
        <CustomDrawer fullScreen isOpen={isOpen} onClose={onClose} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Content>
                <IconWrapper>
                    <PiClipboard size={32} color={palette.secondary[500] as string} />
                </IconWrapper>

                <Typography variant="h1" fontWeight={700} fontSize="24px" lineHeight="33px" color={palette.grey[900]}>
                    What are your assignments for this shift?
                </Typography>
            </Content>

            <LocationSelector
                onSubmit={handleSubmit}
                defaultValue={careLocationsIds}
                currentTab={TabKey.LIVE_CALLS}
                isLoading={isPending}
            />
        </CustomDrawer>
    );
};
