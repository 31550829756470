import { useCallback } from 'react';

import { api } from '~/api';
import { queryClient, useBranchId, useErrorHandledMutation, useToken } from '~/api/common';
import { CreateSlotStaffNotification } from '~/scheduling/api/types/shift-slot/createSlotStaffNotification';

export const useCreateSlotStaffNotification = () => {
    const branchId = useBranchId()!;
    const token = useToken();

    const mutationFn = useCallback(
        async ({ slotId, data }: CreateSlotStaffNotification.Params) => {
            const options = { headers: { Authorization: token } };
            await api.post(`/scheduling/${branchId}/shift-slot/${slotId}/notification`, data, options);
        },
        [branchId, token]
    );

    const onSuccess = useCallback(async () => {
        await queryClient.invalidateQueries({ queryKey: ['suggested-staff', branchId] });
    }, [branchId]);

    return useErrorHandledMutation({ mutationFn, onSuccess });
};
