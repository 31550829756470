import actionTypes from '~/redux/actionTypes';
import { MessageProps } from '~/types/messages';

const messages = (state: MessageProps = {} as MessageProps, action) => {
    switch (action.type) {
        case actionTypes.MESSAGE_SHOW: {
            return action.payload;
        }
        case actionTypes.MESSAGE_HIDE: {
            return {
                ...state,
                open: false,
            };
        }
        default:
            return state;
    }
};

export default messages;
