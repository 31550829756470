import { captureException } from '@sentry/react';
import React, { useEffect } from 'react';
import { isRouteErrorResponse, useNavigate, useRouteError } from 'react-router';

import { CenteredText, CustomButton, CustomButtonContainer, FullScreenContainer } from './shared';

const NavigateError = () => {
    const navigate = useNavigate();
    const error = useRouteError();

    const isRouterError = isRouteErrorResponse(error);

    useEffect(() => {
        if (!error) return;

        if (isRouterError && error.status === 404) navigate('/', { replace: true });
        else captureException(error);
    }, [error]);

    if (isRouterError) return null;

    return (
        <FullScreenContainer>
            <CenteredText>
                An error has occurred.
                <br />
                Please try again later.
            </CenteredText>
            <CustomButtonContainer>
                <CustomButton variant="contained" color="primary" fullWidth onClick={() => window.location.reload()}>
                    Reload
                </CustomButton>
            </CustomButtonContainer>
        </FullScreenContainer>
    );
};

export default NavigateError;
