import { Box } from '@mui/material';
import { useAtom } from 'jotai';
import React from 'react';

import { GetShiftRequestsResult } from '~/scheduling/api/queries/shift-requests/getShiftRequests';

import { selectedRequestsAtom } from '../../../atom';

import { CaregiverCard } from './CaregiverCard';
import { SlotRequestHeader } from './SlotRequestHeader';

interface SlotRequestContentProps {
    shift: GetShiftRequestsResult[number];
    isMultiSelect: boolean;
}

export const SlotRequestContent = ({ shift, isMultiSelect }: SlotRequestContentProps) => {
    const [selectedRequestIds, setSelectedRequestIds] = useAtom(selectedRequestsAtom);

    const hasOnlyOneRequest = shift.requests.length === 1;

    const handleSelectedRequest = (requestId: number) => {
        if (hasOnlyOneRequest) return;

        if (selectedRequestIds.includes(requestId)) {
            setSelectedRequestIds(selectedRequestIds.filter((id) => id !== requestId));
        } else {
            setSelectedRequestIds(isMultiSelect ? [...selectedRequestIds, requestId] : [requestId]);
        }
    };

    return (
        <Box display="flex" flexDirection="column">
            <SlotRequestHeader shift={shift} isMultiSelect={isMultiSelect} />
            <Box overflow="auto" marginTop="16px" sx={{ scrollbarWidth: 'thin' }}>
                {shift.requests.map((request) => (
                    <CaregiverCard
                        key={request.id}
                        request={request}
                        onClick={handleSelectedRequest}
                        selected={hasOnlyOneRequest || selectedRequestIds.includes(request.id)}
                    />
                ))}
            </Box>
        </Box>
    );
};
