import { useMutation } from '@tanstack/react-query-v4';
import { AxiosError } from 'axios';

import { api } from '~/api';
import { useAppDispatch } from '~/constants/redux';
import { useToken } from '~/lib/common';
import { throwError } from '~/redux/actions/messages';
import { signOut } from '~/redux/actions/session';

export const useRequestDeleteAccountMutation = () => {
    const dispatch = useAppDispatch();
    const token = useToken();

    return useMutation({
        mutationFn: async () => {
            const { data } = await api.post('/delete-account/request', null, {
                headers: {
                    Authorization: token,
                },
            });
            return data.response;
        },
        onError: (error) => {
            const customError = error as AxiosError;
            dispatch(throwError(customError));
            throw customError;
        },
        retry: false,
    });
};

export const useConfirmDeleteAccountMutation = () => {
    const dispatch = useAppDispatch();
    const token = useToken();

    type Params = {
        code: string;
    };

    return useMutation({
        mutationFn: async (params: Params) => {
            const { data } = await api.post(
                '/delete-account/confirm',
                { code: params.code },
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );
            return data.response;
        },
        onSuccess: () => {
            dispatch(signOut());
        },
        onError: (error) => {
            const customError = error as AxiosError;
            dispatch(throwError(customError));
            throw customError;
        },
        retry: false,
    });
};
