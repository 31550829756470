import { Box, Typography, styled } from '@mui/material';
import { DataGrid as DataGridMui, GridCellParams, GridColDef, gridClasses } from '@mui/x-data-grid';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { STANDARDIZED_STAFF_SCHEDULE_TYPES_LABELS } from '@allie/utils/src/constants/scheduling/staff-schedule-types.constants';
import { STANDARDIZED_STAFF_TYPES_LABELS } from '@allie/utils/src/constants/scheduling/staff-types.constants';

import { StaffList } from '../../types';
import { useStaffList } from '../../useStaffList';

const DataGridHeader = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary[500],
    fontWeight: 700,
}));

export const DataGrid = styled(DataGridMui)(({ theme }) => ({
    [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
        outline: 'transparent',
    },

    [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]: {
        outline: 'none',
    },

    [`& .${gridClasses.cell}`]: {
        flex: 1,
        '--width': 'unset !important',
    },

    [`& .${gridClasses.cellEmpty}`]: {
        display: 'none !important',
    },

    [`& .${gridClasses.row}`]: {
        cursor: 'pointer',
    },

    [`& .${gridClasses.columnHeader}`]: {
        backgroundColor: theme.palette.primary[50] as string,
        flex: 1,
        width: 'unset !important',
        minWidth: 'unset !important',
        maxWidth: 'unset !important',
    },

    [`& .${gridClasses.filler}, & .${gridClasses['scrollbarFiller--header']}`]: {
        flex: 'unset',
    },
}));

// need to cast because of styled TypeScript bug where component prop is not recognized https://mui.com/material-ui/guides/typescript/#complications-with-the-component-prop
const CustomLink = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[600],
    textDecoration: 'underline',
    cursor: 'pointer',

    '&:hover': {
        fontWeight: 'bold',
        textDecoration: 'none',
    },
})) as typeof Typography;

const parseDate = (date: string) => {
    return DateTime.fromFormat(date, 'MM/dd/yy');
};

const formatDate = (date: string) => {
    return DateTime.fromISO(date).toFormat('MM/dd/yy');
};

const getFirstDateFromVacationInterval = (vacationInterval: string) => {
    return vacationInterval.split(' - ')[0];
};

const getColumns: GridColDef[] = [
    { field: 'staffName', renderHeader: () => <DataGridHeader>Staff Name</DataGridHeader> },
    {
        field: 'staffType',
        renderHeader: () => <DataGridHeader>Staff Type</DataGridHeader>,
        sortable: false,
        valueGetter: (_value, row: StaffList.StaffData) =>
            STANDARDIZED_STAFF_TYPES_LABELS[row.staffType] ?? row.staffType,
    },
    {
        field: 'mainlyServe',
        renderHeader: () => <DataGridHeader>Mainly Serve</DataGridHeader>,
        sortable: false,
    },
    {
        field: 'staffRole',
        headerName: 'Staff Role',
        renderHeader: () => <DataGridHeader>Staff Role</DataGridHeader>,
        sortable: false,
        valueGetter: (_value, row: StaffList.StaffData) =>
            (row.staffRoles.find((role) => role.primary) ?? row.staffRoles[0]).name,
    },
    {
        field: 'scheduleType',
        renderHeader: () => <DataGridHeader>Schedule Type</DataGridHeader>,
        sortable: false,
        valueGetter: (_value, row: StaffList.StaffData) =>
            row.currentSchedule ? STANDARDIZED_STAFF_SCHEDULE_TYPES_LABELS[row.currentSchedule.type] : '-',
    },
    {
        field: 'upcomingVacation',
        valueGetter: (_value, row: StaffList.StaffData) =>
            row.upcomingVacation
                ? `${formatDate(row.upcomingVacation.startDate)} - ${formatDate(row.upcomingVacation.endDate)}`
                : '-',
        renderHeader: () => <DataGridHeader>Upcoming Vacation</DataGridHeader>,
        sortComparator: (a: string, b: string) => {
            if (a === '-') return 1;
            if (b === '-') return -1;

            const startDateA = getFirstDateFromVacationInterval(a);
            const startDateB = getFirstDateFromVacationInterval(b);

            return parseDate(startDateA) < parseDate(startDateB) ? -1 : 1;
        },
    },
    {
        field: 'view',
        headerName: '',
        renderCell: () => (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CustomLink variant="body1">View</CustomLink>
            </Box>
        ),
        resizable: false,
        sortable: false,
    },
    {
        field: 'edit',
        headerName: '',
        renderCell: ({ row }: { row: StaffList.StaffData }) => (
            <CustomLink component={RouterLink} to={`staff-details/${row.id}`}>
                Edit
            </CustomLink>
        ),
        resizable: false,
        sortable: false,
    },
];

interface StaffTableProps {
    staffNameFilter: string;
    onRowClick: (row: StaffList.StaffData) => void;
}

export const StaffTable = ({ staffNameFilter, onRowClick }: StaffTableProps) => {
    const { data, isLoading } = useStaffList();

    // the data grid columns must keep the same reference between renders otherwise the column sizing will break
    const gridColumns = useMemo(() => getColumns, []);

    const handleCellClick = React.useCallback(
        (cell: GridCellParams<StaffList.StaffData>) => {
            // workaround to avoid miss clicks on the edit button and the modal be opened
            if (cell.field !== 'edit') {
                onRowClick(cell.row);
            }
        },
        [onRowClick]
    );

    return (
        <Box sx={{ flex: 1 }}>
            <DataGrid
                rows={data}
                columns={gridColumns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 }, // Initial page size must be specified, otherwise it breaks the autoPageSize
                    },
                    sorting: { sortModel: [{ field: 'staffName', sort: 'asc' }] },
                }}
                filterModel={{
                    items: [{ field: 'staffName', operator: 'contains', value: staffNameFilter }],
                }}
                pageSizeOptions={[5, 10]}
                autoPageSize
                autosizeOnMount
                autosizeOptions={{ expand: true }}
                disableColumnMenu
                onCellClick={handleCellClick}
                loading={isLoading}
            />
        </Box>
    );
};
