import { Box, Typography, styled, useTheme } from '@mui/material';
import { Clipboard } from '@phosphor-icons/react';
import React from 'react';

import SquareButton from '~/components/Shared/Button/SquareButton';

import CustomDrawer from './CustomDrawer';
import { ShiftOptions } from './HeaderV2/components/types';

type ShiftSelectorDialogProps = {
    isOpen: boolean;
    selectedShiftId?: number;
    shiftOptions: ShiftOptions;
    onShiftChange: (newShiftId: number) => void;
    onClose: () => void;
};

const Content = styled(Box)(() => ({
    padding: '16px 24px',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
}));

export const ShiftSelectorDialog = ({
    isOpen,
    onClose,
    shiftOptions,
    selectedShiftId,
    onShiftChange,
}: ShiftSelectorDialogProps) => {
    const { palette } = useTheme();

    const dropdownOptions = shiftOptions.map(({ shift: { id, name } }) => ({
        label: name,
        value: String(id),
    }));

    const getLabel = (id: number) => {
        const [previous, current, next] = shiftOptions;

        if (previous.shift.id === id) {
            return 'Previous';
        }

        if (current.shift.id === id) {
            return 'Current';
        }

        if (next.shift.id === id) {
            return 'Next';
        }

        return '';
    };

    return (
        <CustomDrawer
            isOpen={isOpen}
            onClose={onClose}
            sx={{ height: 'min-content' }}
            closeButton
            header={
                <>
                    <Box padding="4px">
                        <Clipboard size={24} weight="bold" color={palette.secondary[500] as string} />
                    </Box>
                    <Typography variant="h6" fontSize="16px" color={palette.grey[900]} letterSpacing="unset">
                        Shift
                    </Typography>
                </>
            }
        >
            <Content>
                {dropdownOptions.map(({ label, value: id }) => (
                    <SquareButton
                        key={id}
                        label={getLabel(Number(id))}
                        value={label}
                        onClick={() => onShiftChange(+id)}
                        selected={selectedShiftId === +id}
                    />
                ))}
            </Content>
        </CustomDrawer>
    );
};
