import { useMutation } from '@tanstack/react-query-v4';
import { AxiosError } from 'axios';
import { useCallback } from 'react';

import { api } from '~/api';
import { showToast } from '~/components/Shared/Alerting/Toast/utils/showToast';
import { useAppDispatch } from '~/constants/redux';
import { useBranchId, useToken } from '~/lib/common';
import { handleError, throwError } from '~/redux/actions/messages';
import { BaseResponse } from '~/types/shared';

export const useAudioTranscribeMutation = () => {
    const token = useToken();
    const branchId = useBranchId();
    const dispatch = useAppDispatch();

    const mutationFn = useCallback(
        async (form: FormData): Promise<string> => {
            const options = {
                params: { branch_id: branchId },
                headers: { authorization: token },
            };

            const { data } = await api.post<BaseResponse<{ text: string }>>(
                `/resident-shift-notes/transcribe-audio`,
                form,
                options
            );

            if (data.status !== 'OK') {
                const alertMessage = 'There was an error while transcribing your message. Please try again';
                showToast({ message: alertMessage, type: 'error' });
                return '';
            }

            return data.response.text;
        },
        [branchId, token]
    );

    const onError = useCallback(
        (error: unknown) => {
            const customError = error as AxiosError;
            const alertMessage = 'There was an error while transcribing your message. Please try again';
            handleError({ alertMessage, consoleMessage: customError.message, error: customError });
            dispatch(throwError(customError, true, true));
        },
        [dispatch]
    );

    return useMutation({ mutationFn, onError, retry: false });
};
