import { useCallback } from 'react';

import { api } from '~/api';
import { queryClient, useBranchId, useErrorHandledMutation, useToken } from '~/api/common';

export const useApproveShiftRequests = () => {
    const branchId = useBranchId()!;
    const token = useToken();

    const mutationFn = useCallback(
        async (data: { requests: number[] }) => {
            const options = { headers: { Authorization: token } };
            await api.post(`/scheduling/${branchId}/shift-requests/approve-requests`, data, options);
        },
        [branchId, token]
    );

    const onSuccess = useCallback(async () => {
        // Schedule
        await queryClient.invalidateQueries({ queryKey: ['full-schedule', branchId] });
        await queryClient.invalidateQueries({ queryKey: ['suggested-staff', branchId] });

        // Dashboard
        await queryClient.invalidateQueries({ queryKey: ['dashboard-alerts', branchId] });
        await queryClient.invalidateQueries({ queryKey: ['open-shifts', branchId] });
        await queryClient.invalidateQueries({ queryKey: ['budgeted-scheduled', branchId] });

        // Reports
        await queryClient.invalidateQueries({ queryKey: ['overtime-staff', branchId] });
        await queryClient.invalidateQueries({ queryKey: ['scheduled-hours', branchId] });
    }, [branchId]);

    return useErrorHandledMutation({ mutationFn, onSuccess });
};
