import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';

import { UnscheduledCareSectionDetail } from '~/types/operationsDashboard';

import { formatDateTime } from '../constants';

type Props = {
    rows: UnscheduledCareSectionDetail[];
};

export function UnscheduledCareCollapsibleComponent({ rows }: Props) {
    const headers = ['PRN', 'Caregiver', 'Recorded At'];

    function renderRow(item: UnscheduledCareSectionDetail) {
        return (
            <TableRow key={[item.caregiver, item.prnDateTime, item.prnName].join('')}>
                <TableCell align="left">{item.prnName}</TableCell>
                <TableCell align="left">{item.caregiver}</TableCell>
                <TableCell align="left">{formatDateTime(item.prnDateTime)}</TableCell>
            </TableRow>
        );
    }

    return (
        <TableContainer>
            <Table aria-label="details row">
                <TableHead>
                    <TableRow>
                        {headers.map((header) => (
                            <TableCell align="left" key={header}>
                                {header}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>{rows.map(renderRow)}</TableBody>
            </Table>
        </TableContainer>
    );
}
