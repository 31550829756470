import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

const formatTimer = (time: DateTime) => time.diffNow().negate().toFormat('mm:ss');

export const useTimer = (startDate: DateTime) => {
    const [timer, setTimer] = useState<string>();

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(formatTimer(startDate));
        }, 1000);

        return () => clearInterval(interval);
    }, [startDate]);

    return timer ?? formatTimer(startDate);
};
