import { useCallback } from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/api/common';
import { GetRoles } from '~/scheduling/api/types/staff-roles/getRoles';

export type GetRolesResult = NonNullable<ReturnType<typeof useGetRoles>['data']>;

export const useGetRoles = (teamId?: number) => {
    const branchId = useBranchId()!;
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = { headers: { Authorization: token }, params: teamId && { teamId } };
        const { data } = await api.get<GetRoles.Response>(`/scheduling/${branchId}/staff-roles`, options);

        const roles = data.response;
        const roleById = new Map(roles.map((slot) => [slot.id, slot]));

        const roleShifts = roles?.map(({ staffRoleShifts }) => staffRoleShifts).flat() ?? [];
        const roleShiftById = new Map(roleShifts.map((roleShift) => [roleShift.id, roleShift]));

        return { roles, roleById, roleShifts, roleShiftById };
    }, [branchId, token, teamId]);

    return useErrorHandledQuery({ queryKey: ['roles', branchId, teamId], queryFn, staleTime: 300000 });
};
