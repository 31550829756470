import { Box, styled } from '@mui/material';

type GridContainerProps = {
    minmax: number;
};

const GridContainer = styled(Box)<GridContainerProps>(({ theme, minmax }) =>
    theme.unstable_sx({
        width: '100%',
        display: 'grid',
        gridTemplateColumns: {
            xs: '1fr',
            lg: `repeat(auto-fill, minmax(${minmax}px, 1fr))`,
        },
        gap: '12px',
        transition: 'height 0.15s ease-out',
        overflow: 'hidden',
    })
);

export default GridContainer;
