import { Box, Button, Typography } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { DateTime } from 'luxon';
import React from 'react';

import { GetTeamsResult, useGetTeams } from '~/scheduling/api/queries/teams/getTeams';

import SegmentedModal from '../../../../components/SegmentedModal';
import { MultiCalendar } from '../../../../components/calendar';
import { Dropdown } from '../../../../components/form/Dropdown';
import { isPublishNewScheduleModalOpenAtom, publishNextScheduleModalDateRange } from '../../atom';
import { PUBLISH_NEXT_SCHEDULE_PERIOD_OPTIONS } from '../../constants';
import { Dashboard } from '../../types';

import { formatPeriodString, mapPeriodTypeToDates } from './util';

const SelectPeriod = () => {
    const [dateRange, setDateRange] = useAtom(publishNextScheduleModalDateRange);

    const [selectedPeriodType, setSelectedPeriodType] = React.useState<Dashboard.PublishNextSchedulePeriod>();

    function handleSelectorChange(value: Dashboard.PublishNextSchedulePeriod) {
        setSelectedPeriodType(value);

        if (value === 'CUSTOM_PERIOD') return;

        setDateRange(mapPeriodTypeToDates[value](DateTime.now()));
    }

    return (
        <Box width="100%">
            <Typography sx={{ fontSize: '12px', fontWeight: 700, color: 'grey.900', marginBottom: '4px' }}>
                Select period time
            </Typography>
            <Dropdown
                onChange={handleSelectorChange}
                options={PUBLISH_NEXT_SCHEDULE_PERIOD_OPTIONS}
                value={selectedPeriodType}
            />
            <MultiCalendar dateRange={dateRange} setDateRange={setDateRange} />
        </Box>
    );
};

interface SelectTeamProps {
    selectedTeamId: number;
    teams: GetTeamsResult['teams'];
    onChange: (teamId: number) => void;
}

const SelectTeam = (props: SelectTeamProps) => {
    return (
        <Box width="100%">
            <Typography sx={{ fontSize: '12px', fontWeight: 700, color: 'grey.900', marginBottom: '4px' }}>
                Select Team
            </Typography>
            <Dropdown
                value={props.selectedTeamId.toString()}
                onChange={props.onChange}
                options={props.teams.map((team) => ({ value: team.id, label: team.name }))}
            />
        </Box>
    );
};

enum PageState {
    SelectTeam,
    Calendar,
}

interface PublishNewScheduleActionsProps {
    currentPageState: PageState;
    onNext: () => void;
    onPublish: () => void;
}

const PublishNewScheduleActions = (props: PublishNewScheduleActionsProps) => {
    const dateRange = useAtomValue(publishNextScheduleModalDateRange);

    return (
        <>
            {props.currentPageState === PageState.SelectTeam ? (
                <Button onClick={props.onNext}>Next</Button>
            ) : (
                <Box
                    display="flex"
                    justifyContent={dateRange ? 'space-between' : 'flex-end'}
                    width="100%"
                    alignItems="center"
                >
                    {dateRange && (
                        <Typography variant="body1" fontWeight={700} color="grey.500">
                            {formatPeriodString(dateRange)}
                        </Typography>
                    )}
                    <Button onClick={props.onPublish}>Publish</Button>
                </Box>
            )}
        </>
    );
};

const PublishNewScheduleModal = () => {
    const [isOpen, toggle] = useAtom(isPublishNewScheduleModalOpenAtom);
    const [selectedTeamId, setSelectedTeamId] = React.useState<number>();
    const [pageState, setPageState] = React.useState<PageState>(PageState.SelectTeam);

    const { data: teamData } = useGetTeams();
    const teams = teamData?.teams ?? [];

    const handlePublish = () => {
        setPageState(PageState.SelectTeam);
        toggle();
    };

    const handleNext = () => {
        setPageState(PageState.Calendar);
    };

    return (
        <SegmentedModal
            sx={{ maxWidth: '520px' }}
            header="Publish New Schedule"
            isOpen={isOpen}
            onClose={toggle}
            actions={
                <PublishNewScheduleActions currentPageState={pageState} onNext={handleNext} onPublish={handlePublish} />
            }
            closeButton
        >
            {teams.length > 1 && pageState === PageState.SelectTeam ? (
                <SelectTeam selectedTeamId={selectedTeamId ?? teams[0].id} teams={teams} onChange={setSelectedTeamId} />
            ) : (
                <SelectPeriod />
            )}
        </SegmentedModal>
    );
};

export default PublishNewScheduleModal;
