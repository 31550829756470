import React from 'react';

import Base from '~/components/Svg/Base';

type Props = {
    className?: string;
    size?: number;
};

// eslint-disable-next-line import/prefer-default-export
export const LovelyIcon = ({ className, size }: Props) => (
    <Base className={className} size={size}>
        <path
            d="M18.7998 9.91016C17.6698 9.91016 16.6598 10.4602 16.0298 11.3002C15.3998 10.4602 14.3898 9.91016 13.2598 9.91016C11.3498 9.91016 9.7998 11.4702 9.7998 13.3902C9.7998 14.1302 9.9198 14.8202 10.1198 15.4502C11.0998 18.5602 14.1398 20.4302 15.6398 20.9402C15.8498 21.0102 16.1998 21.0102 16.4098 20.9402C17.9098 20.4302 20.9498 18.5702 21.9298 15.4502C22.1398 14.8102 22.2498 14.1302 22.2498 13.3902C22.2598 11.4702 20.7098 9.91016 18.7998 9.91016Z"
            fill="#FA9C7A"
        />
        <path
            d="M20.75 8.33961C20.75 8.56961 20.52 8.71961 20.3 8.65961C18.95 8.30961 17.47 8.59961 16.35 9.39961C16.13 9.55961 15.83 9.55961 15.62 9.39961C14.83 8.81961 13.87 8.49961 12.86 8.49961C10.28 8.49961 8.18 10.6096 8.18 13.2096C8.18 16.0296 9.53 18.1396 10.89 19.5496C10.96 19.6196 10.9 19.7396 10.81 19.6996C8.08 18.7696 2 14.9096 2 8.33961C2 5.43961 4.33 3.09961 7.21 3.09961C8.92 3.09961 10.43 3.91961 11.38 5.18961C12.34 3.91961 13.85 3.09961 15.55 3.09961C18.42 3.09961 20.75 5.43961 20.75 8.33961Z"
            fill="#FA9C7A"
        />
    </Base>
);
