import { Dashboard } from './types.d';

export const PUBLISH_NEXT_SCHEDULE_PERIOD_OPTIONS: {
    value: Dashboard.PublishNextSchedulePeriod;
    label: string;
}[] = [
    { value: 'END_OF_WEEK', label: 'Until end of this week' },
    { value: 'END_OF_NEXT_WEEK', label: 'Until end of next week' },
    { value: 'END_OF_TWO_WEEKS', label: 'Until end of two weeks from now' },
    { value: 'END_OF_NEXT_MONTH', label: 'Until end of next month' },
    { value: 'CUSTOM_PERIOD', label: 'Custom period' },
];

export const PRINT_SCHEDULE_PERIOD_OPTIONS: {
    value: Dashboard.PrintSchedulePeriod;
    label: string;
}[] = [
    { value: 'THIS_WEEK', label: 'This week' },
    { value: 'THIS_MONTH', label: 'This month' },
    { value: 'CUSTOM_PERIOD', label: 'Custom period time' },
];

export const ALL_LOCATIONS_ID = -1;
