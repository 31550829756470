import React from 'react';

import Base from '~/components/Svg/Base';

type Props = {
    className?: string;
    size?: number | string;
};

export const InjectionIcon = ({ className, size }: Props) => (
    <Base className={className} size={size} viewBox="0 0 16 16">
        <path
            d="M7.9974 3.73999L6.1574 5.56666L8.36406 7.77332L7.6574 8.47999L5.45073 6.27332L4.27073 7.45332L6.4774 9.65999L5.77073 10.3667L3.56406 8.15999L2.23073 9.49332C2.01886 9.69855 1.88102 9.96824 1.8388 10.2602C1.79658 10.5521 1.85235 10.8498 1.9974 11.1067L2.66406 12.3667L1.16406 13.8933L2.10406 14.8333L3.63073 13.3333L4.89073 14C5.09393 14.1152 5.32383 14.1749 5.5574 14.1733C5.73334 14.1735 5.90757 14.1388 6.07004 14.0713C6.23252 14.0038 6.38002 13.9048 6.50406 13.78L12.2574 7.99999L7.9974 3.73999ZM13.1974 6.13332L12.0041 4.93332L12.9507 3.99332L13.8907 4.93332L14.8307 3.99332L12.0041 1.16666L11.0641 2.10666L12.0041 3.05332L11.0641 3.99332L9.86406 2.79999L8.70406 1.63332L7.76406 2.57999L8.45073 3.26666V3.27332L12.7241 7.54666H12.7307L13.4174 8.23332L14.3641 7.29332L13.2041 6.13332H13.1974Z"
            fill="currentColor"
        />
    </Base>
);
