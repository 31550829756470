import { useAtomValue } from 'jotai';
import { useMemo } from 'react';

import { testNotificationsAtom } from './pages/Schedule/Staff/testData';

export const useScheduling = () => {
    const notifications = useAtomValue(testNotificationsAtom);

    return useMemo(() => ({ notifications }), [notifications]);
};
