import { api } from '~/api';
import { useV4ErrorHandledQuery } from '~/hooks/useErrorHandledQuery';
import { useToken } from '~/lib/common';
import { CompanyCarePlanResponse } from '~/types/companyCarePlans';

export const useCompanyCarePlansListQuery = (branchId?: number) => {
    const token = useToken();

    return useV4ErrorHandledQuery<CompanyCarePlanResponse[]>(
        ['company-care-plans-list'],
        async (): Promise<CompanyCarePlanResponse[]> => {
            const { data } = await api.get('/company-care-plans', {
                headers: { authorization: token },
                params: { branch_id: branchId },
            });
            return data.response;
        },
        { enabled: !!branchId, staleTime: 1800000 }
    );
};
