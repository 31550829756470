import { useMutation, useQueryClient } from '@tanstack/react-query-v4';

import { api } from '~/api';
import { showToast } from '~/components/Shared/Alerting/Toast/utils/showToast';
import { useAppDispatch } from '~/constants/redux';
import { useV4ErrorHandledQuery } from '~/hooks/useErrorHandledQuery';
import { formatApiParams, useToken } from '~/lib/common';
import { throwError } from '~/redux/actions/messages';
import {
    DailyTasksCreateParams,
    DailyTasksReadParams,
    DailyTasksResponse,
    UpdateDailyTasksParams,
} from '~/types/dailyTasks';

function getTaskCreationMessage(created: boolean, response?: { isPrnExpected?: boolean }): string {
    if (created) {
        return 'The task has been Created successfully!';
    }
    if (response?.isPrnExpected) {
        return "This PRN task was not created because it is already part of the resident's service plan or schedule. Thanks for reporting it!";
    }
    return 'This PRN was not created! Please try again later.';
}

const getToastType = (created: boolean, isPrnExpected?: boolean) => {
    if (created) {
        return 'success';
    }
    if (isPrnExpected) {
        return 'info';
    }
    return 'warning';
};

export const useDailyTasks = (jsonParams: DailyTasksReadParams) => {
    const params = formatApiParams(jsonParams);
    const token = useToken();

    return useV4ErrorHandledQuery<DailyTasksResponse>(
        ['dailyTasks', jsonParams],
        async () => {
            const { data } = await api.get<{ response: DailyTasksResponse }>('/tasks', {
                headers: { authorization: token },
                params,
            });
            return data.response ?? {};
        },
        {
            enabled: !!jsonParams.branchId && !!jsonParams.date,
            staleTime: 300000,
        }
    );
};

export const useCreateDailyTask = () => {
    const token = useToken();
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();

    return useMutation(
        async (jsonParams: DailyTasksCreateParams) => {
            const params = formatApiParams(jsonParams);

            const { data, status } = await api.post('/tasks', params, {
                headers: { authorization: token },
                params: { branch_id: jsonParams.branchId },
            });

            return { status, response: data.response };
        },
        {
            onSuccess: async (data) => {
                const { status, response } = data;
                const created = status === 201;

                showToast({
                    message: getTaskCreationMessage(created, response),
                    duration: 3000,
                    type: getToastType(created, response?.isPrnExpected),
                });

                await Promise.all([
                    queryClient.invalidateQueries({ queryKey: ['user-fraud-status'] }),
                    queryClient.invalidateQueries({ queryKey: ['dailyTasks'] }),
                    // TODO: specify the residentId
                    queryClient.invalidateQueries({ queryKey: ['residentDailyTasks'] }),
                    queryClient.invalidateQueries({ queryKey: ['rewards'] }),
                ]);
            },
            onError: (error: any) => {
                console.error('Error creating task:', error);
                dispatch(throwError(error));
            },
        }
    );
};

export const useUpdateDailyTasks = () => {
    const token = useToken();
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();

    return useMutation(
        async ({ tasks, branchId }: UpdateDailyTasksParams) => {
            const { status } = await api.put(`/tasks`, tasks, {
                headers: { authorization: token },
                params: { branch_id: branchId },
            });

            return { status };
        },
        {
            onSuccess: async (data) => {
                const { status } = data;
                const success = status === 200;

                showToast({
                    message: success ? 'The task has been Updated successfully!' : 'Error updating task.',
                    duration: 3000,
                    type: success ? 'success' : 'error',
                });
                await Promise.all([
                    queryClient.invalidateQueries({ queryKey: ['documentation-actions'] }),
                    queryClient.invalidateQueries({ queryKey: ['dailyTasks'] }),
                    // TODO: specify the residentId
                    queryClient.invalidateQueries({ queryKey: ['residentDailyTasks'] }),
                ]);
            },
            onError: (error: any) => {
                console.error('Error updating task:', error);
                dispatch(throwError(error));
            },
        }
    );
};
