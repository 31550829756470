import { useAtom, useSetAtom } from 'jotai';
import React from 'react';

import CustomNotificationBell from '~/components/Custom/CustomNotificationBell';
import { isLocationSelectorDialogOpenAtom, selectedTabAtom } from '~/pages/Home/atom';
import { usePermissions } from '~/permissions/utils';
import { TabKey } from '~/types/dailyTasks.d';

import useHeaderV2 from '../../../hooks/useHeaderV2';

const LiveCallsNotificationBell = () => {
    const hasPermission = usePermissions();
    const isHeaderV2Enabled = useHeaderV2();
    const hasECallsProduct = hasPermission('Community', 'ecall-caregiver');
    const toggleLocationSelectorDialog = useSetAtom(isLocationSelectorDialogOpenAtom);
    const [selectedTab, setSelectedTab] = useAtom(selectedTabAtom);

    if (!isHeaderV2Enabled || !hasECallsProduct) {
        return null;
    }

    const handlePress = () => {
        if (selectedTab !== TabKey.LIVE_CALLS) {
            setSelectedTab(TabKey.LIVE_CALLS);
        }
        toggleLocationSelectorDialog();
    };

    return <CustomNotificationBell onPress={handlePress} />;
};

export default LiveCallsNotificationBell;
