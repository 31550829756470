import { Box, FormControl, SelectProps, Typography, useTheme } from '@mui/material';
import React from 'react';
import { Control, Controller, FieldValues, RegisterOptions, UseFormReturn } from 'react-hook-form';

import { Dropdown } from './Dropdown';

interface FormDropdownOption {
    value: string | number;
    label: string;
}
export interface FormDropdownOptions extends Array<FormDropdownOption> {}
export interface FormInputProps<T extends FieldValues> {
    name: string;
    form: UseFormReturn<T>;
    label?: string;
    options: FormDropdownOptions;
    placeholder?: string;
    selectProps?: SelectProps;
    icon?: React.ReactNode;
    validations?: RegisterOptions;
}

export const FormDropdown = <T extends FieldValues>({
    name,
    form,
    label,
    placeholder,
    icon,
    options,
    selectProps,
    validations,
}: FormInputProps<T>) => {
    const { palette } = useTheme();

    return (
        <Box sx={{ flex: 1 }}>
            {!!label && (
                <Typography sx={{ fontSize: '12px', fontWeight: 700, color: 'grey.900', marginBottom: '4px' }}>
                    {label}
                </Typography>
            )}

            <FormControl sx={{ width: '100%' }}>
                <Controller
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <>
                            <Dropdown
                                key={JSON.stringify(selectProps?.defaultValue ?? '')}
                                placeholder={placeholder}
                                icon={icon}
                                options={options}
                                selectProps={selectProps}
                                value={value as string}
                                error={!!error}
                                onChange={onChange}
                            />
                            {error && (
                                <Typography
                                    variant="body1"
                                    color={palette.error[500] as string}
                                    fontWeight={400}
                                    fontSize="12px"
                                    mt="4px"
                                    marginInline="10px"
                                >
                                    {error.message}
                                </Typography>
                            )}
                        </>
                    )}
                    rules={validations}
                    control={form.control as Control<FieldValues>}
                    name={name}
                />
            </FormControl>
        </Box>
    );
};
