import { Box, Button, styled } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import React from 'react';

import { NumberInput } from '../../../../components/NumberInput';
import { isChangeBudgetDialogOpenAtom, slotToHaveBudgetChangedAtom } from '../../atom';
import { useUpdateBudgets } from '../../useUpdateBudgets';

const ChangeBudgetDialogWrapper = styled(Box)<{ pushToTheLeft?: boolean }>(({ theme, pushToTheLeft }) => ({
    position: 'absolute',
    bottom: 0,
    right: pushToTheLeft ? undefined : 0,
    left: pushToTheLeft ? 0 : undefined,
    padding: '8px',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.grey[100]}`,
    backgroundColor: '#FFF',
    boxShadow: '0px 5px 8px 0px rgba(57, 57, 57, 0.12)',
    zIndex: 1,
}));

export const ChangeBudgetDialog = ({ pushToTheLeft }: { pushToTheLeft: boolean }) => {
    const [isOpen, toggle] = useAtom(isChangeBudgetDialogOpenAtom);
    const slotToHaveBudgetChanged = useAtomValue(slotToHaveBudgetChangedAtom);
    const [newBudgets, setNewBudgets] = React.useState<Record<number, number> | null>(null);

    const { modifyBudgetSlot, getSlotModifications } = useUpdateBudgets();

    const slotModifications = React.useMemo(() => {
        const modifications = slotToHaveBudgetChanged && getSlotModifications(slotToHaveBudgetChanged);

        return modifications?.reduce<Record<number, number>>((agg, mod) => ({ ...agg, [mod.roleId]: mod.budget }), {});
    }, [getSlotModifications, slotToHaveBudgetChanged]);

    if (!isOpen || !slotToHaveBudgetChanged) {
        return null;
    }

    const updatedBudgets = newBudgets ?? slotModifications ?? {};

    const handleChange = (roleId: number, newBudget: number) => {
        setNewBudgets({ ...updatedBudgets, [roleId]: newBudget });
    };

    const handleSave = () => {
        if (newBudgets) {
            modifyBudgetSlot({
                budgets: slotToHaveBudgetChanged.slot.budgets.map(({ budget, role, shift }) => ({
                    role,
                    shift,
                    budget: newBudgets[role.id] ?? budget,
                })),
                day: slotToHaveBudgetChanged.day,
                shiftIndex: slotToHaveBudgetChanged.shift.index,
                shiftType: slotToHaveBudgetChanged.shift.shiftType,
                location: slotToHaveBudgetChanged.slot.location,
            });
        }
        toggle();
    };

    const handleCancel = () => {
        setNewBudgets(null);
        toggle();
    };

    const budgets = slotToHaveBudgetChanged.slot.budgets;

    return (
        <ChangeBudgetDialogWrapper pushToTheLeft={pushToTheLeft} onClick={(event) => event.stopPropagation()}>
            {budgets.map(({ budget, role }) => (
                <React.Fragment key={role.id}>
                    <NumberInput
                        min={0}
                        label={role.name}
                        value={updatedBudgets[role.id] ?? budget}
                        onChange={(_, newBudget) => handleChange(role.id, newBudget)}
                    />
                    <Box mt="12px" />
                </React.Fragment>
            ))}
            <Box display="flex" justifyContent="flex-end" gap="4px">
                <Button size="small" variant="outlined" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button size="small" onClick={handleSave}>
                    Confirm
                </Button>
            </Box>
        </ChangeBudgetDialogWrapper>
    );
};
