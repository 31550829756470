import { Button, CircularProgress } from '@mui/material';
import React from 'react';

type Props = {
    label: string;
    onClick?: () => void;
    isDisabled: boolean;
    isSubmit?: boolean;
    isLoading?: boolean;
};

const AuthButton = ({ label, onClick, isDisabled, isSubmit, isLoading }: Props) => {
    const handleClick = () => {
        if (onClick) onClick();
    };

    return (
        <Button
            variant="contained"
            size="large"
            onClick={handleClick}
            fullWidth
            disabled={isDisabled || isLoading}
            type={isSubmit ? 'submit' : 'button'}
        >
            {isLoading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : label}
        </Button>
    );
};

export default AuthButton;
