import { api } from '~/api';
import { useV4ErrorHandledQuery } from '~/hooks/useErrorHandledQuery';
import { formatApiParams, useToken } from '~/lib/common';
import { CaregiverPerformanceResponse } from '~/types/caregiverPerformanceDashboard';

type DashboardReadParams = {
    branchId: number;
    startPeriod?: string;
    endPeriod?: string;
};

export const useCaregiverPerformanceDetails = (jsonParams: DashboardReadParams) => {
    const params = jsonParams ? formatApiParams(jsonParams) : undefined;
    const token = useToken();

    return useV4ErrorHandledQuery(
        ['caregiver-performance-details', jsonParams],
        async (): Promise<CaregiverPerformanceResponse> =>
            (
                await api.get(`/dashboard/performance-details`, {
                    headers: {
                        authorization: token,
                    },
                    params,
                })
            ).data.response,
        { enabled: !!(jsonParams?.startPeriod || jsonParams?.endPeriod), staleTime: 300000 }
    );
};
