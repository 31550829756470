import { Box, Typography, useTheme } from '@mui/material';
import { useAtom } from 'jotai';
import React from 'react';
import { PiBellBold, PiMapPinBold } from 'react-icons/pi';

import { useGetChosenCareLocations } from '~/api/queries/assignments/getChoseCareLocations';
import { useSaveChosenCareLocations } from '~/api/queries/assignments/saveChosenCareLocations';
import { ALL_ASSIGNMENTS_ID } from '~/constants/filters';
import { TabKey } from '~/types/dailyTasks.d';

import { selectedAssignmentIdsAtom } from '../../atom';
import CustomDrawer from '../CustomDrawer';

import { LocationSelector } from './LocationSelector';

interface LocationSelectorDialogProps {
    isOpen: boolean;
    selectedTab: TabKey;
    onClose: () => void;
}

export const LocationSelectorDialog = ({ isOpen, selectedTab, onClose }: LocationSelectorDialogProps) => {
    const { palette } = useTheme();
    const [locationIds, setLocationIds] = useAtom(selectedAssignmentIdsAtom);

    const { data: careLocationsIds } = useGetChosenCareLocations();
    const { mutateAsync: saveChosenAssignments, isPending } = useSaveChosenCareLocations();

    const Icon = selectedTab === TabKey.LIVE_CALLS ? PiBellBold : PiMapPinBold;
    const defaultValue = selectedTab === TabKey.LIVE_CALLS ? careLocationsIds : locationIds;

    const handleSubmit = async (selectedIds: number[]) => {
        if (selectedTab === TabKey.LIVE_CALLS) {
            await saveChosenAssignments(selectedIds.filter((id) => id !== ALL_ASSIGNMENTS_ID));
        } else {
            setLocationIds(selectedIds);
        }
        onClose();
    };

    return (
        <CustomDrawer
            isOpen={isOpen}
            onClose={onClose}
            closeButton
            sx={{ display: 'flex', flexDirection: 'column' }}
            header={
                <>
                    <Box padding="4px">
                        <Icon size={24} color={palette.secondary.main} />
                    </Box>
                    <Typography variant="h6" fontSize="16px" color={palette.grey[900]} letterSpacing="unset">
                        {selectedTab === TabKey.LIVE_CALLS ? 'Live Call Notifications' : 'Location'}
                    </Typography>
                </>
            }
        >
            <LocationSelector
                onSubmit={handleSubmit}
                defaultValue={defaultValue}
                currentTab={selectedTab}
                isLoading={isPending}
            />
        </CustomDrawer>
    );
};
