import { Getter, Setter, WritableAtom, atom } from 'jotai';
import { atomWithStorage as baseAtomWithStorage } from 'jotai/utils';

export const atomWithStorage = <T>(key: string, initialValue: T) =>
    baseAtomWithStorage(key, initialValue, undefined, { getOnInit: true });

export const atomWithToggle = (
    initialValue?: boolean,
    listener?: (get: Getter, set: Setter, value: boolean) => void
) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const newAtom = atom(initialValue, (get, set, _: boolean | void) => {
        const newValue = !get(newAtom);
        set(newAtom, newValue);
        listener?.(get, set, newValue);
    });
    return newAtom as WritableAtom<boolean, [], void>;
};

export const guardAtom = <Value, Result>(
    otherAtom: WritableAtom<Value, unknown[], Result>,
    listener?: (set: Setter) => void
) =>
    atom(
        (get) => get(otherAtom) !== null,
        (_, set) => {
            set(otherAtom, null);
            listener?.(set);
        }
    );
