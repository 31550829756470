import { useCallback } from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/api/common';
import { GetChosenCareLocationsResponse } from '~/types/assignments';

export const useGetChosenCareLocations = () => {
    const branchId = useBranchId();
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = { headers: { authorization: token } };

        const { data } = await api.get<GetChosenCareLocationsResponse>(
            `/e-call/${branchId}/care/my-shift-locations`,
            options
        );

        return data.response.careLocationIds;
    }, [branchId, token]);

    return useErrorHandledQuery({
        queryKey: ['my-shift-locations', branchId],
        queryFn,
        staleTime: 30000,
        enabled: !!branchId,
    });
};
