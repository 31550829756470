import { Box } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';

import { Scheduling } from '../../types';

import { Calendar } from './Calendar';
import { LAST_MONTH_OF_YEAR } from './constants';

interface MultiCalendarProps {
    // Recommended to use atoms from jotai to handle the state of this component
    dateRange: Scheduling.DateRange;
    setDateRange: (value: Scheduling.DateRange) => void;
    disablePast?: boolean;
    disableFn?: (date: DateTime) => boolean;
}

export const MultiCalendar = ({ disablePast, dateRange, setDateRange, disableFn }: MultiCalendarProps) => {
    const { startDate, endDate } = dateRange;

    const [calendarYear, setCalendarYear] = React.useState<number>(startDate.year);
    const [calendarMonth, setCalendarMonth] = React.useState<number>(startDate.month);

    const handlePreviousMonth = () => {
        if (calendarMonth === 0) {
            setCalendarYear(calendarYear - 1);
            setCalendarMonth(LAST_MONTH_OF_YEAR);
        } else {
            setCalendarMonth(calendarMonth - 1);
        }
    };

    const handleNextMonth = () => {
        if (calendarMonth === LAST_MONTH_OF_YEAR) {
            setCalendarYear(calendarYear + 1);
            setCalendarMonth(0);
        } else {
            setCalendarMonth(calendarMonth + 1);
        }
    };

    const nextMonth = calendarMonth === LAST_MONTH_OF_YEAR ? 0 : calendarMonth + 1;
    const nextYear = calendarMonth === LAST_MONTH_OF_YEAR ? calendarYear + 1 : calendarYear;

    return (
        <Box display="flex" justifyContent="space-between" gap="16px" paddingTop="24px">
            <Calendar
                startDate={startDate}
                endDate={endDate}
                month={calendarMonth}
                year={calendarYear}
                hideNextButton
                onPreviousMonthClick={handlePreviousMonth}
                onChangeValue={setDateRange}
                disablePast={disablePast}
                disableFn={disableFn}
            />
            <Calendar
                startDate={startDate}
                endDate={endDate}
                month={nextMonth}
                year={nextYear}
                hidePreviousButton
                onNextMonthClick={handleNextMonth}
                onChangeValue={setDateRange}
                disablePast={disablePast}
                disableFn={disableFn}
            />
        </Box>
    );
};
