import { Box, Button, Stack, Typography, styled, useTheme } from '@mui/material';
import { CaretDoubleLeft, GearSix, SignOut } from '@phosphor-icons/react';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router';

import glow from '~/assets/drawer-glow.png';
import logoWhite from '~/assets/logo-white.svg';
import { useAppDispatch } from '~/constants/redux';
import { signOut } from '~/redux/actions/session';
import { atomWithStorage } from '~/state/atomUtils';

import { TopLevelDestination } from '../shared';

import DesktopDrawerDestinations from './DesktopDrawerDestinations';

const DRAWER_WIDTH_OPEN = '240px';
const DRAWER_WIDTH_CLOSED = '80px';

export const isDrawerOpenAtom = atomWithStorage('layout_isDrawerOpen', true);
export const selectedTopLevelIndexAtom = atom<number | null | undefined>(undefined);

const SingleLineTypography = styled(Typography)({
    overflow: 'hidden',
    textAlign: 'start',
    wordBreak: 'break-all',
    // Webkit-specific properties for line-clamping:
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
});

const DesktopDrawerContainer = ({ children }: { children: ReactNode }) => {
    const { palette } = useTheme();

    const isDrawerOpen = useAtomValue(isDrawerOpenAtom);

    return (
        <Stack
            sx={{
                flexShrink: 0,
                position: 'relative',
                bgcolor: palette.primary.main,
                backgroundImage: `url(${glow})`,
                backgroundSize: DRAWER_WIDTH_OPEN,
                backgroundRepeat: 'no-repeat',
                width: isDrawerOpen ? DRAWER_WIDTH_OPEN : DRAWER_WIDTH_CLOSED,
                height: '100%',
                transition: 'width 0.2s',
            }}
        >
            {children}
        </Stack>
    );
};

const DesktopDrawerOpenCloseButton = () => {
    const { palette } = useTheme();

    const [isDrawerOpen, setIsDrawerOpen] = useAtom(isDrawerOpenAtom);
    const setSelectedTopLevelIndex = useSetAtom(selectedTopLevelIndexAtom);

    return (
        <Button
            onClick={() => {
                setIsDrawerOpen(!isDrawerOpen);
                setSelectedTopLevelIndex(undefined);
            }}
            sx={{
                bgcolor: 'white',
                position: 'absolute',
                top: '80px',
                right: 0,
                minWidth: '32px',
                width: '32px',
                height: '32px',
                p: '8px',
                transform: 'translate(50%, -50%)',
                borderRadius: '50%',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                zIndex: 20,
                '&:hover': {
                    bgcolor: palette.grey[100],
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                },
            }}
        >
            <Box
                sx={{
                    width: '16px',
                    height: '16px',
                    transform: isDrawerOpen ? 'rotate(0)' : 'rotate(180deg)',
                    transition: 'transform 0.4s',
                    '& > svg': { fontSize: '16px !important' },
                }}
            >
                <CaretDoubleLeft color={palette.grey[500]} weight="bold" />
            </Box>
        </Button>
    );
};

const DesktopDrawerHeader = ({ singleProduct }: { singleProduct: TopLevelDestination | null }) => {
    const { palette } = useTheme();

    const isDrawerOpen = useAtomValue(isDrawerOpenAtom);

    const logoIconSize = isDrawerOpen && singleProduct ? 24 : 32;
    const logoFontSize = logoIconSize * 0.7;

    return (
        <Stack
            sx={{
                flexDirection: 'row',
                alignItems: 'center',
                gap: '3.2px',
                p: isDrawerOpen ? '32px 16px' : '32px 16px 32px 24px',
                width: '100%',
                userSelect: 'none',
                transition: 'padding 0.2s',
            }}
        >
            <img
                src={logoWhite}
                alt="AllieHealth"
                style={{
                    width: `${logoIconSize}px`,
                    height: `${logoIconSize}px`,
                    transition: 'width 0.2s, height 0.2s',
                }}
            />
            <SingleLineTypography
                sx={{
                    color: 'white',
                    fontSize: `${logoFontSize}px`,
                    fontWeight: 700,
                    opacity: isDrawerOpen ? 1 : 0,
                    transition: 'font-size 0.2s, opacity 0.2s',
                }}
            >
                Allie<span style={{ fontWeight: 400 }}>Health</span>
            </SingleLineTypography>
            {singleProduct && (
                <Box
                    sx={{
                        bgcolor: 'white',
                        p: '2px 4px 1px',
                        borderRadius: '10px',
                        border: `1px solid ${palette.primary[300]}`,
                        opacity: isDrawerOpen ? 1 : 0,
                        transition: 'opacity 0.1s',
                    }}
                >
                    <SingleLineTypography
                        sx={{
                            color: palette.primary[500] as string,
                            fontSize: '10px',
                            fontWeight: 700,
                            lineHeight: '12px',
                        }}
                    >
                        {singleProduct.title}
                    </SingleLineTypography>
                </Box>
            )}
        </Stack>
    );
};

const DesktopDrawerFooter = () => {
    const { palette } = useTheme();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isSignOutDisabled, setIsSignOutDisabled] = useState(false);

    const isDrawerOpen = useAtomValue(isDrawerOpenAtom);

    return (
        <Stack
            sx={{
                flexDirection: 'row',
                alignItems: 'center',
                gap: '4px',
                p: '16px',
                borderTop: `1px solid ${palette.primary[400]}`,
            }}
        >
            <Button
                variant="text"
                onClick={async () => {
                    setIsSignOutDisabled(true);
                    await dispatch(signOut());
                    setIsSignOutDisabled(false);
                }}
                sx={{
                    flex: 1,
                    color: 'white',
                    gap: '8px',
                    p: '8px',
                    minWidth: '48px',
                    '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.1)' },
                    '& > svg': { fontSize: '20px !important', flexShrink: 0 },
                }}
                disabled={isSignOutDisabled}
            >
                <SignOut
                    weight="bold"
                    style={{
                        marginLeft: isDrawerOpen ? 0 : '12px',
                        transition: 'margin-left 0.2s',
                    }}
                />
                <SingleLineTypography
                    sx={{
                        fontWeight: 600,
                        mr: 'auto',
                        opacity: isDrawerOpen ? 1 : 0,
                        transition: 'opacity 0.2s',
                    }}
                >
                    Sign out
                </SingleLineTypography>
            </Button>
            <Stack
                sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '4px',
                    opacity: isDrawerOpen ? 1 : 0,
                    transition: 'opacity 0.2s',
                }}
            >
                <Box
                    sx={{
                        bgcolor: palette.primary[400] as string,
                        width: '1px',
                        height: '20px',
                    }}
                />
                <Button
                    variant="text"
                    onClick={() => navigate('/profile')}
                    sx={{
                        color: 'white',
                        minWidth: 0,
                        p: '8px',
                        '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.1)' },
                        '& > svg': { fontSize: '20px !important', flexShrink: 0 },
                    }}
                >
                    <GearSix weight="fill" />
                </Button>
            </Stack>
        </Stack>
    );
};

const DesktopDrawer = () => (
    <DesktopDrawerContainer>
        <DesktopDrawerOpenCloseButton />
        <DesktopDrawerHeader singleProduct={null} />
        <DesktopDrawerDestinations />
        <DesktopDrawerFooter />
    </DesktopDrawerContainer>
);

export default DesktopDrawer;
