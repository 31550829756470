import { useAtom, useAtomValue } from 'jotai';
import { useMemo } from 'react';

import { useGetCategoriesDetailsQuery } from '~/api/queries/unscheduledTasks/getCategoriesDetails';
import { documentationResidentDataAtom } from '~/pages/Home/eCallComponents/atom';

import { unscheduledTaskCategoriesAtom, unscheduledTaskDetailsAtom } from '../../../state/atom';
import withIcon from '../utils/withIcon';

export default function useTaskDetailsStep(prnCategoryId: number) {
    const categories = useAtomValue(unscheduledTaskCategoriesAtom);
    const [taskDetails, setTaskDetails] = useAtom(unscheduledTaskDetailsAtom);
    const residentData = useAtomValue(documentationResidentDataAtom);

    const { data, isLoading, error } = useGetCategoriesDetailsQuery({
        categoriesIds: categories.map((category) => category.id),
        residentId: residentData?.residentId,
    });

    const categoryDetails = useMemo(() => {
        const category = data?.find((category) => category.id === prnCategoryId);

        if (!category) return;

        return {
            ...category,
            subcategories: category?.subcategories.map((subcategory) => ({
                ...subcategory,
                assistLevels: subcategory.assistLevels.map(withIcon),
            })),
        };
    }, [data, prnCategoryId]);

    return {
        data: categoryDetails,
        isLoading,
        error,
        taskDetails,
        setTaskDetails,
    };
}
