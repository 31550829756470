import { useSelector } from 'react-redux';

import { usePermissionsQuery } from '~/api/queries/permissions';
import { ReduxStore } from '~/types/redux';

import { ResourceActions, ResourceType } from './constants';

export const usePermissions = () => {
    const {
        sessionData: { companyId, regionId, branchId },
    } = useSelector((state: ReduxStore) => state.session);

    const { data: permissions } = usePermissionsQuery();

    return <T extends ResourceType>(type: T, action: ResourceActions<T>, key?: number) => {
        if (!permissions || Object.keys(permissions).length === 0) return null; // Permissions haven't loaded yet

        let autoPopulatedKey = key;

        if (!autoPopulatedKey) {
            if (type === 'Company') autoPopulatedKey = companyId;
            else if (type === 'Region') autoPopulatedKey = regionId;
            else if (type === 'Community') autoPopulatedKey = branchId;
        }

        const resource = autoPopulatedKey ? `${type}:${autoPopulatedKey}` : type;
        return !!permissions[resource]?.[action];
    };
};
