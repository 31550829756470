import { Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';
import React from 'react';
import { Control, Controller, FieldValues, RegisterOptions, UseFormReturn } from 'react-hook-form';

interface FormDatePicker<T extends FieldValues> extends React.ComponentProps<typeof DatePicker> {
    form: UseFormReturn<T>;
    label: string;
    name: string;
    validations?: RegisterOptions;
}

export const FormDatePicker = <T extends FieldValues>({
    label,
    name,
    form,
    validations,
    ...props
}: FormDatePicker<T>) => {
    const { palette } = useTheme();
    return (
        <Box>
            <Typography sx={{ fontSize: '12px', fontWeight: 700, color: 'grey.900', marginBottom: '4px' }}>
                {label}
            </Typography>

            <Controller
                name={name}
                defaultValue={props.defaultValue}
                control={form.control as Control<FieldValues>}
                rules={validations}
                render={({ field, fieldState: { error } }) => (
                    <>
                        <DatePicker
                            key={JSON.stringify(props.defaultValue ?? '')}
                            sx={{
                                width: '100%',
                                '& .MuiIconButton-root': { marginRight: 0 },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: error ? (palette.error[500] as string) : undefined,
                                },
                            }}
                            {...field}
                            {...props}
                        />
                        {error && (
                            <Typography
                                variant="body1"
                                color={palette.error[500] as string}
                                fontWeight={400}
                                fontSize="12px"
                                mt="4px"
                                marginInline="10px"
                            >
                                {error.message}
                            </Typography>
                        )}
                    </>
                )}
            />
        </Box>
    );
};
