import { Box, Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React from 'react';
import { BsCheck as CheckIcon } from 'react-icons/bs';

import { useCompanyCarePlansListQuery } from '~/api/queries/companyCarePlans';
import langDictionary from '~/app-strings';
import { GoldCoin } from '~/components/Svg/GoldCoin';
import { pxToRem } from '~/components/theme/typography';
import { NURSE_CALLS_DIALOG_ANALYTICS } from '~/constants/analytics';

type Props = {
    branchId: number;
    selectedValues: number[];
    onClose: () => void;
    onSubmit: () => void;
    changeSelectedValues: (selectedValues: number[]) => void;
};

const NurseCallsCheckbox = (props: Props) => {
    const { branchId, selectedValues, onClose, onSubmit, changeSelectedValues } = props;

    const { submit: submitTxt, noNurseCallsButton: noNurseCallsButtonTxt } = langDictionary.shared;

    const { data: companyCarePlansList } = useCompanyCarePlansListQuery(branchId);

    const taskOptions = companyCarePlansList
        ?.filter((companyCarePlan) => companyCarePlan.isAllieCarePlan) // Simpler, curated list of PRNs
        .map((task) => ({
            label: task.taskName,
            value: task.companyCarePlanId,
        }));

    const isChecked = (id: number) => selectedValues.includes(id);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isChecked(Number(event.target.value))) {
            const filteredValues = selectedValues.filter((value) => value !== Number(event.target.value));
            changeSelectedValues(filteredValues);
            return;
        }

        changeSelectedValues([...selectedValues, Number(event.target.value)]);
    };

    const isDisabled = selectedValues.length === 0;

    return (
        <>
            <FormGroup
                sx={{
                    display: 'grid',
                    gap: pxToRem(12),
                    pr: { md: pxToRem(12) },
                    mb: pxToRem(16),
                    maxHeight: '70vh',
                    overflowY: 'auto',
                }}
            >
                {taskOptions?.map(({ label, value }) => (
                    <FormControlLabel
                        key={value}
                        value={value}
                        label={label}
                        sx={{
                            paddingBlock: pxToRem(12),
                            pl: pxToRem(12),
                            bgcolor: isChecked(value) ? '#F2F8F8' : 'grey.50',
                            borderRadius: pxToRem(8),
                            border: `1px solid ${isChecked(value) ? '#006b75' : 'transparent'}`,
                            m: '0 !important',
                            '& .MuiFormControlLabel-label': {
                                fontWeight: 700,
                                lineHeight: 1.7,
                            },
                        }}
                        control={
                            <Checkbox
                                sx={{
                                    p: 0,
                                    mr: pxToRem(8),
                                }}
                                checked={isChecked(value)}
                                onChange={handleCheckboxChange}
                                icon={
                                    <Box
                                        sx={{
                                            width: pxToRem(16),
                                            height: pxToRem(16),
                                            borderRadius: '50%',
                                            bgcolor: 'grey.200',
                                        }}
                                    />
                                }
                                checkedIcon={
                                    <CheckIcon
                                        fill="#F2F8F8"
                                        style={{
                                            backgroundColor: '#006b75',
                                            borderRadius: '50%',
                                        }}
                                    />
                                }
                            />
                        }
                    />
                ))}
            </FormGroup>
            <Box
                sx={{
                    mt: pxToRem(8),
                    display: 'grid',
                    gridTemplateColumns: '40% 1fr',
                    gap: pxToRem(12),
                }}
            >
                <Button
                    variant="outlined"
                    color="error"
                    onClick={onClose}
                    sx={{
                        p: pxToRem(12),
                        fontSize: pxToRem(14),
                    }}
                    data-analytics-id={NURSE_CALLS_DIALOG_ANALYTICS.NO_NURSE_CALLS_BUTTON}
                >
                    {noNurseCallsButtonTxt}
                </Button>
                <Button
                    color="primary"
                    onClick={onSubmit}
                    disabled={isDisabled}
                    sx={{
                        fontSize: pxToRem(14),
                        '&:disabled': {
                            bgcolor: '#C7C8CB',
                        },
                    }}
                    startIcon={
                        <Box
                            component="span"
                            sx={{
                                color: isDisabled ? 'currentcolor' : '#FCD7B1',
                                display: 'flex',
                                mt: pxToRem(3),
                            }}
                        >
                            <GoldCoin size={30} />
                        </Box>
                    }
                    data-analytics-id={NURSE_CALLS_DIALOG_ANALYTICS.SUBMIT_BUTTON}
                >
                    {submitTxt}
                </Button>
            </Box>
        </>
    );
};

export default NurseCallsCheckbox;
