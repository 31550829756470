import { ThemeOptions } from '@mui/material';

import { MuiAutocomplete } from './MuiAutocomplete';
import { MuiButton } from './MuiButton';
import { MuiChip } from './MuiChip';
import { MuiFormControl } from './MuiFormControl';
import { MuiRadio } from './MuiRadio';
import { MuiSelect } from './MuiSelect';
import { MuiSkeleton } from './MuiSkeleton';
import { MuiSwitch } from './MuiSwitch';
import { MuiTab, MuiTabs } from './MuiTab';
import { MuiTextField } from './MuiTextField';
import { MuiToggleButton, MuiToggleButtonGroup } from './MuiToggleButton';
import { MuiTooltip } from './MuiTooltip';

type ThemeComponents = NonNullable<ThemeOptions['components']>;
export type ComponentOverride<T extends keyof ThemeComponents> = ThemeComponents[T];

const components: ThemeComponents = {
    MuiAutocomplete,
    MuiButton,
    MuiChip,
    MuiFormControl,
    MuiRadio,
    MuiSelect,
    MuiSkeleton,
    MuiTab,
    MuiTabs,
    MuiTextField,
    MuiToggleButton,
    MuiToggleButtonGroup,
    MuiTooltip,
    MuiSwitch,
};

export default components;
