import { Box, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '~/components/Shared/Loading';
import { pxToRem } from '~/components/theme/typography';
import { Section, schedulingSections } from '~/pages/OperationsDashboard/constants';
import { setSelectedStartDate } from '~/redux/actions/opsDashboard';
import { OperationsLevel, OperationsPeriod, SelectOptionsId } from '~/types/operationsDashboard';
import { ReduxStore } from '~/types/redux';

import MobileDashboardTabPanels from '../Mobile/MobileDashbardTabPanels';
import { SectionDataModule } from '../Module/SectionDataModule';
import SchedulingSections from '../Scheduling';

import { getSectionsToShow, useModuleData } from './helpers';

type Props = {
    mobileTabSelected: 0 | 1;
    region: SelectOptionsId;
    branch: SelectOptionsId;
    careType: string;
    operationsPeriod: OperationsPeriod;
    hasCompanyAccess: boolean;
    hasRegions: boolean;
    hasBranches: boolean;
    onMobileTabChange: (newValue: number) => void;
};

export const OperationsDashboardMain = ({
    mobileTabSelected,
    region,
    branch,
    careType,
    operationsPeriod,
    hasCompanyAccess,
    hasRegions,
    onMobileTabChange,
}: Props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { sessionData } = useSelector((state: ReduxStore) => state.session);
    const { selectedStartDate, selectedStartDatePeriod } = useSelector((state: ReduxStore) => state.opsDashboard);

    const dispatch = useDispatch();

    let level: OperationsLevel;

    if (hasRegions && hasCompanyAccess && region === 'all') {
        level = 'company';
    } else if (hasRegions && branch === 'all') {
        level = 'region';
    } else {
        level = 'branch';
    }

    const moduleData = useModuleData(level, operationsPeriod, sessionData, region, branch, careType, hasCompanyAccess);
    const sectionsToShow = getSectionsToShow(level, moduleData);

    // reset selected start date to the second to last date period every time the period changes
    // don't reset it if the dateOptions get reloaded (changing care type, region etc.), but make sure it is set on first load
    useEffect(() => {
        if (selectedStartDatePeriod !== operationsPeriod || selectedStartDate === undefined) {
            dispatch(
                setSelectedStartDate(moduleData.dateOptions ? moduleData.dateOptions[1] : undefined, operationsPeriod)
            );
        }
    }, [operationsPeriod, moduleData.dateOptions]);

    if (moduleData.status === 'loading') {
        return <Loading />;
    }

    return (
        <Box
            sx={{
                flex: 1,
                overflowY: 'auto',
                backgroundColor: { xs: 'common.white', sm: 'grey.50' },
                p: { sm: pxToRem(32) },
            }}
        >
            {isMobile ? (
                <MobileDashboardTabPanels
                    moduleData={moduleData}
                    sectionsToShow={sectionsToShow}
                    mobileTabSelected={mobileTabSelected}
                    onMobileTabChange={onMobileTabChange}
                    region={region}
                    branch={branch}
                    careType={careType}
                    operationsPeriod={operationsPeriod}
                    level={level}
                />
            ) : (
                <Box
                    sx={{
                        display: 'grid',
                        gap: pxToRem(32),
                    }}
                >
                    <SchedulingSections
                        sectionsToShow={sectionsToShow}
                        regionId={region}
                        branchId={branch}
                        operationsPeriod={operationsPeriod}
                        dateOptions={moduleData?.dateOptions ?? []}
                    />
                    {Object.keys(sectionsToShow)
                        .filter((section) => !schedulingSections.includes(section))
                        .map((section) => (
                            <SectionDataModule
                                key={section}
                                moduleData={moduleData}
                                dataType={section as Section}
                                level={level}
                                operationsPeriod={operationsPeriod}
                                regionId={region}
                                branchId={branch}
                                careType={careType}
                            />
                        ))}
                </Box>
            )}
        </Box>
    );
};
