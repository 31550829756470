import { Box, Typography, styled, useTheme } from '@mui/material';
import { useAtom } from 'jotai';
import React from 'react';
import { FaCircleCheck } from 'react-icons/fa6';
import { PiWarningFill } from 'react-icons/pi';

import { STANDARDIZED_BUDGET_STATUS_TYPES } from '@allie/utils/src/constants/scheduling/budget-stats.contants';

import { budgetRecommendationListAtom } from '../../testData';
import { useBudgetedShifts } from '../../useBudgetedShifts';

import { RecommendationCard } from './RecommendationCard';

const CheckIconContainer = styled(Box)(() => ({
    width: 'fit-content',
    height: '56px',
    padding: '16px',
    borderRadius: '28px',
}));

const CheckIcon = styled(FaCircleCheck)(({ theme }) => ({
    width: '24px',
    height: '24px',
    color: theme.palette.primary[500],
}));

const RecommendationListPlaceholder = () => {
    const { palette } = useTheme();
    const { budgetStatus } = useBudgetedShifts();

    return (
        <Box
            display="flex"
            flexDirection="column"
            height="100%"
            alignItems="center"
            justifyContent="center"
            gap="32px"
            paddingInline="32px"
        >
            <CheckIconContainer
                bgcolor={
                    (budgetStatus === STANDARDIZED_BUDGET_STATUS_TYPES.OVER
                        ? palette.error[50]
                        : palette.primary[50]) as string
                }
            >
                {budgetStatus === STANDARDIZED_BUDGET_STATUS_TYPES.ON_BUDGET ? (
                    <CheckIcon />
                ) : (
                    <PiWarningFill
                        size={24}
                        color={
                            (budgetStatus === STANDARDIZED_BUDGET_STATUS_TYPES.OVER
                                ? palette.error[600]
                                : palette.primary[400]) as string
                        }
                    />
                )}
            </CheckIconContainer>
            <Box>
                <Typography sx={{ textWrap: 'balance' }} textAlign="center" variant="h5" color={palette.grey[900]}>
                    {budgetStatus === STANDARDIZED_BUDGET_STATUS_TYPES.UNDER
                        ? 'You are under budget!'
                        : budgetStatus === STANDARDIZED_BUDGET_STATUS_TYPES.OVER
                          ? 'You are over budget!'
                          : 'You are all good!'}
                </Typography>
                <Box paddingTop="8px" width="100%" />
                <Typography
                    sx={{ textWrap: 'balance' }}
                    textAlign="center"
                    variant="body1"
                    fontWeight={400}
                    color={palette.grey[500]}
                >
                    {budgetStatus !== STANDARDIZED_BUDGET_STATUS_TYPES.ON_BUDGET
                        ? 'Click on any shift to change the number of open shifts available'
                        : 'Your schedule is on budget, keep up the good work!'}
                </Typography>
            </Box>
        </Box>
    );
};

const SHOULD_SHOW_RECOMMENDATION_LIST = false; // TODO - remove this after MVP was launched

const RecommendationList = () => {
    const { palette } = useTheme();
    const [budgetRecommendationList, setBudgetRecommendationList] = useAtom(budgetRecommendationListAtom);

    const handleRemoveRecommendation = (id: number) => {
        setBudgetRecommendationList((prev) => prev.filter((item) => item.id !== id));
    };

    return (
        <Box
            maxWidth="400px"
            height="100%"
            borderRadius="12px"
            border={`1px solid ${palette.grey[100]}`}
            bgcolor="#FFF"
            paddingBlock="16px"
            overflow="auto"
        >
            {!SHOULD_SHOW_RECOMMENDATION_LIST ? (
                <RecommendationListPlaceholder />
            ) : (
                <>
                    <Typography variant="h6" paddingInline="16px" color={palette.grey[900]}>
                        Recommendations
                    </Typography>
                    <Box marginBlock="16px" display="flex" flexDirection="column">
                        {budgetRecommendationList.map((data, idx) => (
                            <React.Fragment key={idx}>
                                <RecommendationCard data={data} onFinish={() => handleRemoveRecommendation(data.id)} />
                                {idx !== budgetRecommendationList.length - 1 && (
                                    <Box marginLeft="36px" borderBottom={`1px solid ${palette.grey[100]}`} />
                                )}
                            </React.Fragment>
                        ))}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default RecommendationList;
