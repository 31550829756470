import { Box, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    Rectangle,
    RectangleProps,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';

import { pxToRem } from '~/components/theme/typography';
import { Section, StandardDataset } from '~/pages/OperationsDashboard/constants';
import { setSelectedStartDate } from '~/redux/actions/opsDashboard';
import { ReduxStore } from '~/types/redux';

import CustomBarChartTooltip from './CustomBarChartTooltip';
import { useChartClick } from './helpers';

export interface CustomBarChartProps {
    data: StandardDataset[];
    data2?: StandardDataset[];
    section: Section;
}

const prepareChartData = (data: StandardDataset[]) =>
    data.map((dataset) => ({
        period: dataset.startDate,
        value: dataset.value,
    }));

type Props = RectangleProps & {
    dataKey?: string;
    period?: string;
    selectedStartDate?: string;
    defaultFill: string;
    hoveredFill: string;
};

const listOfStackedCharts: Section[] = ['CHANGE_OF_CONDITION'];

const stackedChartLegendMap: Partial<Record<Section, { value: string; value2: string }>> = {
    CHANGE_OF_CONDITION: {
        value: 'Without ADL changes',
        value2: 'With ADL changes',
    },
    MEDS_REFUSED: {
        value: 'Meds refused',
        value2: '',
    },
};

const stackedChartColorsMap: Partial<
    Record<Section, { hoveredFill: { value: string; value2: string }; defaultFill: { value: string; value2: string } }>
> = {
    CHANGE_OF_CONDITION: {
        hoveredFill: {
            value: '#006B75',
            value2: '#FA9C7A',
        },
        defaultFill: {
            value: '#C2DBDE',
            value2: '#FEE7DF',
        },
    },
    MEDS_REFUSED: {
        hoveredFill: {
            value: '#FA9C7A',
            value2: '#EC3232',
        },
        defaultFill: {
            value: '#FEE7DF',
            value2: '#FACECE',
        },
    },
};

const CustomBar = (props: Props) => {
    const { x, y, width, height, onMouseOver, onMouseOut, period, selectedStartDate, defaultFill, hoveredFill } = props;
    const [hovered, setHovered] = useState(false);

    const isSelected = period === selectedStartDate;

    const handleMouseOver = (e: React.MouseEvent<SVGRectElement, MouseEvent>) => {
        setHovered(true);
        if (onMouseOver) {
            onMouseOver(e);
        }
    };

    const handleMouseOut = (e: React.MouseEvent<SVGRectElement, MouseEvent>) => {
        setHovered(false);
        if (onMouseOut) {
            onMouseOut(e);
        }
    };

    return (
        <Rectangle
            x={x}
            y={y}
            width={width}
            height={height}
            fill={hovered || isSelected ? hoveredFill : defaultFill}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            cursor="pointer"
        />
    );
};

const CustomBarChart = ({ data, data2, section }: CustomBarChartProps) => {
    const isMobile = useMediaQuery('(max-width:1024px)');

    const { selectedStartDate } = useSelector((state: ReduxStore) => state.opsDashboard);

    let chartData = prepareChartData(data);

    if (data2) {
        chartData = chartData.map((dataset, index) => ({
            ...dataset,
            value2: data2[index].value,
        }));
    }

    const legendFormatter = (value: string) => {
        const legendMap = stackedChartLegendMap[section]!;
        return <span style={{ color: '#6F6F79' }}>{legendMap[value]}</span>;
    };

    const isStackedChart = listOfStackedCharts.includes(section);

    const handlePointClick = useChartClick(setSelectedStartDate);

    return (
        <Box width={1} height={{ xs: pxToRem(158), lg: pxToRem(336) }}>
            <ResponsiveContainer width="99%" height="100%">
                <BarChart data={chartData} onClick={handlePointClick}>
                    <CartesianGrid strokeDasharray="4 4" strokeOpacity={0.5} />
                    <XAxis
                        dataKey="period"
                        fontSize={isMobile ? 9 : 10}
                        fontFamily="Inter"
                        color="#6F6F79"
                        axisLine={false}
                        tickLine={false}
                        tickMargin={7}
                        tickCount={8}
                        tickFormatter={(date: string) => {
                            const [, month, day] = date.split('-');
                            return `${month}/${day}`;
                        }}
                    />
                    <YAxis
                        fontSize={isMobile ? 10 : 12}
                        fontFamily="Inter"
                        color="#6F6F79"
                        axisLine={false}
                        tickLine={false}
                        tickMargin={isMobile ? 3 : 6}
                        width={isMobile ? 25 : 35}
                    />
                    <Tooltip
                        content={<CustomBarChartTooltip colors={stackedChartColorsMap[section]?.hoveredFill} />}
                        cursor={false}
                    />
                    {isStackedChart && (
                        <Legend
                            iconSize={8}
                            iconType="circle"
                            wrapperStyle={{
                                fontSize: '11px',
                                fontFamily: 'Inter',
                                color: '#6F6F79',
                                lineHeight: 1.35,
                            }}
                            formatter={legendFormatter}
                        />
                    )}
                    <Bar
                        dataKey="value"
                        stackId={isStackedChart ? 'a' : undefined}
                        fill={stackedChartColorsMap[section]?.hoveredFill.value ?? '#C2DBDE'}
                        barSize={isMobile ? 10 : 20}
                        shape={
                            <CustomBar
                                selectedStartDate={selectedStartDate}
                                defaultFill={stackedChartColorsMap[section]?.defaultFill.value ?? '#C2DBDE'}
                                hoveredFill={stackedChartColorsMap[section]?.hoveredFill.value ?? '#006B75'}
                            />
                        }
                    />
                    {data2 && (
                        <Bar
                            dataKey="value2"
                            stackId="a"
                            fill={stackedChartColorsMap[section]?.hoveredFill.value2 ?? '#FEE7DF'}
                            barSize={isMobile ? 10 : 20}
                            shape={
                                <CustomBar
                                    selectedStartDate={selectedStartDate}
                                    defaultFill={stackedChartColorsMap[section]?.defaultFill.value2 ?? '#FEE7DF'}
                                    hoveredFill={stackedChartColorsMap[section]?.hoveredFill.value2 ?? '#FA9C7A'}
                                />
                            }
                        />
                    )}
                </BarChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default CustomBarChart;
