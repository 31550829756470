import React from 'react';

import Base from '~/components/Svg/Base';

type Props = {
    className?: string;
    size?: number | string;
    viewBox?: string;
};

export const GoldCoin = ({ className, size, viewBox }: Props) => (
    <Base className={className} size={size} viewBox={viewBox}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.35948 12.48V15.8799C9.35948 16.2337 9.64674 16.521 10.0005 16.521C10.3543 16.521 10.6416 16.2337 10.6416 15.8799V12.48C10.6416 12.1262 10.3543 11.8389 10.0005 11.8389C9.64674 11.8389 9.35948 12.1262 9.35948 12.48ZM4.23145 11.6253V15.0252C4.23145 15.379 4.5187 15.6663 4.87249 15.6663C5.22628 15.6663 5.51353 15.379 5.51353 15.0252V11.6253C5.51353 11.2715 5.22628 10.9844 4.87249 10.9844C4.51869 10.9844 4.23145 11.2715 4.23145 11.6253ZM14.4879 11.6253V15.0252C14.4879 15.379 14.7752 15.6663 15.129 15.6663C15.4826 15.6663 15.7698 15.379 15.7698 15.0252V11.6253C15.7698 11.2715 15.4826 10.9844 15.129 10.9844C14.7752 10.9844 14.4879 11.2715 14.4879 11.6253Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.66699 9.05984V12.4786C1.66699 13.4218 2.29429 14.3383 3.46075 15.0454C4.93678 15.9401 7.315 16.5385 10.0004 16.5385C12.6856 16.5385 15.0638 15.9401 16.5401 15.0454C17.7065 14.3383 18.3337 13.4218 18.3337 12.4786V9.05984C18.3337 8.11669 17.7065 7.20017 16.5401 6.49303C15.064 5.59836 12.6857 5 10.0004 5C7.31508 5 4.93698 5.59836 3.46075 6.49303C2.29429 7.20017 1.66699 8.11669 1.66699 9.05984ZM11.9235 13.0147C11.3072 13.0829 10.6631 13.1195 10.0005 13.1195C9.33771 13.1195 8.69377 13.0829 8.07735 13.0147V15.1451C8.6904 15.2178 9.33528 15.2562 10.0005 15.2562C10.6657 15.2562 11.3106 15.2178 11.9235 15.1451V13.0147ZM17.0515 11.2767C16.8946 11.3979 16.7239 11.5148 16.5399 11.6263C15.6897 12.1416 14.5405 12.5586 13.2054 12.8197V14.9342C14.2665 14.7069 15.1827 14.3687 15.8753 13.9489C16.5768 13.5236 17.0515 13.0457 17.0515 12.4785L17.0515 11.2767ZM2.949 11.2767V12.4784C2.949 13.0456 3.42378 13.5236 4.12525 13.9488C4.81786 14.3687 5.73391 14.7069 6.79515 14.9342V12.8197C5.45996 12.5586 4.31066 12.1416 3.46067 11.6263C3.27651 11.5148 3.10596 11.3979 2.94904 11.2766L2.949 11.2767ZM12.5108 11.6448C13.8674 11.4281 15.0356 11.0391 15.8751 10.53C16.5766 10.1048 17.0514 9.62685 17.0514 9.05965C17.0514 8.4925 16.5766 8.0145 15.8751 7.58925C14.5507 6.78653 12.4096 6.28191 9.99997 6.28191C7.59039 6.28191 5.44951 6.78653 4.12482 7.58925C3.42335 8.01446 2.94857 8.49246 2.94857 9.05965C2.94857 9.62681 3.42335 10.1048 4.12482 10.53C4.96439 11.0391 6.13241 11.4281 7.48897 11.6448C7.52033 11.6474 7.55107 11.6522 7.58106 11.6592C8.3382 11.775 9.1524 11.8374 9.9998 11.8374C10.8472 11.8374 11.6612 11.775 12.4185 11.6592C12.4485 11.6522 12.4791 11.6474 12.5105 11.6448H12.5108Z"
            fill="currentColor"
        />
    </Base>
);
