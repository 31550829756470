import at from 'array.prototype.at';
import AudioRecorder from 'audio-recorder-polyfill';

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

if (isSafari) {
    window.MediaRecorder = AudioRecorder;
}

if (!Array.prototype.at) {
    at.shim();
}
