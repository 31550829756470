import { atom } from 'jotai';

import { Reports } from './types';

const topStaffData: Reports.TopStaffTableData[] = [
    { staffName: 'Brooklyn Simmons', role: 'Caregiver', noCallOffs: 39, pickupCount: 5 },
    { staffName: 'Dianne Russell', role: 'Caregiver', noCallOffs: 27, pickupCount: 7 },
    { staffName: 'Sarah Wilson', role: 'Caregiver', noCallOffs: 14, pickupCount: 6 },
    { staffName: 'Sophia Willians', role: 'Caregiver', noCallOffs: 21, pickupCount: 10 },
];

export const topStaffDataAtom = atom(topStaffData);

const partAndFullTimeAlertData: Reports.PartAndFullTimeAlertTableData[] = [
    { staffName: 'Brooklyn Simmons', staffType: 'Full-time', avgScheduledHoursPerWeek: 24 },
    { staffName: 'Dianne Russell', staffType: 'Full-time', avgScheduledHoursPerWeek: 16 },
    { staffName: 'Sarah Wilson', staffType: 'Part-time', avgScheduledHoursPerWeek: 40 },
    { staffName: 'Sophia Willians', staffType: 'Part-time', avgScheduledHoursPerWeek: 40 },
];

export const partAndFullTimeAlertDataAtom = atom(partAndFullTimeAlertData);

const callOffPatternData: Reports.CallOffPatternTableData[] = [
    {
        staffName: 'Brooklyn Simmons',
        role: { id: 1, name: 'Caregiver' },
        patterns: ['4 times in 90 days', '75% AM Shift on Mondays', '75% for health issues'],
        details: [
            { date: new Date('2024-05-06'), shift: 'AM', reason: 'Health issues' },
            { date: new Date('2024-06-04'), shift: 'AM', reason: 'Health issues' },
            { date: new Date('2024-06-25'), shift: 'AM', reason: 'Health issues' },
        ],
    },
    {
        staffName: 'Diane Russel',
        role: { id: 1, name: 'Caregiver' },
        patterns: ['10 times in 90 days', '90% NOC Shift'],
        details: [
            { date: new Date('2024-03-05'), shift: 'AM', reason: 'Health issues' },
            { date: new Date('2024-03-08'), shift: 'AM', reason: 'Health issues' },
            { date: new Date('2024-03-25'), shift: 'AM', reason: 'Health issues' },
            { date: new Date('2024-03-26'), shift: 'PM', reason: 'Health issues' },
        ],
    },
    {
        staffName: 'Sarah Wilson',
        role: { id: 1, name: 'Caregiver' },
        patterns: ['9 times in 90 days', '66% for broken down vehicle'],
        details: [
            { date: new Date('2024-04-22'), shift: 'AM', reason: 'Broken down vehicle' },
            { date: new Date('2024-04-29'), shift: 'AM', reason: 'Broken down vehicle' },
            { date: new Date('2024-05-25'), shift: 'AM', reason: 'Broken down vehicle' },
            { date: new Date('2024-05-26'), shift: 'PM', reason: 'Broken down vehicle' },
            { date: new Date('2024-05-25'), shift: 'AM', reason: 'Broken down vehicle' },
            { date: new Date('2024-06-26'), shift: 'PM', reason: 'Broken down vehicle' },
        ],
    },
    {
        staffName: 'Sophia Willians',
        role: { id: 1, name: 'Caregiver' },
        patterns: ['11 times in 90 days', '73% PM Shift on Wednesday', '73% for child care'],
        details: [
            { date: new Date('2024-05-16'), shift: 'PM', reason: 'Child care' },
            { date: new Date('2024-05-23'), shift: 'PM', reason: 'Child care' },
            { date: new Date('2024-05-30'), shift: 'PM', reason: 'Child care' },
            { date: new Date('2024-06-06'), shift: 'PM', reason: 'Child care' },
            { date: new Date('2024-06-13'), shift: 'PM', reason: 'Child care' },
            { date: new Date('2024-06-20'), shift: 'PM', reason: 'Child care' },
            { date: new Date('2024-06-27'), shift: 'PM', reason: 'Child care' },
            { date: new Date('2024-07-04'), shift: 'PM', reason: 'Child care' },
        ],
    },
];
export const callOffPatternDataAtom = atom(callOffPatternData);
