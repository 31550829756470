import { useCallback } from 'react';

import { api } from '~/api';
import { queryClient, useBranchId, useErrorHandledMutation, useToken } from '~/api/common';

export const useSaveChosenCareLocations = () => {
    const branchId = useBranchId();
    const token = useToken();

    const mutationFn = useCallback(
        async (careLocationIds: number[]) => {
            const options = { headers: { Authorization: token } };

            await api.post(`/e-call/${branchId}/care/my-shift-locations`, { careLocationIds }, options);
        },
        [token, branchId]
    );

    const onSuccess = useCallback(async () => {
        await queryClient.invalidateQueries({ queryKey: ['my-shift-locations', branchId] });
    }, [branchId]);

    return useErrorHandledMutation({ mutationFn, onSuccess });
};
