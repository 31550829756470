import { Box, Button, CircularProgress, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { useSetAtom } from 'jotai';
import { DateTime } from 'luxon';
import React from 'react';

import { useCallResidentRoutine } from '~/api/queries/call/callResidentRoutine';
import { useCompleteCall } from '~/api/queries/call/completeCall';
import { useTimer } from '~/hooks/useTimer';

import { documentationResidentDataAtom, isDocumentationFlowModalOpenAtom } from '../atom';
import { ECall } from '../types.d';

const CallDetailsContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey[25],
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '8px',
    margin: '16px',
    flex: 1,

    [theme.breakpoints.up('sm')]: {
        margin: 0,
    },
}));

const ResidentReachedCallDetailsContainer = styled(Box)(({ theme }) => ({
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        gap: '16px',
        alignItems: 'center',
    },
}));

interface TipProps {
    step: number;
    label: string | React.ReactNode;
}

const Step = ({ step, label }: TipProps) => {
    const { palette } = useTheme();

    return (
        <Box display="flex" gap="8px">
            <Box
                display="flex"
                minWidth="24px"
                height="24px"
                bgcolor={palette.secondary[300] as string}
                borderRadius="12px"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="body1" color={palette.grey[900]} fontWeight={600} fontSize="12px">
                    {step}
                </Typography>
            </Box>
            <Typography variant="body1" color={palette.grey[900]} fontWeight={500} fontSize="16px">
                {label}
            </Typography>
        </Box>
    );
};

interface InProgressCallDetailsProps {
    callId: number;
    calledAt: DateTime;
    roomNumber: string;
    suggestedLocations?: string[];
}

const InProgressCallDetails = ({ calledAt, roomNumber, suggestedLocations, callId }: InProgressCallDetailsProps) => {
    const { palette, breakpoints } = useTheme();
    const timer = useTimer(calledAt);
    const isDesktop = useMediaQuery(breakpoints.up('sm'));
    const { data, isLoading, error } = useCallResidentRoutine(callId);

    if (isLoading || error) return;

    return (
        <>
            <Box padding="16px">
                <Step step={1} label={`Go to Room ${roomNumber}`} />

                {suggestedLocations && (
                    <Box
                        marginTop="8px"
                        display="flex"
                        flexDirection="column"
                        bgcolor="white"
                        padding="16px"
                        borderRadius="8px"
                    >
                        <Typography variant="body1" color={palette.grey[600]} fontWeight={500}>
                            Resident not there? Try these locations:
                        </Typography>

                        <Typography variant="body1" color={palette.grey[600]} fontWeight={400}>
                            {suggestedLocations.join(', ')}
                        </Typography>
                    </Box>
                )}

                <Box width="100%" height="16px" />

                <Step step={2} label="Double tap Resident's button to stop the timer" />

                <Box
                    marginTop="16px"
                    padding="16px 24px"
                    borderRadius="8px"
                    bgcolor={palette.warning[200] as string}
                    border={`2px solid ${palette.warning[500]}`}
                    sx={isDesktop ? { width: 'fit-content' } : undefined}
                >
                    <Typography variant="body1" color={palette.grey[900]} fontWeight={400}>
                        Resident has been waiting for <span style={{ fontWeight: 700, fontSize: '16px' }}>{timer}</span>
                    </Typography>
                </Box>
            </Box>

            <Box bgcolor={palette.grey[50]} padding="16px">
                <Typography variant="body1" color={palette.grey[600]} fontWeight={500}>
                    {data?.shiftName} Routine
                </Typography>
                <ul style={{ paddingLeft: '16px' }}>
                    {data?.tasks?.map((task, index) => (
                        <li key={index}>
                            <Typography variant="body1" color={palette.grey[600]} fontWeight={400}>
                                {task}
                            </Typography>
                        </li>
                    ))}
                </ul>
            </Box>
        </>
    );
};

interface ResidentReachedCallDetailsProps {
    startedAt: DateTime;
    callId: number;
    onClick: () => void;
}

export const ResidentReachedCallDetails = ({ startedAt, callId, onClick }: ResidentReachedCallDetailsProps) => {
    const { palette, breakpoints } = useTheme();
    const isDesktop = useMediaQuery(breakpoints.up('sm'));
    const timer = useTimer(startedAt);

    const { mutateAsync: completeCall, isPending } = useCompleteCall();

    const handleCompleteCall = async () => {
        await completeCall(callId);

        onClick();
    };

    return (
        <ResidentReachedCallDetailsContainer>
            <Box flex={1}>
                <Step
                    step={1}
                    label={
                        <>
                            Click <span style={{ fontWeight: 700 }}>Done</span> to complete request
                        </>
                    }
                />
                <Box
                    marginTop="16px"
                    marginBottom={isDesktop ? 0 : '16px'}
                    padding="16px 24px"
                    borderRadius="8px"
                    bgcolor={palette.warning[200] as string}
                    border={`2px solid ${palette.warning[500]}`}
                    sx={isDesktop ? { width: 'fit-content' } : undefined}
                >
                    <Typography variant="body1" color={palette.grey[900]} fontWeight={400} lineHeight="16px">
                        You have been in progress for <span style={{ fontWeight: 700, fontSize: '16px' }}>{timer}</span>
                    </Typography>
                </Box>
            </Box>

            <Button
                sx={{ minWidth: '150px', maxHeight: '46px' }}
                fullWidth={!isDesktop}
                variant="contained"
                onClick={handleCompleteCall}
                disabled={isPending}
            >
                {isPending ? <CircularProgress size={20} sx={{ color: 'white' }} /> : 'Done'}
            </Button>
        </ResidentReachedCallDetailsContainer>
    );
};

interface CallDetailsProps {
    callId: number;
    calledAt: DateTime;
    resident: ECall.Resident;
    status: ECall.CallStatus;
    claimedByUser: boolean;
    suggestedLocations?: string[];
    startedAt?: DateTime;
}

export const CallDetails = ({
    callId,
    resident,
    suggestedLocations,
    calledAt,
    status,
    claimedByUser,
    startedAt,
}: CallDetailsProps) => {
    const openDocumentationFlowModal = useSetAtom(isDocumentationFlowModalOpenAtom);
    const setResidentData = useSetAtom(documentationResidentDataAtom);

    if (!claimedByUser) return null;

    const handleStartDocumentationflow = () => {
        openDocumentationFlowModal();
        setResidentData({ ...resident, residentId: resident.id });
    };

    return (
        <CallDetailsContainer>
            {status === ECall.CallStatus.InProgress && (
                <InProgressCallDetails
                    callId={callId}
                    roomNumber={resident.roomNumber}
                    calledAt={calledAt}
                    suggestedLocations={suggestedLocations}
                />
            )}
            {status === ECall.CallStatus.ResidentReached && (
                <ResidentReachedCallDetails
                    callId={callId}
                    startedAt={startedAt!}
                    onClick={handleStartDocumentationflow}
                />
            )}
        </CallDetailsContainer>
    );
};
