import { useCallback } from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/api/common';
import { UnscheduledTasks } from '~/types/unscheduledTasks';

export const useGetCategoriesQuery = (isECall?: boolean) => {
    const branchId = useBranchId();
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = {
            params: { branch_id: branchId, isECall },
            headers: { authorization: token },
        };
        const { data } = await api.get<UnscheduledTasks.GetCategoriesResponse>(`/prn-tasks/categories`, options);

        return data.response;
    }, [branchId, token]);

    return useErrorHandledQuery({
        queryKey: ['unscheduled-tasks', isECall],
        queryFn,
        staleTime: 300000,
        enabled: !!branchId,
    });
};
