import { Box, Button, Typography, useTheme } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import React from 'react';
import { PiWarningCircleFill } from 'react-icons/pi';
import { useNavigate } from 'react-router';

import CustomModal from '~/scheduling/components/CustomModal';

import { isConfirmPhoneNumberModalOpenAtom, userPhoneNumberAtom } from './atom';

const formatPhoneNumber = (value?: string | null) => {
    if (!value) return '';

    const pureValue = value.replace(/[-()+\s]/g, '');

    const countryCode = pureValue.slice(0, 1);
    const areaCode = `(${pureValue.slice(1, 4)})`;
    const firstPart = pureValue.slice(4, 7);
    const secondPart = pureValue.slice(7);

    if (pureValue.length <= 3) return pureValue;
    if (pureValue.length <= 4) return `+${countryCode} ${areaCode}`;
    if (pureValue.length <= 7) return `+${countryCode} ${areaCode} ${firstPart}`;
    return `+${countryCode} ${areaCode} ${firstPart}-${secondPart}`;
};

export const ConfirmPhoneNumberModal = () => {
    const navigate = useNavigate();
    const { palette } = useTheme();

    const [isConfirmPhoneNumberModalOpen, toggleConfirmPhoneNumberModal] = useAtom(isConfirmPhoneNumberModalOpenAtom);
    const phoneNumber = useAtomValue(userPhoneNumberAtom);

    const handleSignUpClick = () => {
        toggleConfirmPhoneNumberModal();
        navigate('/signup', { replace: true });
    };

    return (
        <CustomModal isOpen={isConfirmPhoneNumberModalOpen} onClose={toggleConfirmPhoneNumberModal} center closeButton>
            <Box padding="24px" display="flex" alignItems="center" flexDirection="column" gap="20px">
                <Box bgcolor={palette.secondary[50] as string} padding="32px" borderRadius="50%" width="fit-content">
                    <PiWarningCircleFill size={32} color={palette.secondary[500] as string} />
                </Box>

                <Box display="flex" flexDirection="column" gap="8px" width="100%">
                    <Typography variant="h6" textAlign="center" color={palette.grey[900]} letterSpacing="unset">
                        Confirm Phone Number
                    </Typography>
                    <Box bgcolor={palette.primary[50] as string} padding="10px 12px" width="100%" borderRadius="20px">
                        <Typography
                            variant="h6"
                            color={palette.primary[500] as string}
                            textAlign="center"
                            letterSpacing="unset"
                        >
                            {formatPhoneNumber(phoneNumber)}
                        </Typography>
                    </Box>
                    <Typography
                        variant="body2"
                        color={palette.grey[500]}
                        fontSize="14px"
                        lineHeight="24px"
                        textAlign="center"
                        sx={{ textWrap: 'balance' }}
                    >
                        We could not find the phone number you entered. Is the above number correct? If this is the
                        first time you are using AllieHealth you will need to sign up below.
                    </Typography>
                </Box>

                <Box display="flex" flexDirection="column" gap="8px" width="100%">
                    <Button fullWidth size="large" onClick={toggleConfirmPhoneNumberModal}>
                        Edit Phone Number
                    </Button>
                    <Button variant="outlined" fullWidth size="large" onClick={handleSignUpClick}>
                        Create Account
                    </Button>
                </Box>
            </Box>
        </CustomModal>
    );
};
