import { Box, Button, CircularProgress, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { PiArrowsHorizontalFill } from 'react-icons/pi';
import { useSelector } from 'react-redux';

import { useClaimCallMutation } from '~/api/queries/call/claimCall';
import { CustomAvatar } from '~/components/Custom';
import { useTimer } from '~/hooks/useTimer';
import { ReduxStore } from '~/types/redux';

import { ECall } from '../types.d';
import { useCalls } from '../useCalls';

import { CallDetails } from './CallDetails';

const CallCardContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,

    [theme.breakpoints.up('sm')]: {
        gap: '16px',
        flexDirection: 'row',
    },
}));

const CallCardContentContainer = styled(Box)(({ theme }) => ({
    flex: 1,
    borderBottom: 1,
    borderBottomColor: theme.palette.grey[100],
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
        gap: '16px',
        padding: '16px',
        alignItems: 'flex-start',
        flexDirection: 'row',
    },
}));

const CallCardContent = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',

    [theme.breakpoints.up('sm')]: {
        flex: 1,
    },
}));

const UnclaimedButtonContainer = styled(Box)(({ theme }) => ({
    padding: '16px',
    borderRadius: '8px',
    gap: '12px',
    display: 'flex',
    flexDirection: 'column',
    margin: '16px',
    marginTop: 0,

    [theme.breakpoints.up('sm')]: {
        flex: 1,
        margin: 0,
        padding: 0,
        flexDirection: 'row',
    },
}));

const UnclaimedButton = ({
    calledAt,
    unclaimedLevel,
    isLoading,
    onClick,
}: {
    calledAt: DateTime;
    unclaimedLevel: ECall.UnclaimedLevel;
    isLoading?: boolean;
    onClick: () => void;
}) => {
    const timer = useTimer(calledAt);
    const { palette, breakpoints } = useTheme();
    const isDesktop = useMediaQuery(breakpoints.up('sm'));

    const styles =
        unclaimedLevel === ECall.UnclaimedLevel.Level3
            ? {
                  backgroundColor: palette.error[50] as string,
                  border: `1px solid ${palette.error[900] as string}`,
              }
            : {
                  backgroundColor: palette.warning[200] as string,
                  border: `1px solid ${palette.warning[500]}`,
              };

    return (
        <UnclaimedButtonContainer sx={styles}>
            <Box sx={isDesktop ? { flex: 1, padding: '16px' } : undefined}>
                <Typography
                    variant="body1"
                    fontWeight={700}
                    color={
                        unclaimedLevel === ECall.UnclaimedLevel.Level3
                            ? (palette.error[900] as string)
                            : palette.grey[900]
                    }
                >
                    Call
                </Typography>
                <Typography
                    variant="body2"
                    color={
                        unclaimedLevel === ECall.UnclaimedLevel.Level3
                            ? (palette.error[900] as string)
                            : palette.grey[900]
                    }
                >
                    Resident has been waiting for <span style={{ fontWeight: 700 }}>{timer}</span>
                </Typography>
            </Box>
            <Button
                sx={[{ minWidth: '150px' }, isDesktop ? { margin: '16px' } : {}]}
                variant={unclaimedLevel === ECall.UnclaimedLevel.Level3 ? 'containedError' : 'warning'}
                onClick={onClick}
            >
                {isLoading ? <CircularProgress size={20} thickness={4} sx={{ color: 'white' }} /> : 'Claim'}
            </Button>
        </UnclaimedButtonContainer>
    );
};

interface StatusBadgeProps {
    calledAt: DateTime;
    startedAt?: DateTime;
    status: ECall.CallStatus;
    claimedByUser: boolean;
}

const StatusBadge = ({ calledAt, status, startedAt, claimedByUser }: StatusBadgeProps) => {
    const { palette, breakpoints } = useTheme();
    const timer = useTimer(startedAt ?? calledAt);
    const isDesktop = useMediaQuery(breakpoints.up('sm'));

    return (
        <Box
            mt="8px"
            display="flex"
            padding="8px"
            borderRadius="8px"
            justifyContent="space-between"
            gap="16px"
            bgcolor={claimedByUser ? (palette.secondary[200] as string) : palette.grey[100]}
            width={isDesktop ? (claimedByUser ? 'fit-content' : '240px') : undefined}
        >
            <Typography variant="body1" color={palette.grey[900]} fontWeight={700} lineHeight="18px">
                {status === ECall.CallStatus.Claimed ? 'Claimed' : 'In Progress'}
            </Typography>
            {!claimedByUser && (
                <Typography variant="body1" color={palette.grey[900]} fontWeight={400}>
                    {status === ECall.CallStatus.Claimed ? 'Called' : 'Started'} {timer} ago
                </Typography>
            )}
        </Box>
    );
};

export const CallCard = ({
    calledAt,
    status,
    id,
    unclaimedLevel,
    suggestedLocations,
    resident,
    claimedCaregiver,
    startedAt,
}: ECall.Call) => {
    const { mutateAsync: claimCallMutation, isPending } = useClaimCallMutation();
    const { claimCall } = useCalls();
    const [showDetails, setShowDetails] = useState(false);
    const { palette, breakpoints } = useTheme();
    const isDesktop = useMediaQuery(breakpoints.up('sm'));

    const {
        userId,
        firstName: userFirstName,
        lastName: userLastName,
    } = useSelector((state: ReduxStore) => state.session.sessionData);

    const claimedByUser = claimedCaregiver?.id === userId;

    const handleClick = () => {
        if (status !== ECall.CallStatus.Unclaimed && claimedByUser) {
            setShowDetails((prev) => !prev);
        }
    };

    const handleClaimCall = async () => {
        const caregiver: ECall.Caregiver = { id: userId, firstName: userFirstName, lastName: userLastName };

        await claimCallMutation({ callId: id });

        claimCall(id, caregiver);
    };

    return (
        <CallCardContainer>
            <CallCardContentContainer
                sx={{
                    backgroundColor:
                        status !== ECall.CallStatus.Unclaimed && !claimedByUser ? palette.grey[50] : undefined,
                }}
            >
                <CallCardContent
                    onClick={handleClick}
                    sx={{
                        padding: isDesktop ? undefined : '16px',
                        boxShadow: showDetails && !isDesktop ? '0px 4px 14.1px 0px rgba(0, 0, 0, 0.10)' : 'none',
                    }}
                >
                    <CustomAvatar
                        firstName={resident.firstName}
                        lastName={resident.lastName}
                        photo={resident.photo}
                        size={64}
                    />
                    <Box display="flex" flex={1} flexDirection="column">
                        <Box flex={1} display="flex" gap="4px" alignItems="center">
                            <Typography fontSize="16px" color={palette.grey[900]} textAlign="left" fontWeight={700}>
                                {resident.firstName}{' '}
                                {status === ECall.CallStatus.Unclaimed
                                    ? resident.lastName
                                    : `${resident.lastName.charAt(0)}.`}
                            </Typography>
                            {status !== ECall.CallStatus.Unclaimed && (
                                <>
                                    <PiArrowsHorizontalFill size={16} color={palette.grey[900]} />
                                    <Typography color={palette.grey[900]} textAlign="left" fontWeight={400}>
                                        {claimedByUser
                                            ? 'Me'
                                            : `${claimedCaregiver?.firstName} ${claimedCaregiver?.lastName.charAt(0)}`}
                                    </Typography>
                                </>
                            )}
                        </Box>
                        <Typography color={palette.secondary.main} fontWeight={700}>
                            Apt {resident.roomNumber ?? 'not set'}
                        </Typography>
                        {status !== ECall.CallStatus.Unclaimed && (!isDesktop || claimedByUser) && (
                            <StatusBadge
                                calledAt={calledAt}
                                status={status}
                                startedAt={startedAt}
                                claimedByUser={claimedByUser}
                            />
                        )}
                    </Box>
                    {status !== ECall.CallStatus.Unclaimed &&
                        claimedByUser &&
                        !isDesktop &&
                        (showDetails ? (
                            <FaChevronUp size={20} color={palette.grey[300]} />
                        ) : (
                            <FaChevronDown size={20} color={palette.grey[300]} />
                        ))}
                </CallCardContent>
                {status === ECall.CallStatus.Unclaimed && (
                    <UnclaimedButton
                        calledAt={calledAt}
                        unclaimedLevel={unclaimedLevel}
                        onClick={handleClaimCall}
                        isLoading={isPending}
                    />
                )}

                {(showDetails || isDesktop) && (
                    <CallDetails
                        callId={id}
                        claimedByUser={claimedByUser}
                        status={status}
                        resident={resident}
                        suggestedLocations={suggestedLocations}
                        calledAt={calledAt}
                        startedAt={startedAt}
                    />
                )}

                {status !== ECall.CallStatus.Unclaimed && isDesktop && !claimedByUser && (
                    <Box flex={1}>
                        <StatusBadge
                            calledAt={calledAt}
                            status={status}
                            startedAt={startedAt}
                            claimedByUser={claimedByUser}
                        />
                    </Box>
                )}
            </CallCardContentContainer>
        </CallCardContainer>
    );
};
