import { useCallback } from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/api/common';
import { CallResidentRoutine } from '~/types/call/callResidentRoutine';

export const useCallResidentRoutine = (callId: number) => {
    const token = useToken();
    const branchId = useBranchId();

    const queryFn = useCallback(async () => {
        const options = { headers: { authorization: token }, params: { callId } };

        const { data } = await api.get<CallResidentRoutine.Response>(
            `/e-call/${branchId}/care/resident-routine`,
            options
        );

        return data.response;
    }, [token, branchId, callId]);

    return useErrorHandledQuery({
        queryKey: ['call-resident-routine', callId],
        queryFn,
        staleTime: 300000,
        enabled: !!branchId && !!callId,
    });
};
