import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material';
import React from 'react';

import { CustomSelect } from '~/components/Custom';
import { MOBILE_SELECTOR_STYLES, SELECTOR_STYLES } from '~/components/Filtering/constants';
import { SelectStructure } from '~/types/inputs';
import { SelectOptionsId } from '~/types/operationsDashboard';

type Props = {
    currentRegion: SelectOptionsId;
    onChange: (newValue: SelectOptionsId) => void;
    disabled?: boolean;
    regionOptions: SelectStructure[];
};

const RegionSelector = ({ currentRegion, onChange, disabled, regionOptions }: Props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <CustomSelect
            id="region"
            label="Region"
            value={currentRegion}
            fullWidth
            options={regionOptions}
            onChange={(value) => onChange(value === 'all' ? 'all' : Number(value))}
            disabled={disabled}
            sx={isMobile ? { ...MOBILE_SELECTOR_STYLES, ...SELECTOR_STYLES } : SELECTOR_STYLES}
            truncate
        />
    );
};
export default RegionSelector;
