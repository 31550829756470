import { useCallback } from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/api/common';
import { AssignmentListResponse } from '~/types/assignments';

export const useGetAssignments = (enabled = true) => {
    const token = useToken();
    const branchId = useBranchId();

    const queryFn = useCallback(async () => {
        const { data } = await api.get<AssignmentListResponse>(`/assignments`, {
            headers: { Authorization: token },
            params: { branch_id: branchId },
        });

        return data.response;
    }, [branchId, token]);

    return useErrorHandledQuery({
        queryKey: ['assignments', branchId],
        queryFn,
        enabled: !!branchId && enabled,
        staleTime: 300000,
    });
};
