import { usePostHog } from 'posthog-js/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useResidentQuery } from '~/api/queries/residents';
import { useCreateDailyTask } from '~/api/queries/tasks/dailyTasks';
import { CustomMobileDialog } from '~/components/Custom/CustomMobileDialog';
import Loading from '~/components/Shared/Loading';
import { DailyTasksCreateParams, RecentUnscheduledTasksResponse } from '~/types/dailyTasks';
import { ReduxStore } from '~/types/redux';

import RecentUnscheduledTaskCheckbox from './RecentUnscheduledTaskCheckbox';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    residentId: number;
    date: string;
    shift: number;
    tasks: RecentUnscheduledTasksResponse[];
};

const RecentUnscheduledTasksDialog = (props: Props) => {
    const { branchId, userId } = useSelector((state: ReduxStore) => state.session.sessionData);
    const { isOpen, onClose, residentId, date, shift, tasks } = props;

    const posthog = usePostHog();

    const createTaskMutation = useCreateDailyTask();

    const [selectedValues, setSelectedValues] = useState<number[]>([]);

    const [isCreateLoading, setIsCreateLoading] = useState(false);

    useEffect(() => {
        if (isOpen) {
            posthog.capture('recent_unscheduled_tasks_dialog:open', {
                user_id: userId,
            });
        }
    }, [isOpen]);

    if (!branchId) {
        throw new Error('Branch ID is missing');
    }

    const {
        data: residentData,
        isLoading: residentDataIsLoading,
        isError: residentDataIsError,
    } = useResidentQuery(residentId, branchId);

    if (residentDataIsLoading) {
        return <Loading />;
    }

    if (residentDataIsError || !residentData) {
        return null;
    }

    const handleSubmit = async () => {
        setIsCreateLoading(true);

        posthog.capture('recent_unscheduled_tasks_dialog:submit', {
            user_id: userId,
            care_plan_ids: selectedValues,
            care_plan_count: selectedValues.length,
        });
        await Promise.all(
            selectedValues.map(async (companyCarePlanId) => {
                const params: DailyTasksCreateParams = {
                    branchId,
                    residentId,
                    companyCarePlanId,
                    date,
                    shiftId: shift,
                };

                await createTaskMutation.mutateAsync(params);
            })
        );

        setIsCreateLoading(false);

        onClose();
    };

    const handleNoSelect = () => {
        posthog.capture('recent_unscheduled_tasks_dialog:no_tasks_selected', {
            user_id: userId,
            tasks,
            resident_id: residentId,
        });
        onClose();
    };

    const residentName = `${residentData?.firstName} ${residentData?.lastName}`;

    return (
        <CustomMobileDialog
            closeable
            open={isOpen}
            title={`Did you complete any of these tasks this shift for ${residentName}?`}
            onClose={onClose}
            width="100%"
            content={
                <RecentUnscheduledTaskCheckbox
                    tasks={tasks}
                    onClose={handleNoSelect}
                    selectedValues={selectedValues}
                    onSubmit={handleSubmit}
                    changeSelectedValues={setSelectedValues}
                    loading={isCreateLoading}
                />
            }
        />
    );
};

export default RecentUnscheduledTasksDialog;
