import { Box, Stack, Typography, styled, useTheme } from '@mui/material';
import { Coins, Icon, Star, ThumbsUp, Timer, UsersThree } from '@phosphor-icons/react';
import { DateTime } from 'luxon';
import React from 'react';

import { SHIFT_SLOT_AVAILABLE_FLAG } from '@allie/utils/src/constants/scheduling/shift-slot.constants';

import { useGetLocations } from '~/scheduling/api/queries/locations/getLocations';
import { GetMyScheduleResult } from '~/scheduling/api/queries/shift-slot/getMySchedule';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';
import { VerticalSeparator } from '~/scheduling/components/shared';

const iconByFlagType: Record<SHIFT_SLOT_AVAILABLE_FLAG, Icon> = {
    BONUS: Coins,
    OVERTIME: Timer,
    FRIEND: UsersThree,
    PREFERRED: ThumbsUp,
};

const SlotStatusBox = styled(Box)({
    padding: '2px 6px',
    borderRadius: '6px',
    textTransform: 'uppercase',
});

const SlotStatusText = styled(Typography)({
    color: 'white',
    fontSize: '11px',
    fontWeight: 600,
    lineHeight: '16px',
});

const SlotDotSeparator = styled(Box)(({ theme: { palette } }) => ({
    backgroundColor: palette.grey[300],
    width: '4px',
    height: '4px',
    borderRadius: '50%',
}));

const SlotFlagItem = styled(Stack)(({ theme: { palette } }) => ({
    backgroundColor: palette.grey[25],
    flexDirection: 'row',
    alignItems: 'center',
    gap: '2px',
    padding: '3px 4px',
    borderRadius: '6px',
    border: `1px solid ${palette.grey[200]}`,
}));

const SlotFlagText = styled(Typography)({
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '16px',
});

const SlotItemDetails = ({
    slot,
    renderPending,
    renderAdjacencies,
    renderFlags,
}: {
    slot: GetMyScheduleResult['slots'][number];
    renderPending?: true;
    renderAdjacencies?: true;
    renderFlags?: true;
}) => {
    const { palette } = useTheme();

    const { data: roleData } = useGetRoles();
    const roleById = roleData?.roleById;
    const roleShiftById = roleData?.roleShiftById;

    const { data: locationData } = useGetLocations();
    const locationById = locationData?.locationById;

    const { shiftDay, roleId, roleShiftId, locationId, request, flags, adjacencies } = slot;
    const { day: dayStr, weekdayShort: weekdayStr } = DateTime.fromISO(shiftDay);
    const role = roleById?.get(roleId)!.name;
    const roleShift = roleShiftById?.get(roleShiftId)!.name;
    const location = locationById?.get(locationId)!.abbreviation;
    const hasFlags = !!flags.length;
    const isPending = !!request?.id;

    const colors =
        isPending && renderPending
            ? {
                  day: palette.grey[300],
                  weekday: palette.grey[300],
                  month: palette.grey[300],
                  shift: palette.grey[300],
                  star: palette.grey[300],
                  role: palette.grey[300],
                  location: palette.grey[300],
                  flag: palette.grey[300],
              }
            : {
                  day: palette.primary.main,
                  weekday: palette.grey[600],
                  shift: palette.grey[900],
                  star: palette.primary.main,
                  role: palette.secondary.main,
                  location: palette.grey[900],
                  flag: palette.grey[500],
              };

    return (
        <Stack direction="row" alignItems="center" spacing="16px">
            <Stack alignItems="center">
                <Typography color={colors.day} variant="h5">
                    {dayStr}
                </Typography>
                <Typography color={colors.weekday} fontSize="12px" lineHeight="16px">
                    {weekdayStr}
                </Typography>
            </Stack>

            <VerticalSeparator height="32px" />

            <Stack flex={1} spacing="4px">
                <Stack direction="row" alignItems="center" spacing="6px">
                    <Typography color={colors.shift} fontSize="16px" fontWeight={700}>
                        {roleShift} Shift
                    </Typography>
                    {hasFlags && renderFlags && <Star color={colors.star} fontSize="14px" weight="fill" />}
                    {isPending && renderPending ? (
                        <SlotStatusBox bgcolor={palette.grey[300]}>
                            <SlotStatusText>Pending</SlotStatusText>
                        </SlotStatusBox>
                    ) : adjacencies.previous && renderAdjacencies ? (
                        <SlotStatusBox bgcolor={palette.error[300] as string}>
                            <SlotStatusText>On Previous</SlotStatusText>
                        </SlotStatusBox>
                    ) : adjacencies.following && renderAdjacencies ? (
                        <SlotStatusBox bgcolor={palette.error[300] as string}>
                            <SlotStatusText>On Following</SlotStatusText>
                        </SlotStatusBox>
                    ) : null}
                </Stack>

                <Stack direction="row" alignItems="center" spacing="6px">
                    <Typography color={colors.role} fontSize="15px" fontWeight={600}>
                        {role}
                    </Typography>
                    <SlotDotSeparator />
                    <Typography color={colors.location} fontSize="15px" fontWeight={400}>
                        {location}
                    </Typography>
                    {hasFlags && renderFlags && (
                        <>
                            <SlotDotSeparator />
                            {flags.map(({ type, message }) => {
                                const Icon = iconByFlagType[type];
                                return (
                                    <SlotFlagItem key={type}>
                                        <Icon color={colors.flag} fontSize="16px" weight="fill" />
                                        <SlotFlagText color={colors.flag}>{message}</SlotFlagText>
                                    </SlotFlagItem>
                                );
                            })}
                        </>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};

export default SlotItemDetails;
