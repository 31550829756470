import { Box, Typography } from '@mui/material';
import React from 'react';

import { ProTipIcon } from '~/components/Svg/ProTipIcon';
import { pxToRem } from '~/components/theme/typography';

const ProTip = () => (
    <Box
        sx={{
            color: '#FA9C7A',
            display: 'flex',
            alignItems: 'center',
            gap: pxToRem(8),
            lineHeight: 1.3,
        }}
    >
        <ProTipIcon size={17} />
        <Typography
            sx={{
                fontSize: { xs: pxToRem(14) },
                fontWeight: 700,
            }}
        >
            PRO TIP
        </Typography>
    </Box>
);

export default ProTip;
