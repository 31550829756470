import { DateTime } from 'luxon';
import { Thread, spawn } from 'threads';

import { getWeekInterval } from '@allie/utils/src/scheduling/date-helpers';

import { formatCalendarDateToDateTime, formatDateTimeToCalendarDate } from '~/scheduling/components/calendar/util';
import { Scheduling } from '~/scheduling/types';

import { Dashboard, PrintOutWokerParams } from '../../types.d';

export function formatPeriodString(period: Scheduling.DateRange): string {
    const { startDate, endDate } = period;
    const startAt = { ...startDate, month: startDate.month + 1 };
    const endAt = { ...endDate, month: endDate.month + 1 };

    return `${DateTime.fromObject(startAt).toFormat('MMM dd, yyyy')} - ${DateTime.fromObject(endAt).toFormat('MMM dd, yyyy')}`;
}

export const isSameCalendarDate = (date1: Scheduling.CalendarData, date2: Scheduling.CalendarData) => {
    const dateTime1 = formatCalendarDateToDateTime(date1);
    const dateTime2 = formatCalendarDateToDateTime(date2);

    return dateTime1.toISODate() === dateTime2.toISODate();
};

export const mapPeriodTypeToDates: Record<
    Exclude<Dashboard.PublishNextSchedulePeriod, 'CUSTOM_PERIOD'>,
    (date: DateTime) => Scheduling.DateRange
> = {
    END_OF_WEEK: (date) => ({
        startDate: formatDateTimeToCalendarDate(date),
        endDate: formatDateTimeToCalendarDate(date.endOf('week')),
    }),
    END_OF_NEXT_WEEK: (date) => ({
        startDate: formatDateTimeToCalendarDate(date),
        endDate: formatDateTimeToCalendarDate(date.plus({ weeks: 1 }).endOf('week')),
    }),
    END_OF_TWO_WEEKS: (date) => ({
        startDate: formatDateTimeToCalendarDate(date),
        endDate: formatDateTimeToCalendarDate(date.plus({ weeks: 2 }).endOf('week')),
    }),
    END_OF_NEXT_MONTH: (date) => ({
        startDate: formatDateTimeToCalendarDate(date),
        endDate: formatDateTimeToCalendarDate(date.plus({ months: 1 }).endOf('month')),
    }),
};

export const mapPrintSchedulePeriodDates: Record<
    Exclude<Dashboard.PrintSchedulePeriod, 'CUSTOM_PERIOD'>,
    (date: DateTime, firstDayOfWeek?: number) => Scheduling.DateRange
> = {
    THIS_WEEK: (date, firstDayOfWeek) => {
        if (!firstDayOfWeek) {
            return {
                startDate: formatDateTimeToCalendarDate(date.startOf('week')),
                endDate: formatDateTimeToCalendarDate(date.endOf('week')),
            };
        }

        const interval = getWeekInterval(date, firstDayOfWeek);

        return {
            startDate: formatDateTimeToCalendarDate(interval.start),
            endDate: formatDateTimeToCalendarDate(interval.end),
        };
    },
    THIS_MONTH: (date) => ({
        startDate: formatDateTimeToCalendarDate(date.startOf('month')),
        endDate: formatDateTimeToCalendarDate(date.endOf('month')),
    }),
    // TODO - uncomment after revert https://app.graphite.dev/github/pr/AllieHealth/core/547
    // NEXT_MONTH: (date) => ({
    //     startDate: formatDateTimeToCalendarDate(date.plus({ month: 1 }).startOf('month')),
    //     endDate: formatDateTimeToCalendarDate(date.plus({ months: 1 }).endOf('month')),
    // }),
};

export const renderPdf = async (
    shifts: Dashboard.Shift[],
    budgets: Dashboard.Budget[],
    type: PrintOutWokerParams.PrintOutType
) => {
    const renderWorker = await spawn<
        (
            shifts: PrintOutWokerParams.Shift[],
            budgets: PrintOutWokerParams.Budget[],
            type: PrintOutWokerParams.PrintOutType
        ) => Blob
    >(new Worker(new URL('../PrintOut/render-worker', import.meta.url)));

    const convertedShifts = shifts.map<PrintOutWokerParams.Shift>((shift) => ({
        ...shift,
        days: shift.days.map(({ day, ...rest }) => ({ day: day.toJSDate(), ...rest })),
    }));
    const convertedBudgets = budgets.map<PrintOutWokerParams.Budget>((budget) => ({
        ...budget,
        days: budget.days.map(({ day, ...rest }) => ({ day: day.toJSDate(), ...rest })),
    }));

    const pdfBlob = await renderWorker(convertedShifts, convertedBudgets, type);

    await Thread.terminate(renderWorker);

    return pdfBlob;
};
