import { Button } from '@mui/material';
import React from 'react';
import { FiFilter as SortIcon } from 'react-icons/fi';

import langDictionary from '~/app-strings';
import { pxToRem } from '~/components/theme/typography';

type Props = {
    onClick: () => void;
};

const SortButton = ({ onClick }: Props) => {
    const { sortButtonTitle: sortButtonTitleTxt } = langDictionary.shared;

    return (
        <Button
            variant="outlined"
            startIcon={<SortIcon />}
            onClick={onClick}
            sx={{
                color: { xs: '#DEDEE0', sm: 'grey.700' },
                bgcolor: 'transparent',
                borderColor: '#DEDEE0',
                width: '100%',
                height: pxToRem(36),
                fontSize: { xs: pxToRem(14), sm: pxToRem(16) },
                fontWeight: 600,
                '&:hover': {
                    borderColor: '#DEDEE0',
                    opacity: 0.7,
                },
            }}
        >
            {sortButtonTitleTxt}
        </Button>
    );
};

export default SortButton;
