import { Box, Skeleton, Typography } from '@mui/material';
import React from 'react';

import { useGetCommunitySettings } from '~/scheduling/api/queries/community-settings/getCommunitySettings';
import { useGetOvertimeStaff } from '~/scheduling/api/queries/reports/useGetOvertimeStaff';

import { usePeriod } from '../../usePeriod';

import { Cell, Row, Table, TableLink } from './Table';

const headers = ['Staff Name', 'Past Overtime', 'Upcoming  Overtime', 'Total Overtime', ''];

export const PeopleScheduledOvertimeTable = () => {
    const { data: communitySettings, isLoading: isGetCommunitySettingsLoading } = useGetCommunitySettings();
    const { period, isPastPeriod } = usePeriod();
    const { data, isLoading } = useGetOvertimeStaff({ period, firstDayOfWeek: communitySettings?.firstDayOfWeek });

    const totalHoursOvertime = data?.reduce((sum, { totalOvertimeHours }) => sum + totalOvertimeHours, 0) ?? 0;

    if (isLoading || isGetCommunitySettingsLoading) {
        return <Skeleton component="div" width="100%" height="200px" />;
    }

    return (
        <Box>
            <Typography variant="body1" fontWeight={700} fontSize="16px">
                People Scheduled Overtime
            </Typography>
            <Box height="16px" />
            <Box sx={{ p: '24px', backgroundColor: '#fff', borderRadius: '8px' }}>
                <Table headers={headers}>
                    {data?.map(({ name, pastOvertimeHours, scheduledOvertimeHours, totalOvertimeHours }, rowIdx) => (
                        <Row key={`row${rowIdx}`}>
                            <Cell>{name}</Cell>
                            <Cell>{pastOvertimeHours}h</Cell>
                            <Cell>{isPastPeriod ? '-' : scheduledOvertimeHours + 'h'}</Cell>
                            <Cell>{totalOvertimeHours}h</Cell>
                            <Cell>
                                <TableLink href="/scheduling/full-schedule" type="secondary">
                                    Edit Schedule
                                </TableLink>
                            </Cell>
                        </Row>
                    ))}
                    <Row sx={{ backgroundColor: 'error.25' }}>
                        <Cell sx={{ color: 'error.600', fontWeight: 500, textWrap: 'nowrap' }}>
                            Total Overtime Hours
                        </Cell>
                        <Cell />
                        <Cell />
                        <Cell sx={{ color: 'error.600', fontWeight: 700 }}>{totalHoursOvertime}h</Cell>
                        <Cell />
                    </Row>
                </Table>
            </Box>
        </Box>
    );
};
