import { useCallback } from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/api/common';
import { UnscheduledTasks } from '~/types/unscheduledTasks';

interface GetCategoriesDetailsQueryParams {
    categoriesIds: number[];
    residentId?: number;
}

export const useGetCategoriesDetailsQuery = (params: GetCategoriesDetailsQueryParams) => {
    const branchId = useBranchId();
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = {
            params: {
                branch_id: branchId,
                residentId: params.residentId,
                ...params.categoriesIds.reduce(
                    (agg, categoryId, index) => ({
                        ...agg,
                        [`categoriesIds[${index}]`]: categoryId,
                    }),
                    {}
                ),
            },
            headers: { authorization: token },
        };

        const { data } = await api.get<UnscheduledTasks.GetCategoriesDetailsResponse>(
            `/prn-tasks/categories/details`,
            options
        );

        return data.response;
    }, [branchId, token, params]);

    return useErrorHandledQuery({
        queryKey: ['categories-details', params],
        queryFn,
        enabled: !!branchId && !!params.categoriesIds.length && !!params.residentId,
        staleTime: 30000,
    });
};
