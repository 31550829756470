import { Box, Button, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';

import { useTimer } from '~/hooks/useTimer';

export const RequestDocumentationTask = ({ callTime, onClick }: { callTime: DateTime; onClick: () => void }) => {
    const { palette } = useTheme();
    const timer = useTimer(callTime);

    return (
        <Box
            marginBottom="8px"
            bgcolor={palette.grey[50]}
            border={`1px solid ${palette.grey[200]}`}
            padding="16px"
            borderRadius="8px"
        >
            <Typography variant="body1" color={palette.grey[900]} fontWeight={700} lineHeight="24px" fontSize="16px">
                Fill in request documentation
            </Typography>
            <Typography variant="body1" color={palette.grey[900]} fontWeight={400} lineHeight="24px">
                Call time {timer}
            </Typography>
            <Box height="16px" />
            <Button fullWidth onClick={onClick}>
                Start
            </Button>
        </Box>
    );
};
