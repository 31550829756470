import { useAtomValue } from 'jotai';
import { DateTime } from 'luxon';
import { useCallback } from 'react';

import { budgetRecommendationOnHoverAtom } from './atom';
import { budgetRecommendationListAtom } from './testData';

interface SlotParams {
    locationId: number;
    day: DateTime;
    shiftId: number;
}

const shouldRevertGradient = (idx: number) => idx % 2 !== 0;

export const useSlotOnHover = () => {
    const budgetRecommendationOnHover = useAtomValue(budgetRecommendationOnHoverAtom);
    const budgetRecommendationList = useAtomValue(budgetRecommendationListAtom);

    const getRecommendationById = useCallback(
        (id: number) => budgetRecommendationList.find((item) => item.id === id),
        [budgetRecommendationList]
    );

    const isOnHover = useCallback(
        (slot: SlotParams) => {
            if (!budgetRecommendationOnHover) {
                return false;
            }
            const recommendationOnHover = getRecommendationById(budgetRecommendationOnHover);

            return (
                !!recommendationOnHover &&
                recommendationOnHover.location.id === slot.locationId &&
                recommendationOnHover.recommendations.some(
                    ({ day, shift }) => day.hasSame(slot.day, 'day') && shift.id === slot.shiftId
                )
            );
        },
        [budgetRecommendationOnHover, getRecommendationById]
    );

    const getBorderStyle = useCallback(
        (slot: SlotParams) => {
            if (!isOnHover(slot) || !budgetRecommendationOnHover) {
                return;
            }

            const recommendationOnHover = getRecommendationById(budgetRecommendationOnHover)!;

            if (recommendationOnHover.recommendations.length === 1) {
                return {
                    borderWidth: '3px',
                    borderRadius: '8px',
                    reversedGradient: false,
                };
            }

            const indexOfSlot = recommendationOnHover.recommendations.findIndex(
                ({ day, shift }) => day.hasSame(slot.day, 'day') && shift.id === slot.shiftId
            );

            if (indexOfSlot === 0) {
                return { borderWidth: '3px 0 3px 3px', borderRadius: '8px 0 0 8px', reversedGradient: false };
            } else if (indexOfSlot === recommendationOnHover.recommendations.length - 1) {
                return {
                    borderWidth: '3px 3px 3px 0',
                    borderRadius: '0 8px 8px 0',
                    reversedGradient: shouldRevertGradient(indexOfSlot),
                };
            }

            return {
                borderWidth: '3px 0 3px 0',
                borderRadius: '0',
                reversedGradient: shouldRevertGradient(indexOfSlot),
            };
        },
        [budgetRecommendationOnHover, getRecommendationById, isOnHover]
    );

    return { getBorderStyle };
};
