import { Button } from '@mui/material';
import React from 'react';

import { pxToRem } from '~/components/theme/typography';

type Props = {
    text: string;
    isDisabled: boolean;
    onClick: () => void;
};

export default ({ text, isDisabled, onClick }: Props) => (
    <Button
        variant="outlined"
        onClick={onClick}
        fullWidth
        disabled={isDisabled}
        disableRipple
        sx={{
            py: pxToRem(16),
            fontSize: {
                xs: pxToRem(16),
            },
            lineHeight: {
                xs: 1.5,
            },
            fontWeight: 400,
            color: 'primary.dark',
            borderColor: 'primary.dark',
            textTransform: 'none',
            '&:hover': { bgcolor: 'transparent' },
            '&:focus-visible': {
                borderColor: 'transparent',
                outline: '2px solid #006B75',
            },
        }}
    >
        {text}
    </Button>
);
