import { Box, Typography, styled } from '@mui/material';
import React from 'react';

type Props = {
    label: string;
    value: string;
    onClick: () => void;
    selected?: boolean;
};

const Container = styled(Box)<{ selected?: boolean }>(({ theme, selected }) => ({
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${selected ? theme.palette.primary[500] : theme.palette.grey[100]}`,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px 16px',
    gap: '8px',
    borderRadius: '8px',
    aspectRatio: 1,
    width: '100%',
    minWidth: 0,
    boxSizing: 'border-box',
    cursor: 'pointer',
    backgroundColor: selected ? theme.palette.primary[50] : 'white',
    maxHeight: '100px',
}));

const Label = styled(Typography)<{ selected?: boolean }>(({ theme, selected }) => ({
    wordBreak: 'break-word',
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '12px',
    color: selected ? theme.palette.primary[500] : theme.palette.grey[900],
}));

export default function SquareButton({ label, selected, onClick, value }: Readonly<Props>) {
    return (
        <Container onClick={onClick} selected={selected}>
            <Label noWrap={false} sx={{ flexShrink: 1, overflow: 'hidden' }} selected={selected}>
                {label}
            </Label>
            <Label noWrap={false} sx={{ flexShrink: 1, overflow: 'hidden', fontWeight: 600 }} selected={selected}>
                {value}
            </Label>
        </Container>
    );
}
