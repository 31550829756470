import { useCallback } from 'react';

import { api } from '~/api';
import { queryClient, useBranchId, useErrorHandledMutation, useToken } from '~/api/common';
import { MarkSlotOpen } from '~/scheduling/api/types/shift-slot/markSlotOpen';

export const useMarkSlotOpen = () => {
    const branchId = useBranchId()!;
    const token = useToken();

    const mutationFn = useCallback(
        async ({ slotId, ...params }: MarkSlotOpen.Params) => {
            const { reason, notes } = params.data ?? {};
            const data = { reason: reason ?? undefined, notes: notes ?? undefined };
            const options = { headers: { Authorization: token } };
            await api.patch(`/scheduling/${branchId}/shift-slot/${slotId}/mark-open`, data, options);
        },
        [branchId, token]
    );

    const onSuccess = useCallback(async () => {
        // Schedule
        await queryClient.invalidateQueries({ queryKey: ['full-schedule', branchId] });
        await queryClient.invalidateQueries({ queryKey: ['suggested-staff', branchId] });

        // Dashboard
        await queryClient.invalidateQueries({ queryKey: ['dashboard-alerts', branchId] });
        await queryClient.invalidateQueries({ queryKey: ['open-shifts', branchId] });
        await queryClient.invalidateQueries({ queryKey: ['budgeted-scheduled', branchId] });

        // Reports
        await queryClient.invalidateQueries({ queryKey: ['call-offs', branchId] });
        await queryClient.invalidateQueries({ queryKey: ['overtime-staff', branchId] });
        await queryClient.invalidateQueries({ queryKey: ['scheduled-hours', branchId] });
    }, [branchId]);

    return useErrorHandledMutation({ mutationFn, onSuccess });
};
