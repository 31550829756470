import { Box, Chip, Typography, useTheme } from '@mui/material';
import { useAtomValue } from 'jotai';
import React from 'react';

import { useGetLocations } from '~/scheduling/api/queries/locations/getLocations';
import { GetShiftRequestsResult } from '~/scheduling/api/queries/shift-requests/getShiftRequests';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';

import { selectedRequestsAtom } from '../../../atom';

interface SelectionCounterProps {
    openSlotsAmount: number;
}

const SelectionCounter = ({ openSlotsAmount }: SelectionCounterProps) => {
    const selectedRequestsIds = useAtomValue(selectedRequestsAtom);
    const { palette } = useTheme();
    const isCompleted = selectedRequestsIds.length === openSlotsAmount;

    return (
        <Box
            marginBottom="12px"
            alignItems="center"
            display="flex"
            gap="4px"
            boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.08)"
            bgcolor={(isCompleted ? palette.primary[25] : palette.error[25]) as string}
            border={`1px solid ${isCompleted ? palette.primary[200] : palette.error[200]}`}
            borderRadius="6px"
            padding="4px 4px 4px 8px"
        >
            <Typography
                flex={1}
                variant="body1"
                color={palette.grey[500]}
                fontWeight={600}
                fontSize="13px"
                lineHeight="16px"
            >
                Choose {openSlotsAmount} staff for this shift.
            </Typography>
            <Box
                flexShrink={0}
                padding="2px 6px"
                bgcolor={(isCompleted ? palette.primary[600] : palette.error[600]) as string}
                borderRadius="4px"
            >
                <Typography variant="body1" fontWeight={400} fontSize="13px" color="white" lineHeight="16px">
                    <span style={{ fontSize: '15px', fontWeight: 700, lineHeight: '20px' }}>
                        {selectedRequestsIds.length}
                    </span>
                    /{openSlotsAmount}
                </Typography>
            </Box>
        </Box>
    );
};

interface SlotRequestHeaderProps {
    shift: GetShiftRequestsResult[number];
    isMultiSelect: boolean;
}

export const SlotRequestHeader = ({ shift, isMultiSelect }: SlotRequestHeaderProps) => {
    const { shiftDay, staffRoleId, staffRoleShiftId, locationId } = shift;

    const { data: roleData } = useGetRoles();
    const roleById = roleData?.roleById;
    const roleShiftById = roleData?.roleShiftById;

    const { data: locationData } = useGetLocations();
    const locationById = locationData?.locationById;

    const dayStr = shiftDay.toFormat('EEE, MMM d');
    const role = roleById?.get(staffRoleId)?.name;
    const roleShift = roleShiftById?.get(staffRoleShiftId)?.name;
    const location = locationById?.get(locationId)?.abbreviation;

    return (
        <>
            {isMultiSelect && <SelectionCounter openSlotsAmount={shift.openSlotsAmount} />}
            <Box display="flex" gap="48px" justifyContent="space-between">
                <Box display="flex" gap="4px">
                    <Typography variant="h6" color="grey.900">
                        {dayStr}
                    </Typography>
                    <Typography variant="h6" fontWeight={400} color="grey.900">
                        ({roleShift} Shift)
                    </Typography>
                </Box>
                <Box display="flex" gap="8px">
                    <Chip size="medium" label={role} />
                    <Chip size="medium" label={location} />
                </Box>
            </Box>
        </>
    );
};
