import { DateTime } from 'luxon';
import { useCallback } from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/api/common';
import { GetShiftRequests } from '~/scheduling/api/types/shift-requests/getShiftRequests';

export type GetShiftRequestsResult = NonNullable<ReturnType<typeof useGetShiftRequests>['data']>;

export const useGetShiftRequests = () => {
    const branchId = useBranchId()!;
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = { headers: { Authorization: token } };
        const { data } = await api.get<GetShiftRequests.Response>(`/scheduling/${branchId}/shift-requests/`, options);

        return data.response.map(remapSlot);
    }, [branchId, token]);

    return useErrorHandledQuery({ queryKey: ['shift-requests', branchId], queryFn, staleTime: 30000 });
};

const remapSlot = ({ shiftDay, requests, ...suggested }: GetShiftRequests.Shift) => ({
    ...suggested,
    shiftDay: DateTime.fromISO(shiftDay),
    requests: requests.map(remapRequest),
});

const remapRequest = ({ createdAt, ...request }: GetShiftRequests.Request) => ({
    ...request,
    createdAt: DateTime.fromISO(createdAt),
});
