import { CancelOutlined, CheckCircleOutlined } from '@mui/icons-material';
import { Box, CircularProgress, styled } from '@mui/material';
import React from 'react';

import { pxToRem } from '~/components/theme/typography';
import { ONBOARDING } from '~/constants/onboarding';
import { TaskLoadingState } from '~/types/dailyTasks';

const ButtonsColumn = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    })
);

const ButtonContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        width: pxToRem(54),
        height: pxToRem(54),
        position: 'relative',
    })
);

const ButtonLoading = styled(CircularProgress)(({ theme }) =>
    theme.unstable_sx({
        position: 'absolute',
        margin: pxToRem(4),
        pointerEvents: 'none',
    })
);

type Props = {
    onToggleRejectDialog: () => void;
    onConfirmTask: () => void;
    loadingState?: TaskLoadingState;
    isPrnTask?: boolean;
};

export const TaskOptions = (props: Props) => {
    const { onToggleRejectDialog, onConfirmTask, isPrnTask, loadingState = null } = props;

    const buttonStyles = {
        color: isPrnTask ? 'grey.300' : '#2C2D3B',
        width: pxToRem(54),
        height: pxToRem(54),
        opacity: loadingState ? 0.2 : 1,
    };

    return (
        <ButtonsColumn>
            <ButtonContainer>
                {loadingState === 'confirm' && <ButtonLoading size={46} thickness={4.8} />}
                <CheckCircleOutlined
                    id={ONBOARDING.CHECK_TASK_BTN.TARGET}
                    onClick={() => !isPrnTask && !loadingState && onConfirmTask()}
                    sx={buttonStyles}
                />
            </ButtonContainer>
            <ButtonContainer>
                {loadingState === 'reject' && <ButtonLoading size={46} thickness={4.8} />}
                <CancelOutlined
                    id={ONBOARDING.REFUSE_TASK_BTN.TARGET}
                    onClick={() => !isPrnTask && !loadingState && onToggleRejectDialog()}
                    sx={buttonStyles}
                />
            </ButtonContainer>
        </ButtonsColumn>
    );
};
