import { Box, useTheme } from '@mui/material';
import React from 'react';

import TeamSelector from '~/scheduling/components/TeamSelector';

import { useBudgetedShifts } from '../../useBudgetedShifts';

import { ShiftRows, WeekRow } from './CalendarRow';

const ShiftCalendar = () => {
    const { palette } = useTheme();
    const { budgetedShifts } = useBudgetedShifts();

    return (
        <Box
            flex={1}
            display="flex"
            flexDirection="column"
            borderRadius="12px"
            padding="24px"
            border={`1px solid ${palette.grey[100]}`}
            bgcolor="#FFF"
            overflow="auto"
        >
            <Box>
                <TeamSelector />
            </Box>

            <Box flex={1} display="flex" gap="8px" flexDirection="column" marginTop="24px">
                <WeekRow />
                {budgetedShifts.map((budgetedShift) => (
                    <ShiftRows key={budgetedShift.shift.id} {...budgetedShift} />
                ))}
            </Box>
        </Box>
    );
};

export default ShiftCalendar;
