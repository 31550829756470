import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import langDictionary from '~/app-strings';
import { pxToRem } from '~/components/theme/typography';

const PersonalInfoContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(16),
        display: 'flex',
        flexDirection: 'column',
    })
);

const PersonalInfoTitle = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(8),
    })
) as typeof Typography;

const PersonalInfoBullets = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        ml: pxToRem(24),
        listStylePosition: 'outside',
        textAlign: 'left',
        textIndent: pxToRem(-17),
    })
) as typeof Typography;

type Props = {
    personalInfo: string | null;
};

const ResidentPersonalInfoContainer = (props: Props) => {
    const { personalInfo } = props;
    const { residents: residentsDictionary } = langDictionary;

    if (!personalInfo) {
        return null;
    }

    const bullets = personalInfo
        ? personalInfo.split('\n').map((bullet, index) => ({
              key: index,
              value: bullet,
          }))
        : [];

    return (
        <PersonalInfoContainer>
            <PersonalInfoTitle variant="h2">{residentsDictionary.personalInfo}</PersonalInfoTitle>
            {bullets.map((bullet) => (
                <PersonalInfoBullets key={bullet.key} variant="body2" component="li">
                    {bullet.value}
                </PersonalInfoBullets>
            ))}
        </PersonalInfoContainer>
    );
};

export default ResidentPersonalInfoContainer;
