import { Box, Typography } from '@mui/material';
import React from 'react';

import { CHECK_IN_STATUS } from '@allie/utils/src/constants/rewards.constants';

import { pxToRem } from '~/components/theme/typography';

import { CHECK_IN_ICON_COLOR_MAP } from './constants';

const LegendItem = ({ status, text }: { status: string; text: string }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: pxToRem(4) }}>
        <Box
            sx={{
                width: pxToRem(8),
                height: pxToRem(8),
                borderRadius: '50%',
                bgcolor: CHECK_IN_ICON_COLOR_MAP[status],
            }}
        />
        <Typography
            sx={{
                fontSize: { xs: pxToRem(12), lg: pxToRem(14) },
                display: 'inline',
                lineHeight: 1.7,
                color: '#424350',
            }}
        >
            {text}
        </Typography>
    </Box>
);

export const CheckInCalendarLegend = () => (
    <Box
        sx={{
            display: 'flex',
            gap: 2,
            margin: '0 auto',
            width: pxToRem(320),
            px: pxToRem(30),
        }}
    >
        <LegendItem status={CHECK_IN_STATUS.COMPLETE} text="Check-in" />
        <LegendItem status={CHECK_IN_STATUS.MISSED} text=" Missed Check-in" />
    </Box>
);
