import { Typography } from '@mui/material';
import React, { ReactNode } from 'react';

import { pxToRem } from '~/components/theme/typography';

export const TooltipContent = ({ content }: { content: ReactNode }) => (
    <Typography
        sx={{
            fontSize: { xs: pxToRem(18) },
            lineHeight: 1.3,
            textAlign: 'left',
        }}
    >
        {content}
    </Typography>
);

export const TooltipContentBold = ({ text, color }: { text: string; color?: string }) => (
    <Typography
        sx={{
            fontSize: { xs: pxToRem(18) },
            lineHeight: 1.3,
            fontWeight: 700,
            color: color || 'primary.dark',
        }}
        component="span"
    >
        {text}
    </Typography>
);
