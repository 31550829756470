import {
    Box,
    LinearProgress,
    IconButton as MuiIconButton,
    Stack,
    Typography,
    linearProgressClasses,
    styled,
    useTheme,
} from '@mui/material';
import { X } from '@phosphor-icons/react';
import React, { ComponentProps, useEffect } from 'react';

import { CustomAvatar } from '~/components/Custom';
import { ShiftSelector } from '~/components/Filtering';
import { UnscheduledTaskDialogSteps } from '~/pages/Home/components/NewUnscheduledTaskDialog/types/unscheduledTaskDialogSteps';

import useShiftSelectorOptions from '../hooks/useShiftSelectorOptions';
import useResidentInfo from '../steps/resident/hooks/useResidentInfo';

type HeaderProps = {
    onClose: () => void;
    step: UnscheduledTaskDialogSteps;
    shiftId: number | null;
    setShiftId: (shiftId: number) => void;
    progress: number;
    isECall?: boolean;
};

type HeaderContainerProps = {
    onClose?: () => void;
    children: React.ReactNode;
};

const HeaderContainerBox = styled(Box)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: '16px',
}));

const IconButton = styled(MuiIconButton)(({ theme }) => ({
    padding: '5px',
    [theme.breakpoints.down('md')]: {
        borderColor: 'transparent',
    },
    [theme.breakpoints.up('md')]: {
        border: '1px solid',
        borderColor: theme.palette.divider,
    },
}));

const StepProgressBar = styled(LinearProgress)(({ theme }) => ({
    height: '2px',
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[100],
    },
    [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: theme.palette.primary[500] as string,
    },
}));

const CloseButton = ({ ...props }: ComponentProps<typeof MuiIconButton>) => {
    const { palette } = useTheme();

    return (
        <IconButton {...props}>
            <X color={palette.grey[900]} weight="bold" size={14} />
        </IconButton>
    );
};

const HeaderContainer = ({ children }: Readonly<HeaderContainerProps>) => {
    return (
        <HeaderContainerBox>
            <Box display="flex" flexDirection="row" alignItems="center" flexGrow={1} gap="8px">
                {children}
            </Box>
        </HeaderContainerBox>
    );
};

export default function Header({ onClose, isECall, step, shiftId, setShiftId, progress }: Readonly<HeaderProps>) {
    const { resident, photo } = useResidentInfo();
    const { nearbyShifts, currentShiftId, handleShiftChange } = useShiftSelectorOptions();
    const { palette } = useTheme();

    useEffect(() => {
        setShiftId(Number(currentShiftId));
    }, [currentShiftId, setShiftId]);

    const isSelectResidentStep = step === UnscheduledTaskDialogSteps.SELECT_RESIDENT;
    const isTheLastStep = step === UnscheduledTaskDialogSteps.CONFETTI;

    return (
        <>
            <HeaderContainer>
                <>
                    {isSelectResidentStep ? (
                        <Typography variant="body1" fontSize="16px" fontWeight={700}>
                            Who did you assist?
                        </Typography>
                    ) : (
                        <>
                            {resident && <CustomAvatar size={40} photo={photo} fullName={resident.fullName} />}
                            <Box display="flex" flexDirection="column" gap="4px">
                                <Typography variant="body1" color={palette.grey[900]} sx={{ textWrap: 'balance' }}>
                                    <span style={{ fontWeight: 700 }}>{resident.fullName}</span>{' '}
                                    <span style={{ color: palette.secondary[500] as string }}>
                                        {resident?.roomNumber}
                                    </span>
                                </Typography>
                            </Box>
                        </>
                    )}
                    <Box display="flex" gap="8px" justifyContent="flex-end" alignItems="center" flexGrow={1}>
                        {shiftId && !isECall && !isTheLastStep && (
                            <ShiftSelector
                                selectedShiftId={shiftId}
                                shiftOptions={nearbyShifts}
                                onChange={(value) => handleShiftChange(value, setShiftId)}
                                isDialog
                            />
                        )}
                        {!isECall && <CloseButton onClick={onClose} />}
                    </Box>
                </>
            </HeaderContainer>
            <Stack spacing={2} sx={{ flexGrow: 1 }}>
                <StepProgressBar variant="determinate" value={progress} />
            </Stack>
        </>
    );
}
