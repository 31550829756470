import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';

import { useGetCommunitySettings } from '~/scheduling/api/queries/community-settings/getCommunitySettings';
import { useCreateMasterSchedule } from '~/scheduling/api/queries/master-schedule/createMasterSchedule';
import { teamIdAtom } from '~/scheduling/atoms';
import SegmentedModal from '~/scheduling/components/SegmentedModal';

import { isSaveOptimizationModalOpenAtom } from '../../atom';
import { createMasterScheduleInput } from '../../helpers';
import { useBudgetedShifts } from '../../useBudgetedShifts';
import { useUpdateBudgets } from '../../useUpdateBudgets';

const SaveOptimizationModalActions = ({
    hasSelectedDate,
    onConfirm,
    isPending,
}: {
    hasSelectedDate: boolean;
    onConfirm: VoidFunction;
    isPending: boolean;
}) => {
    const toggle = useSetAtom(isSaveOptimizationModalOpenAtom);

    return (
        <>
            <Button variant="outlined" onClick={toggle} disabled={isPending}>
                Cancel
            </Button>
            <Button disabled={!hasSelectedDate || isPending} onClick={onConfirm}>
                {isPending ? <CircularProgress size={20} thickness={4} sx={{ color: 'white' }} /> : 'Confirm'}
            </Button>
        </>
    );
};

const SaveOptimizationModal = () => {
    const navigate = useNavigate();

    const { data: communitySettings } = useGetCommunitySettings();
    const firstDayOfWeek = communitySettings?.firstDayOfWeek;

    const now = DateTime.now();
    const weekday = now.weekday;
    const nextWeek = now.plus({ week: 1 });
    const startOfTheWeek = useMemo(
        () =>
            firstDayOfWeek
                ? weekday <= firstDayOfWeek
                    ? nextWeek.plus({ days: firstDayOfWeek - weekday })
                    : nextWeek.minus({ days: weekday - firstDayOfWeek })
                : undefined,
        [firstDayOfWeek, weekday, nextWeek]
    );

    const [selectedDate, setSelectedDate] = React.useState(startOfTheWeek);
    const [isOpen, toggle] = useAtom(isSaveOptimizationModalOpenAtom);
    const { reset } = useUpdateBudgets();

    const teamId = useAtomValue(teamIdAtom);
    const { budgetedShifts } = useBudgetedShifts();
    const { budgetsToChange } = useUpdateBudgets();

    const { mutateAsync: createMasterSchedule, isPending: isCreateMasterSchedulePending } =
        useCreateMasterSchedule(teamId);

    // Wait for community settings to load
    useEffect(() => {
        if (startOfTheWeek && !selectedDate) setSelectedDate(startOfTheWeek);
    }, [startOfTheWeek]);

    const handleConfirm = async () => {
        if (!selectedDate) return;

        const input = createMasterScheduleInput(selectedDate, budgetsToChange, budgetedShifts);
        await createMasterSchedule(input);
        toggle();
        reset();
        navigate('/scheduling/full-schedule');
    };

    if (!selectedDate) return null;

    return (
        <SegmentedModal
            isOpen={isOpen}
            header="Save Changes"
            onClose={toggle}
            actions={
                <SaveOptimizationModalActions
                    hasSelectedDate={!!selectedDate?.isValid}
                    onConfirm={handleConfirm}
                    isPending={isCreateMasterSchedulePending}
                />
            }
        >
            <Box>
                <Typography sx={{ fontSize: '12px', fontWeight: 700, color: 'grey.900', marginBottom: '4px' }}>
                    Effective date
                </Typography>
                <DatePicker
                    value={selectedDate}
                    onChange={(date) => date && setSelectedDate(date)}
                    format="cccc, MM/dd/yyyy"
                    disabled={isCreateMasterSchedulePending}
                    sx={{ width: '100%', '& .MuiIconButton-root': { marginRight: 0 } }}
                />
            </Box>
        </SegmentedModal>
    );
};

export default SaveOptimizationModal;
