import { useCallback } from 'react';

import { api } from '~/api';
import { queryClient, useBranchId, useErrorHandledMutation, useToken } from '~/api/common';

export const useDeleteShiftRequest = () => {
    const branchId = useBranchId()!;
    const token = useToken();

    const mutationFn = useCallback(
        async ({ requestId }: { requestId: number }) => {
            const options = { headers: { Authorization: token } };
            await api.delete(`/scheduling/${branchId}/shift-requests/${requestId}`, options);
        },
        [branchId, token]
    );

    const onSuccess = useCallback(async () => {
        await queryClient.invalidateQueries({ queryKey: ['my-schedule', branchId] }); // Also invalidates 'other-staff'
    }, [branchId]);

    return useErrorHandledMutation({ mutationFn, onSuccess });
};
