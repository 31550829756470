import { Search as SearchIcon } from '@mui/icons-material';
import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { useAtom, useAtomValue } from 'jotai';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ShiftOption } from '@allie/utils/src/shifts';

import { useGetAssignments } from '~/api/queries/assignments/getAssignments';
import { useBranchesByRegionQuery } from '~/api/queries/companyInfo';
import { CustomTextField } from '~/components/Custom';
import { INPUT_STYLES, MOBILE_INPUT_STYLES } from '~/components/Custom/constants';
import { BranchSelector, ShiftSelector, ZoneSelector } from '~/components/Filtering';
import AssignmentSelector from '~/components/Filtering/AssignmentSelector';
import SortButton from '~/components/Shared/Sorting/SortButton';
import { usePermissions } from '~/permissions/utils';
import { DailyTasksByTabStrict, DailyTasksResponse, TabKey } from '~/types/dailyTasks';
import { ReduxStore } from '~/types/redux';

import { selectedAssignmentIdsAtom, selectedDateAtom } from '../../atom';
import { ALL_ASSIGNMENTS_ID, shouldUseAssignments } from '../../helpers';
import { TabSelector } from '../TabSelector';

const MAX_DAYS_TO_SHOW = 3;

const TopFixedBar = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        backgroundColor: { xs: '#006B75', sm: theme.palette.common.white },
    })
);

const TopFiltersContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        p: '12px 16px 8px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: { xs: 'space-between', sm: 'flex-end' },
        flexWrap: 'wrap',
        columnGap: { xs: 1, sm: 2 },
        gap: '4px',
    })
);

const TopFiltersRow = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        width: '100%',
        height: '48px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '8px',
    })
);

type ShiftOptions = Array<{
    shiftDayDate: string;
    shift: ShiftOption;
}>;

interface HeaderProps {
    tasksByTab: DailyTasksByTabStrict;
    currentShiftId: number;
    currentShiftDayDate: string;
    selectedBranchId?: number;
    selectedShiftId: number;
    searchValue: string;
    selectedTab: TabKey;
    dailyTasksList?: DailyTasksResponse;
    shiftOptions: ShiftOptions;
    onFilterChange: (key: string) => (value: number) => void;
    onSearchValueChange: (value: string) => void;
    onSortButtonClick: () => void;
    onTabChange: (tab: number) => void;
}

export const Header = ({
    tasksByTab,
    currentShiftDayDate,
    currentShiftId,
    selectedShiftId,
    selectedBranchId,
    selectedTab,
    searchValue,
    dailyTasksList,
    shiftOptions,
    onSearchValueChange,
    onFilterChange,
    onSortButtonClick,
    onTabChange,
}: HeaderProps) => {
    const { company, companyId, regionId, branch, branchId } = useSelector(
        (state: ReduxStore) => state.session.sessionData
    );
    const { breakpoints } = useTheme();
    const hasPermission = usePermissions();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const selectedAssignmentIds = useAtomValue(selectedAssignmentIdsAtom);

    const { data: branchesByRegionData } = useBranchesByRegionQuery({ branchId, regionId, companyId });
    const { data: assignments } = useGetAssignments();
    const [selectedDate, setSelectedDate] = useAtom(selectedDateAtom);

    const currentShiftDateTzDateTime = DateTime.fromISO(currentShiftDayDate);
    const branchesWithAccess = branchesByRegionData?.branches.filter((branch) => branch.hasAccess) ?? [];
    const renderBranchSelector =
        hasPermission('Community', 'read-all-resident-actions') && branchesWithAccess.length > 1;

    const filteredAssignments = useMemo(
        () =>
            assignments?.filter(
                ({ branchShiftId, assignmentId }) =>
                    (!branchShiftId || branchShiftId === selectedShiftId) &&
                    dailyTasksList?.[selectedShiftId]?.some((task) => task.assignmentId === assignmentId) // Only show assignments that have tasks related for the shift
            ) ?? [],
        [assignments, selectedShiftId, dailyTasksList]
    );

    const assignmentOptions = useMemo(
        () => [
            {
                label: 'All',
                value: ALL_ASSIGNMENTS_ID, // Not ideal, but useful while we don't have nicer types for CustomSelect
            },
            ...filteredAssignments.map(({ assignmentId, assignmentName }) => ({
                label: assignmentName,
                value: assignmentId,
            })),
        ],
        [filteredAssignments]
    );

    const handleDateChange = (newDate: DateTime | null) => {
        if (newDate) {
            setSelectedDate(newDate as DateTime<true>);
        }
    };

    const getDateInputProps = (date?: DateTime) => {
        return {
            InputProps: {
                value: date?.hasSame(currentShiftDateTzDateTime, 'day') ? 'Today' : date?.toFormat('MM/dd/yyyy'),
            },
        };
    };

    return (
        <TopFixedBar>
            <TopFiltersContainer>
                {renderBranchSelector && (
                    <TopFiltersRow>
                        <BranchSelector
                            value={selectedBranchId ?? -1}
                            options={branchesWithAccess.map((branch) => ({
                                label: branch.branchName,
                                value: branch.branchId.toString(),
                            }))}
                            onChange={(newValue) => onFilterChange('branch')(newValue)}
                            isHeader
                            fullWidth
                        />
                    </TopFiltersRow>
                )}
                <TopFiltersRow>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <Box
                            sx={{
                                width: '100%',
                                '& .MuiInputBase-input': {
                                    padding: '8px 16px',
                                },
                                ...(isMobile ? MOBILE_INPUT_STYLES : INPUT_STYLES),
                            }}
                        >
                            <MobileDatePicker
                                label="Shift Date"
                                format="MM/dd/yyyy"
                                value={selectedDate}
                                onChange={handleDateChange}
                                minDate={
                                    hasPermission('Community', 'read-all-resident-actions')
                                        ? undefined
                                        : DateTime.now().minus({ days: MAX_DAYS_TO_SHOW }) // For caregivers, from AH-784
                                }
                                maxDate={currentShiftDateTzDateTime}
                                slotProps={{
                                    textField: (params) => getDateInputProps(params.value as DateTime),
                                }}
                            />
                        </Box>
                    </LocalizationProvider>
                    <ShiftSelector
                        width="100%"
                        selectedShiftId={selectedShiftId}
                        currentShiftId={
                            selectedDate && currentShiftDateTzDateTime.hasSame(selectedDate, 'day')
                                ? currentShiftId
                                : undefined
                        }
                        shiftOptions={shiftOptions}
                        fullWidth
                        onChange={(newShift) => onFilterChange('shift')(+newShift)}
                    />
                </TopFiltersRow>
                <TopFiltersRow>
                    <CustomTextField
                        label="Resident Name/Apt"
                        placeholder="Type by Name/Apt"
                        value={searchValue}
                        fullWidth
                        startAdornment={<SearchIcon htmlColor="#DEDEE0" />}
                        onChange={onSearchValueChange}
                        sx={isMobile ? MOBILE_INPUT_STYLES : INPUT_STYLES}
                    />
                </TopFiltersRow>
                <TopFiltersRow>
                    <Box sx={{ flex: 1 }}>
                        {shouldUseAssignments(company, branch) ? (
                            <AssignmentSelector
                                options={assignmentOptions}
                                value={selectedAssignmentIds[0]}
                                onChange={onFilterChange('assignmentId')}
                                fullWidth
                                disabled={!filteredAssignments.length}
                            />
                        ) : (
                            <ZoneSelector
                                width="100%"
                                branchId={selectedBranchId}
                                fullWidth
                                onChange={(value) => onFilterChange('zoneId')(+value)}
                            />
                        )}
                    </Box>
                    <Box sx={{ width: '30%' }}>
                        <SortButton onClick={onSortButtonClick} />
                    </Box>
                </TopFiltersRow>
            </TopFiltersContainer>
            <TabSelector tabs={tasksByTab} selectedTab={selectedTab} onTabChange={onTabChange} />
        </TopFixedBar>
    );
};
