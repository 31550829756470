import { useSelector } from 'react-redux';

import { getNearbyShiftsAtDateTimeUtc, getShiftAtDateTimeUtc } from '@allie/utils/src/shifts';

import { useBranchShifts } from '~/api/queries/branch';
import { getDateInUtc } from '~/lib/date';
import { ReduxStore } from '~/types/redux';

export default function useShiftSelectorOptions() {
    const { branchId } = useSelector((state: ReduxStore) => state.session.sessionData);
    const { timezone } = useSelector((state: ReduxStore) => state.session);

    const branchShifts = useBranchShifts(branchId);

    const nearbyShifts = getNearbyShiftsAtDateTimeUtc(getDateInUtc(new Date()), timezone, branchShifts);

    const {
        shift: { id: currentShiftId },
    } = getShiftAtDateTimeUtc(getDateInUtc(new Date()), timezone, branchShifts);

    const handleShiftChange = (newShiftId: string, setFn: (value: number) => void) => {
        setFn(Number(newShiftId));
    };

    return {
        nearbyShifts,
        currentShiftId,
        handleShiftChange,
    };
}
