import { Box, Button, Typography } from '@mui/material';
import React from 'react';

import { GoldCoin } from '~/components/Svg/GoldCoin';
import { pxToRem } from '~/components/theme/typography';
import { NURSE_CALLS_DIALOG_ANALYTICS } from '~/constants/analytics';

type Props = {
    residentName: string;
    onAccept: () => void;
};

const NurseCallsCheckboxPrompt = (props: Props) => {
    const { residentName, onAccept } = props;

    return (
        <>
            <Typography textAlign="left">
                Now share what call light requests (PRN care) from {residentName}{' '}
                <Box component="span" fontWeight="bold">
                    you answered
                </Box>{' '}
                this shift.
            </Typography>
            <Button
                onClick={onAccept}
                color="primary"
                variant="contained"
                fullWidth
                sx={{
                    mt: 2,
                }}
                startIcon={
                    <Box
                        component="span"
                        sx={{
                            color: '#FCD7B1',
                            display: 'flex',
                            mt: pxToRem(3),
                        }}
                    >
                        <GoldCoin size={30} />
                    </Box>
                }
                data-analytics-id={NURSE_CALLS_DIALOG_ANALYTICS.CONTINUE_PROMPT_BUTTON}
            >
                Let&apos;s Do It!
            </Button>
        </>
    );
};

export default NurseCallsCheckboxPrompt;
