import { Box, Button, CircularProgress, Skeleton, Stack, Typography, styled, useTheme } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect, useMemo, useState } from 'react';
import { IoReload } from 'react-icons/io5';
import { PiCalendarBlankFill } from 'react-icons/pi';

import { useGetLocations } from '~/scheduling/api/queries/locations/getLocations';
import { useGetShiftRequests } from '~/scheduling/api/queries/shift-requests/getShiftRequests';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';
import CustomConfetti from '~/scheduling/components/CustomConfetti';

import { handledRequestsAtom, skippedRequestsAtom } from './atom';
import Header from './component/Header';
import { ShiftApprovalCards } from './component/ShiftApprovalCards';
import { ShiftApproval as ShiftApprovalType } from './types';

const IconContainer = styled(Box)(({ theme }) => ({
    width: '56px',
    height: '56px',
    padding: '16px',
    backgroundColor: theme.palette.primary[50],
    borderRadius: '100px',
}));

const EmptyState = ({ refetch }: { refetch: VoidFunction }) => {
    const setHandledRequests = useSetAtom(handledRequestsAtom);
    const [skippedRequests, setSkippedRequests] = useAtom(skippedRequestsAtom);
    const { palette } = useTheme();

    const handleRefresh = () => {
        refetch();
        setSkippedRequests(0);
        setHandledRequests(0);
    };

    return (
        <Stack alignItems="center" height="100%" justifyContent="center" padding="24px">
            <IconContainer>
                <PiCalendarBlankFill size={24} color={palette.primary[600] as string} />
            </IconContainer>
            <Box height="32px" />
            <Typography color={palette.grey[900]} variant="h5" letterSpacing="unset">
                You are all set!
            </Typography>
            <Typography
                color={palette.grey[500]}
                variant="body1"
                textAlign="center"
                sx={{ textWrap: 'balance' }}
                fontWeight={400}
                marginTop="8px"
            >
                You have handled with all shift applications. Great job!
            </Typography>
            {skippedRequests > 0 && (
                <>
                    <Box height="32px" />
                    <Button
                        variant="outlined"
                        title="Reload"
                        onClick={handleRefresh}
                        startIcon={<IoReload size={16} color="#000" />}
                    >
                        Restart
                    </Button>
                </>
            )}
        </Stack>
    );
};

const LoadingState = () => (
    <Box width="100%" height="100%" padding="16px">
        <Skeleton width="100%" height="100%" />
    </Box>
);

const ShiftApprovalCounter = ({ shiftsToHandle }: { shiftsToHandle: number }) => {
    const { palette } = useTheme();
    const handledRequests = useAtomValue(handledRequestsAtom);

    const progress = shiftsToHandle > 0 ? Math.floor((100 * handledRequests) / shiftsToHandle) : 0;

    return (
        <Box display="flex" gap="8px" alignItems="center" color={palette.grey[25]}>
            <Box position="relative" height="24px">
                <CircularProgress
                    sx={{ position: 'absolute', color: 'rgba(255, 255, 255, 0.5)' }}
                    variant="determinate"
                    value={100}
                    size={24}
                    thickness={9}
                />
                <CircularProgress variant="determinate" value={progress} size={24} color="inherit" thickness={9} />
            </Box>
            <Typography variant="body1" fontWeight={500} fontSize="14px">
                <span style={{ fontWeight: 700, fontSize: '16px' }}>
                    {shiftsToHandle ? shiftsToHandle - handledRequests : 0}
                </span>{' '}
                Left
            </Typography>
        </Box>
    );
};

const ShiftApproval = () => {
    const [startParty, setStartParty] = useState(false);
    const handledRequests = useAtomValue(handledRequestsAtom);
    const {
        data: slotRequestsData,
        isLoading: isGetSlotRequestsLoading,
        error: getSlotRequestsError,
        refetch,
    } = useGetShiftRequests();
    const slotRequests = slotRequestsData ?? [];

    const { data: locationsData, isLoading: isGetLocationsLoading, error: getLocationError } = useGetLocations();
    const { data: rolesData, isLoading: isGetRolesLoading, error: getRolesError } = useGetRoles();

    const isLoading = isGetSlotRequestsLoading || isGetLocationsLoading || isGetRolesLoading;

    useEffect(() => {
        if (slotRequests.length && slotRequests.length === handledRequests) setStartParty(true);
    }, [handledRequests]);

    const shiftsToApprove = useMemo<ShiftApprovalType.CardProps[]>(() => {
        return slotRequests.map((slotRequest) => {
            const location = locationsData?.locationById.get(slotRequest.locationId);
            const role = rolesData?.roleById.get(slotRequest.staffRoleId);
            const shift = role?.staffRoleShifts.find((shift) => shift.id === slotRequest.staffRoleShiftId);

            return {
                ...slotRequest,
                // The case where there are no location, role, and shift is
                // handle by the if statement below checking the errors states
                locationName: location?.name ?? '',
                staffRoleName: role?.name ?? '',
                shiftName: shift?.name ?? '',
            };
        });
    }, [locationsData, rolesData, slotRequests]);

    if (getRolesError || getLocationError || getSlotRequestsError) return null;

    return (
        <Stack height="100%">
            <Header actions={<ShiftApprovalCounter shiftsToHandle={shiftsToApprove.length} />} />
            {isLoading ? (
                <LoadingState />
            ) : !shiftsToApprove.length || handledRequests === shiftsToApprove.length ? (
                <EmptyState refetch={refetch} />
            ) : (
                <ShiftApprovalCards shiftsToApprove={shiftsToApprove} />
            )}
            <CustomConfetti activate={startParty} stop={() => setStartParty(false)} />
        </Stack>
    );
};

export default ShiftApproval;
