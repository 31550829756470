import { Add as AddIcon } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { cloneDeep, isEmpty, orderBy } from 'lodash';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useSound from 'use-sound';

import { getNearbyShiftsAtDateTimeUtc, getShiftAtDateTimeUtc } from '@allie/utils/src/shifts';

import { useBranchShifts, withBranchShiftSelector } from '~/api/queries/branch';
import { useDailyTasks, useUpdateDailyTasks } from '~/api/queries/tasks/dailyTasks';
import notificationSound from '~/assets/notification-sound.mp3';
import { CustomTabPanel as TabPanel } from '~/components/Custom';
import CustomConfetti from '~/components/Custom/CustomConfetti';
import WithHeader from '~/components/Layout/WithHeader';
import { showToast } from '~/components/Shared/Alerting/Toast/utils/showToast';
import Loading from '~/components/Shared/Loading';
import { ALL_ASSIGNMENTS_ID } from '~/constants/filters';
import { HOME_FILTERS } from '~/constants/localStorage';
import { getDateInUtc } from '~/lib/date';
import CheckTaskDialog from '~/pages/Home/components/CheckTaskDialog';
import TaskNotesDialog from '~/pages/Home/components/TaskNotesDialog';
import UnscheduledTaskDialog from '~/pages/Home/components/UnscheduledTaskDialog';
import PageStructure from '~/pages/PageStructure';
import { usePermissions } from '~/permissions/utils';
import { setSortBy, setSortOrder } from '~/redux/actions/filters';
import { hideAlert } from '~/redux/actions/messages';
import { toggleResidentParty } from '~/redux/actions/residents';
import {
    CheckTaskMode,
    DailyTasksByTabStrict,
    DailyTasksShiftDetail,
    TabKey,
    TaskToUpdate,
} from '~/types/dailyTasks.d';
import { AppDispatch, ReduxStore } from '~/types/redux';
import { SortBy } from '~/types/sort.d';

import {
    isLocationSelectorDialogOpenAtom,
    isShiftSelectorDialogOpenAtom,
    isSortDialogOpenAtom,
    selectedAssignmentIdsAtom,
    selectedDateAtom,
    selectedTabAtom,
} from './atom';
import { Header } from './components/Header';
import HeaderV2 from './components/HeaderV2';
import useHeaderV2 from './components/HeaderV2/hooks/useHeaderV2';
import { LocationSelectorDialog } from './components/LocationSelectorDialog';
import NewUnscheduledTaskDialog from './components/NewUnscheduledTaskDialog';
import useNewPrnFlow from './components/NewUnscheduledTaskDialog/hooks/useNewPrnFlow';
import { ShiftSelectorDialog } from './components/ShiftSelectorDialog';
import { SortDialog } from './components/SortDialog';
import { ResidentRows as ConfirmedResidentRows } from './confirmedTaskListComponents/ResidentRows';
import { LiveCalls } from './eCallComponents';
import { ECall } from './eCallComponents/types.d';
import { useCalls } from './eCallComponents/useCalls';
import {
    ALL_ZONES_ID,
    NO_RESIDENT_SELECTED_ID,
    filterTasksByName,
    groupTasksByResidentId,
    shouldUseAssignments,
    taskRecordSorter,
} from './helpers';
import { ResidentRows as PendingResidentRows } from './taskListComponents/ResidentRows';
import { useSortBy } from './useSortBy';

const TasksContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    position: 'relative',
    width: '100%',
}));

const AddButtonStyle = styled(Button)(({ theme }) => ({
    marginBottom: 'env(safe-area-inset-bottom)',
    padding: 0,
    borderRadius: '50%',
    minWidth: 'unset',
    width: '72px',
    height: '72px',
    position: 'fixed',
    right: '12px',
    bottom: '80px',
    border: '2px solid #fff',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
    '& svg': {
        fontSize: '32px !important',
    },

    [theme.breakpoints.up('sm')]: {
        right: '24px',
        bottom: '24px',
    },
}));

interface PropsFromRedux {
    timezone: string;
    zoneId?: number;
    sortBy?: ECall.SortingKey | SortBy.SortingKey;
    sortOrder?: SortBy.SortOrder;
    displayParty: boolean;
}

interface HomeProps extends PropsFromRedux {
    selectedBranchId: number | undefined;
    setSelectedBranchId: (branchId: number) => void;
    dispatchHideAlert: () => void;
    dispatchSetSortBy: (sortBy: string) => void;
    dispatchSetSortOrder: (sortOrder: string) => void;
    dispatchToggleResidentParty: () => void;
}

const Home = ({
    timezone,
    zoneId,
    sortBy,
    sortOrder,
    displayParty,
    selectedBranchId,
    setSelectedBranchId,
    dispatchHideAlert,
    dispatchSetSortBy,
    dispatchSetSortOrder,
    dispatchToggleResidentParty,
}: HomeProps) => {
    const isHeaderV2Enabled = useHeaderV2();
    const { search } = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(search), [search]);
    const { calls } = useCalls();

    const { company, branch, branchId } = useSelector((state: ReduxStore) => state.session.sessionData);

    const shifts = useBranchShifts(selectedBranchId);
    const { mutateAsync: updateTaskMutation } = useUpdateDailyTasks();

    const {
        shift: { id: currentShiftId },
        shiftDayDate: currentShiftDayDate,
    } = getShiftAtDateTimeUtc(getDateInUtc(new Date()), timezone, shifts);

    const nearbyShifts = getNearbyShiftsAtDateTimeUtc(getDateInUtc(new Date()), timezone, shifts);

    const currentShiftDateTzDateTime = DateTime.fromISO(currentShiftDayDate) as DateTime<true>;

    const [selectedDate, setSelectedDate] = useAtom(selectedDateAtom);
    const datepickerDate = selectedDate ?? currentShiftDateTzDateTime;

    const [shift, setShift] = useState<number | null>(currentShiftId as number);
    // only select shift if it is a valid shift
    const isSelectedShiftValid =
        nearbyShifts.find(({ shift: { id: nearbyShiftId } }) => nearbyShiftId === shift) !== undefined;
    const selectedShiftId = shift && isSelectedShiftValid ? shift : (currentShiftId as number);

    useEffect(() => {
        if (!selectedDate) {
            setSelectedDate(currentShiftDateTzDateTime);
        }
    }, [currentShiftDayDate]);

    // get shift id from query params initially if set to support linking documentation rate from email
    useEffect(() => {
        const selectedShiftId = queryParams.get('selected_shift_id');

        if (selectedShiftId && !isNaN(Number(selectedShiftId))) {
            setShift(Number(selectedShiftId));
        }
    }, [queryParams.get('selected_shift_id')]);

    // get shift day date from query params initially if set to support linking documentation rate from email
    useEffect(() => {
        const selectedShiftDate = queryParams.get('selected_shift_date');

        const dateTimeDate = selectedShiftDate ? DateTime.fromISO(selectedShiftDate) : undefined;

        if (dateTimeDate?.isValid) {
            setSelectedDate(dateTimeDate);
        }
    }, [queryParams.get('selected_shift_date')]);

    const [selectedAssignmentIds, setSelectedAssignmentIds] = useAtom(selectedAssignmentIdsAtom);
    const resetSelectedAssignmentIds = useResetAtom(selectedAssignmentIdsAtom);

    const [selectedTab, setSelectedTab] = useAtom(selectedTabAtom);
    const [isAddedTaskDialogOpen, setIsAddedTaskDialogOpen] = useState<boolean>(false);
    const [isCheckTaskDialogOpen, setIsCheckTaskDialogOpen] = useState<boolean>(false);
    const [checkTaskDialogType] = useState<CheckTaskMode>('Complete');
    const [isTaskNotesDialogOpen, setIsTaskNotesDialogOpen] = useState<boolean>(false);
    const [selectedTaskNotes, setSelectedTaskNotes] = useState<string>();
    const [selectedResidentId, setSelectedResidentId] = useState<number>(NO_RESIDENT_SELECTED_ID);
    const [playCoinSound] = useSound(notificationSound);
    const [addedTaskDefaultResidentId, setAddedTaskDefaultResidentId] = useState<number>();
    const [searchValue, setSearchValue] = useState<string>('');
    const [isShiftSelectorDialogOpen, toggleShiftSelectorDialog] = useAtom(isShiftSelectorDialogOpenAtom);
    const [isLocationSelectorDialogOpen, toggleLocationSelectorDialog] = useAtom(isLocationSelectorDialogOpenAtom);
    const [isSortDialogOpen, toggleSortDialog] = useAtom(isSortDialogOpenAtom);

    const {
        isEnabled: isNewPrnFlowEnabled,
        isUnscheduledTaskDialogOpen: showNewUnscheduledTaskDialog,
        setIsUnscheduledTaskDialogOpen: setShowNewUnscheduledTaskDialog,
    } = useNewPrnFlow();

    const hasPermission = usePermissions();

    const { data: dailyTasksList, isLoading: dailyTasksListIsLoading } = useDailyTasks({
        branchId: selectedBranchId,
        date: datepickerDate.toFormat('yyyy-MM-dd'),
    });

    const { handleSort } = useSortBy();

    const permissionError = () => {
        showToast({
            message: 'Sorry, but this feature is exclusive for Directors/Caregivers',
            type: 'error',
        });
    };

    const handleFilterChange = (filter: string) => (newValue: number) => {
        if (filter === 'assignmentId') setSelectedAssignmentIds([newValue]);
        else resetSelectedAssignmentIds();

        if (filter === 'branch') {
            setSelectedBranchId(newValue);

            // Reset the shift id to use the current shift
            setShift(null);
        } else if (filter === 'shift') {
            setShift(newValue);
        } else if (filter === 'resident') {
            setSelectedResidentId(newValue);
        } else {
            // when zone/assignment filter is changed unselect the currently selected resident
            setSelectedResidentId(NO_RESIDENT_SELECTED_ID);
        }
    };

    const handleAddTaskSubmit = () => {
        dispatchToggleResidentParty();
        playCoinSound();
    };

    const handleAddedTaskDialogOpen = () => {
        // If the user is not a Caregiver, throw an error and exit the function.
        if (!hasPermission('Community', 'undertake-resident-action')) {
            permissionError();
            return;
        }

        if (isNewPrnFlowEnabled) {
            setShowNewUnscheduledTaskDialog(true);
            return;
        }

        setIsAddedTaskDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsAddedTaskDialogOpen(false);
        setIsCheckTaskDialogOpen(false);
        setIsTaskNotesDialogOpen(false);
        setSelectedTaskNotes('');
    };

    const handleCompleteTaskClick = async ({ task }: { task: TaskToUpdate }) => {
        // If the user is not a allowed to perform this action, exit the function.
        if (!hasPermission('Community', 'update-resident-action')) {
            return;
        }

        // Trigger the action for updating the Task as Complete.
        await updateTaskMutation({ tasks: [task], branchId: Number(selectedBranchId) });

        if (hasPermission('Community', 'update-reward') && task.taskStatusId === 2) {
            // Trigger the sound for earning points.
            playCoinSound();
        }
    };

    const handleSortOptions = (selectedSortBy?: string, selectedSortOrder?: string) => {
        if (sortBy !== selectedSortBy && selectedSortBy) {
            dispatchSetSortBy(selectedSortBy);
        }

        if (sortOrder !== selectedSortOrder && selectedSortOrder) {
            dispatchSetSortOrder(selectedSortOrder);
        }
    };

    useEffect(() => {
        // This check is necessary to ensure the correct initial tab will be selected
        if (!hasPermission('Community', 'ecall-caregiver') && selectedTab === TabKey.LIVE_CALLS) {
            setSelectedTab(TabKey.PENDING);
        }
    }, []);

    const tasksByTab = useMemo<DailyTasksByTabStrict>(() => {
        if (!selectedShiftId) {
            return [];
        }

        const newTasksToShow: DailyTasksShiftDetail[] =
            dailyTasksList && dailyTasksList[selectedShiftId]?.length
                ? cloneDeep(dailyTasksList[selectedShiftId])
                      .filter((task) => {
                          if (shouldUseAssignments(company, branch)) {
                              return (
                                  selectedAssignmentIds.includes(ALL_ASSIGNMENTS_ID) ||
                                  selectedAssignmentIds.includes(task.assignmentId)
                              );
                          }

                          if (isHeaderV2Enabled) {
                              return (
                                  selectedAssignmentIds.includes(ALL_ZONES_ID) ||
                                  selectedAssignmentIds.includes(task.zoneId)
                              );
                          }

                          return zoneId === task.zoneId || zoneId === ALL_ZONES_ID;
                      })
                      .filter((task) => {
                          if (selectedResidentId === NO_RESIDENT_SELECTED_ID) {
                              return true;
                          }

                          return task.resident.id === selectedResidentId;
                      })
                : [];

        const pendingTasks = newTasksToShow.filter((task) => task.taskStatus === 'Undocumented');
        const completedTasks = newTasksToShow.filter((task) => task.taskStatus !== 'Undocumented');
        pendingTasks.sort(taskRecordSorter);
        completedTasks.sort(taskRecordSorter);

        const tabs: DailyTasksByTabStrict = [
            {
                key: 1,
                tabName: 'pending',
                tasks: pendingTasks,
            },
            {
                key: 2,
                tabName: 'completed',
                tasks: completedTasks,
            },
        ];

        if (hasPermission('Community', 'ecall-caregiver')) {
            tabs.unshift({
                key: 0,
                tabName: 'calls',
                // TODO - use the correct data type when the backend is integrated
                tasks: calls.filter(
                    (a) => a.status === ECall.CallStatus.Unclaimed
                ) as unknown[] as DailyTasksShiftDetail[],
            });
        }

        return tabs;
    }, [selectedShiftId, selectedAssignmentIds, zoneId, JSON.stringify(dailyTasksList), selectedResidentId, calls]);

    useEffect(() => {
        if (selectedShiftId) {
            // Update the filters into the localStorage.
            const newFilters = {
                date: datepickerDate?.toFormat('yyyy-MM-dd HH:mm:ss'),
                shift: selectedShiftId,
            };
            localStorage.setItem(HOME_FILTERS, JSON.stringify(newFilters));
        }
    }, [datepickerDate?.toFormat('yyyy-MM-dd'), selectedShiftId]);

    useEffect(() => {
        if (!dailyTasksListIsLoading) {
            dispatchHideAlert();
        }
    }, [dailyTasksListIsLoading]);

    // HACK ASCENT: not showing loading so the page doesn't scroll to the top again immediately
    if (!currentShiftDateTzDateTime || (hasPermission('Community', 'undertake-resident-action') && !selectedBranchId)) {
        return <Loading />;
    }

    return (
        <WithHeader
            header={
                isHeaderV2Enabled ? (
                    <HeaderV2
                        selectedTab={selectedTab}
                        tasksByTab={tasksByTab}
                        onTabChange={setSelectedTab}
                        currentShiftDayDate={currentShiftDayDate}
                        selectedShiftId={selectedShiftId}
                        nearbyShifts={nearbyShifts.map((shiftOptions) => shiftOptions.shift)}
                        currentShiftId={currentShiftId as number}
                    />
                ) : (
                    <Header
                        tasksByTab={tasksByTab}
                        currentShiftDayDate={currentShiftDayDate}
                        currentShiftId={currentShiftId as number}
                        dailyTasksList={dailyTasksList}
                        selectedShiftId={selectedShiftId}
                        selectedBranchId={selectedBranchId}
                        selectedTab={selectedTab}
                        searchValue={searchValue}
                        shiftOptions={nearbyShifts}
                        onFilterChange={handleFilterChange}
                        onSearchValueChange={setSearchValue}
                        onTabChange={setSelectedTab}
                        onSortButtonClick={toggleSortDialog}
                    />
                )
            }
        >
            <PageStructure sx={{ padding: '0 !important' }}>
                {isEmpty(tasksByTab) ? (
                    <Typography variant="subtitle1">
                        There are no Tasks for the Date, Shift and Floor selected
                    </Typography>
                ) : (
                    <TasksContainer>
                        {!!branchId &&
                            tasksByTab.map(({ key, tasks }) => {
                                const filteredTasks = filterTasksByName({
                                    zoneId: zoneId ?? ALL_ZONES_ID,
                                    tasks,
                                    searchValue,
                                    assignmentId: tasks[0]?.assignmentId,
                                    filterBy: shouldUseAssignments(company, branch) ? 'assignmentId' : 'zoneId',
                                });

                                const residentsTasks = groupTasksByResidentId(filteredTasks);

                                const residentsTasksList = Object.values(residentsTasks);

                                const defaultResidentsTasksList = orderBy(
                                    residentsTasksList,
                                    (item) => Number(item.roomNumber),
                                    sortOrder === 'asc' ? 'asc' : 'desc'
                                );

                                const residentsTasksListSorted = handleSort(
                                    defaultResidentsTasksList,
                                    sortBy,
                                    sortOrder === 'asc'
                                );

                                return (
                                    <TabPanel
                                        key={key}
                                        value={selectedTab}
                                        index={key}
                                        sx={{
                                            display: selectedTab === TabKey.LIVE_CALLS ? 'flex' : undefined,
                                            flex: key === TabKey.LIVE_CALLS ? 1 : undefined,
                                        }}
                                        TabPanelProps={{ padding: 0, width: '100%' }}
                                    >
                                        {selectedTab === TabKey.LIVE_CALLS && <LiveCalls />}
                                        {selectedTab === TabKey.PENDING && (
                                            <PendingResidentRows
                                                residentTasksList={residentsTasksListSorted}
                                                date={datepickerDate.toFormat('yyyy-MM-dd')}
                                                shift={selectedShiftId}
                                                branchId={branchId}
                                                setSelectedResidentId={setAddedTaskDefaultResidentId}
                                            />
                                        )}
                                        {selectedTab === TabKey.COMPLETED && (
                                            <ConfirmedResidentRows
                                                residentTasksList={residentsTasksListSorted}
                                                branchId={branchId}
                                            />
                                        )}
                                    </TabPanel>
                                );
                            })}
                    </TasksContainer>
                )}
                {hasPermission('Community', 'undertake-resident-action') && selectedTab !== TabKey.LIVE_CALLS && (
                    <AddButtonStyle variant="contained" color="primary" onClick={handleAddedTaskDialogOpen}>
                        <AddIcon />
                    </AddButtonStyle>
                )}
                <UnscheduledTaskDialog
                    isOpen={isAddedTaskDialogOpen}
                    defaultResidentId={addedTaskDefaultResidentId}
                    timezone={timezone}
                    currentShift={selectedShiftId}
                    onSubmit={handleAddTaskSubmit}
                    onClose={handleCloseDialog}
                    canChangeResident
                />
                <NewUnscheduledTaskDialog
                    isOpen={showNewUnscheduledTaskDialog}
                    onClose={() => setShowNewUnscheduledTaskDialog(false)}
                />
                <CheckTaskDialog
                    isOpen={isCheckTaskDialogOpen}
                    dialogType={checkTaskDialogType}
                    onSubmit={handleCompleteTaskClick}
                    onClose={handleCloseDialog}
                />
                <TaskNotesDialog
                    isOpen={isTaskNotesDialogOpen}
                    taskNote={selectedTaskNotes}
                    onClose={handleCloseDialog}
                />
                <SortDialog
                    isOpen={isSortDialogOpen}
                    onClose={toggleSortDialog}
                    onSort={handleSortOptions}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    selectedTab={selectedTab}
                />
                <ShiftSelectorDialog
                    isOpen={isShiftSelectorDialogOpen}
                    onClose={toggleShiftSelectorDialog}
                    selectedShiftId={selectedShiftId}
                    onShiftChange={(newShiftId) => {
                        handleFilterChange('shift')(newShiftId);
                        toggleShiftSelectorDialog();
                    }}
                    shiftOptions={nearbyShifts}
                />
                <LocationSelectorDialog
                    isOpen={isLocationSelectorDialogOpen}
                    onClose={toggleLocationSelectorDialog}
                    selectedTab={selectedTab}
                />

                <CustomConfetti activate={displayParty} stop={dispatchToggleResidentParty} />
            </PageStructure>
        </WithHeader>
    );
};

const mapStateToProps = ({ session, filters, residents }: ReduxStore): PropsFromRedux => {
    const { timezone } = session;
    const { displayParty } = residents;
    const {
        caregiverApp: { zoneId, sortBy, sortOrder },
    } = filters;

    return {
        timezone,
        zoneId,
        sortBy: sortBy as ECall.SortingKey | SortBy.SortingKey,
        sortOrder: sortOrder as SortBy.SortOrder,
        displayParty,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    dispatchHideAlert: () => dispatch(hideAlert()),
    dispatchSetSortBy: (sortBy: string) => dispatch(setSortBy(sortBy)),
    dispatchSetSortOrder: (sortOrder: string) => dispatch(setSortOrder(sortOrder)),
    dispatchToggleResidentParty: () => dispatch(toggleResidentParty()),
});

// This cast is needed because all the props come from redux and we don't want to expose them to the parent
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withBranchShiftSelector(Home)) as unknown as () => React.ReactNode;
