import { Typography, useTheme } from '@mui/material';
import { groupBy } from 'lodash';
import React, { useMemo } from 'react';

import ButtonGrid from '~/components/Shared/Button/ButtonGrid';
import SquareIconButton from '~/components/Shared/Button/SquareIconButton';

import PrnFlowStep from '../../components/PrnFlowStep';

import useSelectCategoryStep from './hooks/useSelectCategoryStep';
import { PrnTaskCategories } from './types/prnTaskCategories';

export default function SelectCategoryStep({ isECall }: { isECall?: boolean }) {
    const { prnTaskCategories, isLoading, isError, setSelectedCategories, selectedCategories } =
        useSelectCategoryStep(isECall);
    const { palette } = useTheme();

    const handleSelectCategory = (category: PrnTaskCategories.Category) => {
        const isAlreadySelected = selectedCategories.some((c) => c.id === category.id);
        if (isAlreadySelected) {
            setSelectedCategories(selectedCategories.filter((c) => c.id !== category.id));
        } else {
            setSelectedCategories([...selectedCategories, category]);
        }
    };

    const selectedCategoriesIds = useMemo(
        () => selectedCategories.map((category) => category.id),
        [selectedCategories]
    );

    return (
        <PrnFlowStep isLoading={isLoading} isError={isError}>
            <Typography variant="body1" fontWeight={700} mb="10px" fontSize="16px">
                Select all that Apply
            </Typography>

            {Object.entries(groupBy(prnTaskCategories, 'section')).map(([section, categories]) => (
                <React.Fragment key={section}>
                    {section !== 'all' && (
                        <Typography
                            variant="body2"
                            mt={4}
                            mb={1}
                            fontSize="14px"
                            fontWeight={600}
                            textTransform="capitalize"
                        >
                            {section}
                        </Typography>
                    )}
                    <ButtonGrid>
                        {categories.map((category) => (
                            <SquareIconButton
                                selected={selectedCategoriesIds.includes(category.id)}
                                key={category.id}
                                icon={
                                    <category.icon
                                        color={
                                            selectedCategoriesIds.includes(category.id)
                                                ? (palette.primary[500] as string)
                                                : palette.grey[900]
                                        }
                                        size={24}
                                    />
                                }
                                label={category.label}
                                onClick={() => handleSelectCategory(category)}
                            />
                        ))}
                    </ButtonGrid>
                </React.Fragment>
            ))}
        </PrnFlowStep>
    );
}
