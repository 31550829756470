import { Box, Card, Typography } from '@mui/material';
import React from 'react';

import { pxToRem } from '~/components/theme/typography';

const EmptySectionText = () => (
    <Typography
        sx={{
            fontSize: { lg: pxToRem(14) },
            color: '#6F6F79',
            lineHeight: 1.45,
        }}
    >
        No data available
    </Typography>
);

const EmptySectionModule = ({ sectionTitle, miniDisplay }: { sectionTitle: string; miniDisplay?: string }) => {
    if (miniDisplay === 'table') return <EmptySectionText />;

    return (
        <Box
            sx={{
                '&:not(:first-of-type)': {
                    mt: { lg: 0, xs: pxToRem(24) },
                },
            }}
        >
            <Typography
                sx={{
                    fontSize: { lg: pxToRem(20), xs: pxToRem(16) },
                    fontWeight: 'bold',
                    lineHeight: 1.2,
                    mb: pxToRem(14),
                }}
            >
                {sectionTitle}
            </Typography>
            <Card
                variant="outlined"
                sx={{
                    borderRadius: '12px',
                    p: { xs: pxToRem(12), lg: pxToRem(20) },
                }}
            >
                <EmptySectionText />
            </Card>
        </Box>
    );
};

export default EmptySectionModule;
