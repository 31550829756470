import range from 'lodash/range';

import { STANDARDIZED_STAFF_SCHEDULE_TYPES } from '@allie/utils/src/constants/scheduling/staff-schedule-types.constants';

import { StaffList } from '../../types';

const WEEK_DAYS = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

export type ShiftsGroup = { shiftIds: number[]; name: string };

export const buildHeaders = (type: STANDARDIZED_STAFF_SCHEDULE_TYPES, firstDayOfWeek: number): string[] => {
    if (type === STANDARDIZED_STAFF_SCHEDULE_TYPES.FOUR_ON_TWO_OFF) {
        return ['Day 1', 'Day 2', 'Day 3', 'Day 4'];
    }

    return range(7).map((i) => WEEK_DAYS[(firstDayOfWeek + i - 1) % 7]);
};

export const buildRows = (
    staffSchedule: Pick<StaffList.StaffSchedule, 'type' | 'shifts'>,
    shifts: ShiftsGroup[],
    firstDayOfWeek: number
): { label: string; filled: boolean }[][] => {
    const templateRows: { label: string; filled: boolean }[][] = shifts.map(() => []);
    const templateColumns = buildHeaders(staffSchedule.type, firstDayOfWeek);

    const rows = staffSchedule.shifts;

    const template = templateRows.map(() => templateColumns.map(() => ({ label: '', filled: false })));

    return template.map((row, rowIdx) =>
        row.map((cell, cellIdx) => {
            const shift = shifts[rowIdx];
            const hasScheduledShift = rows.find(
                ({ staffRoleShiftId, weekday, index }) =>
                    // the "includes" is necessary since it can has more
                    // than one id value for the same shift name, once each
                    // role has its own shift id value
                    shift.shiftIds.includes(staffRoleShiftId) &&
                    (staffSchedule.type !== STANDARDIZED_STAFF_SCHEDULE_TYPES.FOUR_ON_TWO_OFF
                        ? // for weekday, we need to shift the value by the first day of week,
                          // moduling the result by 7. For example, if the firstDayOfWeek is 3
                          // we need to shift up all indexes by 4 to get the correct position in
                          // the schedule.
                          (weekday! + (7 - firstDayOfWeek)) % 7
                        : index) === cellIdx
            );

            if (hasScheduledShift) return { label: shift.name, filled: true };

            return cell;
        })
    );
};
