import { Box, Chip, Typography, styled, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { PiCheckCircleFill, PiCircle } from 'react-icons/pi';

import { GetShiftRequestsResult } from '~/scheduling/api/queries/shift-requests/getShiftRequests';
import { useGetStaffList } from '~/scheduling/api/queries/staff/getStaffList';

const Caption = styled(Typography)(() => ({
    fontSize: 13,
    fontWeight: 400,
    lineHeight: '16px',
}));

interface CaregiverCardProps {
    request: GetShiftRequestsResult[number]['requests'][number];
    selected?: boolean;
    onClick: (requestId: number) => void;
}

export const CaregiverCard = ({ request, selected, onClick }: CaregiverCardProps) => {
    const { palette } = useTheme();
    const { data: staffListData } = useGetStaffList();
    const staffById = staffListData?.staffById;

    const { id: requestId, staffId, flags, createdAt } = request;
    const staff = staffById ? staffById.get(staffId) : undefined;

    if (!staff) return null;

    return (
        <Box
            onClick={() => onClick(requestId)}
            sx={{
                backgroundColor: selected ? (palette.primary[50] as string) : 'white',
                display: 'flex',
                gap: '16px',
                padding: '12px',
                borderRadius: '8px',
                alignItems: 'center',
                border: `1px solid ${palette.grey[200]}`,
                cursor: 'pointer',
                marginBottom: '16px',
            }}
        >
            {selected ? (
                <PiCheckCircleFill
                    style={{ flexShrink: 0 }}
                    aria-checked="true"
                    size={24}
                    color={palette.primary[500] as string}
                />
            ) : (
                <PiCircle
                    style={{ flexShrink: 0 }}
                    aria-checked="false"
                    size={24}
                    color={palette.primary[500] as string}
                />
            )}
            <Box display="flex" flexDirection="column" gap="4px">
                <Box display="flex" gap="4px" alignItems="flex-end">
                    <Typography variant="body1" color={palette.grey[900]} fontWeight={700} fontSize="16px">
                        {staff.name}
                    </Typography>
                    <Caption color={palette.primary[500] as string} sx={{ lineHeight: '20px' }} fontWeight={500}>
                        ({createdAt.toRelative({ base: DateTime.now(), locale: 'en' })})
                    </Caption>
                </Box>
                <Box display="flex" alignItems="center" gap="4px" flexWrap="wrap">
                    {flags.map((flag, index) => (
                        <Chip sx={{ borderRadius: '100px' }} key={index} label={flag.text} size="small" />
                    ))}
                </Box>
            </Box>
        </Box>
    );
};
