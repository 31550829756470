import { Box, Typography } from '@mui/material';
import React from 'react';
import { Control, Controller, FieldValues, RegisterOptions, UseFormReturn } from 'react-hook-form';

import { TextField } from './TextField';

export interface FormInputProps<T extends FieldValues> extends React.ComponentProps<typeof TextField> {
    form: UseFormReturn<T>;
    name: string;
    label: string;
    placeholder?: string;
    validations?: RegisterOptions;
    maskFn?: (value: string) => string;
    icon?: React.ReactNode;
}

export const FormTextField = <T extends FieldValues>({
    name,
    form,
    label,
    placeholder,
    icon,
    ...props
}: FormInputProps<T>) => {
    return (
        <Controller
            name={name}
            rules={props.validations}
            control={form.control as Control<FieldValues>}
            render={({ field, fieldState: { error } }) => {
                const value = (field.value ?? '') as string;

                return (
                    <Box>
                        <Typography sx={{ fontSize: '12px', fontWeight: 700, color: 'grey.900', marginBottom: '4px' }}>
                            {label}
                        </Typography>
                        <TextField
                            placeholder={placeholder}
                            helperText={error?.message}
                            onChange={(value) => field.onChange(props.maskFn?.(value.target.value) ?? value)}
                            value={props.maskFn?.(value) ?? value}
                            icon={icon}
                            {...props}
                        />
                    </Box>
                );
            }}
        />
    );
};
