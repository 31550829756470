import { Box } from '@mui/material';
import React, { useEffect } from 'react';

import { useEmailSubscribeMutation, useEmailUnsubscribeMutation } from '~/api/queries/emailSubscription';
import allieLogo from '~/assets/allie_logo.svg';
import MainContainer from '~/components/Auth/MainContainer';
import Loading from '~/components/Shared/Loading';
import { pxToRem } from '~/components/theme/typography';
import PageStructure from '~/pages/PageStructure';

import Button from './Button';
import { Container } from './Container';
import { Header } from './Header';

export const ManageSubscriptionPage = () => {
    const { mutate: unsubscribeMutate, isLoading: isUnsubscribeLoading } = useEmailUnsubscribeMutation();
    const {
        mutate: subscribeMutate,
        isLoading: isSubscribeLoading,
        isSuccess: isSubscribeSuccess,
    } = useEmailSubscribeMutation();

    const searchParams = new URLSearchParams(window.location.search);
    const userId = searchParams.get('userId')!;
    const workflowName = searchParams.get('workflowName')!;
    const emailId = searchParams.get('emailId')!;
    const token = searchParams.get('token')!;

    useEffect(() => {
        unsubscribeMutate({
            userId: Number(userId),
            workflowName,
            emailId,
            token,
        });
    }, []);

    if (isUnsubscribeLoading) {
        return <Loading />;
    }

    let buttonText = 'Re-Subscribe to Emails';
    if (isSubscribeLoading) {
        buttonText = 'Loading...';
    } else if (isSubscribeSuccess) {
        buttonText = 'Successfully Re-Subscribed';
    }

    return (
        <PageStructure>
            <MainContainer>
                <Box
                    sx={{
                        display: {
                            xs: 'none',
                            lg: 'flex',
                        },
                        justifyContent: 'center',
                    }}
                >
                    <Box component="img" src={allieLogo} alt="AllieHealth Logo" width={pxToRem(176)} />
                </Box>
                <Container>
                    <Box
                        component="img"
                        src={allieLogo}
                        alt="AllieHealth Logo"
                        width={pxToRem(144)}
                        sx={{
                            display: {
                                lg: 'none',
                            },
                        }}
                    />
                    <Header />
                    <Button
                        text={buttonText}
                        isDisabled={isSubscribeSuccess || isUnsubscribeLoading}
                        onClick={() =>
                            subscribeMutate({
                                userId: Number(userId),
                                workflowName,
                                emailId,
                                token,
                            })
                        }
                    />
                </Container>
            </MainContainer>
        </PageStructure>
    );
};
