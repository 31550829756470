import { useCallback } from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/api/common';
import { GetLocations } from '~/scheduling/api/types/locations/getLocations';

export type GetLocationsResult = NonNullable<ReturnType<typeof useGetLocations>['data']>;

export const useGetLocations = () => {
    const branchId = useBranchId()!;
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = { headers: { Authorization: token } };
        const { data } = await api.get<GetLocations.Response>(`/scheduling/${branchId}/locations`, options);

        const locations = data.response;
        const locationById = new Map(locations.map((slot) => [slot.id, slot]));

        return { locations, locationById };
    }, [branchId, token]);

    return useErrorHandledQuery({ queryKey: ['locations', branchId], queryFn, staleTime: 300000 });
};
