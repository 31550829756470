import React from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/api/common';
import { GetCallOffs } from '~/scheduling/api/types/reports/getCallOffs';
import { ReportPeriod } from '~/scheduling/pages/Reports/types';

import { getPeriodParams } from './helpers';

type GetCallOffsParams = {
    period: ReportPeriod;
    firstDayOfWeek?: number;
};

export const useGetCallOffs = (params: GetCallOffsParams) => {
    const branchId = useBranchId();
    const token = useToken();

    const queryFn = React.useCallback(async () => {
        const options = { headers: { Authorization: token }, params: getPeriodParams(params) };

        const { data } = await api.get<GetCallOffs.Response>(`/scheduling/${branchId}/reports/call-offs`, options);

        return data.response;
    }, [branchId, params, token]);

    return useErrorHandledQuery({
        queryKey: ['call-offs', branchId, params],
        queryFn,
        staleTime: 300000,
        enabled: !!branchId && !!params.period && !!params.firstDayOfWeek,
    });
};
