import { Box, Button, Typography, useTheme } from '@mui/material';
import React from 'react';
import { IconType } from 'react-icons';

import CustomModal from '~/scheduling/components/CustomModal';

interface MotivationMessageModalProps {
    isOpen: boolean;
    title: string;
    description: string;
    buttonText: string;
    icon: IconType;
    onContinue: () => void;
    buttonVariant?: React.ComponentProps<typeof Button>['variant'];
}

export const MotivationMessageModal = ({
    isOpen,
    title,
    description,
    buttonText,
    buttonVariant,
    icon: Icon,
    onContinue,
}: MotivationMessageModalProps) => {
    const { palette } = useTheme();

    return (
        <CustomModal isOpen={isOpen} center onClose={() => null}>
            <Box
                display="flex"
                flexDirection="column"
                gap="24px"
                alignItems="center"
                padding="40px 24px"
                borderRadius="12px"
            >
                <Box padding="32px" bgcolor={palette.secondary[50] as string} borderRadius="50%">
                    <Icon size={36} color={palette.secondary[500] as string} />
                </Box>
                <Typography variant="h1" fontWeight={700} fontSize="20px" lineHeight="24px" textAlign="center">
                    {title}
                </Typography>
                <Typography
                    variant="body2"
                    fontSize="16px"
                    lineHeight="20px"
                    textAlign="center"
                    sx={{ textWrap: 'balance' }}
                >
                    {description}
                </Typography>
                <Button variant={buttonVariant} fullWidth onClick={onContinue}>
                    {buttonText}
                </Button>
            </Box>
        </CustomModal>
    );
};
