import actionTypes from '~/redux/actionTypes';
import { AppDispatch } from '~/types/redux';

export const setCaregiverZoneId = (zoneId: number) => (dispatch: AppDispatch) => {
    dispatch({
        type: actionTypes.FILTER_CAREGIVER_ZONE_UPDATE,
        payload: { zoneId },
    });
};

export const setSortBy = (sortBy: string) => (dispatch: AppDispatch) => {
    dispatch({
        type: actionTypes.SORT_BY_UPDATE,
        payload: { sortBy },
    });
};

export const setSortOrder = (sortOrder: string) => (dispatch: AppDispatch) => {
    dispatch({
        type: actionTypes.SORT_ORDER_UPDATE,
        payload: { sortOrder },
    });
};
