import { atom } from 'jotai';

import { atomWithStorage, atomWithToggle } from '~/scheduling/utils/atoms';
import { ResidentResponse } from '~/types/residents';

export const startDocumentationFlowAtom = atomWithToggle(false);

export const documentationResidentDataAtom = atom<Pick<
    ResidentResponse,
    'residentId' | 'firstName' | 'lastName' | 'photo'
> | null>(null);

export const isOnShiftConfirmModalOpenAtom = atomWithToggle(true);
export const isAssignmentsModalOpenAtom = atomWithToggle(false);
export const isDocumentationFlowModalOpenAtom = atomWithToggle(false);

export const isOnCurrentShiftAtom = atomWithStorage('ecall_isOnCurrentShift', false);
