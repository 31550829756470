import { InputAdornment, TextField } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { PiMagnifyingGlass } from 'react-icons/pi';

import { ResidentsListResponse } from '~/types/residents';

import PrnFlowStep from '../../components/PrnFlowStep';

import ResidentRowItem from './components/ResidentRowItem';
import useSelectResidentStep from './hooks/useSelectResidentStep';

export default function SelectResidentStep() {
    const { residentsList, isLoading, isError, selectedResident, setSelectedResident } = useSelectResidentStep();
    const [search, setSearch] = useState<string | null>(null);

    const handleSelectResident = (resident: ResidentsListResponse) => {
        if (selectedResident?.residentId === resident.residentId) {
            setSelectedResident(null);
            return;
        }

        setSelectedResident(resident);
    };

    const residentsToShow = useMemo(
        () =>
            residentsList.filter(
                (resident) =>
                    !search ||
                    resident.roomNumber?.includes(search) ||
                    `${resident.firstName} ${resident.lastName}`.toLowerCase().includes(search)
            ),
        [residentsList, search]
    );

    return (
        <PrnFlowStep isLoading={isLoading} isError={isError}>
            <TextField
                sx={{ padding: '16px' }}
                placeholder="Search resident by name or room"
                type="text"
                onChange={({ target }) => setSearch(target.value.toLowerCase())}
                InputProps={{
                    sx: { ['& .MuiInputBase-input']: { padding: '12px' } },
                    endAdornment: (
                        <InputAdornment position="end" sx={{ paddingInline: '4px 12px' }}>
                            <PiMagnifyingGlass size={24} />
                        </InputAdornment>
                    ),
                }}
            />
            {residentsToShow?.map((resident) => (
                <ResidentRowItem
                    key={resident.residentId}
                    resident={resident}
                    selected={selectedResident?.residentId === resident.residentId}
                    handleSelect={handleSelectResident}
                />
            ))}
        </PrnFlowStep>
    );
}
