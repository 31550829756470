import React from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/api/common';
import { GetScheduledHours } from '~/scheduling/api/types/reports/getScheduledHours';
import { ReportPeriod } from '~/scheduling/pages/Reports/types';

import { getPeriodParams } from './helpers';

type GetScheduledHoursParams = {
    period: ReportPeriod;
    firstDayOfWeek?: number;
};

export const useGetScheduledHours = (params: GetScheduledHoursParams) => {
    const branchId = useBranchId();
    const token = useToken();

    const queryFn = React.useCallback(async () => {
        const options = { headers: { Authorization: token }, params: getPeriodParams(params) };

        const { data } = await api.get<GetScheduledHours.Response>(
            `/scheduling/${branchId}/reports/scheduled-hours`,
            options
        );

        return data.response;
    }, [branchId, params, token]);

    return useErrorHandledQuery({
        queryKey: ['scheduled-hours', branchId, params],
        queryFn,
        staleTime: 300000,
        enabled: !!branchId && !!params.period && !!params.firstDayOfWeek,
    });
};
