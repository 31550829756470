import { Box, Typography, styled } from '@mui/material';
import React from 'react';

type Props = {
    selected: boolean;
    label: string;
    onClick: () => void;
};

const Container = styled(Box)<{ selected: boolean }>(({ theme, selected }) => ({
    padding: '12px',
    width: '100%',
    height: '45px',
    backgroundColor: selected ? theme.palette.primary[50] : 'white',
    border: `1px solid ${selected ? theme.palette.primary[500] : theme.palette.grey[50]}`,
    borderRadius: '8px',
    cursor: 'pointer',
}));

const Label = styled(Typography)<{ selected: boolean }>(({ theme, selected }) => ({
    color: selected ? theme.palette.primary[500] : theme.palette.grey[600],
    fontWeight: 600,
    textAlign: 'left',
    textWrap: 'balance',
}));

export default function ListItemButton({ label, selected, onClick }: Readonly<Props>) {
    return (
        <Container selected={selected} onClick={onClick}>
            <Label selected={selected} variant="body1">
                {label}
            </Label>
        </Container>
    );
}
