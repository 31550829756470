import React from 'react';

import DesktopHeader from '~/components/Layout/DesktopHeader';
import WithHeader from '~/components/Layout/WithHeader';
import TeamSelector from '~/scheduling/components/TeamSelector';

import { LayoutBackground } from '../../components/LayoutBackground';

import Alerts from './components/Alerts';
import PrintSchedule from './components/Modal/PrintScheduleModal';
import PublishNewScheduleModal from './components/Modal/PublishNewScheduleModal';
import ReviewOpenShiftsModal from './components/Modal/ReviewOpenShiftsModal';
import StaffVacationModal from './components/Modal/StaffVacationModal';
import QuickActions from './components/QuickActions';
import BudgetTable from './components/Table/BudgetTable';
import OpenShiftTable from './components/Table/OpenShiftTable';
import StaffingRequirementTable from './components/Table/StaffingRequirementTable';

const Dashboard = () => (
    <WithHeader header={<DesktopHeader actions={<TeamSelector />} />}>
        <LayoutBackground>
            <Alerts />
            <QuickActions />
            <OpenShiftTable />
            <BudgetTable />
            <StaffingRequirementTable />
        </LayoutBackground>

        <ReviewOpenShiftsModal />
        <PublishNewScheduleModal />
        <StaffVacationModal />
        <PrintSchedule />
    </WithHeader>
);

export default Dashboard;
