import { useAtom } from 'jotai';
import { useMemo } from 'react';

import { periodAtom } from './atom';

export function usePeriod() {
    const [period, setPeriod] = useAtom(periodAtom);

    const isPastPeriod = ['Last week', 'Last month'].includes(period);

    return {
        period: useMemo(() => period, [period]),
        isPastPeriod,
        setPeriod,
    };
}
