import { Box, Button, CircularProgress, Stack, Switch, Typography, styled, useTheme } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { useGetAssignments } from '~/api/queries/assignments/getAssignments';
import { useGetCareLocations } from '~/api/queries/assignments/getCareLocations';
import { useGetZones } from '~/api/queries/zones/getZones';
import { ALL_ASSIGNMENTS_ID } from '~/constants/filters';
import { TabKey } from '~/types/dailyTasks.d';
import { ReduxStore } from '~/types/redux';

import { shouldUseAssignments } from '../../helpers';

import { LocationItem } from './LocationItem';

const FooterContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '16px',
    borderTop: `1px solid ${theme.palette.divider}`,
    alignItems: 'center',
    gap: '12px',
}));

const Content = styled(Stack)(() => ({
    padding: '24px',
    flexGrow: 1,
    overflowY: 'auto',
    height: '100%',
    gap: '12px',
}));

type FormData = { assignments: number[] };

interface LocationSelectorProps {
    currentTab: TabKey;
    defaultValue?: number[];
    isLoading: boolean;
    onSubmit: (assignmentIds: number[]) => void;
}

export const LocationSelector = ({ defaultValue, currentTab, isLoading, onSubmit }: LocationSelectorProps) => {
    const { palette } = useTheme();
    // TODO - get rid of this and use atoms
    const { company, branch } = useSelector((state: ReduxStore) => state.session.sessionData);

    const isLiveCallsTab = currentTab === TabKey.LIVE_CALLS;
    const { data: assignments, isLoading: isGetAssignmentsLoading } = useGetAssignments(!isLiveCallsTab);
    const { data: zones, isLoading: isGetZonesLoading } = useGetZones(!isLiveCallsTab);
    const { data: careLocations, isLoading: isGetCareLocationsLoading } = useGetCareLocations(isLiveCallsTab);

    const locations = useMemo(() => {
        if (currentTab === TabKey.LIVE_CALLS) {
            return careLocations;
        }

        return shouldUseAssignments(company, branch)
            ? assignments?.map((assignment) => ({ id: assignment.assignmentId, name: assignment.assignmentName }))
            : zones?.map((zone) => ({ id: zone.zoneId, name: zone.zoneName }));
    }, [company, branch, assignments, currentTab, careLocations, zones]);

    const areQueriesLoading = isGetZonesLoading || isGetAssignmentsLoading || isGetCareLocationsLoading;

    const form = useForm<FormData>({ defaultValues: { assignments: [] } });
    const selectedAssignments = form.watch('assignments');

    useEffect(() => {
        if (defaultValue && locations) {
            const selectedAssignments =
                defaultValue.includes(ALL_ASSIGNMENTS_ID) || locations.length === defaultValue.length
                    ? [ALL_ASSIGNMENTS_ID, ...locations.map(({ id }) => id)]
                    : defaultValue;
            form.reset({ assignments: selectedAssignments });
        }
    }, [defaultValue, locations]);

    const handleSubmit = ({ assignments }: FormData) => {
        onSubmit(assignments);
    };

    const handleSelection = (id: number, prevValue: number[]): number[] => {
        const options = locations?.map(({ id }) => id) ?? [];

        // unselecting `All` when another option is unselected
        if (prevValue.includes(ALL_ASSIGNMENTS_ID)) {
            return prevValue.filter((e) => e !== ALL_ASSIGNMENTS_ID && e !== id);
        }

        // unselect an option
        if (prevValue.includes(id)) {
            return prevValue.filter((e) => e !== id);
        }

        const selectedAll = options && [...prevValue, id].length === options.length;

        // includes `All` option
        if (selectedAll) {
            return [...prevValue, id, ALL_ASSIGNMENTS_ID];
        }

        return [...prevValue, id];
    };

    const handleToggleAll = (selectAll: boolean) => {
        const options = locations?.map(({ id }) => id) ?? [];

        return selectAll ? [...options, ALL_ASSIGNMENTS_ID] : [];
    };

    return (
        <form
            style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'auto' }}
            onSubmit={form.handleSubmit(handleSubmit)}
        >
            <Controller
                name="assignments"
                control={form.control}
                render={({ field }) =>
                    areQueriesLoading ? (
                        <CircularProgress size={32} sx={{ color: palette.primary[500] as string }} />
                    ) : (
                        <Content>
                            <Box display="flex" justifyContent="space-between">
                                <Typography
                                    variant="label"
                                    component="p"
                                    fontSize="14px"
                                    color={palette.grey[400]}
                                    fontWeight={600}
                                >
                                    Locations
                                </Typography>
                                <Box display="flex" gap="8px" alignSelf="flex-end">
                                    <Typography variant="label" component="p" fontSize="14px" color={palette.grey[400]}>
                                        Select All
                                    </Typography>
                                    <Switch
                                        checked={field.value?.includes(ALL_ASSIGNMENTS_ID)}
                                        onChange={(_, checked) => field.onChange(handleToggleAll(checked))}
                                    />
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="column" gap="8px">
                                {locations?.map(({ id, name }) => (
                                    <LocationItem
                                        selected={field.value?.includes(id)}
                                        key={id}
                                        label={name}
                                        onClick={() => field.onChange(handleSelection(id, field.value))}
                                        onOnlyClick={() => field.onChange([id])}
                                    />
                                ))}
                            </Box>
                        </Content>
                    )
                }
            />
            <FooterContainer>
                <Button fullWidth type="submit" disabled={!selectedAssignments.length || isLoading}>
                    {isLoading ? <CircularProgress size={20} sx={{ color: palette.primary[500] as string }} /> : 'Save'}
                </Button>
            </FooterContainer>
        </form>
    );
};
