import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import React from 'react';

import { api } from '~/api';
import { useBranchId, useToken } from '~/api/common';
import { GetBudget } from '~/scheduling/api/types/master-schedule/getBudget';

// Ignoring the error for budget for now since it's going to pop up often until we have budgets productized
const useErrorIgnoredQuery = <T>({ queryFn: _queryFn, ...options }: UseQueryOptions<T>) => {
    const queryFn = () => (_queryFn as () => Promise<T>)().catch((err) => console.log(err));

    return useQuery({ queryFn, retry: false, ...options });
};

export const useGetBudget = (teamId?: number) => {
    const branchId = useBranchId()!;
    const token = useToken();

    const queryFn = React.useCallback(async () => {
        const options = { headers: { Authorization: token } };
        const { data } = await api.get<GetBudget.Response>(
            `/scheduling/${branchId}/master-schedule/team/${teamId}/budget`,
            options
        );

        return data.response;
    }, [token, branchId, teamId]);

    return useErrorIgnoredQuery({
        queryKey: ['budget', branchId, teamId],
        queryFn,
        staleTime: 300000,
        enabled: !!branchId && !!teamId,
    });
};
