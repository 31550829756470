import React from 'react';

import Base from '~/components/Svg/Base';

type Props = {
    className?: string;
    size?: number;
};

// eslint-disable-next-line import/prefer-default-export
export const HandStopIcon = ({ className, size }: Props) => (
    <Base className={className} size={size}>
        <path
            d="M12.775 24C11.5583 24 10.446 23.7707 9.438 23.312C8.42933 22.854 7.55833 22.246 6.825 21.488C6.09167 20.7293 5.521 19.85 5.113 18.85C4.70433 17.85 4.5 16.8167 4.5 15.75V5.75C4.5 5.4 4.621 5.104 4.863 4.862C5.10433 4.62067 5.4 4.5 5.75 4.5C6.1 4.5 6.396 4.62067 6.638 4.862C6.87933 5.104 7 5.4 7 5.75V12H8V2.75C8 2.4 8.121 2.104 8.363 1.862C8.60433 1.62067 8.9 1.5 9.25 1.5C9.6 1.5 9.89567 1.62067 10.137 1.862C10.379 2.104 10.5 2.4 10.5 2.75V11H11.5V1.25C11.5 0.9 11.621 0.604 11.863 0.362C12.1043 0.120667 12.4 0 12.75 0C13.1 0 13.3957 0.120667 13.637 0.362C13.879 0.604 14 0.9 14 1.25V11H15V3.25C15 2.9 15.121 2.604 15.363 2.362C15.6043 2.12067 15.9 2 16.25 2C16.6 2 16.896 2.12067 17.138 2.362C17.3793 2.604 17.5 2.9 17.5 3.25V14.025C16.4667 14.1583 15.625 14.6043 14.975 15.363C14.325 16.121 14 17 14 18H15C15 17.1667 15.2917 16.4583 15.875 15.875C16.4583 15.2917 17.1667 15 18 15H18.5V9.25C18.5 8.9 18.621 8.60433 18.863 8.363C19.1043 8.121 19.4 8 19.75 8C20.1 8 20.396 8.121 20.638 8.363C20.8793 8.60433 21 8.9 21 9.25V15.75C21 16.8167 20.8 17.8457 20.4 18.837C20 19.829 19.4373 20.7083 18.712 21.475C17.9873 22.2417 17.121 22.854 16.113 23.312C15.1043 23.7707 13.9917 24 12.775 24Z"
            fill="#FA9C7A"
        />
    </Base>
);
