import { Box, Typography, useTheme } from '@mui/material';
import { useAtomValue } from 'jotai';
import React, { useMemo } from 'react';

import ButtonGrid from '~/components/Shared/Button/ButtonGrid';
import ListItemButton from '~/components/Shared/Button/ListItemButton';
import SquareIconButton from '~/components/Shared/Button/SquareIconButton';

import PrnFlowStep from '../../components/PrnFlowStep';
import { StepContent } from '../../components/StepContent';
import { useDuplicateSteps } from '../../hooks/useDuplicateSteps';
import { useEmergencyFlow } from '../../hooks/useEmergencyFlow';
import { unscheduledTaskCategoriesAtom } from '../../state/atom';
import { UnscheduledTaskDialogSteps } from '../../types/unscheduledTaskDialogSteps';

import useTaskDetailsStep from './hooks/useTaskDetailsStep';
import { PrnTaskDetails } from './types/prnTaskDetails';

export default function SelectTaskDetailsStep() {
    const { palette } = useTheme();
    const { duplicateStepCounter } = useDuplicateSteps();
    const { isEmergencyStep } = useEmergencyFlow();
    const categories = useAtomValue(unscheduledTaskCategoriesAtom);

    const category = useMemo(
        () => categories.filter((category) => category.section !== 'other')[duplicateStepCounter],
        [categories, duplicateStepCounter]
    );

    const { data, error, isLoading, taskDetails, setTaskDetails } = useTaskDetailsStep(category?.id);

    const currentTaskDetails = useMemo(
        (): PrnTaskDetails.TaskDetails =>
            taskDetails.find((e) => e.categoryId === category?.id) ?? {
                assistLevelId: undefined,
                categoryId: category?.id ?? 0,
                subcategoryId: undefined,
                activitiesIds: [],
                categoryCode: category?.code,
            },
        [data, category, taskDetails]
    );

    const selectedSubcategory = useMemo(() => {
        if (data && data.subcategories.length <= 1) return data.subcategories[0];

        return data?.subcategories.find((e) => e.id === currentTaskDetails.subcategoryId);
    }, [data, currentTaskDetails]);

    const defaultActivities = useMemo(() => {
        // these checks are required to properly validate the data on the footer submit button
        if (!selectedSubcategory || !selectedSubcategory.activities.length) return [0];

        if (selectedSubcategory.activities.length === 1) return [selectedSubcategory.activities[0].id];

        return [];
    }, [selectedSubcategory]);

    const handleSelectSubcategory = (subcategoryId: number) => {
        const prevValue = taskDetails.find((a) => a.categoryId === category.id);
        const newValue: PrnTaskDetails.TaskDetails = {
            categoryId: category.id,
            categoryCode: category.code,
            assistLevelId: undefined,
            activitiesIds: defaultActivities,
            subcategoryId,
        };

        if (prevValue) {
            setTaskDetails(taskDetails.map((e) => (e.categoryId === category.id ? newValue : e)));
        } else {
            setTaskDetails([...taskDetails, newValue]);
        }
    };

    const handleSelectAssistLevel = (assistLevelId: number) => {
        const prevValue = taskDetails.find((a) => a.categoryId === category.id);
        const newValue: PrnTaskDetails.TaskDetails = {
            categoryId: category.id,
            categoryCode: category.code,
            assistLevelId,
            subcategoryId: selectedSubcategory?.id,
            activitiesIds: defaultActivities,
        };

        if (prevValue) {
            setTaskDetails(taskDetails.map((e) => (e.categoryId === category.id ? newValue : e)));
        } else {
            setTaskDetails([...taskDetails, newValue]);
        }
    };

    const handleSelectActivity = (activityId: number) => {
        const prevValue = taskDetails.find((a) => a.categoryId === category.id);
        const previousSelectedActivitiesIds = prevValue?.activitiesIds || [];

        // in case of emergency flow - the activities must be single selection
        if (isEmergencyStep(UnscheduledTaskDialogSteps.SELECT_TASK_DETAILS)) {
            const newValue: PrnTaskDetails.TaskDetails = {
                categoryId: category.id,
                assistLevelId: selectedSubcategory?.assistLevels[0].id,
                categoryCode: category.code,
                subcategoryId: selectedSubcategory?.id,
                activitiesIds: previousSelectedActivitiesIds.includes(activityId) ? [] : [activityId],
            };

            return setTaskDetails(
                taskDetails.length ? taskDetails.map((e) => (e.categoryId === category.id ? newValue : e)) : [newValue]
            );
        }

        // decide whether to add or remove the chosen subcategory
        const newActivitiesIds = previousSelectedActivitiesIds.includes(activityId)
            ? prevValue!.activitiesIds.filter((e) => e !== activityId)
            : [...(prevValue?.activitiesIds || []), activityId];

        const newValue: PrnTaskDetails.TaskDetails = {
            categoryId: category.id,
            assistLevelId: currentTaskDetails.assistLevelId,
            categoryCode: category.code,
            subcategoryId: currentTaskDetails.subcategoryId,
            activitiesIds: newActivitiesIds,
        };

        if (prevValue) {
            setTaskDetails(taskDetails.map((e) => (e.categoryId === category.id ? newValue : e)));
        } else {
            setTaskDetails([...taskDetails, newValue]);
        }
    };

    return (
        <PrnFlowStep isLoading={isLoading} isError={!!error}>
            <Typography variant="body1" fontSize="16px" fontWeight={700} color={palette.grey[900]}>
                Fill out the details below
            </Typography>
            <Box height="16px" />
            <StepContent category={category}>
                {isEmergencyStep(UnscheduledTaskDialogSteps.SELECT_TASK_DETAILS) && data ? (
                    <Box display="flex" flexDirection="column" gap="8px">
                        {data.subcategories[0].activities.map(({ id, label }) => (
                            <ListItemButton
                                selected={(currentTaskDetails.activitiesIds ?? []).includes(id)}
                                key={id}
                                label={label}
                                onClick={() => handleSelectActivity(id)}
                            />
                        ))}
                    </Box>
                ) : (
                    <>
                        {!!data && data.subcategories.length > 1 && (
                            <Box display="flex" flexDirection="column" gap="8px">
                                {data.subcategories.map(({ id, label }) => (
                                    <ListItemButton
                                        selected={currentTaskDetails.subcategoryId === id}
                                        key={id}
                                        label={label}
                                        onClick={() => handleSelectSubcategory(id)}
                                    />
                                ))}
                            </Box>
                        )}
                        {!!data && selectedSubcategory && selectedSubcategory.assistLevels?.length > 1 && (
                            <Box display="flex" flexDirection="column" gap="16px" alignItems="center">
                                <Typography variant="body1" color={palette.grey[900]}>
                                    Assist Level
                                </Typography>
                                <ButtonGrid maxItemsPerRow={3}>
                                    {selectedSubcategory.assistLevels.map(({ id, label, icon: Icon }) => (
                                        <SquareIconButton
                                            selected={currentTaskDetails.assistLevelId === id}
                                            key={id}
                                            icon={
                                                <Icon
                                                    color={
                                                        id === currentTaskDetails.assistLevelId
                                                            ? (palette.primary[500] as string)
                                                            : palette.grey[900]
                                                    }
                                                    weight="thin"
                                                    size={24}
                                                />
                                            }
                                            label={label}
                                            onClick={() => handleSelectAssistLevel(id)}
                                        />
                                    ))}
                                </ButtonGrid>
                            </Box>
                        )}
                        {!!data && selectedSubcategory && selectedSubcategory.activities.length > 1 && (
                            <Box display="flex" flexDirection="column" gap="16px">
                                <Typography variant="body1" color={palette.grey[900]} textAlign="center">
                                    Activities
                                </Typography>
                                <Box display="flex" flexDirection="column" gap="8px">
                                    {selectedSubcategory.activities.map(({ id, label }) => (
                                        <ListItemButton
                                            selected={(currentTaskDetails.activitiesIds ?? []).includes(id)}
                                            key={id}
                                            label={label}
                                            onClick={() => handleSelectActivity(id)}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        )}
                    </>
                )}
            </StepContent>
        </PrnFlowStep>
    );
}
