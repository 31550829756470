import { v4QueryClient } from '..';
import { useMutation } from '@tanstack/react-query-v4';
import { AxiosError } from 'axios';
import qs from 'qs';

import { api } from '~/api';
import { showToast } from '~/components/Shared/Alerting/Toast/utils/showToast';
import { useAppDispatch } from '~/constants/redux';
import { useV4ErrorHandledQuery } from '~/hooks/useErrorHandledQuery';
import { formatApiParams, useToken } from '~/lib/common';
import { throwError } from '~/redux/actions/messages';
import {
    ResidentShiftNotesCreateParams,
    ResidentShiftNotesDeleteParams,
    ResidentShiftNotesReadParams,
    ResidentShiftNotesResponse,
    ResidentShiftNotesUpdateParams,
} from '~/types/residentShiftNotes';

export const useResidentShiftNotesQuery = (jsonParams: ResidentShiftNotesReadParams) => {
    const { residentId, branchId } = jsonParams;
    const params = formatApiParams(jsonParams);
    params['date_shifts'] = qs.stringify(jsonParams.dateShifts);
    const token = useToken();

    return useV4ErrorHandledQuery(
        ['residentShiftNotes', residentId],
        async () => {
            const options = { headers: { authorization: token }, params };
            const { data } = await api.get('/resident-shift-notes', options);
            return data.response as ResidentShiftNotesResponse[];
        },
        { enabled: !!residentId && !!branchId, staleTime: 300000 }
    );
};

const invalidateQueries = ({ userId, residentId }: { userId: number; residentId: number }) =>
    Promise.all([
        v4QueryClient.invalidateQueries({
            queryKey: ['residentShiftNotes', residentId],
            exact: false,
            refetchType: 'active',
        }),
        v4QueryClient.invalidateQueries({
            queryKey: ['rewards', userId],
            exact: false,
            refetchType: 'active',
        }),
    ]);

export const useResidentShiftNotesCreateMutation = (userId: number) => {
    const dispatch = useAppDispatch();
    const token = useToken();

    return useMutation({
        mutationFn: async (jsonParams: ResidentShiftNotesCreateParams) => {
            const params = formatApiParams(jsonParams);
            const options = {
                headers: { authorization: token },
                params: { branch_id: jsonParams.branchId }, // For permission checking
            };
            await api.post('/resident-shift-notes', params, options);
        },
        onSuccess: async (_, { residentId }) => {
            await invalidateQueries({ userId, residentId });

            showToast({
                message: 'Note created successfully!',
                type: 'success',
            });
        },
        onError: (error) => {
            const customError = error as AxiosError;
            dispatch(throwError(customError));
            throw customError;
        },
        retry: false,
    });
};

export const useResidentShiftNotesUpdateMutation = (userId: number) => {
    const dispatch = useAppDispatch();
    const token = useToken();

    return useMutation({
        mutationFn: async (jsonParams: ResidentShiftNotesUpdateParams) => {
            const { id, ...params } = formatApiParams(jsonParams) as any;
            const options = {
                headers: { authorization: token },
                params: { branch_id: jsonParams.branchId }, // For permission checking
            };
            await api.put(`/resident-shift-notes/${id}`, params, options);
        },
        onSuccess: async (_, { residentId }) => {
            await invalidateQueries({ userId, residentId });

            showToast({
                message: 'Note updated successfully!',
                type: 'success',
            });
        },
        onError: (error) => {
            const customError = error as AxiosError;
            dispatch(throwError(customError));
            throw customError;
        },
        retry: false,
    });
};

export const useResidentShiftNotesDeleteMutation = (userId: number) => {
    const dispatch = useAppDispatch();
    const token = useToken();

    return useMutation({
        mutationFn: async (jsonParams: ResidentShiftNotesDeleteParams) => {
            const options = {
                headers: { authorization: token },
                params: { branch_id: jsonParams.branchId }, // For permission checking
            };
            await api.delete(`/resident-shift-notes/${jsonParams.id}`, options);
        },
        onSuccess: async (_, { residentId }) => {
            await invalidateQueries({ userId, residentId });

            showToast({
                message: 'Note deleted successfully!',
                type: 'success',
            });
        },
        onError: (error) => {
            const customError = error as AxiosError;
            dispatch(throwError(customError));
            throw customError;
        },
        retry: false,
    });
};
