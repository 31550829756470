import posthog from 'posthog-js';
import { toast } from 'sonner';

import { ToastMessage } from '../types/toastMessage';

export const showToast = ({ message, duration = 3000, type = 'default', id, position }: ToastMessage) => {
    const functionMap = {
        success: toast.success,
        error: toast.error,
        info: toast.info,
        warning: toast.warning,
        default: toast,
    };
    const toastFunction = functionMap[type] || functionMap.default;

    posthog.capture('toast:show', {
        message,
        duration,
        type,
    });

    toastFunction(message, {
        id,
        duration,
        position,
        onAutoClose: () => {
            posthog.capture('toast:auto_close', {
                message,
                duration,
                type,
            });
        },
        onDismiss: () => {
            posthog.capture('toast:dismiss', {
                message,
                duration,
                type,
            });
        },
    });
};

export const hideToast = (id: string | number) => {
    toast.dismiss(id);
};
