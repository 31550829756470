export const MOBILE_INPUT_STYLES = {
    '& .MuiTextField-root': {
        width: '100%',
        '& fieldset': {
            borderColor: '#DEDEE0 !important',
            '&:hover': {
                borderColor: '#DEDEE0',
            },
        },
        '& .MuiInputBase-root': {
            backgroundColor: 'unset',
        },
        '& .MuiInputBase-input': {
            color: 'common.white',
            '&::placeholder': {
                color: 'primary.200',
            },
        },
        '& label': {
            color: 'common.white',
        },
    },
};

export const INPUT_STYLES = {
    '& .MuiTextField-root': {
        width: '100%',
        '& fieldset': {
            borderColor: 'gray.400',
        },
        '& .MuiInputBase-root': {
            backgroundColor: 'unset',
        },
        '& .MuiInputBase-input': {
            color: 'common.black',
            '&::placeholder': {
                color: 'grey.300',
            },
        },
        '& label': {
            color: 'gray.400',
        },
    },
};
