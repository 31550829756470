import { Box, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import React from 'react';

import { selectedDateAtom } from '../testData';

import { DateGroupSelector } from './DateGroupSelector';

interface SectionLayoutProps {
    title: string;
    chart: React.ReactNode;
    table: React.ReactNode;
    dateGroupTitle: string;
    sideComponent?: React.ReactNode;
    dateOptions: string[];
}

export const SectionLayout = ({
    title,
    chart,
    table,
    dateOptions,
    dateGroupTitle,
    sideComponent,
}: SectionLayoutProps) => {
    const [selectedDate, setSelectedDate] = useAtom(selectedDateAtom);

    return (
        <Box
            sx={{
                '&:not(:first-of-type)': {
                    mt: { lg: 0, xs: '24px' },
                },
            }}
        >
            <Typography
                variant="body1"
                fontSize={{ lg: '20px', xs: '16px' }}
                fontWeight={700}
                lineHeight="120%"
                marginBottom="14px"
            >
                {title}
            </Typography>
            <Box
                borderRadius="12px"
                bgcolor="white"
                border="1px solid rgba(0, 0, 0, 0.12)"
                sx={{ transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms' }}
                width="100%"
                maxHeight={{ lg: '396px' }}
                display="grid"
                gridTemplateColumns={{ lg: `1fr minmax(400px, 1fr)` }}
            >
                <Box padding="20px">{chart}</Box>
                <Box padding="20px" sx={{ borderStyle: 'solid', borderWidth: '0 0 0 1px', borderColor: '#ECECF3' }}>
                    {selectedDate && (
                        <DateGroupSelector
                            sectionTitle={dateGroupTitle}
                            dateOptions={dateOptions}
                            selectedDate={selectedDate}
                            onDateChange={setSelectedDate}
                            sideComponent={sideComponent}
                        />
                    )}
                    {table}
                </Box>
            </Box>
        </Box>
    );
};
