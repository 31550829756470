import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';

import { ShowerSectionDetail } from '~/types/operationsDashboard';

import { formatDay } from '../constants';

type Props = {
    rows: ShowerSectionDetail[];
};

export function ShowerCollapsibleComponent({ rows }: Props) {
    const headers = ['Date', 'Missed Reason', 'Caregiver'];

    function renderRow(item: ShowerSectionDetail) {
        return (
            <TableRow key={[item.date, item.reason, item.caregiverName].join('')}>
                <TableCell align="left">{formatDay(item.date)}</TableCell>
                <TableCell align="left">{item.reason}</TableCell>
                <TableCell align="left">{item.caregiverName || '-'}</TableCell>
            </TableRow>
        );
    }

    return (
        <TableContainer>
            <Table aria-label="details row">
                <TableHead>
                    <TableRow>
                        {headers.map((header) => (
                            <TableCell align="left" key={header}>
                                {header}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>{rows.map(renderRow)}</TableBody>
            </Table>
        </TableContainer>
    );
}
