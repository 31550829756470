import { Avatar, Box, Button, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { Briefcase } from '@phosphor-icons/react';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router';

import WithHeader from '~/components/Layout/WithHeader';
import { useCreateShiftRequest } from '~/scheduling/api/queries/shift-requests/createShiftRequest';
import { useDeleteShiftRequest } from '~/scheduling/api/queries/shift-requests/deleteShiftRequest';
import { GetMyScheduleResult, useGetMySchedule } from '~/scheduling/api/queries/shift-slot/getMySchedule';
import { GetOtherStaffResult, useGetOtherStaff } from '~/scheduling/api/queries/shift-slot/getOtherStaff';
import { useGetRoles } from '~/scheduling/api/queries/staff-roles/getRoles';
import CustomModal from '~/scheduling/components/CustomModal';
import { HorizontalSeparator } from '~/scheduling/components/shared';
import { compareBoolean, compareValue } from '~/scheduling/utils/compare';

import SlotItemDetails from '../components/SlotItem/SlotItemDetails';

const ShiftDetailsHeader = ({ slot }: { slot: GetMyScheduleResult['slots'][number] }) => {
    const formattedMonth = DateTime.fromISO(slot.shiftDay).toFormat('MMMM, yyyy');

    return (
        <Stack p="16px 24px" spacing="8px" boxShadow="0 4px 16px rgba(0, 0, 0, 0.075)">
            <Typography color="grey.600" fontSize="16px" fontWeight={700}>
                {formattedMonth}
            </Typography>
            <SlotItemDetails slot={slot} />
        </Stack>
    );
};

const ShiftDetailsItems = ({ slot }: { slot: GetMyScheduleResult['slots'][number] }) => {
    const { data: otherSlots } = useGetOtherStaff(slot.id);

    const filteredSlots = useMemo(
        () =>
            otherSlots?.sort(
                (a, b) =>
                    compareBoolean(a.isSelf, b.isSelf) ||
                    compareBoolean(!!a.staff, !!b.staff) ||
                    compareValue(a.roleId, b.roleId)
            ) ?? [],
        [otherSlots]
    );

    return (
        <Stack height="100%" overflow="hidden">
            <Typography p="24px" fontSize="16px">
                Staff on this shift
            </Typography>
            <Stack p="24px" pt={0} spacing="16px" sx={{ overflowY: 'auto' }}>
                {filteredSlots.map((slot, index) => (
                    <>
                        {index > 0 && <HorizontalSeparator key={`separator-${index}`} />}
                        <ShiftDetailsItem key={index} slot={slot} />
                    </>
                ))}
            </Stack>
        </Stack>
    );
};

const ShiftDetailsItem = ({ slot: { roleId, staff, isSelf, isPending } }: { slot: GetOtherStaffResult[number] }) => {
    const { data: roleData } = useGetRoles();
    const roleById = roleData?.roleById;

    const role = roleById?.get(roleId)!.name;
    const staffText = (staff ?? 'Open Shift') + (isSelf ? ' (You)' : '');
    const isOpen = isPending || !staff;

    return (
        <Stack direction="row" alignItems="center" spacing="12px">
            <ShiftDetailsItemAvatar isOpen={isOpen} />
            <Stack spacing="4px">
                {isPending && (
                    <Box
                        sx={({ palette }) => ({
                            bgcolor: palette.error[600] as string,
                            p: '2px 6px',
                            borderRadius: '4px',
                        })}
                    >
                        <Typography
                            color="white"
                            fontSize="13px"
                            fontWeight={600}
                            lineHeight="16px"
                            textTransform="uppercase"
                        >
                            Waiting Approval
                        </Typography>
                    </Box>
                )}
                <Typography color="secondary.main" fontSize="16px" fontWeight={600}>
                    {role}
                </Typography>
                <Typography fontSize="16px" fontWeight={600}>
                    {staffText}
                </Typography>
            </Stack>
        </Stack>
    );
};

const ShiftDetailsItemAvatar = ({ isOpen }: { isOpen: boolean }) => (
    <Avatar
        sx={({ palette }) => ({
            bgcolor: !isOpen ? palette.grey[300] : palette.grey[100],
            width: '48px',
            height: '48px',
            ...(isOpen && {
                border: '1px dashed',
                borderColor: palette.secondary.main,
            }),
        })}
    />
);

const ShiftDetailsFooter = ({
    slot: { shiftDay, roleId, roleShiftId, locationId, isAvailable, request },
    setIsRequestModalOpen,
}: {
    slot: GetMyScheduleResult['slots'][number];
    setIsRequestModalOpen: (value: boolean) => void;
}) => {
    const shiftData = {
        shiftDay,
        locationId,
        staffRoleId: roleId,
        staffRoleShiftId: roleShiftId,
    };

    const { mutateAsync: createSlotRequest, isPending: isCreateSlotRequestPending } = useCreateShiftRequest();
    const { mutateAsync: deleteSlotRequest, isPending: isDeleteSlotRequestPending } = useDeleteShiftRequest();

    if (!isAvailable) return null;

    return (
        <Box p="16px 24px" boxShadow="0 -4px 16px rgba(0, 0, 0, 0.075)">
            {request?.id ? (
                <Button
                    variant="outlined"
                    color="error"
                    size="large"
                    onClick={() => deleteSlotRequest({ requestId: request.id })}
                    disabled={isDeleteSlotRequestPending}
                    fullWidth
                >
                    {isDeleteSlotRequestPending ? (
                        <CircularProgress size={20} thickness={4} sx={{ color: 'error.100' }} />
                    ) : (
                        'Cancel Request'
                    )}
                </Button>
            ) : (
                <Button
                    size="large"
                    onClick={async () => {
                        await createSlotRequest(shiftData);
                        setIsRequestModalOpen(true);
                    }}
                    disabled={isCreateSlotRequestPending}
                    fullWidth
                >
                    {isCreateSlotRequestPending ? (
                        <CircularProgress size={20} thickness={4} sx={{ color: 'white' }} />
                    ) : (
                        'Request to Claim Shift'
                    )}
                </Button>
            )}
        </Box>
    );
};

const ShiftDetailsRequestModal = ({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: (value: boolean) => void }) => {
    const { palette } = useTheme();

    return (
        <CustomModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <Stack alignItems="center" p="40px 24px 24px" spacing="24px">
                <Box
                    sx={{
                        bgcolor: palette.grey[50],
                        width: '100px',
                        height: '100px',
                        p: '32px',
                        borderRadius: '50%',
                    }}
                >
                    <Briefcase color={palette.primary.main} weight="fill" fontSize="36px" />
                </Box>
                <Stack alignItems="center" spacing="8px">
                    <Typography fontSize="20px" fontWeight={700} lineHeight="32px">
                        Request Sent!
                    </Typography>
                    <Typography
                        color={palette.grey[500]}
                        fontSize="14px"
                        fontWeight={400}
                        lineHeight="24px"
                        textAlign="center"
                        sx={{ textWrap: 'balance' }}
                    >
                        You are now awaiting confirmation from your manager. We will notify you as soon as it is
                        confirmed.
                    </Typography>
                </Stack>
                <Button onClick={() => setIsOpen(false)} fullWidth>
                    I understand
                </Button>
            </Stack>
        </CustomModal>
    );
};

const ShiftDetails = () => {
    const params = useParams();
    const id = Number(params.id);

    const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);

    const { data: myScheduleData } = useGetMySchedule();
    const slotById = myScheduleData?.slotById;

    if (!slotById) return null;

    const slot = slotById.get(id);

    if (!slot) return <Navigate to=".." />;

    return (
        <WithHeader mobileHeader>
            <ShiftDetailsHeader slot={slot} />
            <ShiftDetailsItems slot={slot} />
            <ShiftDetailsFooter slot={slot} setIsRequestModalOpen={setIsRequestModalOpen} />
            <ShiftDetailsRequestModal isOpen={isRequestModalOpen} setIsOpen={setIsRequestModalOpen} />
        </WithHeader>
    );
};

export default ShiftDetails;
