import { useAtom } from 'jotai';

import { PRN_TASK_CATEGORIES } from '@allie/utils/src/constants/prn-tasks.constants';

import { useGetCategoriesQuery } from '~/api/queries/unscheduledTasks/getCategories';
import { UnscheduledTasks } from '~/types/unscheduledTasks';

import { unscheduledTaskCategoriesAtom } from '../../../state/atom';
import withIcon from '../utils/withIcon';

interface Category extends UnscheduledTasks.Category {
    section: 'all' | 'other';
}

export default function useSelectCategoryStep(isECall = false) {
    const [selectedCategories, setSelectedCategories] = useAtom(unscheduledTaskCategoriesAtom);

    const { data: prnTaskCategories, isLoading, isError } = useGetCategoriesQuery(isECall);

    return {
        prnTaskCategories: prnTaskCategories?.map(addSection).map(withIcon) ?? [],
        isLoading,
        isError,
        selectedCategories,
        setSelectedCategories,
    };
}

const categoriesFromSectionOther = [
    PRN_TASK_CATEGORIES.QUESTION,
    PRN_TASK_CATEGORIES.UNWELL,
    PRN_TASK_CATEGORIES.ACCIDENTALLY_PRESSED,
];

export const addSection = (category: UnscheduledTasks.Category): Category => {
    return {
        ...category,
        section: categoriesFromSectionOther.includes(category.code) ? 'other' : 'all',
    };
};
