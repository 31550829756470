import React from 'react';
import { FaBell } from 'react-icons/fa';

import AuthIcon from '~/components/Auth/AuthIcon';
import ActionPromptDialog from '~/components/Shared/ActionPromptDialog';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onCancel: () => void;
    handleActivateNotifications: () => void;
    isLoading?: boolean;
};

const ActivateNotificationsDialog = ({ isOpen, onClose, onCancel, handleActivateNotifications, isLoading }: Props) => (
    <ActionPromptDialog
        title="Activate Notifications"
        description="Get instant notifications to stay connected with the latest information. Enable notifications and receive instant updates to stay up to date."
        icon={<AuthIcon icon={<FaBell size={36} />} size={100} padding={32} />}
        primaryActionLabel="Allow"
        onPrimaryAction={handleActivateNotifications}
        isOpen={isOpen}
        onClose={onClose}
        onCancel={onCancel}
        isLoading={isLoading}
    />
);

export default ActivateNotificationsDialog;
