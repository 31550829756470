import React from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/api/common';
import { GetStaff } from '~/scheduling/api/types/staff/getStaff';

export const useGetStaff = (staffId?: number) => {
    const branchId = useBranchId()!;
    const token = useToken();

    const queryFn = React.useCallback(async () => {
        const options = { headers: { Authorization: token } };
        const { data } = await api.get<GetStaff.Response>(`/scheduling/${branchId}/staff/${staffId}`, options);

        return data.response;
    }, [token, branchId, staffId]);

    return useErrorHandledQuery({
        queryKey: ['staff', branchId, staffId],
        queryFn,
        enabled: !!branchId && !!staffId,
        staleTime: 300000,
    });
};
