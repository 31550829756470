import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

import { pxToRem } from '~/components/theme/typography';
import { ResidentNotesSectionDetail } from '~/types/operationsDashboard';

type Props = {
    rows: ResidentNotesSectionDetail[];
};

export function ResidentNotesCollapsibleComponent({ rows }: Props) {
    return (
        <Box paddingBlock={pxToRem(8)} paddingInline={pxToRem(16)} width={'100%'}>
            {rows.map((item, index) => (
                <Box key={index}>
                    <Typography variant={'body1'} fontSize={{ sm: pxToRem(14), md: pxToRem(16) }}>
                        {item.note}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
}
