import { useAtom } from 'jotai';
import { DateTime } from 'luxon';
import React from 'react';

import { STANDARDIZED_STAFF_ROLE_SHIFT_TYPES } from '@allie/utils/src/constants/scheduling/staff-roles.constants';

import { budgetsToChangeAtom } from './atom';
import { getShiftDuration, isTheSameSlot } from './helpers';
import { StaffOptimizations } from './types';
import { useBudgetedShifts } from './useBudgetedShifts';

export const useUpdateBudgets = () => {
    const { budgetedShifts, updateScheduledBudget } = useBudgetedShifts();
    const [budgetsToChange, setBudgetsToChange] = useAtom(budgetsToChangeAtom);

    const getPreviousBudget = React.useCallback(
        (change: { shiftIndex: number; day: DateTime; locationId: number; roleId: number }) => {
            const budgetsForTheShift = budgetedShifts.find((shift) => shift.shift.index === change.shiftIndex);
            const budgetForTheDay = budgetsForTheShift?.days.find(({ day }) => day.hasSame(change.day, 'day'));
            const budgetForTheLocation = budgetForTheDay?.slots.find(
                ({ location }) => location.id === change.locationId
            );
            const previousRoleBudget = budgetForTheLocation?.budgets.find(({ role }) => role.id === change.roleId);
            return previousRoleBudget;
        },
        [budgetedShifts]
    );

    const applyRecommendation = React.useCallback(
        (budgetRecommendation: StaffOptimizations.BudgetRecommendation) => {
            const changes = budgetRecommendation.recommendations.map((recommendation) => ({
                ...recommendation,
                shiftName: recommendation.shift.name,
                action: budgetRecommendation.action,
                locationId: budgetRecommendation.location.id,
                shiftId: recommendation.shift.id,
                roleId: budgetRecommendation.role.id,
            }));

            const budgetsToChange = changes.map<StaffOptimizations.BudgetToUpdate>((change) => {
                const previousBudget = getPreviousBudget({ ...change, shiftIndex: change.shift.index });

                return {
                    day: change.day,
                    shiftId: change.shiftId,
                    roleId: change.roleId,
                    shiftIndex: change.shift.index,
                    locationId: change.locationId,
                    shiftType: change.shift.shiftType,
                    budget: previousBudget!.budget + change.quantity * (change.action === 'increase' ? 1 : -1),
                };
            });

            setBudgetsToChange((prev) => [
                // filtering slots that will be modified
                ...prev.filter((slot) => !budgetsToChange.some((newSlot) => isTheSameSlot(slot, newSlot))),
                ...budgetsToChange,
            ]);

            changes.forEach((change) => {
                updateScheduledBudget(
                    change.quantity * (change.action === 'increase' ? 1 : -1) * getShiftDuration(change.shift)
                );
            });
        },
        [setBudgetsToChange, getPreviousBudget, updateScheduledBudget]
    );

    const modifyBudgetSlot = React.useCallback(
        (
            params: StaffOptimizations.Slot & {
                shiftType: STANDARDIZED_STAFF_ROLE_SHIFT_TYPES;
                shiftIndex: number;
                day: DateTime;
            }
        ) => {
            params.budgets.forEach(({ budget, role, shift }) => {
                const previousBudget = getPreviousBudget({
                    ...params,
                    locationId: params.location.id,
                    roleId: role.id,
                });
                const previousChange = budgetsToChange.find(
                    (change) =>
                        isTheSameSlot(change, {
                            ...params,
                            locationId: params.location.id,
                        }) && change.roleId === role.id
                );

                const diff =
                    (previousChange ? budget - previousChange.budget : budget - previousBudget!.budget) *
                    getShiftDuration(shift);

                updateScheduledBudget(diff);
            });

            setBudgetsToChange((prev) => [
                ...prev.filter((slot) => !isTheSameSlot(slot, { ...params, locationId: params.location.id })),
                ...params.budgets.map(({ budget, role, shift }) => ({
                    day: params.day,
                    shiftId: shift.id,
                    roleId: role.id,
                    shiftIndex: shift.index,
                    locationId: params.location.id,
                    shiftType: shift.shiftType,
                    budget: budget,
                })),
            ]);
        },
        [budgetsToChange, setBudgetsToChange, getPreviousBudget, updateScheduledBudget]
    );

    const getSlotModifications = React.useCallback(
        (slot: StaffOptimizations.BudgetSlotToChange) =>
            budgetsToChange.filter((prev) =>
                isTheSameSlot(prev, {
                    ...slot,
                    shiftIndex: slot.shift.index,
                    shiftType: slot.shift.shiftType,
                    locationId: slot.slot.location.id,
                })
            ),
        [budgetsToChange]
    );

    const reset = React.useCallback(() => setBudgetsToChange([]), [setBudgetsToChange]);

    return {
        budgetsToChange: React.useMemo(() => budgetsToChange, [budgetsToChange]),
        modifyBudgetSlot,
        getSlotModifications,
        applyRecommendation,
        reset,
    };
};
