import { gridClasses } from '@mui/x-data-grid-old';
import React from 'react';

import { StripedDataGrid } from '../../Tables/OverallStatsTable';

type Props = React.ComponentProps<typeof StripedDataGrid>;

export const Table = (props: Props) => (
    <StripedDataGrid
        {...props}
        disableColumnMenu
        sx={{
            maxHeight: '300px',
            [`& .${gridClasses.footerContainer}`]: {
                display: 'none',
            },
            [`& .${gridClasses.row}`]: {
                minHeight: 'unset !important',
                maxHeight: 'unset !important',
            },
            // eslint-disable-next-line react/prop-types
            ...(props.sx ?? {}),
        }}
    />
);
