import { Box, Button, Skeleton, Typography, styled, useTheme } from '@mui/material';
import { useSetAtom } from 'jotai';
import range from 'lodash/range';
import { DateTime } from 'luxon';
import Pluralize from 'pluralize';
import React from 'react';
import { MdClose, MdFlag, MdWarning } from 'react-icons/md';
import { useNavigate } from 'react-router';

import { DASHBOARD_ALERT_TYPES } from '@allie/utils/src/constants/scheduling/alerts.constants';

import { useGetDashboardAlerts } from '~/scheduling/api/queries/dashboard/getDashboardAlerts';
import { GetDashboardAlerts } from '~/scheduling/api/types/dashboard/getDashboardAlerts';
import useDismissibleAlert from '~/scheduling/hooks/useDismissibleAlert';

import { isReviewOpenShiftsModalOpenAtom } from '../../atom';

const AlertContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '12px',
    padding: '8px 12px',
    backgroundColor: '#FFF',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: '6px',
}));

const TextWithIconContainer = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
}));

const WarningIcon = styled(MdWarning)(({ theme }) => ({
    color: theme.palette.error[600],
    fontSize: '20px',
}));

const FlagIcon = styled(MdFlag)(({ theme }) => ({
    color: theme.palette.grey[400],
    fontSize: '20px',
}));

const CloseIcon = styled(MdClose)(({ theme }) => ({
    color: theme.palette.grey[900],
    width: '20px',
    height: '20px',
    cursor: 'pointer',
}));

const BoldText = ({ red, children }: { red?: boolean; children: React.ReactNode }) => (
    <Typography variant="body1" fontWeight={'600'} color={red ? 'error.600' : 'grey.900'}>
        {children}
    </Typography>
);

const ApproveShiftsAlert = (props: GetDashboardAlerts.ApproveShiftsAlertData) => {
    const toggle = useSetAtom(isReviewOpenShiftsModalOpenAtom);

    const { openShiftSlotsToApprove } = props;

    return (
        <AlertContainer>
            <TextWithIconContainer>
                <WarningIcon />
                <Box display="flex" gap="4px">
                    <Typography variant="body1" fontWeight={400}>
                        [ACTION NEEDED] You have
                    </Typography>
                    <BoldText>{openShiftSlotsToApprove} open shifts needing approval</BoldText>
                </Box>
            </TextWithIconContainer>
            <Button variant="contained" size="small" onClick={toggle}>
                Approve Shifts
            </Button>
        </AlertContainer>
    );
};

const NotifyTeamAlert = (props: GetDashboardAlerts.NotifyTeamAlertData) => {
    const navigate = useNavigate();

    return (
        <AlertContainer>
            <TextWithIconContainer>
                <WarningIcon />
                <Box display="flex" gap="4px">
                    <Typography variant="body1" fontWeight={400}>
                        [ACTION NEEDED] You have
                    </Typography>
                    <BoldText red>{props.openShifts} open shifts</BoldText>
                    <Typography variant="body1" fontWeight={400}>
                        over the next 3 days
                    </Typography>
                </Box>
            </TextWithIconContainer>
            <Button variant="contained" size="small" onClick={() => navigate('/scheduling/full-schedule')}>
                View Schedule
            </Button>
        </AlertContainer>
    );
};

const PublishAlert = (props: GetDashboardAlerts.PublishAlertData) => {
    const navigate = useNavigate();

    const today = DateTime.now();
    const publishDate = DateTime.fromISO(props.publishDate);
    const remainingDays = Math.ceil(publishDate.diff(today, 'days').days);

    return (
        <AlertContainer>
            <TextWithIconContainer>
                <WarningIcon />
                <Typography variant="body1" fontWeight={400}>
                    [ACTION NEEDED] The schedule for the next week will be automatically published{' '}
                    {`in ${Pluralize('day', remainingDays, true)}`}
                </Typography>
            </TextWithIconContainer>
            <Button variant="contained" size="small" onClick={() => navigate('/scheduling/full-schedule')}>
                Edit Schedule
            </Button>
        </AlertContainer>
    );
};

const VacationsAlert = (props: { data: GetDashboardAlerts.VacationsAlertData[] }) => {
    const { show, closeAlert } = useDismissibleAlert('vacation');
    const { palette } = useTheme();

    if (!show) {
        return null;
    }

    const vacationFormattedText = props.data.reduce((text, vacation, idx) => {
        let connective = '';
        const formattedStartDate = DateTime.fromISO(vacation.startDate).toFormat('M/d');
        const formattedEndDate = DateTime.fromISO(vacation.endDate).toFormat('M/d');

        if (idx === props.data.length - 1) {
            connective = '';
        } else if (idx === props.data.length - 2) {
            connective = ' and ';
        } else {
            connective = ', ';
        }

        return text + `${vacation.staff.name} (${formattedStartDate} - ${formattedEndDate})${connective}`;
    }, '');

    return (
        <AlertContainer sx={{ padding: '16px' }}>
            <TextWithIconContainer>
                <FlagIcon />
                <Typography variant="body1" fontWeight={400}>
                    <span style={{ fontWeight: 600 }}>{vacationFormattedText}</span>{' '}
                    {props.data.length > 1 ? 'are' : 'is'} on vacations{' '}
                    <span style={{ fontWeight: 600, color: palette.error[600] as string }}>today</span>
                </Typography>
            </TextWithIconContainer>
            <CloseIcon onClick={closeAlert} />
        </AlertContainer>
    );
};

const Alerts = () => {
    const { data: alerts, isPending } = useGetDashboardAlerts();

    const mapAlert: Record<DASHBOARD_ALERT_TYPES, (props: GetDashboardAlerts.AlertData) => React.ReactElement | null> =
        {
            [DASHBOARD_ALERT_TYPES.NOTIFY_TEAM]: (props: GetDashboardAlerts.NotifyTeamAlertData) =>
                props.openShifts ? <NotifyTeamAlert {...props} /> : null,
            [DASHBOARD_ALERT_TYPES.PUBLISH]: (props: GetDashboardAlerts.PublishAlertData) =>
                props.publishDate ? <PublishAlert {...props} /> : null,
            [DASHBOARD_ALERT_TYPES.VACATIONS]: (props: GetDashboardAlerts.VacationsAlertData[]) =>
                props.length > 0 ? <VacationsAlert data={props} /> : null,
            [DASHBOARD_ALERT_TYPES.APPROVE_SHIFTS]: (props: GetDashboardAlerts.ApproveShiftsAlertData) =>
                props.openShiftSlotsToApprove ? <ApproveShiftsAlert {...props} /> : null,
        };

    return (
        <>
            {isPending
                ? range(3).map((i) => <Skeleton key={i} width="100%" height="52px" />)
                : alerts?.map((alert) => mapAlert[alert.type](alert.data))}
        </>
    );
};

export default Alerts;
