import { useSetAtom } from 'jotai';
import { orderBy } from 'lodash';
import { useEffect, useMemo } from 'react';

import { useBranchesByRegionQuery } from '~/api/queries/companyInfo';
import { useOperationsBranchStatsQuery, useOperationsOverallStatsQuery } from '~/api/queries/dashboard/operations';
import {
    BRANCH_SECTIONS_MAP,
    OVERALL_SECTIONS_MAP,
    SchedulingSection,
    Section,
    SectionData,
} from '~/pages/OperationsDashboard/constants';
import {
    OperationsLevel,
    OperationsModuleData,
    OperationsOverallStatsSectionType,
    OperationsPeriod,
    OperationsStatsSection,
    SelectOptionsId,
} from '~/types/operationsDashboard';
import { SessionData } from '~/types/session';

import { selectedDateAtom } from '../Scheduling/testData';

export const useModuleData = (
    level: OperationsLevel,
    operationsPeriod: OperationsPeriod,
    sessionData: SessionData,
    rawRegionId: SelectOptionsId,
    rawBranchId: SelectOptionsId,
    rawCareType: string,
    hasCompanyAccess: boolean
): OperationsModuleData => {
    const { companyId } = sessionData;
    const setSelectedDate = useSetAtom(selectedDateAtom);

    const getId = (str: SelectOptionsId) => (str === 'all' ? undefined : Number(str));
    const regionId = getId(rawRegionId);
    const branchId = getId(rawBranchId);
    const careType = rawCareType === 'all' ? undefined : rawCareType;

    const {
        data: operationsOverallStatsData,
        isLoading: operationsOverallStatsIsLoading,
        isError: operationsOverallStatsIsError,
    } = useOperationsOverallStatsQuery(
        level !== 'branch' && (hasCompanyAccess || !!(!hasCompanyAccess && regionId))
            ? {
                  groupBy: operationsPeriod,
                  companyId: Number.isInteger(companyId) ? companyId : undefined,
                  regionId: Number.isInteger(regionId) ? regionId : undefined,
                  branchId: Number.isInteger(branchId) ? branchId : undefined,
                  careType,
              }
            : undefined
    );

    const {
        data: branchesByRegionData,
        isLoading: branchesByRegionDataIsLoading,
        isError: branchesByRegionDataIsError,
    } = useBranchesByRegionQuery({
        companyId,
        regionId,
        branchId,
    });

    const {
        data: operationsBranchStatsData,
        isLoading: operationsBranchStatsDataIsLoading,
        isError: operationsBranchStatsDataIsError,
    } = useOperationsBranchStatsQuery(
        level === 'branch' && branchId
            ? {
                  groupBy: operationsPeriod,
                  branchId,
                  careType,
              }
            : undefined
    );

    // get the current date options from the first section
    // the date options across all section should be the same
    const dateOptions = useMemo(() => {
        // in case where overallStats is null and branchStats exists, use branchStats
        if (!operationsOverallStatsData && operationsBranchStatsData) {
            const distinctDates = Array.from(
                new Set(operationsBranchStatsData.sections[0]?.datasets?.map(({ startDate }) => startDate) ?? [])
            );

            return orderBy(distinctDates, (dateStr) => new Date(dateStr), ['desc']);
        }

        // for `overallStats`, the API returns the same date for each `branchId`
        // therefore, it can have duplicated dates
        const distinctDates = Array.from(
            new Set(operationsOverallStatsData?.sections[0]?.datasets?.map(({ startDate }) => startDate) ?? [])
        );

        return orderBy(distinctDates, (dateStr) => new Date(dateStr), ['desc']);
    }, [JSON.stringify(operationsOverallStatsData), JSON.stringify(operationsBranchStatsData)]);

    useEffect(() => {
        if (dateOptions && dateOptions.length > 0) {
            setSelectedDate(dateOptions[0]);
        }
    }, [dateOptions, setSelectedDate]);

    if (
        branchesByRegionDataIsLoading ||
        (level !== 'branch' && operationsOverallStatsIsLoading) ||
        (level === 'branch' && operationsBranchStatsDataIsLoading)
    ) {
        return {
            status: 'loading',
        };
    }

    if (
        branchesByRegionDataIsError ||
        !branchesByRegionData ||
        (level !== 'branch' && (operationsOverallStatsIsError || !operationsOverallStatsData)) ||
        (level === 'branch' && (operationsBranchStatsDataIsError || !operationsBranchStatsData))
    ) {
        return {
            status: 'error',
        };
    }

    return {
        status: 'success',
        dateOptions,
        overallData: operationsOverallStatsData,
        branchData: operationsBranchStatsData,
        branchesByRegion: branchesByRegionData,
    };
};

export const getSectionsToShow = (level: OperationsLevel, moduleData: OperationsModuleData) => {
    if (moduleData.status === 'loading' || moduleData.status === 'error')
        return {} as Record<Section | SchedulingSection, SectionData>;

    const { overallData, branchData } = moduleData;

    const sections: OperationsStatsSection[] = (level !== 'branch' ? overallData : branchData)?.sections ?? [];
    const sectionNameToSection: { [key in OperationsOverallStatsSectionType]?: OperationsStatsSection } =
        Object.fromEntries(sections.map((section) => [section.section, section]));

    const sectionsMap = level !== 'branch' ? OVERALL_SECTIONS_MAP : BRANCH_SECTIONS_MAP;
    const filteredSections = Object.entries(sectionsMap).filter(
        ([section]) => sectionNameToSection[section as Section]?.show
    );

    return Object.fromEntries(filteredSections) as Record<Section | SchedulingSection, SectionData>;
};
