import { ComponentOverride } from '.';

export const MuiAutocomplete: ComponentOverride<'MuiAutocomplete'> = {
    styleOverrides: {
        root: ({ theme: { palette } }) => ({
            // Size small
            '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                padding: '12px',

                '& .MuiAutocomplete-input': {
                    padding: 0,
                    paddingLeft: '12px',
                    fontSize: '15px',
                    fontWeight: 400,
                    '&::placeholder': {
                        color: palette.grey[300],
                    },
                },
            },
        }),
    },
};
