import React from 'react';

import Base from '~/components/Svg/Base';

type Props = {
    className?: string;
    size?: number | string;
    viewBox?: string;
};

export const MicrophoneIcon = ({ className, size, viewBox }: Props) => (
    <Base className={className} size={size} viewBox={viewBox}>
        <path
            d="M19.1202 9.11938C18.7302 9.11938 18.4202 9.42938 18.4202 9.81938V11.3994C18.4202 14.9394 15.5402 17.8194 12.0002 17.8194C8.46018 17.8194 5.58018 14.9394 5.58018 11.3994V9.80938C5.58018 9.41938 5.27018 9.10938 4.88018 9.10938C4.49018 9.10938 4.18018 9.41938 4.18018 9.80938V11.3894C4.18018 15.4594 7.31018 18.8094 11.3002 19.1694V21.2994C11.3002 21.6894 11.6102 21.9994 12.0002 21.9994C12.3902 21.9994 12.7002 21.6894 12.7002 21.2994V19.1694C16.6802 18.8194 19.8202 15.4594 19.8202 11.3894V9.80938C19.8102 9.42938 19.5002 9.11938 19.1202 9.11938Z"
            fill="currentColor"
        />
        <path
            d="M12.0001 1.99951C9.56008 1.99951 7.58008 3.97951 7.58008 6.41951V11.5395C7.58008 13.9795 9.56008 15.9595 12.0001 15.9595C14.4401 15.9595 16.4201 13.9795 16.4201 11.5395V6.41951C16.4201 3.97951 14.4401 1.99951 12.0001 1.99951ZM13.3101 8.94951C13.2401 9.20951 13.0101 9.37951 12.7501 9.37951C12.7001 9.37951 12.6501 9.36951 12.6001 9.35951C12.2101 9.24951 11.8001 9.24951 11.4101 9.35951C11.0901 9.44951 10.7801 9.25951 10.7001 8.94951C10.6101 8.63951 10.8001 8.31951 11.1101 8.23951C11.7001 8.07951 12.3201 8.07951 12.9101 8.23951C13.2101 8.31951 13.3901 8.63951 13.3101 8.94951ZM13.8401 7.00951C13.7501 7.24951 13.5301 7.38951 13.2901 7.38951C13.2201 7.38951 13.1601 7.37951 13.0901 7.35951C12.3901 7.09951 11.6101 7.09951 10.9101 7.35951C10.6101 7.46951 10.2701 7.30951 10.1601 7.00951C10.0501 6.70951 10.2101 6.36951 10.5101 6.26951C11.4701 5.91951 12.5301 5.91951 13.4901 6.26951C13.7901 6.37951 13.9501 6.70951 13.8401 7.00951Z"
            fill="currentColor"
        />
    </Base>
);
