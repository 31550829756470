import { Box, Button, Typography, styled, useTheme } from '@mui/material';
import React from 'react';
import { PiClipboard } from 'react-icons/pi';

import CustomModal from '~/scheduling/components/CustomModal';

const ButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: '8px',

    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
    },
}));

interface OnShiftConfirmationModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    onLogout: () => void;
    endOfShift?: boolean;
}

export const OnShiftConfirmationModal = ({
    isOpen,
    endOfShift,
    onClose,
    onConfirm,
    onLogout,
}: OnShiftConfirmationModalProps) => {
    const { palette } = useTheme();

    return (
        <CustomModal isOpen={isOpen} onClose={onClose} center>
            <Box
                display="flex"
                flexDirection="column"
                gap="24px"
                alignItems="center"
                padding="40px 24px"
                borderRadius="12px"
            >
                <Box padding="32px" bgcolor={palette.secondary[50] as string} borderRadius="50%">
                    <PiClipboard size={36} color={palette.secondary[500] as string} />
                </Box>
                <Typography variant="h1" fontWeight={700} fontSize="20px" lineHeight="24px" textAlign="center">
                    {endOfShift ? 'Your shift is over! Are you on the next shift?' : 'Are you on your shift?'}
                </Typography>
                <ButtonContainer>
                    <Button variant="outlined" fullWidth onClick={onConfirm}>
                        Yes
                    </Button>
                    <Button variant="outlined" fullWidth onClick={onClose}>
                        No
                    </Button>
                    {endOfShift && (
                        <Button variant="outlined" fullWidth onClick={onLogout}>
                            Not your account? Log off
                        </Button>
                    )}
                </ButtonContainer>
            </Box>
        </CustomModal>
    );
};
