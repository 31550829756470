import { Scheduling } from '../../types';

export const WEEK_DAYS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'] as const;
export const LAST_MONTH_OF_YEAR = 11; // starts at 0

const currentMonth = new Date().getMonth();
const currentYear = new Date().getFullYear();
const currentDay = new Date().getDate();

export const initialCalendarDate: Scheduling.CalendarData = {
    day: currentDay,
    month: currentMonth,
    year: currentYear,
};
