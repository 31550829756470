import { api } from '~/api';
import { formatApiParams } from '~/lib/common';
import actionTypes from '~/redux/actionTypes';
import loading from '~/redux/actions/loading';
import { throwError } from '~/redux/actions/messages';
import '~/redux/reducers/taskStatuses';
import { TaskStatusesReadParams, TaskStatusesResponse } from '~/types/taskStatuses';

/**
 * Fetches the list of Task Statuses.
 * @param jsonParams the JSON object with the params for fetching the Task Statuses list.
 */
export const readTaskStatuses =
    (jsonParams: TaskStatusesReadParams, actionType = actionTypes.TASK_STATUS_READ_LIST) =>
    async (dispatch, getState) => {
        try {
            // Enable the loading process.
            dispatch(loading(actionTypes.TASK_STATUS_LOADING, true, false));

            // Get the token.
            const { token } = getState().session.sessionData;

            // Encode the params to be sent to the API.
            const params = formatApiParams(jsonParams);

            // Fetch the Residents List.
            const taskStatusesList: TaskStatusesResponse[] = (
                await api.get('/task-statuses', {
                    headers: {
                        authorization: token,
                    },
                    params,
                })
            ).data.response;

            dispatch({
                type: actionType,
                payload: taskStatusesList,
            });
        } catch (error) {
            dispatch(throwError(error));
        } finally {
            // Disable the loading process.
            dispatch(loading(actionTypes.TASK_STATUS_LOADING, false));
        }
    };
