import { DateTime } from 'luxon';

import { DAYS_IN_WEEK } from '~/scheduling/constants';
import { ReportPeriod } from '~/scheduling/pages/Reports/types';

type GetPeriodParams = {
    period: ReportPeriod;
    firstDayOfWeek?: number;
};

export const getPeriodParams = ({ period, firstDayOfWeek }: GetPeriodParams): { startDay: string; endDay: string } => {
    const today = DateTime.now();
    const daysToStartOfWeek = (today.weekday - (firstDayOfWeek ?? 0) + DAYS_IN_WEEK) % DAYS_IN_WEEK;
    const startOfWeek = today.minus({ days: daysToStartOfWeek });
    const lastMonth = today.minus({ month: 1 });

    const mapPeriod: Record<ReportPeriod, { startDay: string; endDay: string }> = {
        'This week': {
            startDay: startOfWeek.toFormat('yyyy-MM-dd'),
            endDay: startOfWeek.plus({ days: DAYS_IN_WEEK - 1 }).toFormat('yyyy-MM-dd'),
        },
        'Last week': {
            startDay: startOfWeek.minus({ week: 1 }).toFormat('yyyy-MM-dd'),
            endDay: startOfWeek.minus({ days: 1 }).toFormat('yyyy-MM-dd'),
        },
        'This month': {
            startDay: today.startOf('month').toFormat('yyyy-MM-dd'),
            endDay: today.endOf('month').toFormat('yyyy-MM-dd'),
        },
        'Last month': {
            startDay: lastMonth.startOf('month').toFormat('yyyy-MM-dd'),
            endDay: lastMonth.endOf('month').toFormat('yyyy-MM-dd'),
        },
    };

    return mapPeriod[period];
};
