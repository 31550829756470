import { Box, Typography } from '@mui/material';
import React from 'react';

import langDictionary from '~/app-strings';

type Props = {
    firstName: string;
    lastName: string;
    roomNumber: string;
};

export const ResidentDetails = (props: Props) => {
    const { firstName, lastName, roomNumber } = props;

    const { room: roomTxt } = langDictionary.home;

    return (
        <Box
            sx={{
                flexGrow: 1,
            }}
        >
            <Typography
                sx={{
                    textAlign: 'left',
                    fontWeight: 700,
                }}
            >
                {`${firstName} ${lastName}`}
            </Typography>
            <Typography
                sx={{
                    color: 'secondary.main',
                    fontWeight: 700,
                }}
            >
                {`${roomTxt} ${roomNumber ?? 'not set'}`}
            </Typography>
        </Box>
    );
};
