import { atom } from 'jotai';
import { DateTime } from 'luxon';

import { Notifications } from './types';

const testNotifications = [
    {
        diffDays: 0,
        shiftId: 1,
        roleId: 1,
        careTypeId: 1,
        status: 'approved',
    },
    {
        diffDays: 1,
        shiftId: 3,
        roleId: 1,
        careTypeId: 2,
        status: 'denied',
    },
    {
        diffDays: 2,
        shiftId: 1,
        roleId: 2,
        careTypeId: 1,
        status: 'approved',
    },
    {
        diffDays: 3,
        shiftId: 1,
        roleId: 2,
        careTypeId: 1,
        status: 'denied',
    },
    {
        diffDays: 4,
        shiftId: 2,
        roleId: 2,
        careTypeId: 2,
        status: 'approved',
    },
    {
        diffDays: -1,
        shiftId: 2,
        roleId: 2,
        careTypeId: 1,
        status: 'denied',
    },
    {
        diffDays: -2,
        shiftId: 2,
        roleId: 1,
        careTypeId: 2,
        status: 'approved',
    },
    {
        diffDays: -3,
        shiftId: 3,
        roleId: 1,
        careTypeId: 1,
        status: 'denied',
    },
].map(({ diffDays, ...notification }) => ({
    ...notification,
    day: DateTime.now().plus({ days: diffDays }),
})) as Notifications.Item[];

export const testNotificationsAtom = atom(testNotifications);
