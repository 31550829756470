import { FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';

import { REPORT_PERIODS } from '../constants';
import { ReportPeriod } from '../types';
import { usePeriod } from '../usePeriod';

export const PeriodSelector = () => {
    const { period, setPeriod } = usePeriod();

    const periods = REPORT_PERIODS.map((period) => ({ value: period, label: period }));

    return (
        <FormControl size="small">
            <Select value={period} onChange={(e) => setPeriod(e.target.value as ReportPeriod)} sx={{ fontWeight: 500 }}>
                {periods.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
