import { Box, Button, Chip, Typography, useTheme } from '@mui/material';
import { Stack } from '@mui/material';
import { useAtom } from 'jotai';
import React from 'react';
import { useNavigate } from 'react-router';

import { STANDARDIZED_STAFF_SCHEDULE_TYPES_LABELS } from '@allie/utils/src/constants/scheduling/staff-schedule-types.constants';
import {
    STANDARDIZED_STAFF_TYPES,
    STANDARDIZED_STAFF_TYPES_LABELS,
} from '@allie/utils/src/constants/scheduling/staff-types.constants';

import SegmentedModal from '~/scheduling/components/SegmentedModal';

import { isStaffScheduleModalOpenAtom } from '../../atom';
import { StaffList } from '../../types';
import StaffSchedulePreview from '../StaffSchedulePreview';

interface StaffScheduleModalHeaderProps {
    name: string;
    role: string;
    type: STANDARDIZED_STAFF_TYPES;
}

const StaffScheduleModalHeader = ({ name, role, type }: StaffScheduleModalHeaderProps) => {
    const { palette } = useTheme();

    return (
        <Box>
            <Typography variant="body1" fontSize={16} fontWeight={700} color={palette.grey[900]}>
                {name}
            </Typography>
            <Box display="flex" gap="4px" marginTop="8px">
                <Chip size="small" label={STANDARDIZED_STAFF_TYPES_LABELS[type] ?? type} />
                <Chip size="small" label={role} />
            </Box>
        </Box>
    );
};

const StaffScheduleModalActions = ({ staffId }: { staffId: number }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`./staff-details/${staffId}`);
    };

    return (
        <Box display="flex" justifyContent="flex-end">
            <Button variant="outlined" onClick={handleClick}>
                Edit Staff
            </Button>
        </Box>
    );
};

export const StaffScheduleModal = ({ staff }: { staff: StaffList.StaffData }) => {
    const { palette } = useTheme();
    const [isOpen, toggle] = useAtom(isStaffScheduleModalOpenAtom);

    return (
        <SegmentedModal
            isOpen={isOpen}
            minWidth="510px"
            onClose={toggle}
            center
            closeButton
            actions={<StaffScheduleModalActions staffId={staff.id} />}
            header={<StaffScheduleModalHeader name={staff.staffName} role={staff.mainlyServe} type={staff.staffType} />}
        >
            <Stack spacing="12px">
                <Typography variant="body1" fontSize={16} fontWeight={700} color={palette.grey[900]}>
                    Shift Schedule{' '}
                    {staff.currentSchedule?.type && (
                        <span style={{ fontWeight: 400 }}>
                            ({STANDARDIZED_STAFF_SCHEDULE_TYPES_LABELS[staff.currentSchedule.type]})
                        </span>
                    )}
                </Typography>
                {staff.currentSchedule ? (
                    <StaffSchedulePreview schedule={staff.currentSchedule} />
                ) : (
                    <Typography variant="body1" fontSize={14} fontWeight={400} color={palette.grey[900]}>
                        No set schedule
                    </Typography>
                )}
            </Stack>
        </SegmentedModal>
    );
};
