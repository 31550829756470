import { useCallback } from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/api/common';
import { CareLocationsResponse } from '~/types/assignments';

export const useGetCareLocations = (enabled = true) => {
    const token = useToken();
    const branchId = useBranchId();

    const queryFn = useCallback(async () => {
        const { data } = await api.get<CareLocationsResponse>('/care-location', {
            headers: { Authorization: token },
            params: { branch_id: branchId },
        });

        return data.response;
    }, [branchId, token]);

    return useErrorHandledQuery({
        queryKey: ['care-locations', branchId],
        queryFn,
        enabled: !!branchId && enabled,
        staleTime: 300000,
    });
};
