import { useCallback } from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/api/common';
import { GetWeekInfo } from '~/scheduling/api/types/community-settings/getWeekInfo';

export type GetWeekInfoResult = NonNullable<ReturnType<typeof useGetWeekInfo>['data']>;

export const useGetWeekInfo = () => {
    const branchId = useBranchId()!;
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = { headers: { Authorization: token } };
        const { data } = await api.get<GetWeekInfo.Response>(
            `/scheduling/${branchId}/community-settings/week-info`,
            options
        );

        return data.response;
    }, [branchId, token]);

    return useErrorHandledQuery({ queryKey: ['week-info', branchId], queryFn, staleTime: 300000 });
};
