import { styled, useMediaQuery, useTheme } from '@mui/material';
import { Bell } from '@phosphor-icons/react';
import React from 'react';

import { ChipContainer } from '~/pages/Home/components/HeaderV2/components/HeaderChip';

const UnseenCount = styled('div')(({ theme: { palette } }) => ({
    position: 'absolute',
    top: -5,
    right: -10,
    backgroundColor: palette.warning.light,
    color: palette.primary.main,
    borderRadius: '50%',
    padding: '6px',
    fontSize: '12px',
    fontWeight: 600,
    minWidth: '18px',
    height: '18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const CustomNotificationBell = ({ unseenCount, onPress }: { unseenCount?: number; onPress?: () => void }) => {
    const { breakpoints } = useTheme();
    const isDesktop = useMediaQuery(breakpoints.up('sm'));

    const shouldShowUnseenCount = unseenCount !== undefined && unseenCount > 0;

    return (
        <ChipContainer
            onClick={onPress}
            sx={{
                ...(shouldShowUnseenCount && { marginRight: '4px' }),
                borderRadius: '16px',
                height: '32px',
                minWidth: '32px',
                padding: { xs: '0px', sm: '0px 8px' },
                textWrap: 'nowrap',
                flex: 'unset',
            }}
        >
            {isDesktop ? 'Call Notifications' : ''}
            <Bell size={20} weight="bold" />
            {shouldShowUnseenCount && <UnseenCount>{unseenCount}</UnseenCount>}
        </ChipContainer>
    );
};

export default CustomNotificationBell;
