import { ComponentOverride } from '.';

export const MuiFormControl: ComponentOverride<'MuiFormControl'> = {
    defaultProps: {},
    styleOverrides: {
        root: {
            // Size medium
            '& .MuiSelect-select.MuiInputBase-input': {
                padding: '12px 40px 12px 16px !important',
            },

            // Size small
            '& .MuiInputBase-sizeSmall': {
                '& .MuiSelect-select.MuiInputBase-input': {
                    padding: '8px 40px 8px 16px !important',
                },
            },
        },
    },
};
