import { Typography } from '@mui/material';
import React from 'react';

import langDictionary from '~/app-strings';
import { CustomMobileDialog } from '~/components/Custom/CustomMobileDialog';

type Props = {
    isOpen: boolean;
    taskNote: string;
    onClose: () => void;
};

export const TaskNotesViewDialog = (props: Props) => {
    const { isOpen, taskNote, onClose } = props;

    const { taskNotes: taskNotesTxt } = langDictionary.home;

    return (
        <CustomMobileDialog
            closeable
            open={isOpen}
            title={taskNotesTxt}
            width="100%"
            onClose={onClose}
            content={
                <Typography
                    sx={{
                        textAlign: 'left',
                    }}
                    variant="body2"
                >
                    {taskNote}
                </Typography>
            }
        />
    );
};
