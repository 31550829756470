import { Box } from '@mui/system';
import { formatDistanceToNow, parseISO } from 'date-fns';
import React from 'react';

import { pxToRem } from '~/components/theme/typography';
import { RecentEvent } from '~/types/residents';

type Props = {
    events: RecentEvent[];
};

export const RecentEvents = ({ events }: Props) => {
    if (!events || events.length === 0) {
        return null;
    }

    return (
        <Box
            sx={{
                border: '1px solid #DEDEE0',
                borderRadius: pxToRem(8),
                backgroundColor: '#F8F8F9',
                fontSize: pxToRem(14),
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {events.map((event) => (
                <Box
                    sx={{
                        padding: pxToRem(12),
                        display: 'flex',
                        borderBottom: '1px solid #EFEFF1',
                        '&:last-child': {
                            borderBottom: 'none',
                        },
                    }}
                >
                    <Box
                        sx={{
                            flexGrow: 1,
                            color: '#CB7E63',
                            fontWeight: 500,
                        }}
                    >
                        {event.type}: {event.details}
                    </Box>
                    <Box
                        sx={{
                            color: '#6F6F79',
                        }}
                    >
                        {formatDistanceToNow(parseISO(event.date), {
                            addSuffix: true,
                        })}
                    </Box>
                </Box>
            ))}
        </Box>
    );
};
